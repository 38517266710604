import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getCandidateRegistration, requestCourseEnrolments } from "../../../core/store/reducers/admission.slice";
import "./print-reciept.style.less";

const PrintTransferReceipt = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const [courseRelData, setCourseRelData] = useState(null as any);
    const [amtRows, setAmtRows] = useState(null as any);
    const [oldData, setOldData] = useState(null as any);
    const [newData, setNewData] = useState(null as any);
    const [candidateData, setCandidateData] = useState(null as any);
    useEffect(() => {
        dispatch(requestCourseEnrolments({ body: { roll_no: params?.id }, params: { limit: 1, page: 1 } })).then((val: any) => {
            if (val?.payload?.data?.data?.[0]) {
                setOldData(val?.payload?.data?.data?.[0])
                let newRollno = val?.payload?.data?.data?.[0]?.remarks;
                newRollno = newRollno.split(" ");
                newRollno = newRollno[newRollno?.length - 1];
                const registration_number = val?.payload?.data?.data?.[0]?.candidate_id?.registration_number;
                dispatch(getCandidateRegistration({ query: registration_number })).then((val: any) => {
                    if (val?.payload?.data?.data) {
                        setCandidateData(val?.payload?.data?.data)
                    }
                })
                dispatch(requestCourseEnrolments({ body: { roll_no: newRollno }, params: { limit: 1, page: 1 } })).then((val: any) => {
                    if (val?.payload?.data?.data?.[0]) {
                        setNewData(val?.payload?.data?.data?.[0])
                    }
                })
            }
        })
    }, []);


    const Print = () => {
        //console.log('print');
        let printContents: any = document.getElementById("report-view")?.innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    };
    // console.log(candidate, "");

    return (
        <>
            {/* <Button onClick={Print}>Print</Button> */}
            <div className="report-view-container">
                <div className="report-view" id="report-view">
                    <div>
                        {/* <p style={{ color: "black", margin: "0", fontWeight: "bold", float: "right", padding: "5px", background: "#00ffff !important" }}>GSTIN - 21AAFCV2531H1ZQ</p> */}
                        <p style={{ textAlign: 'center', color: "black", margin: "0 0 10px 0", fontWeight: "bold", fontSize: '22px' }}>VANIK EDUCATION PVT LTD</p>
                        <p style={{ textAlign: 'center', color: "black", margin: "0 0 10px 0", fontWeight: "bold", fontSize: '18px' }}>TRANSFER RECEIPT </p>
                        <table cellSpacing={0} border={1} style={{ width: '100%', fontSize: '14px' }}>
                            <tbody>
                                <tr>
                                    <td style={{ padding: '10px 20px', fontWeight: "bold" }}>
                                        <table style={{ width: '100%' }}>
                                            <tr>
                                                <td style={{ width: '50%' }}>Registration Number</td><td>{candidateData?.registration_number}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '50%' }}>Student Name</td><td>{candidateData?.candidate_name}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '50%' }}>Email</td><td>{candidateData?.email}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '50%' }}>Phone</td><td>{candidateData?.contact}</td>
                                            </tr>
                                        </table>

                                        {/* <p style={{ color: "black" }}>Reverse Charge : &nbsp;&nbsp;<span>Nil</span></p>
                                        <p style={{ color: "black" }}>Invoice No. : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>
                                            {oldData?.invoice_number}
                                        </span></p>
                                        <p style={{ color: "black" }}>Invoice Date : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{moment(oldData?.actual_oldData_date).format("DD MMM YYYY")}</p>
                                        <p style={{ color: "black" }} className="capitalize">State :  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{courseRelData?.center_ref?.center_city_ref?.state}</p>
                                        <p style={{ color: "black", margin: "0" }}>State Code : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;21 </p> */}
                                    </td>
                                    <td style={{ padding: '3px 7px 1px 7px', fontWeight: "bold" }}>
                                        <p style={{ color: "black", margin: "0", fontWeight: "bold" }}>Student Photo :</p>
                                        <table border={0} cellSpacing={0} cellPadding={0}>
                                            <tbody>
                                                <tr>
                                                    <td style={{ padding: '3px 7px 1px 7px' }}>
                                                        <img
                                                            src={candidateData?.photo}
                                                            alt=""
                                                            width={136}
                                                            height={137}
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table cellSpacing={0} border={1} style={{ width: '100%', fontSize: '14px', fontWeight: 'bold' }}>
                            <tbody>
                                <tr>
                                    <td>
                                        <p
                                            style={{ color: "black", margin: "0" }}
                                            className="text-center">Old Batch Details</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table cellSpacing={0} border={1} style={{ width: '100%', fontSize: '14px' }}>
                            <tbody>
                                <tr>
                                    <td style={{ padding: '10px 20px', fontWeight: "bold" }}>
                                        <table style={{ width: '100%' }}>
                                            <tr>
                                                <td style={{ width: '50%' }}>Roll No</td><td className="b400">{oldData?.roll_no}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '50%' }}>Session</td><td className="b400">{oldData?.batch_id?.session_id?.session}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '50%' }}>Center</td><td className="b400">{oldData?.batch_id?.center_id?.center_name}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '50%' }}>Course</td><td className="b400">{oldData?.batch_id?.course_id?.course_name}</td>
                                            </tr>

                                        </table>
                                    </td>
                                    <td style={{ padding: '3px 7px 1px 7px', fontWeight: "bold" }}>
                                        <table style={{ width: '100%' }}>
                                            <tr>
                                                <td style={{ width: '50%' }}>Exam</td><td className="b400">{oldData?.batch_id?.exam_id?.exam_name}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '50%' }}>Batch Code</td><td className="b400">{oldData?.batch_id?.batch_code_id?.batch_code}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '50%' }}>Batch Type</td><td className="b400">{oldData?.batch_id?.batch_type_id?.batch_type_name}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '50%' }}>Batch St. Date</td><td className="b400">{oldData?.batch_id?.batch_time_id?.batch_time_from}  {oldData?.batch_id?.batch_time_id?.batch_time_to}</td>
                                            </tr>
                                            <tr>
                                                <td>Date of purchase</td><td className="b400">{moment(oldData?.created_on).format("DD MMM YYYY")}</td>
                                            </tr>
                                        </table>
                                        {/* <p style={{ color: "black" }}>Place of delivery : &nbsp;&nbsp;&nbsp;{oldData?.candidate_id?.present_address?.present_city}</p>
                                        <p style={{ color: "black" }}>Date of supply : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{moment(oldData?.actual_oldData_date).format("DD MMM YYYY")}</p>
                                        <p style={{ color: "black" }}>Place of supply : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{oldData?.batch_id?.center_id?.center_city}</p> */}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table cellSpacing={0} border={1} style={{ width: '100%', fontSize: '14px', fontWeight: 'bold' }}>
                            <tbody>
                                <tr>
                                    <td>
                                        <p
                                            style={{ color: "black", margin: "0" }}
                                            className="text-center">New Batch Details</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table cellSpacing={0} border={1} style={{ width: '100%', fontSize: '14px' }}>
                            <tbody>
                                <tr>
                                    <td style={{ padding: '10px 20px', fontWeight: "bold" }}>
                                        <table style={{ width: '100%' }}>
                                            <tr>
                                                <td style={{ width: '50%' }}>Roll No</td><td className="b400">{newData?.roll_no}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '50%' }}>Session</td><td className="b400">{newData?.batch_id?.session_id?.session}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '50%' }}>Center</td><td className="b400">{newData?.batch_id?.center_id?.center_name}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '50%' }}>Course</td><td className="b400">{newData?.batch_id?.course_id?.course_name}</td>
                                            </tr>

                                        </table>
                                    </td>
                                    <td style={{ padding: '3px 7px 1px 7px', fontWeight: "bold" }}>
                                        <table style={{ width: '100%' }}>
                                            <tr>
                                                <td style={{ width: '50%' }}>Exam</td><td className="b400">{newData?.batch_id?.exam_id?.exam_name}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '50%' }}>Batch Code</td><td className="b400">{newData?.batch_id?.batch_code_id?.batch_code}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '50%' }}>Batch Type</td><td className="b400">{newData?.batch_id?.batch_type_id?.batch_type_name}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '50%' }}>Batch St. Date</td><td className="b400">{newData?.batch_id?.batch_time_id?.batch_time_from}  {newData?.batch_id?.batch_time_id?.batch_time_to}</td>
                                            </tr>
                                            <tr>
                                                <td>Date of purchase</td><td className="b400">{moment(newData?.created_on).format("DD MMM YYYY")}</td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PrintTransferReceipt;
