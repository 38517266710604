import { Col, Pagination, Row, Table } from "antd";
import React from "react";
import { TABLE_ROWS_COUNT } from "../../constants";
type Props = {
  loading: boolean;
  dataSource: any[];
  columns: any[];
  maxWidth?: string;
  title: string;
  actions?: Array<{ icon: JSX.Element; action: () => void; tooltip: string }>;
  counts?: Array<{ title: string; count: JSX.Element }>;
  defaultPage: number;
  currentPage: number;
  totalRows: number;
  rowSelection?: any;
  onChange?: any;
  onPageChange: (e: any) => void;
};
const DataTableComponent: React.FC<Props> = ({
  loading,
  dataSource,
  columns,
  title,
  maxWidth = "1000px",
  counts,
  actions,
  defaultPage,
  totalRows,
  onPageChange,
  currentPage,
  rowSelection,
  onChange,
}: Props) => {

  return (
    <>
      <div className="mx-6 bg-primary-white-blueish border-primary-gray-02">
        {/* <Row align="middle" className="border-bottom-primary-gray-02">
          <Col flex="auto" className="pt-3 pb-3">
            <span className="font-size-18 pl-6">{title}</span>
          </Col>
          <Col className="pr-4">
            <Space>
              {actions?.map(
                (
                  {
                    icon,
                    action,
                    tooltip,
                  }: { icon: JSX.Element; action: () => void; tooltip: string },
                  index: number
                ) => {
                  return (
                    <Tooltip title={tooltip} key={index}>
                      <Button
                        size="large"
                        icon={icon}
                        onClick={action}
                      ></Button>
                    </Tooltip>
                  );
                }
              )}
            </Space>
          </Col>
        </Row>
        <Row>
          <Col span={24} className="pt-3 pb-3 border-bottom-primary-gray-02">
            <Row>
              {counts.map(
                (
                  { title, count }: { title: string; count: JSX.Element },
                  index: number
                ) => {
                  return (
                    <Col key={index}>
                      <Row>
                        <Col className="pl-6 font-size-12" key={index + 1}>
                          {title}
                        </Col>
                        <Col className="pl-4" key={index + 2}>
                          {count}
                        </Col>
                      </Row>
                    </Col>
                  );
                }
              )}
            </Row>
          </Col>
        </Row> */}
        <Table
          className="app-table"
          tableLayout="auto"
          pagination={false}
          loading={loading}
          scroll={{ x: maxWidth, y: 'calc(100vh - 200px)' }}
          dataSource={dataSource}
          columns={columns}
          rowSelection={rowSelection}
          onChange={onChange}
          size="small"
        // locale={{
        //   emptyText: (
        //     <div>
        //       <div className="no-data">No data Found</div>
        //     </div>
        //   ),
        // }}
        />

        <Row justify="end">
          <Col className="bg-primary-white-bluish pt-3 pb-3 pr-6">
            <Pagination
              showTotal={(total, range) => {
                console.log(total, range)
                return total > 0
                  ? `${range[0]}-${range[1]} of ${total} Total`
                  : "";
              }}
              responsive={true}
              showSizeChanger={false}
              defaultCurrent={defaultPage ? defaultPage : currentPage}
              current={currentPage}
              total={totalRows}
              defaultPageSize={TABLE_ROWS_COUNT}
              onChange={onPageChange}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};
export default DataTableComponent;
