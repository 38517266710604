import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  API_ALL_EXAM_CATEGORY,
  API_CREATE_QUIZ,
  API_GET_ALL_CATEGORIES_AND_EXAMS,
  API_GET_DESCRIPTION_BY_QUIZ_ID,
  API_QUESTION_DETAILS_BY_ID,
  API_QUIZ_DETAILS_BY_ID,
  API_SAVE_NEW_DESCRIPTION,
  API_UPDATE_QUESTION_BY_ID,
  API_UPDATE_QUIZ,
  API_WEBSITE_CONTENT_SUB_CAT_BY_CAT_ID,
  API_WEBSITE_GENERIC_CREATE,
  API_WEBSITE_GENERIC_LIST_MINIMAL,
  API_WEBSITE_GENERIC_LIST_W_PAGE,
  API_WEBSITE_GENERIC_UPDATE,
  API_WEBSITE_SUB_EXAM_BY_EXAM,
  API_WEBSITE_SUB_EXAM_BY_EXAM_MULTI,
} from "../../../shared/routes/website.routes";
import { User } from "../../../shared/types/user-management";
import { Client } from "../../api-client";
const config = {
  name: "userManagement",
};
export const getGenericList: any = createAsyncThunk(
  `${config.name}/getGenericList`,
  async (payload: any) => {
    let params = payload.params;
    return Client.post(
      API_WEBSITE_GENERIC_LIST_W_PAGE(payload.module),
      payload.body,
      { params }
    );
  }
);
export const getGenericListMinimal: any = createAsyncThunk(
  `${config.name}/getGenericListMinimal`,
  async (payload: any) => {
    return Client.get(API_WEBSITE_GENERIC_LIST_MINIMAL(payload.module), {
      params: payload.params,
    });
  }
);

export const genericWebsiteCreate: any = createAsyncThunk(
  `${config.name}/genericWebsiteCreate`,
  async ({ payload, module }: any) => {
    return Client.post(API_WEBSITE_GENERIC_CREATE(module), payload);
  }
);
export const genericWebsiteUpdate: any = createAsyncThunk(
  `${config.name}/genericWebsiteUpdate`,
  async ({ payload, module }: any) => {
    return Client.put(
      API_WEBSITE_GENERIC_UPDATE(module, payload?._id),
      payload
    );
  }
);

export const genericWebsiteExamCategories: any = createAsyncThunk(
  `${config.name}/genericWebsiteExamCategories`,
  async () => {
    return Client.get(API_ALL_EXAM_CATEGORY);
  }
);

export const genericWebsiteModuleData: any = createAsyncThunk(
  `${config.name}/genericWebsiteModuleData`,
  async () => {
    return Client.get(API_ALL_EXAM_CATEGORY);
  }
);

export const getContentSubCategoriesByContentCategoryId: any = createAsyncThunk(
  `${config.name}/getContentSubCategoriesByContentCategoryId`,
  async (id: any) => {
    return Client.get(API_WEBSITE_CONTENT_SUB_CAT_BY_CAT_ID(id));
  }
);

export const createQuiz: any = createAsyncThunk(
  `${config.name}/createQuiz`,
  async (payload: any) => {
    return Client.post(API_CREATE_QUIZ, payload);
  }
);
export const updateQuiz: any = createAsyncThunk(
  `${config.name}/updateQuiz`,
  async ({ payload, id }: any) => {
    return Client.patch(API_UPDATE_QUIZ(id), payload);
  }
);
export const getQuizDetails: any = createAsyncThunk(
  `${config.name}/getQuizDetails`,
  async (id: any) => {
    return Client.get(API_QUIZ_DETAILS_BY_ID(id));
  }
);
export const getQuestionDetails: any = createAsyncThunk(
  `${config.name}/getQuestionDetails`,
  async (id: any) => {
    return Client.get(API_QUESTION_DETAILS_BY_ID(id));
  }
);

export const getAllDescriptionByQuiz: any = createAsyncThunk(
  `${config.name}/getAllDescriptionByQuiz`,
  async (id: any) => {
    return Client.get(API_GET_DESCRIPTION_BY_QUIZ_ID(id));
  }
);

export const getAllExamsAndCategories: any = createAsyncThunk(
  `${config.name}/getAllExamsAndCategories`,
  async (id: any) => {
    return Client.get(API_GET_ALL_CATEGORIES_AND_EXAMS);
  }
);
export const getAllSubExamsByExam: any = createAsyncThunk(
  `${config.name}/getAllSubExamsByExam`,
  async (id: any) => {
    return Client.get(API_WEBSITE_SUB_EXAM_BY_EXAM(id));
  }
);
export const getAllSubExamsByExamMulti: any = createAsyncThunk(
  `${config.name}/getAllSubExamsByExamMulti`,
  async (payload: any) => {
    return Client.post(API_WEBSITE_SUB_EXAM_BY_EXAM_MULTI, payload);
  }
);
export const updateQuestion: any = createAsyncThunk(
  `${config.name}/updateQuestion`,
  async ({ id, payload }: any) => {
    return Client.post(API_UPDATE_QUESTION_BY_ID(id), payload);
  }
);
export const saveNewDescription: any = createAsyncThunk(
  `${config.name}/saveNewDescription`,
  async (payload: any) => {
    return Client.post(API_SAVE_NEW_DESCRIPTION, payload);
  }
);

export const websiteManagementReducer = createSlice({
  name: config.name,
  initialState: {
    loading: false,
    genericList: [] as any,
    total: 0 as number,
    filters: [] as any,
    examList: [] as any,
  },
  reducers: {
    resetWebsiteData: (state) => {},
    setFilters: (state, action) => {
      state.filters = action?.payload;
    },
    resetFilters: (state) => {
      state.filters = [] as any;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getGenericList.pending, (state) => {
        state.loading = true;
        state.genericList = [] as Array<User>;
      })
      .addCase(getGenericList.fulfilled, (state, action) => {
        state.loading = false;
        state.genericList = action?.payload?.data?.data;
        state.total = action?.payload?.data?.total;
      })
      .addCase(getGenericList.rejected, (state) => {
        state.loading = false;
        state.genericList = [] as Array<User>;
      });

    builder
      .addCase(genericWebsiteExamCategories.pending, (state) => {
        state.loading = true;
        state.examList = [] as Array<User>;
      })
      .addCase(genericWebsiteExamCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.examList = action?.payload?.data?.data;
        state.total = action?.payload?.data?.total;
      })
      .addCase(genericWebsiteExamCategories.rejected, (state) => {
        state.loading = false;
        state.examList = [] as Array<User>;
      });
  },
});

export const { resetWebsiteData, resetFilters, setFilters } =
  websiteManagementReducer.actions;
export default websiteManagementReducer.reducer;
