import { Col, Descriptions, Row, Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { requestInstalmentsByEnrol, requestPaymentsByEnrol } from "../../../core/store/reducers/admission.slice";
import { RootState } from "../../../shared/types/common";


export function EnrolmentDetails({ enrolmentDetails }: any) {
    const dispatch = useDispatch()
    const { user } = useSelector((state: RootState) => state.auth);
    const [allInstalments, setAllInstalments] = useState([] as any);
    const [paymentEntries, setPaymentEntries] = useState([] as any);
    useEffect(() => {
        // dispatch(requestCourseEnrolments({ params: { offset: 0, limit: 10 }, body: { roll_no } }));
        dispatch(requestInstalmentsByEnrol(enrolmentDetails?._id)).then((val: any) => {
            if (val?.payload?.data?.success) {
                setAllInstalments(val?.payload?.data?.data?.map((data: any, index: number) => {
                    return {
                        ...data, installment_no: index + 1
                    }
                }))
            }
        })
        dispatch(requestPaymentsByEnrol(enrolmentDetails?._id)).then((val: any) => {
            if (val?.payload?.data?.success) {
                setPaymentEntries(val?.payload?.data?.data?.map((data: any, index: number) => {
                    return {
                        ...data, installment_no: index + 1
                    }
                }))
            }
        })
    }, [enrolmentDetails])
    const installmentColumns = [
        {
            title: "Installment No.",
            dataIndex: "installment_no",
            key: "installment_no",
        },
        {
            title: "Ins. Admission Amount",
            dataIndex: "admission_amount",
            key: "admission_amt",
        },
        {
            title: "Ins. Tuition Amount",
            dataIndex: "tuition_amount",
            key: "tuition_amt",
        },
        {
            title: "Ins. Book Amount",
            dataIndex: "books_amount",
            key: "book_amt",
            // className: "capitalize",
        },
        {
            title: "Ins. Charges",
            key: "ins_charges",
            render: (rec: any) => rec.net_instalment_amount - rec.admission_amount - rec.tuition_amount - rec.books_amount
            // className: "capitalize",
        },
        {
            title: "Ins. Amount",
            dataIndex: "net_instalment_amount",
            key: "net_amt",
            // className: "capitalize",
        },
        {
            title: "Paid",
            dataIndex: "realized",
            key: "net_amt",
            render: (a: any) => a ? 'Yes' : 'No'
            // className: "capitalize",
        },
        {
            title: "Installment Date",
            dataIndex: "prefered_instalment_date",
            key: "prefered_instalment_date",
            render: (key: any) => moment(key).format("Do-MMM-YYYY"),
        },
    ];


    const payColumns = [
        {
            title: "Invoice Number",
            dataIndex: "invoice_number",
            key: "invoice_number",
        },
        {
            title: "Payment Mode",
            dataIndex: "payment_mode",
            key: "payment_mode",
        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
        },
        {
            title: "Payment Type",
            dataIndex: "payment_type",
            key: "payment_type",
            className: "capitalize",
        },
        {
            title: "Cheque/DD/Pay Date",
            dataIndex: "actual_payment_date",
            key: "actual_payment_date",
            render: (dt: any) => dt ? moment(dt).format("DD MMM, YYYY") : ''
        },
        {
            title: "EDC Charges",
            dataIndex: "edc_charges",
            key: "edc_charges",
        },
        {
            title: "QR Charges",
            dataIndex: "qr_charges",
            key: "qr_charges",
        },
        {
            title: "Total Amount",
            dataIndex: "total_amount",
            key: "total_amount",
        },
        {
            title: "Ins. Charges",
            dataIndex: "instalment_charges",
            key: "instalment_charges",
        },
        {
            title: "In Favor Of",
            dataIndex: "in_favor_of",
            key: "in_favor_of",
        },
        {
            title: "Bank Name",
            dataIndex: "bank_name",
            key: "bank_name",
        },
        {
            title: "Branch Name",
            dataIndex: "branch_name",
            key: "branch_name",
        },
        {
            title: "Trans / DD / Cheque No",
            dataIndex: "ref_no",
            key: "ref_no",
        },
        {
            title: "Remarks",
            dataIndex: "remarks",
            key: "remarks",
        },
        {
            title: "Special Relaxation Details",
            key: "ref_no",
            render: (rec: any) => <>
                Rs. {rec?.special_discount} on {rec?.special_discount_applicable_on}<br /><em>{rec?.special_discount_remarks}</em>
            </>
        },
        {
            title: "Is Book Fee",
            dataIndex: "is_book_fee",
            key: "is_book_fee",
            render: (a: any) => {
                return a ? "Yes" : "No";
            },
        }
    ];
    let candidateData = enrolmentDetails?.candidate_id as any
    let batchDetails = {} as any
    let feeDetails = enrolmentDetails as any
    // let allInstalments = [] as any
    // let paymentEntries = [] as any
    // console.log(feeDetails)
    return (<>
        <Row gutter={10}>
            <Col span={24}>
                <Descriptions title="Student Details" className="table-description">
                    <Descriptions.Item label="Registration Number">{candidateData?.registration_number}</Descriptions.Item>
                    <Descriptions.Item label="Roll No">{enrolmentDetails?.roll_no}</Descriptions.Item>
                    <Descriptions.Item label="Candidate Name">{candidateData?.candidate_name}</Descriptions.Item>
                    <Descriptions.Item label="Contact">{candidateData?.contact}</Descriptions.Item>
                    <Descriptions.Item label="Email">{candidateData?.email}</Descriptions.Item>
                </Descriptions>
            </Col>
        </Row>
        <Row gutter={10} className="mt-4">
            <Col span={24}>
                <Descriptions title="Batch Details" className="table-description">
                    <Descriptions.Item label="Session">{enrolmentDetails?.batch_id?.session_id?.session}</Descriptions.Item>
                    <Descriptions.Item label="Center">{enrolmentDetails?.batch_id?.center_id?.center_name}</Descriptions.Item>
                    <Descriptions.Item label="Course">{enrolmentDetails?.batch_id?.course_id?.course_name}</Descriptions.Item>
                    <Descriptions.Item label="Batch Type">{enrolmentDetails?.batch_id?.batch_type_id?.batch_type_name}</Descriptions.Item>
                    <Descriptions.Item label="Exam">{enrolmentDetails?.batch_id?.exam_id?.exam_name}</Descriptions.Item>
                    <Descriptions.Item label="Stream">{enrolmentDetails?.batch_id?.stream_id?.stream_name}</Descriptions.Item>
                    <Descriptions.Item label="Batch">{enrolmentDetails?.batch_id?.batch_code_id?.batch_code}</Descriptions.Item>
                    {enrolmentDetails?.transferred && <Descriptions.Item className="transferbg" label="Transfer Status">{enrolmentDetails?.remarks}</Descriptions.Item>}
                </Descriptions>
            </Col>
        </Row>
        <Descriptions title="Course Fee Details" className="table-description mt-4">
            <Descriptions.Item label="Total No of Installments">
                {feeDetails?.n_instalments}
            </Descriptions.Item>
            <Descriptions.Item label="Payment Info">
                Instalment
            </Descriptions.Item>
            <Descriptions.Item label="Installment charges">
                {feeDetails?.instalment_discount && feeDetails?.instalment_discount != 0 ? <><span className="line-through">Rs. {feeDetails.instalment_fee}</span>&nbsp; &nbsp;Rs. {(feeDetails.instalment_fee - feeDetails.instalment_discount)}</> : <>Rs. {feeDetails.instalment_fee}</>}
            </Descriptions.Item>
            <Descriptions.Item label="Special Discount"> {feeDetails?.tuition_discount ? 'Rs. ' + feeDetails?.tuition_discount : 'NA'} </Descriptions.Item>
            <Descriptions.Item label="Total Fee" span={3}>
                {" "}
                {/* Rs {feeDetails?.total_fee} */}
                {feeDetails?.total_discount && feeDetails?.total_discount != 0 ? <><span className="line-through">Rs. {feeDetails.total_fee}</span>&nbsp; &nbsp;Rs. {(feeDetails.total_fee - feeDetails.total_discount)}</> : <>Rs. {feeDetails.total_fee}</>}
            </Descriptions.Item>
            <Descriptions.Item label="Admission fee">
                {" "}
                {/* Rs {feeDetails?.admission_fee} */}
                {feeDetails?.admission_fee_discount && feeDetails?.admission_fee_discount != 0 ? <><span className="line-through">Rs. {feeDetails.admission_fee}</span>&nbsp; &nbsp;Rs. {(feeDetails.admission_fee - feeDetails.admission_fee_discount)}</> : <>Rs. {feeDetails.admission_fee}</>}
            </Descriptions.Item>
            <Descriptions.Item label="Tuition Fee">
                {" "}
                {feeDetails?.tuition_discount && feeDetails?.tuition_discount != 0 ? <><span className="line-through">Rs. {feeDetails.tuition_discount}</span>&nbsp; &nbsp;Rs. {(feeDetails.tution_fee - feeDetails.tuition_discount)}</> : <>Rs. {feeDetails.tution_fee}</>}
            </Descriptions.Item>
            <Descriptions.Item label="Book fee">
                {feeDetails?.books_fee_discount && feeDetails?.books_fee_discount != 0 ? <><span className="line-through">Rs. {feeDetails.book_fee}</span>&nbsp; &nbsp;Rs. {(feeDetails.book_fee - feeDetails.books_fee_discount)}</> : <>Rs. {feeDetails.book_fee}</>}
            </Descriptions.Item>
        </Descriptions>
        <Descriptions title="Instalment Details" className="mt-4"></Descriptions>
        <Col span={24} className={'mt-3'}>
            <Table
                loading={false}
                dataSource={allInstalments}
                columns={installmentColumns}
                size="small"
                pagination={false}
            />
        </Col>


        <Descriptions title="Payment Details" className="mt-4"></Descriptions>
        <Table
            loading={false}
            dataSource={paymentEntries}
            columns={payColumns}
            size="small"
            scroll={{ x: '2000px' }}
            pagination={false}
        />
        <p></p>





    </>
    )
}