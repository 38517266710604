import {
    EditFilled
} from "@ant-design/icons";
import { Button, Table, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getQuizDetails } from "../../../core/store/reducers/website.slice";
import { RootState } from "../../../shared/types/common";
import CreateEditQuestionComponent from "./question-edit.component";
//   import CreateEditQuizComponent from "./create-edit-quiz.component";

function QuestionsListComponent({ quizData, onClose }: any) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // List states
    const { screenType } = useSelector((state: RootState) => state.settings);
    const { user } = useSelector((state: RootState) => state.auth);

    // List local state
    const [entityListData, setEntityListData] = useState([] as any);

    // Flag to open create/edit form
    const [createEditOpen, setCreateEditOpen] = useState(false);
    const [quizDetailsOpen, setQuizDetailsOpen] = useState(false);
    const [selectedEntity, setSelectedEntity] = useState(null as any);
    const [filterVisible, setFilterVisible] = useState(false);

    // useEffect Hooks
    useEffect(() => {
        getQuizData()
    }, []);
    function getQuizData() {
        const quizId = quizData._id;
        dispatch(getQuizDetails(quizId)).then((val: any) => {
            setEntityListData(val?.payload?.data?.data?.questions)
        })
    }

    const editEntityListner = (entity: any) => {
        setSelectedEntity(entity);
        setCreateEditOpen(true);
    };
    const columns = [
        {
            title: "S. No.",
            sorter: true,
            key: "sno",
            dataIndex: "sno",
        },
        {
            title: "Section",
            sorter: true,
            key: "section",
            dataIndex: ["q_id", "section"],
        },
        {
            title: "Question",
            dataIndex: ["q_id", "qu", "0"],
            sorter: true,
            key: "quiz_name",
            render: (data: any) => {
                return <div dangerouslySetInnerHTML={{ __html: data.replace(/(<([^>]+)>)/ig, '') }} />
            }
        },
        {
            title: "Marks",
            dataIndex: ["q_id", "plus_mark"],
            sorter: true,
            key: "plus_mark",
        },
        {
            title: "Negative Marks",
            dataIndex: ["q_id", "minus_mark"],
            sorter: true,
            key: "minus_mark",
        },
        {
            title: "Actions",
            width: "160px",
            float: 'right',
            render: (a: any) => {
                return (
                    <>
                        <Tooltip title={"Mark Inactive"}>
                            <Button
                                onClick={() => editEntityListner(a)}
                                type="link"
                                className="table-button"
                                icon={<EditFilled />}
                            ></Button>
                        </Tooltip>
                    </>
                )
            },
            key: "actions",
        },
    ];


    const createEditSubmit = () => {
        setCreateEditOpen(false);
        setSelectedEntity(null)
        getQuizData()
    };


    return (
        <>
            <Table
                dataSource={entityListData}
                columns={columns}
                tableLayout="auto"
            // scroll={{ x: 2000 }}
            />
            {createEditOpen && (
                <CreateEditQuestionComponent
                    onClose={() => setCreateEditOpen(false)}
                    visible={createEditOpen}
                    selectedQuestion={selectedEntity}
                    quizData={quizData}
                    questionList={entityListData}
                    onSubmit={createEditSubmit}
                />
            )}

        </>
    );
};
export default QuestionsListComponent;
