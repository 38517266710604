import { EditOutlined } from "@ant-design/icons";
import { Button, Col, Descriptions, Divider, Drawer, Popconfirm, Row, Space, Steps, Tabs, Tooltip, notification } from "antd";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { BiCartAdd, BiCartAlt, BiFilterAlt, BiKey, BiLogInCircle, BiRefresh } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { requestCandidateList, setFilters } from "../../../core/store/reducers/admission.slice";
import { getFeeStructure } from "../../../core/store/reducers/masters.slice";
import { createStudentLoginCreds, resetPasswordStudentByAdmin } from "../../../core/store/reducers/user-management.slice";
import ContentHeaderComponent from "../../../shared/components/content-header/content-header.component";
import DataTableComponent from "../../../shared/components/data-table/data-table.component";
import GenericFormComponent from "../../../shared/components/generic-form/generic-form.component";
import { TABLE_ROWS_COUNT } from "../../../shared/constants";
import { RootState } from "../../../shared/types/common";
import { formatDate } from "../../../shared/utils/utils";
import AdmissionForm from "../new-admission/index";
import CandidateCard from "./candidate-card";
import CandidateCardNoBatch from "./candidate-card-no-batch";
import CourseCard from "./course-card";
import CourseCardCreate from "./course-card-create";
import ManualCourseAllotment from "./manual-course-allotment";


function CandidatesList() {
    const { user } = useSelector((state: RootState) => state.auth);

    const [paymentModal, setPaymentModal] = useState(false)
    const [courseAllotmentModal, setCourseAllotmentModal] = useState(false)
    const [candidateData, setCandidateData] = useState([]) as any
    const [courseData, setCourseData] = useState([])
    const [feeData, setFeeData] = useState([])
    const [step, setStep] = useState(1)
    const [filterVisible, setFilterVisible] = useState(false);
    const [candidateList, setCandidateList] = useState([] as any);
    const dispatch = useDispatch();
    const { candidates, loadingCandidates, filters, total } = useSelector((state: RootState) => state.admission);
    // Actions and selections
    const [sorter, setSorter] = useState({ sort_by: "", sort_type: "" });
    const [paidType, setPaidType] = useState('unpaid' as any);
    const [pagination, setPagination] = useState({ page: 1, limit: TABLE_ROWS_COUNT });
    const [editDetails, setEditDetailsModal] = useState(false)

    // useEffect(() => {
    //     dispatch(requestCandidateList());
    // }, [])

    const loadList = useCallback(
        (_page: any = 1) => {
            const data = {
                ...pagination,
                ...sorter,
                paid_type: paidType
            };
            dispatch(requestCandidateList({ params: data, body: filters }));
        },
        [filters, dispatch, sorter, pagination, paidType]
    );
    useEffect(() => {
        loadList();
    }, [loadList]);

    useEffect(() => {
        // if (candidates?.length > 0) {
        setCandidateList(
            candidates.map((obj: any, index: number) => {
                return { key: index, ...obj };
            })
        );
        // }
    }, [candidates]);
    const onPageChange = (pageNumber: number) => {
        setPagination({
            page: pageNumber,
            limit: pagination.limit,
        });
    };

    const getPaymentDetails = (values: any) => {
        dispatch(getFeeStructure({ ...values })).then((val: any) => {
            if (val?.payload?.data?.success) {
                setFeeData(val?.payload?.data?.data)
                setCourseData(values)
                setStep(2)
            }
        });
    }
    const generateStudentLoginCreds = (a: any) => {
        dispatch(createStudentLoginCreds({
            registration_id: a._id,
            name: a.candidate_name,
            user_name: a.email,
            contact: a.contact,
            password: a.contact,
            created_by: user._id,
            modified_by: user._id,
        })).then((val: any) => {
            if (val?.payload?.data?.success) {
                notification.success({ message: 'Login credentials created successfully. User ID is email and default password is mobile number.', duration: 10000 })
                loadList()
            }
        })
    }
    const resetPasswordAdmin = (a: any) => {
        dispatch(resetPasswordStudentByAdmin({
            user_name: a.email,
            password: a.contact
        })).then((val: any) => {
            if (val?.payload?.data?.success) {
                notification.success({ message: "Password reset successfully to Phone Number - " + a.contact, duration: 20000 })
            }
        })
    }

    const columns = [
        {
            title: 'System ID',
            dataIndex: '_id',
            key: '_id',
        },
        {
            title: 'Reg. No.',
            dataIndex: 'registration_number',
            key: 'registration_number',
        },
        {
            title: 'Name',
            dataIndex: 'candidate_name',
            key: 'candidate_name',
        },
        {
            title: 'DOB',
            dataIndex: 'dob',
            key: 'dob',
            render: (age: any) => formatDate(age, "MMM DD, Y")
        },
        {
            title: 'Email ID',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            key: 'gender',
            className: 'capitalize'
        },
        {
            title: 'Contact',
            dataIndex: 'contact',
            key: 'contact',
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
        },
        {
            title: "Created Date",
            dataIndex: "created_at",
            sorter: true,
            key: "created_at",
            render: (a: any) => {
                return a ? moment(a).format("MMM DD, Y LT") : "";
            },
        },
        {
            title: "Updated Date",
            dataIndex: "updated_at",
            sorter: true,
            key: "updated_at",
            render: (a: any) => {
                return a ? moment(a).format("MMM DD, Y LT") : "";
            },
        },
        {
            title: 'Action',
            fixed: 'right',
            render: (a: any) => {
                return (
                    <>
                        <Tooltip title={"Edit"}>
                            <Button
                                type="link"
                                className="table-button"
                                icon={<EditOutlined />}
                                onClick={() => {
                                    setCandidateData(a)
                                    setEditDetailsModal(true)
                                }}
                            ></Button>
                        </Tooltip>
                        {/* <Tooltip title={"Details"}>
                            <Button
                                // onClick={() => navigate("/enquiry-details/" + a._id)}
                                type="link"
                                className="table-button"
                                icon={<ArrowRightOutlined />}
                            ></Button>
                        </Tooltip> */}
                        <Tooltip title={"Add Classroom Course"}>
                            <Button
                                onClick={() => {
                                    setCandidateData(a)
                                    setPaymentModal(true)
                                }}
                                type="link"
                                className="table-button"
                                icon={<BiCartAdd />}
                            ></Button>
                        </Tooltip>
                        {paidType == 'paid' && <Tooltip title={"Renew Classroom Course"}>
                            <Button
                                onClick={() => {
                                    setCandidateData(a)
                                    setPaymentModal(true)
                                }}
                                type="link"
                                className="table-button"
                                icon={<BiRefresh />}
                            ></Button>
                        </Tooltip>}
                        {a?.u_id && user?.user_role !== "counsellor" && <Tooltip title={"Add Other Courses"}>
                            <Button
                                onClick={() => {
                                    setCandidateData(a)
                                    setCourseAllotmentModal(true)
                                }}
                                type="link"
                                className="table-button"
                                icon={<BiCartAlt />}
                            ></Button>
                        </Tooltip>}
                        {!a?.u_id && <Popconfirm
                            title={`Are you sure to generate login ID for this user? Username: ${a.email}, Password: ${a.contact}`}
                            onConfirm={() => {
                                generateStudentLoginCreds(a)
                            }}
                            onCancel={() => { }}
                            okText="Yes"
                            cancelText="No"
                        ><Tooltip title={"Generate Login ID"}>
                                <Button
                                    type="link"
                                    className="table-button"
                                    icon={<BiLogInCircle />}
                                ></Button>
                            </Tooltip></Popconfirm>}
                        {a?.u_id && <Popconfirm
                            title={`Are you sure to reset password for this user to phone number ${a.contact}?`}
                            onConfirm={() => {
                                resetPasswordAdmin(a)
                            }}
                            onCancel={() => { }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Tooltip title={"Reset Password to Phone Number"}>
                                <Button
                                    type="link"
                                    className="table-button"
                                    icon={<BiKey />}
                                ></Button>
                            </Tooltip>
                        </Popconfirm>}
                    </>
                );
            },
            key: "actions",
        },
    ];
    const headerButtons: any = (
        <Space>
            <Button
                shape="circle"
                type="primary"
                icon={
                    <span role="img" className="anticon">
                        <BiFilterAlt />
                    </span>
                }
                onClick={() => {
                    setFilterVisible(!filterVisible);
                }}
            ></Button>
        </Space>
    );
    const onChangeTab = (key: string) => {
        setPaidType(key)
    }
    const onFilterApply = (values: any) => {
        let arrFilter = [];
        for (let [key, value] of Object.entries(values)) {
            if (value !== "") {
                arrFilter.push({ key: key, value: value });
            }
        }
        let refinedArray = arrFilter.filter((data) => {
            return data.value !== undefined;
        });
        let obj = {} as any;
        refinedArray.forEach((data: any) => {
            obj[data.key] = data.value;
        });
        setPagination({
            page: 1,
            limit: pagination.limit,
        });
        dispatch(setFilters(obj)); //filters
    };
    const formProps = {
        name: "candidate-filters",
        enableEnterSubmit: true,
        formElements: [
            {
                name: "candidate_name",
                label: "",
                placeholder: "Candidate Name Search",
                type: "Input",
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
            },
            {
                name: "contact",
                label: "",
                placeholder: "Contact Number",
                type: "InputNumber",
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
            },
            {
                name: "email",
                label: "",
                placeholder: "Email",
                type: "Input",
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
            },
            {
                name: "category",
                label: "",
                placeholder: "Category",
                type: "Input",
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
            },
        ],
        initialValues: {},
        extraButtons: [
            {
                htmlType: "submit",
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 3 },
                loading: false,
                submitButtonText: "Clear",
                type: "clearForm",
            },
            {
                htmlType: "submit",
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 3 },
                loading: false,
                submitButtonText: "Apply",
                submitButtonType: "primary",
            },
        ],
        onFinish: onFilterApply,
        onFinishFailed: null,
        onValuesChange: null,
    };
    const onChangeHandler = (_pagination: any, _filters: any, _sorter: any) => {
        if (_sorter.order === undefined) {
            setSorter({ sort_by: "", sort_type: "" });
        } else {
            let order = "";
            let columnKey = "";
            if (_sorter.order === "ascend") order = "asc";
            else order = "desc";

            if (_sorter.columnKey === "enabled") {
                columnKey = "status";
            } else if (_sorter.columnKey === "displayName") {
                columnKey = "name";
            } else {
                columnKey = _sorter.columnKey;
            }
            setSorter({ sort_by: columnKey, sort_type: order });
        }
    };

    return <>
        <div
            className="mx-6 mt-2 bg-primary-white-blueish border-primary-gray-02"
            style={{ borderBottom: "none!important" }}
        >
            <Row>
                <Col span={24}>
                    <ContentHeaderComponent
                        title="Manage Candidates"
                        subtitle=""
                        buttons={headerButtons}
                    />
                </Col>
            </Row>

            {filterVisible && (
                <Row className="bg-body">
                    <Col span={24} className="py-1">
                        <div className="px-3 table-filter-form">
                            <GenericFormComponent {...formProps} />
                        </div>
                    </Col>
                </Row>
            )}
            <Row>
                <Col span={24}>
                    <Tabs className="table-status-tabs mx-3" defaultActiveKey="unpaid" onChange={(onChangeTab)}>
                        <Tabs.TabPane tab="Unpaid" key="unpaid"></Tabs.TabPane>
                        <Tabs.TabPane tab="Paid" key="paid"></Tabs.TabPane>
                        <Tabs.TabPane tab="Website Only" key="website"></Tabs.TabPane>
                    </Tabs>
                </Col>
            </Row>
        </div>

        <DataTableComponent
            loading={loadingCandidates}
            dataSource={candidateList}
            columns={columns}
            onChange={onChangeHandler}
            title={"Candidate List"}
            defaultPage={0}
            currentPage={pagination.page}
            totalRows={total}
            onPageChange={onPageChange}
            maxWidth="1500px"
        />

        {editDetails && <Drawer title="Start Admission" width={"80%"} placement="right" onClose={() => setEditDetailsModal(false)} open={editDetails}>
            <AdmissionForm isEdit={true} onClose={() => { setEditDetailsModal(false); loadList(); }} data={candidateData} id={candidateData?._id}  ></AdmissionForm>
        </Drawer>}

        {paymentModal && <Drawer title="Payment Details" width={"80%"} placement="right" onClose={() => setPaymentModal(false)} open={paymentModal}>
            <Steps
                size="small"
                current={step}
                items={[
                    {
                        title: 'Course Details',
                    },
                    {
                        title: 'Payment Details',
                    },
                ]}
            />

            {
                step === 1 ? <Row style={{ marginTop: "20px" }}>
                    <Col span={24}>
                        <CandidateCardNoBatch candidateData={candidateData}></CandidateCardNoBatch>
                    </Col>
                    <Divider></Divider>
                    <Descriptions title="Course Details">
                    </Descriptions>
                    <Col span={24}>
                        <CourseCardCreate candidateData={candidateData} setCourseData={(data: any) => getPaymentDetails(data)}></CourseCardCreate>
                    </Col>
                </Row> : <Row style={{ marginTop: "20px" }}>
                    <Col span={24}>
                        <CandidateCard candidateData={candidateData} courseData={courseData}></CandidateCard>
                    </Col>
                    <Divider></Divider>

                    <Col span={24}>
                        <CourseCard candidateData={candidateData} courseData={courseData} feeData={feeData} setPaymentModal={() => setPaymentModal(false)}></CourseCard>
                    </Col>
                </Row>
            }

        </Drawer>}
        {
            courseAllotmentModal && <Drawer title="Manual Course Allotment" width={"80%"} placement="right" onClose={() => setCourseAllotmentModal(false)} open={courseAllotmentModal}>
                <ManualCourseAllotment candidateData={candidateData} setModalOpen={setCourseAllotmentModal} />
            </Drawer>
        }
    </>


}
export default CandidatesList;


