export const ROUTE_ENQUIRY_DASHBOARD = "/enquiry-dashboard";
export const ROUTE_ENQUIRY_LIST = "/enquiries";
export const ROUTE_ENQUIRY_DETAILS = (id: string) => "/enquiry-details/" + id;

const PREFIX = "api/lms";
export const API_ENQUIRY_DASHBOARD = `${PREFIX}/enquiry/dashboard`;
export const API_GET_ENQUIRY_LIST = `${PREFIX}/list-enquiries`;
export const API_POST_ENQUIRY = `${PREFIX}/enquiry`;
export const API_POST_BULK_ENQUIRY = `${PREFIX}/bulk-enquiry`;
export const API_GET_ENQUIRY_BY_ID = (id: string) =>
  `${PREFIX}/enquiry/byid/${id}`;
export const API_UPDATE_ENQUIRY_BY_ID = (id: string) =>
  `${PREFIX}/enquiry/byid/${id}`;
export const API_DELETE_ENQUIRY_BY_ID = (id: string) =>
  `${PREFIX}/enquiry/byid/${id}`;
export const API_POST_ENQUIRY_ACTIVITY = (id: string) =>
  `${PREFIX}/enquiry/${id}/activity`;
export const API_GET_COUNSELLOR_LIST = `${PREFIX}/enquiry/get-counsellor-list`;

export const API_DOWNLOAD_DAILY_REPORT = (date: string) => `${PREFIX}/enquiry/daily-report/${date}`
export const API_DOWNLOAD_MONTHLY_REPORT = (date: string) => `${PREFIX}/enquiry/monthly-report/${date}`