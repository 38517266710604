import { Button, Col, Drawer, Form, Input, Row, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMasterDropdownData, getTopicBySubject } from "../../../core/store/reducers/masters.slice";
import { genericWebsiteCreate, genericWebsiteExamCategories, genericWebsiteUpdate } from "../../../core/store/reducers/website.slice";
import { RootState } from "../../../shared/types/common";
import { showNotification } from "../../../shared/utils/utils";
const { Option } = Select




const CreateEditTopics = ({ onClose, visible, app, title, selectedEntity, onSubmit }: any) => {
    const [form] = Form.useForm();

    // const { screenType } = useSelector((state: RootState) => state.settings);
    const { user } = useSelector((state: RootState) => state.auth);


    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(genericWebsiteExamCategories({ payload: null }))
    }, [])

    const [courseId, setCourseId] = useState(selectedEntity?.course_id?._id || null)
    const [centerId, setCenterId] = useState(selectedEntity?.center_id?._id || null)
    const [batchTypeId, setBatchTypeId] = useState(selectedEntity?.batch_type_id?._id || null)
    const [examId, setExamId] = useState(selectedEntity?.exam_id?._id || null)
    const [subjectList, setSubjectList] = useState([] as any)
    const [topics, setTopics] = useState([] as any);

    useEffect(() => {

        dispatch(getMasterDropdownData({ schemaKey: "exam-subject", labelKey: "name" })).then((val: any) => {
            if (val?.payload?.data?.success) {
                setSubjectList(val?.payload?.data?.data)

            }
        })
        if (selectedEntity?.batch_id) {
            // set(selectedEntity?.exam_id?._id)
            getRelevantData("batch", selectedEntity?.batch_id?.stream_id)
        }

        if (selectedEntity?.subject_id) {
            // set(selectedEntity?.exam_id?._id)
            getRelevantData("subject", selectedEntity?.subject_id?._id)
        }

    }, [selectedEntity])




    // const beforeUpload = (file: RcFile) => {
    //     const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    //     if (!isJpgOrPng) {
    //         message.error('You can only upload JPG/PNG file!');
    //         setLoadingPhoto(false);

    //     }
    //     const isLt2M = file.size / 1024 < 150;
    //     if (!isLt2M) {
    //         message.error('Image must smaller than 150 KB!');
    //         setLoadingPhoto(false);

    //     }
    //     if (isJpgOrPng && isLt2M) {
    //         return true
    //     }
    //     return Upload.LIST_IGNORE;
    // };



    // const handleChangePhoto = (info: any) => {
    //     if (info.file.status === 'uploading') {
    //         getBase64(info.file.originFileObj).then((url) => {
    //             setLoadingPhoto(false);
    //             setPhotoUrl(url);
    //         })
    //         return;
    //     }
    //     if (info.file.status === 'done') {
    //         // Get this url from response in real world.
    //         getBase64(info.file.originFileObj).then((url) => {
    //             setLoadingPhoto(false);
    //             setPhotoUrl(url);
    //         })
    //         // return;
    //     }

    // };




    const getRelevantData = (event: any, key: any) => {

        if (event === "subject") {
            dispatch(getTopicBySubject(key)).then((val: any) => {
                if (val?.payload?.data?.success) {
                    setTopics(val?.payload?.data?.data)
                }
            })
        }
    }


    const onFinish = (values: any) => {
        if (selectedEntity) {
            const payload = {
                ...values,
                _id: selectedEntity._id,
                updated_by: user._id,
                module_type: app
            };
            dispatch(genericWebsiteUpdate({ payload: payload, module: 'module-data' })).then((val: any) => {
                if (val?.payload?.data) {
                    showNotification(app + " Updated Successfully", "success");
                    onSubmit();
                }
            });
        } else {
            const payload = {
                ...values,
                created_by: user._id,
                updated_by: user._id,
                module_type: app
            }
            dispatch(genericWebsiteCreate({ payload: payload, module: 'module-data' })).then((val: any) => {
                if (val.payload?.data?.success) {
                    showNotification(app + " Saved Successfully", "success");
                    onSubmit();
                }
            })
        }
    }


    const extraButtons = [
        {
            htmlType: "button",
            colSpan: 5,
            loading: false,
            submitButtonText: "Cancel",
            onClick: onClose,
        },
        {
            htmlType: "submit",
            colSpan: 5,
            loading: false,
            submitButtonText: "Clear",
            type: "clearForm",
        },
        {
            htmlType: "submit",
            colSpan: 5,
            loading: false,
            submitButtonText: "Save",
            submitButtonType: "primary",
        }
    ]



    return <Drawer
        maskClosable={false}
        title={"Manage " + title}
        className="form-drawer"
        onClose={onClose}
        open={visible}
        width={"50%"}
    >
        <Form
            initialValues={{
                ...selectedEntity,
                exam_id: selectedEntity?.exam_id?._id,
                stream_id: selectedEntity?.batch_id?.stream_id,
                batch_id: selectedEntity?.batch_id?._id,
                subject_id: selectedEntity?.subject_id?._id,
                topic_id: selectedEntity?.topic_id?._id,
            }}
            className="paddingx20 app-form full-width"
            onFinish={(values) => onFinish(values)}
        >
            <Row gutter={20}>
                <Col span={12} className="gutter-row">
                    <Form.Item name="title" label="Title">
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12} className="gutter-row">
                    <Form.Item name="link" label="Link">
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={24} className="gutter-row">
                    <Form.Item name="description" label="Description">
                        <TextArea></TextArea>
                    </Form.Item>
                </Col>






                {/* 

                <Col span={12}>
                    <Form.Item label={"Exam"} rules={[{ required: true }]} name={"exam_id"}>
                        <Select placeholder={"Choose Exam"} onChange={(evt) => getRelevantData("stream", evt)}>
                            {
                                examList.map((item: any) => {
                                    return <Option value={item?.value}>{item?.label}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                </Col> */}


                <Col span={12} className="gutter-row">
                    <Form.Item name="subject_id" label="Select Subject">
                        <Select style={{ width: "100%" }} placeholder={"Choose Subject "} onChange={(evt) => getRelevantData("subject", evt)}>
                            {
                                subjectList.map((item: any) => {
                                    return <Option value={item?._id}>{item?.name}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                </Col>



                <Col span={12} className="gutter-row">
                    <Form.Item name="topic_id" label="Select Topic">
                        <Select style={{ width: "100%" }} placeholder={"Choose Topic "}>
                            {
                                topics.map((item: any) => {
                                    return <Option value={item?._id}>{item?.name}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                </Col>



                {/* 
                <Form.Item rules={[{ required: true, message: 'Please Upload Photo' }]} label="Upload Banner" name="banner">
                    <Upload beforeUpload={beforeUpload} accept={".png,.jpeg,.jpg"} maxCount={1}
                        onChange={handleChangePhoto} showUploadList={false} listType="picture-card">

                        {photo ? (
                            <img
                                src={photo}
                                alt="avatar"
                                style={{
                                    width: '100%',
                                }}
                            />
                        ) : (
                            <div>
                                {loadingphoto ? <LoadingOutlined /> : <PlusOutlined />}
                                <div style={{ marginTop: 8 }}>Upload</div>
                            </div>
                        )}


                    </Upload>
                </Form.Item> */}


                <Row className="button-row" justify="end" gutter={16}>

                    {extraButtons.map((btn: any, index: number) => {
                        return (
                            <Col
                                {...btn?.colSpanResponsive}
                                span={btn.colSpan}
                                key={index}
                                className="gutter-row"
                            >
                                <Form.Item>
                                    <Button
                                        loading={btn.loading}
                                        // size="large"
                                        {...(btn.onClick ? { onClick: btn.onClick } : {})}
                                        {...(btn.type === "clearForm"
                                            ? {
                                                onClick: () => {
                                                    form.resetFields();
                                                    form.submit();
                                                },
                                            }
                                            : {})}
                                        className={"mt20"}
                                        type={btn.submitButtonType}
                                        htmlType={
                                            btn.type === "clearForm" ? "button" : btn.htmlType
                                        }
                                        block
                                    >
                                        {btn.submitButtonText}
                                    </Button>
                                </Form.Item>
                            </Col>
                        );
                    })}
                </Row>
            </Row>

        </Form>
    </Drawer>
}

export default CreateEditTopics