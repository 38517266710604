import { Button, Col, Row, Space, Tabs, Tooltip } from "antd";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { BiFilterAlt, BiReceipt } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { requestOnlineCourseEnrolments, setFilters } from "../../../core/store/reducers/admission.slice";
import ContentHeaderComponent from "../../../shared/components/content-header/content-header.component";
import DataTableComponent from "../../../shared/components/data-table/data-table.component";
import GenericFormComponent from "../../../shared/components/generic-form/generic-form.component";
import { TABLE_ROWS_COUNT } from "../../../shared/constants";
import { RootState } from "../../../shared/types/common";


function OnlineEnrolmentsList() {
    const [filterVisible, setFilterVisible] = useState(false);
    const [enrolmentsList, setEnrolmentsList] = useState([] as any);
    const dispatch = useDispatch();
    const { enrolments, filters, total } = useSelector((state: RootState) => state.admission);
    // Actions and selections
    const [sorter, setSorter] = useState({ sort_by: "", sort_type: "" });
    const [pagination, setPagination] = useState({ page: 1, limit: TABLE_ROWS_COUNT });
    const [courseType, setCourseType] = useState('live' as any);

    useEffect(() => {
        loadList();
    }, [])

    const loadList = useCallback(
        (_page: any = 1) => {
            const data = {
                ...pagination,
                ...sorter
            };
            let filter = { ...filters, course_type: courseType, old_purchase: { $ne: true } }
            if (courseType.indexOf('-old') !== -1) {
                filter = { ...filter, course_type: courseType.split('-old').join(""), old_purchase: true }
            }
            dispatch(requestOnlineCourseEnrolments({ params: data, body: filter }))
        },
        [filters, dispatch, sorter, pagination, courseType]
    );
    useEffect(() => {
        loadList();
    }, [loadList]);

    useEffect(() => {
        // if (enrolments?.length > 0) {
        setEnrolmentsList(
            enrolments.map((obj: any, index: number) => {
                return { key: index, ...obj };
            })
        );
        // }
    }, [enrolments]);
    const onPageChange = (pageNumber: number) => {
        setPagination({
            page: pageNumber,
            limit: pagination.limit,
        });
    };

    const columns = [
        {
            title: 'Reg. No.',
            dataIndex: ['candidate_id', 'registration_number'],
            key: 'candidate_name',
        },
        {
            title: 'Roll No.',
            dataIndex: 'roll_no',
            key: 'roll_no',
        },
        {
            title: 'Name',
            dataIndex: ['candidate_id', 'candidate_name'],
            key: 'candidate_name',
        },
        {
            title: 'Mobile',
            dataIndex: ['candidate_id', 'contact'],
            key: 'contact',
        },
        {
            title: 'Email',
            dataIndex: ['candidate_id', 'email'],
            key: 'email',
        },
        {
            title: 'Course Name',
            dataIndex: ['website_course_id', 'course_name'],
            key: 'email',
        },
        {
            title: 'Amount',
            dataIndex: 'total_amount',
            key: 'total_amount',
        },
        {
            title: 'Purchased On',
            render: (rec: any) => rec?.purchased_date ? moment(rec?.purchased_date).format('DD MMM, YYYY') : moment(rec?.created_on).format('DD MMM, YYYY'),
            key: 'purchased',
            width: '110px'
        },
        {
            title: 'Invoice Number',
            dataIndex: 'invoice_number',
            key: 'invoice_number',
        },
        {
            title: 'Bank Name',
            dataIndex: 'bank_name',
            key: 'bank_name',
        },
        {
            title: 'Ref. No.',
            dataIndex: 'ref_no',
            key: 'ref_no',
        },
        {
            title: 'Remarks',
            dataIndex: 'remarks',
            key: 'remarks',
            render: (a: any) => a?.indexOf('{"') == -1 ? a : "Full Paid Online"
        },
        {
            title: 'Valid Till',
            render: (a: any) => a?.validity ? moment(a?.validity).format('DD MMM, YY') : a?.website_course_id?.batch_id?.end_date ? moment(a?.website_course_id?.batch_id?.end_date).format('DD MMM, YYYY') : "Inactive",
            key: 'remarks',
        },
        {
            title: 'Action',
            // dataIndex: 'address',
            fixed: 'right',
            render: (a: any, record: any) => {
                return (
                    <>

                        <Tooltip title={"Print Receipt"}>
                            <Button
                                onClick={() => {
                                    window.open("/print-online-receipt/" + record._id)
                                }}
                                type="link"
                                className="table-button"
                                icon={<BiReceipt />}
                            ></Button>
                        </Tooltip>
                    </>
                );
            },
            key: "actions",
        },
    ];
    const headerButtons = (
        <Space>
            <Button
                shape="circle"
                type="primary"
                icon={
                    <span role="img" className="anticon">
                        <BiFilterAlt />
                    </span>
                }
                onClick={() => {
                    setFilterVisible(!filterVisible);
                }}
            ></Button>
        </Space>
    );
    const onFilterApply = (values: any) => {
        let arrFilter = [];
        for (let [key, value] of Object.entries(values)) {
            if (value !== "") {
                arrFilter.push({ key: key, value: value });
            }
        }
        let refinedArray = arrFilter.filter((data) => {
            return data.value !== undefined;
        });
        let obj = {} as any;
        refinedArray.forEach((data: any) => {
            obj[data.key] = data.value;
        });
        setPagination({
            page: 1,
            limit: pagination.limit,
        });
        dispatch(setFilters(obj)); //filters
    };
    const formProps = {
        name: "candidate-filters",
        enableEnterSubmit: true,
        formElements: [
            {
                name: "roll_no",
                label: "",
                placeholder: "Search Roll Number",
                type: "Input",
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
            },
        ],
        initialValues: {},
        extraButtons: [
            {
                htmlType: "submit",
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 3 },
                loading: false,
                submitButtonText: "Clear",
                type: "clearForm",
            },
            {
                htmlType: "submit",
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 3 },
                loading: false,
                submitButtonText: "Apply",
                submitButtonType: "primary",
            },
        ],
        onFinish: onFilterApply,
        onFinishFailed: null,
        onValuesChange: null,
    };
    const onChangeHandler = (_pagination: any, _filters: any, _sorter: any) => {
        if (_sorter.order === undefined) {
            setSorter({ sort_by: "", sort_type: "" });
        } else {
            let order = "";
            let columnKey = "";
            if (_sorter.order === "ascend") order = "asc";
            else order = "desc";

            if (_sorter.columnKey === "enabled") {
                columnKey = "status";
            } else if (_sorter.columnKey === "displayName") {
                columnKey = "name";
            } else {
                columnKey = _sorter.columnKey;
            }
            setSorter({ sort_by: columnKey, sort_type: order });
        }
    };
    const onChangeTab = (key: string) => {
        setCourseType(key)
    }
    return <>
        <div
            className="mx-6 mt-2 bg-primary-white-blueish border-primary-gray-02"
            style={{ borderBottom: "none!important" }}
        >
            <Row>
                <Col span={24}>
                    <ContentHeaderComponent
                        title="Manage Enrolments"
                        subtitle=""
                        buttons={headerButtons}
                    />
                </Col>
            </Row>

            {filterVisible && (
                <Row className="bg-body">
                    <Col span={24} className="py-1">
                        <div className="px-3 table-filter-form">
                            <GenericFormComponent {...formProps} />
                        </div>
                    </Col>
                </Row>
            )}
            <Row>
                <Col span={24}>
                    <Tabs className="table-status-tabs mx-3" defaultActiveKey={courseType} onChange={(onChangeTab)}>
                        <Tabs.TabPane tab="Live (New)" key="live"></Tabs.TabPane>
                        <Tabs.TabPane tab="Recorded(New)" key="recorded"></Tabs.TabPane>
                        <Tabs.TabPane tab="Test Series (NEW)" key="test-series"></Tabs.TabPane>
                        <Tabs.TabPane tab="Live (Old)" key="live-old"></Tabs.TabPane>
                        <Tabs.TabPane tab="Recorded (Old)" key="recorded-old"></Tabs.TabPane>
                        <Tabs.TabPane tab="Test Series (OLD)" key="test-series-old"></Tabs.TabPane>
                        <Tabs.TabPane tab="Publications" key="publications"></Tabs.TabPane>

                    </Tabs>
                </Col>
            </Row>
        </div>
        <DataTableComponent
            loading={false}
            dataSource={enrolmentsList}
            columns={columns}
            onChange={onChangeHandler}
            title={"ENrolments List"}
            defaultPage={0}
            currentPage={pagination.page}
            totalRows={total}
            onPageChange={onPageChange}
            maxWidth="2000px"
        />


    </>


}
export default OnlineEnrolmentsList;


