import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  API_DELETE_ENQUIRY_BY_ID,
  API_ENQUIRY_DASHBOARD,
  API_GET_COUNSELLOR_LIST,
  API_GET_ENQUIRY_BY_ID,
  API_GET_ENQUIRY_LIST,
  API_POST_BULK_ENQUIRY,
  API_POST_ENQUIRY,
  API_POST_ENQUIRY_ACTIVITY,
  API_UPDATE_ENQUIRY_BY_ID,
} from "../../../shared/routes/lms.routes";
import { Client } from "../../api-client";
const config = {
  name: "lms",
};

export const getEnquiryList: any = createAsyncThunk(
  `${config.name}/getEnquiryList`,
  async ({ params, body }: any) => {
    return Client.post(API_GET_ENQUIRY_LIST, body, { params });
  }
);
export const getEnquiryById: any = createAsyncThunk(
  `${config.name}/getEnquiryById`,
  async (id: string) => {
    return Client.get(API_GET_ENQUIRY_BY_ID(id));
  }
);
export const postNewEnquiry: any = createAsyncThunk(
  `${config.name}/postNewEnquiry`,
  async (payload: any) => {
    return Client.post(API_POST_ENQUIRY, payload);
  }
);
export const updateEnquiryById: any = createAsyncThunk(
  `${config.name}/updateEnquiryById`,
  async (payload: any) => {
    const _id = payload._id;
    delete payload._id;
    return Client.put(API_UPDATE_ENQUIRY_BY_ID(_id), payload);
  }
);
export const deleteEnquiryById: any = createAsyncThunk(
  `${config.name}/deleteEnquiryById`,
  async (id: any) => {
    return Client.delete(API_DELETE_ENQUIRY_BY_ID(id));
  }
);
export const postBulkEnquiry: any = createAsyncThunk(
  `${config.name}/postBulkEnquiry`,
  async (payload: any) => {
    return Client.post(API_POST_BULK_ENQUIRY, payload);
  }
);
export const postEnquiryActivity: any = createAsyncThunk(
  `${config.name}/postEnquiryActivity`,
  async (payload: any) => {
    return Client.post(API_POST_ENQUIRY_ACTIVITY(payload.id), payload);
  }
);
export const getCounsellorList: any = createAsyncThunk(
  `${config.name}/getCounsellorList`,
  async (params: any) => {
    return Client.get(API_GET_COUNSELLOR_LIST, { params });
  }
);
export const getEnquiryDashboardData: any = createAsyncThunk(
  `${config.name}/getEnquiryDashboardData`,
  async (params: any) => {
    return Client.get(API_ENQUIRY_DASHBOARD, { params });
  }
);
export const lmsReducer = createSlice({
  name: config.name,
  initialState: {
    loadingEnquiries: false,
    enquiryList: [] as any,
    total: 0 as number,
    filters: {} as any,
  },
  reducers: {
    resetEnquiryList: (state) => {
      state.loadingEnquiries = false;
      state.enquiryList = [];
      state.total = 0;
      state.filters = {};
    },
    setFilters: (state, action) => {
      state.filters = action?.payload;
    },
    resetFilters: (state) => {
      state.filters = {} as any;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getEnquiryList.pending, (state) => {
        state.loadingEnquiries = true;
      })
      .addCase(getEnquiryList.fulfilled, (state, action) => {
        state.loadingEnquiries = false;
        state.enquiryList = action?.payload?.data?.data;
        state.total = action?.payload?.data?.total;
      })
      .addCase(getEnquiryList.rejected, (state) => {
        state.loadingEnquiries = false;
        state.enquiryList = [];
        state.total = 0;
      });
  },
});

export const { resetEnquiryList, setFilters, resetFilters } =
  lmsReducer.actions;
export default lmsReducer.reducer;
