import { Divider, Drawer } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createMasterData, getBatchType, getCenterList,
  getCourseListByCenter,
  getExams,
  getMasterDropdownData,
  getStreams,
  getVenues,
  updateMasterData
} from "../../../core/store/reducers/masters.slice";
import GenericFormComponent from "../../../shared/components/generic-form/generic-form.component";
import { RootState } from "../../../shared/types/common";
import { showNotification } from "../../../shared/utils/utils";

interface Props {
  onClose: () => void;
  visible: boolean;
  moduleName: string;
  selectedModuleData?: any;
  moduleData: any;
  onSubmit?: () => void;
}
const CreateEditBatchTypeRelationship = ({
  onClose,
  visible,
  moduleName,
  selectedModuleData = null,
  moduleData,
  onSubmit = () => {
    return;
  },
}: Props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth);
  const { centers, courses, batchType, exams, streams, venues } = useSelector((state: RootState) => state.masters);
  const { screenType } = useSelector((state: RootState) => state.settings);
  const [formProps, setFormProps] = useState({} as any);
  const [centerList, setCenterList] = useState([] as any);
  const [courseList, setCourseList] = useState([] as any);
  const [batchTypeList, setBatchTypeList] = useState([] as any);
  const [examList, setExamList] = useState([] as any);
  const [streamList, setStreamList] = useState([] as any);
  const [venueList, setVenueList] = useState([] as any);
  const [companyList, setCompanyList] = useState([] as any);
  const [batchCodeList, setBatchCodeList] = useState([] as any);
  const [batchTimeList, setBatchTimeList] = useState([] as any);
  const [sessionList, setSessionList] = useState([] as any);
  const [formValues, setFormValues] = useState({} as any);

  var colSpan = screenType.isPortrait ? 24 : (moduleName == 'fee' ? 8 : 12);

  const getRelevantData = (event: any, formValues: any) => {

    if (event === "center_id") {
      if (moduleName !== 'venue-relationship') {
        if (moduleName === 'course-relationship') {
          dispatch(getMasterDropdownData({ schemaKey: "company", labelKey: "company_name" })).then((val: any) => {
            if (val?.payload?.data?.success) {
              setCompanyList(val?.payload?.data?.data?.map((val: any) => {
                return { label: val.company_name, value: val._id }
              }))
            }
          })
          dispatch(getMasterDropdownData({ schemaKey: "course", labelKey: "course_name" })).then((val: any) => {
            if (val?.payload?.data?.success) {
              setCourseList(val?.payload?.data?.data?.map((val: any) => {
                return { label: val.course_name, value: val._id }
              }))
            }
          })
        } else {
          dispatch(getCourseListByCenter(formValues))
        }
        if (moduleName === 'batch' || moduleName === 'fee') {
          dispatch(getMasterDropdownData({ schemaKey: "session", labelKey: "session" })).then((val: any) => {
            if (val?.payload?.data?.success) {
              setSessionList(val?.payload?.data?.data?.map((val: any) => {
                return { label: val.session, value: val._id }
              }))
            }
          })
        }
        if (moduleName === 'batch') {
          dispatch(getVenues(formValues))
          dispatch(getMasterDropdownData({ schemaKey: "batch-code", labelKey: "batch_code description" })).then((val: any) => {
            if (val?.payload?.data?.success) {
              setBatchCodeList(val?.payload?.data?.data?.map((val: any) => {
                return { label: val.description + "(" + val.batch_code + ")", value: val._id }
              }))
            }
          })
          dispatch(getMasterDropdownData({ schemaKey: "batch-timings", labelKey: "batch_time_from batch_time_to" })).then((val: any) => {
            if (val?.payload?.data?.success) {
              setBatchTimeList(val?.payload?.data?.data?.map((val: any) => {
                return { label: val.batch_time_from + "-" + val.batch_time_to, value: val._id }
              }))
            }
          })
        }
      } else {
        dispatch(getMasterDropdownData({ schemaKey: "venue", labelKey: "venue_name" })).then((val: any) => {
          if (val?.payload?.data?.success) {
            setVenueList(val?.payload?.data?.data?.map((val: any) => {
              return { label: val.venue_name, value: val._id }
            }))
          }
        })
      }
    }
    if (event === "course_id") {
      if (moduleName === 'batch-type-relationship') {
        dispatch(getMasterDropdownData({ schemaKey: "batch-type", labelKey: "batch_type_name" })).then((val: any) => {
          if (val?.payload?.data?.success) {
            setBatchTypeList(val?.payload?.data?.data?.map((val: any) => {
              return { label: val.batch_type_name, value: val._id }
            }))
          }
        })
      } else {
        dispatch(getBatchType(formValues))
      }
    }
    if (event === "batch_type_id") {
      if (moduleName === 'exam-type-relationship') {
        dispatch(getMasterDropdownData({ schemaKey: "exam", labelKey: "exam_name" })).then((val: any) => {
          if (val?.payload?.data?.success) {
            setExamList(val?.payload?.data?.data?.map((val: any) => {
              return { label: val.exam_name, value: val._id }
            }))
          }
        })
      } else {
        dispatch(getExams(formValues))
      }
    }
    if (event === "exam_id") {
      if (moduleName === 'stream-relationship') {
        dispatch(getMasterDropdownData({ schemaKey: "stream", labelKey: "stream_name" })).then((val: any) => {
          if (val?.payload?.data?.success) {
            setStreamList(val?.payload?.data?.data?.map((val: any) => {
              return { label: val.stream_name, value: val._id }
            }))
          }
        })
      } else {
        dispatch(getStreams(formValues))
      }
    }
    // if (event === "stream_id") {
    //   dispatch(getBatch(formValues))
    // }
  }
  useEffect(() => {
    centers?.length == 0 && dispatch(getCenterList())
  }, [])
  useEffect(() => {
    // centers?.length > 0 && 
    setCenterList(centers.map((val: any) => {
      return { name: val.center_name, value: val._id }
    }))
  }, [centers])
  useEffect(() => {
    // courses?.length > 0 && 
    setCourseList(courses.map((val: any) => {
      return { name: val.course_ref?.course_name, value: val.course_ref?._id }
    }))
  }, [courses])
  useEffect(() => {
    // batchType?.length > 0 && 
    setBatchTypeList(batchType.map((val: any) => {
      return { name: val.batch_type_id?.batch_type_name, value: val.batch_type_id?._id }
    }))
  }, [batchType])
  useEffect(() => {
    // exams?.length > 0 && 
    setExamList(exams.map((val: any) => {
      return { name: val.exam_id?.exam_name, value: val.exam_id?._id }
    }))
  }, [exams])
  useEffect(() => {
    // streams?.length > 0 && 
    setStreamList(streams.map((val: any) => {
      return { name: val.stream_id?.stream_name, value: val.stream_id?._id }
    }))
  }, [streams])
  useEffect(() => {
    // venues?.length > 0 && 
    setVenueList(venues.map((val: any) => {
      return { name: val.venue_id?.venue_name, value: val.venue_id?._id }
    }))
  }, [venues])

  const onFinish = (values: any) => {
    let payload = {
      ...formValues,
      modified_by: user._id,
    };
    if (moduleName === 'fee') {
      if (payload?.general_instalment_structure?.length > 0) {
        payload = { ...payload, general_total_fee: payload?.general_total_fee - payload?.installment_charges }
      }
    }
    if (moduleName === 'course-relationship') {
      payload = { ...payload, adm_company_ref: payload.adm_company, book_company_ref: payload.book_company, center_ref: payload.center_id, course_ref: payload.course_id }
    }
    if (selectedModuleData === null) {
      payload.created_by = user._id;
      dispatch(createMasterData({ path: moduleData.key, body: payload })).then(
        (val: any) => {
          if (val?.payload?.data) {
            showNotification("New Record Created", "success");
            onSubmit();
          }
        }
      );
    } else {
      payload.updated_by = user._id;
      payload.modified_by = user._id;
      payload = { ...selectedModuleData, ...payload };
      dispatch(updateMasterData({ path: moduleData.key, body: payload })).then(
        (val: any) => {
          if (val?.payload?.data) {
            showNotification("Record Updated", "success");
            onSubmit();
          }
        }
      );
    }
  };

  const onFinishFailed = (_errorInfo: any) => {
    return;
  };

  const onValuesChange = (value: any, _allValues: any) => {
    setFormValues(_allValues);
    if (value.center_id) {
      setFormValues({ ..._allValues, course_id: null, batch_type_id: null, exam_id: null, stream_id: null, batch_id: null, venue_id: null })
    }
    if (value.course_id) {
      setFormValues({ ..._allValues, batch_type_id: null, exam_id: null, stream_id: null, batch_id: null })
    }
    if (value.batch_type_id) {
      setFormValues({ ..._allValues, exam_id: null, stream_id: null, batch_id: null })
    }
    if (value.exam_id) {
      setFormValues({ ..._allValues, stream_id: null, batch_id: null })
    }
    if (value.stream_id) {
      setFormValues({ ..._allValues, batch_id: null })
    }
    if (moduleName === 'fee') {
      if (value.general_installments_count) {
        for (let k = 0; k < value.general_installments_count; ++k) {
          delete _allValues[`general_admission_amt_${k}`]
          delete _allValues[`general_tuition_amt_${k}`]
          delete _allValues[`general_book_amt_${k}`]
          delete _allValues[`general_net_amt_${k}`]
          delete _allValues[`general_ins_sub_after_day_${k}`]
        }
      }
      if (value.ph_installments_count) {
        for (let k = 0; k < value.ph_installments_count; ++k) {
          delete _allValues[`ph_admission_amt_${k}`]
          delete _allValues[`ph_tuition_amt_${k}`]
          delete _allValues[`ph_book_amt_${k}`]
          delete _allValues[`ph_net_amt_${k}`]
          delete _allValues[`ph_ins_sub_after_day_${k}`]
        }
      }
      let ph_admission_fee = parseFloat(_allValues.general_admission_fee || 0) - parseFloat(_allValues.ph_admission_discount || 0);
      let ph_tuition_fee = parseFloat(_allValues.general_tuition_fee || 0) - parseFloat(_allValues.ph_tuition_discount || 0);
      let ph_books_fee = parseFloat(_allValues.general_books_fee || 0) - parseFloat(_allValues.ph_books_discount || 0);
      if (_allValues.general_installments_count && _allValues.general_installments_count > 0) {
        _allValues.general_instalment_structure = [];
        let ga = 0;
        let gt = 0;
        let gb = 0;
        let gn = 0;

        for (let k = 0; k < _allValues.general_installments_count - 1; ++k) {
          _allValues[`general_admission_amt_${k}`] = parseFloat(_allValues[`general_admission_amt_${k}`] || 0)
          _allValues[`general_tuition_amt_${k}`] = parseFloat(_allValues[`general_tuition_amt_${k}`] || 0)
          _allValues[`general_book_amt_${k}`] = parseFloat(_allValues[`general_book_amt_${k}`] || 0)
          _allValues[`general_net_amt_${k}`] = _allValues[`general_admission_amt_${k}`] + _allValues[`general_tuition_amt_${k}`] + _allValues[`general_book_amt_${k}`]
          if (k == _allValues.general_installments_count - 1) {
          } else {
            _allValues.general_instalment_structure.push({
              installment_no: k + 1,
              admission_amt: _allValues[`general_admission_amt_${k}`],
              tuition_amt: _allValues[`general_tuition_amt_${k}`],
              book_amt: _allValues[`general_book_amt_${k}`],
              net_amt: _allValues[`general_net_amt_${k}`],
              ins_sub_after_day: parseInt(_allValues[`general_ins_sub_after_day_${k}`] || 0),
            })
          }
          ga += _allValues[`general_admission_amt_${k}`]
          gt += _allValues[`general_tuition_amt_${k}`]
          gb += _allValues[`general_book_amt_${k}`]
          gn += _allValues[`general_net_amt_${k}`]
        }
        let k = _allValues.general_installments_count - 1
        _allValues.general_instalment_structure.push({
          installment_no: k + 1,
          admission_amt: parseFloat(_allValues.general_admission_fee) - ga,
          tuition_amt: parseFloat(_allValues.general_tuition_fee) - gt,
          book_amt: parseFloat(_allValues.general_books_fee) - gb,
          net_amt: parseFloat(_allValues.general_total_fee) - gn,
          ins_sub_after_day: parseInt(_allValues[`general_ins_sub_after_day_${k}`] || 0),
        })
        _allValues[`general_admission_amt_${k}`] = parseFloat(_allValues.general_admission_fee) - ga
        _allValues[`general_tuition_amt_${k}`] = parseFloat(_allValues.general_tuition_fee) - gt
        _allValues[`general_book_amt_${k}`] = parseFloat(_allValues.general_books_fee) - gb
        _allValues[`general_net_amt_${k}`] = parseFloat(_allValues.general_admission_fee) + parseFloat(_allValues.general_tuition_fee) + parseFloat(_allValues.general_books_fee) + parseFloat(_allValues.installment_charges) - gn
      }
      if (_allValues.ph_installments_count && _allValues.ph_installments_count > 0) {
        _allValues.ph_instalment_structure = [];
        let ga = 0;
        let gt = 0;
        let gb = 0;
        let gn = 0;
        for (let k = 0; k < _allValues.ph_installments_count - 1; ++k) {
          _allValues[`ph_admission_amt_${k}`] = parseFloat(_allValues[`ph_admission_amt_${k}`] || 0)
          _allValues[`ph_tuition_amt_${k}`] = parseFloat(_allValues[`ph_tuition_amt_${k}`] || 0)
          _allValues[`ph_book_amt_${k}`] = parseFloat(_allValues[`ph_book_amt_${k}`] || 0)
          _allValues[`ph_net_amt_${k}`] = _allValues[`ph_admission_amt_${k}`] + _allValues[`ph_tuition_amt_${k}`] + _allValues[`ph_book_amt_${k}`]
          if (k == _allValues.ph_installments_count - 1) {
          } else {
            _allValues.ph_instalment_structure.push({
              installment_no: k + 1,
              admission_amt: _allValues[`ph_admission_amt_${k}`],
              tuition_amt: _allValues[`ph_tuition_amt_${k}`],
              book_amt: _allValues[`ph_book_amt_${k}`],
              net_amt: _allValues[`ph_net_amt_${k}`],
              ins_sub_after_day: parseInt(_allValues[`ph_ins_sub_after_day_${k}`] || 0),
            })
          }
          ga += _allValues[`ph_admission_amt_${k}`]
          gt += _allValues[`ph_tuition_amt_${k}`]
          gb += _allValues[`ph_book_amt_${k}`]
          gn += _allValues[`ph_net_amt_${k}`]
        }
        let k = _allValues.ph_installments_count - 1
        _allValues.ph_instalment_structure.push({
          installment_no: k + 1,
          admission_amt: parseFloat(_allValues.ph_admission_fee) - ga,
          tuition_amt: parseFloat(_allValues.ph_tuition_fee) - gt,
          book_amt: parseFloat(_allValues.ph_books_fee) - gb,
          net_amt: parseFloat(_allValues.ph_total_fee) - gn,
          ins_sub_after_day: parseInt(_allValues[`ph_ins_sub_after_day_${k}`] || 0),
        })
        _allValues[`ph_admission_amt_${k}`] = parseFloat(_allValues.ph_admission_fee) - ga
        _allValues[`ph_tuition_amt_${k}`] = parseFloat(_allValues.ph_tuition_fee) - gt
        _allValues[`ph_book_amt_${k}`] = parseFloat(_allValues.ph_books_fee) - gb
        _allValues[`ph_net_amt_${k}`] = parseFloat(_allValues.ph_admission_fee) + parseFloat(_allValues.ph_tuition_fee) + parseFloat(_allValues.ph_books_fee) + parseFloat(_allValues.installment_charges) - gn
      }
      setFormValues({
        ..._allValues,
        general_total_fee: parseFloat(_allValues.general_admission_fee || 0) + parseFloat(_allValues.general_tuition_fee || 0) + parseFloat(_allValues.general_books_fee || 0) + (_allValues.general_instalment_structure?.length > 0 ? parseFloat(_allValues.installment_charges || 0) : 0),
        ph_total_discount: parseFloat(_allValues.ph_admission_discount || 0) + parseFloat(_allValues.ph_tuition_discount || 0) + parseFloat(_allValues.ph_books_discount || 0),
        ph_admission_fee,
        ph_tuition_fee,
        ph_books_fee,
        ph_total_fee: ph_admission_fee + ph_tuition_fee + ph_books_fee,
      })
    }
    getRelevantData(Object.keys(value)[0], _allValues);

  };
  useEffect(() => {
    let formData = {} as any;
    if (selectedModuleData) {
      if (selectedModuleData?.center_id) {
        formData.center_id = selectedModuleData?.center_id?._id || selectedModuleData?.center_id;
      }
      if (selectedModuleData?.course_id) {
        formData.course_id = selectedModuleData?.course_id?._id || selectedModuleData?.course_id
      }
      if (selectedModuleData?.batch_type_id) {
        formData.batch_type_id = selectedModuleData?.batch_type_id?._id
      }
      if (selectedModuleData?.exam_id) {
        formData.exam_id = selectedModuleData?.exam_id?._id
      }
      if (selectedModuleData?.stream_id) {
        formData.stream_id = selectedModuleData?.stream_id?._id
      }
      if (selectedModuleData?.batch_id) {
        formData.batch_id = selectedModuleData?.batch_id?._id
      }
      if (selectedModuleData?.adm_company) {
        formData.adm_company = selectedModuleData?.adm_company
      }
      if (selectedModuleData?.book_company) {
        formData.book_company = selectedModuleData?.book_company
      }
      if (selectedModuleData?.venue_id) {
        formData.venue_id = selectedModuleData?.venue_id?._id
      }
      if (selectedModuleData?.session_id) {
        formData.session_id = selectedModuleData?.session_id?._id
      }
      if (selectedModuleData?.batch_code_id) {
        formData.batch_code_id = selectedModuleData?.batch_code_id?._id
      }
      if (selectedModuleData?.batch_time_id) {
        formData.batch_time_id = selectedModuleData?.batch_time_id?._id
      }
      if (formData.center_id) {
        getRelevantData('center_id', formData);
      }
      if (formData.course_id) {
        getRelevantData('course_id', formData);
      }
      if (formData.batch_type_id) {
        getRelevantData('batch_type_id', formData);
      }
      if (formData.exam_id) {
        getRelevantData('exam_id', formData);
      }
      if (formData.stream_id) {
        getRelevantData('stream_id', formData);
      }
      if (formData.batch_id) {
        getRelevantData('batch_id', formData);
      }
      if (formData.adm_company) {
        // getRelevantData('company', formData);
      }
      if (moduleName === 'batch') {
        formData = { ...selectedModuleData, ...formData, start_date: moment(selectedModuleData.start_date), end_date: moment(selectedModuleData.end_date) }
      }
      if (moduleName === 'fee') {
        for (let k = 0; k < selectedModuleData?.general_instalment_structure?.length; ++k) {
          formData[`general_admission_amt_${k}`] = selectedModuleData?.general_instalment_structure[k]['admission_amt']
          formData[`general_tuition_amt_${k}`] = selectedModuleData?.general_instalment_structure[k]['tuition_amt']
          formData[`general_book_amt_${k}`] = selectedModuleData?.general_instalment_structure[k]['book_amt']
          formData[`general_net_amt_${k}`] = selectedModuleData?.general_instalment_structure[k]['net_amt']
          formData[`general_ins_sub_after_day_${k}`] = selectedModuleData?.general_instalment_structure[k]['ins_sub_after_day']
        }
        for (let k = 0; k < selectedModuleData?.ph_instalment_structure?.length; ++k) {
          formData[`ph_admission_amt_${k}`] = selectedModuleData?.ph_instalment_structure[k]['admission_amt']
          formData[`ph_tuition_amt_${k}`] = selectedModuleData?.ph_instalment_structure[k]['tuition_amt']
          formData[`ph_book_amt_${k}`] = selectedModuleData?.ph_instalment_structure[k]['book_amt']
          formData[`ph_net_amt_${k}`] = selectedModuleData?.ph_instalment_structure[k]['net_amt']
          formData[`ph_ins_sub_after_day_${k}`] = selectedModuleData?.ph_instalment_structure[k]['ins_sub_after_day']
        }
        formData = { ...selectedModuleData, ...formData }
      }
    }
    setFormValues(formData)
  }, [selectedModuleData])

  useEffect(() => {
    setFormProps({
      name: "creat-edit-" + moduleData.key,
      heading: (selectedModuleData ? "Edit " : "Add New ") + moduleData.label,
      initialValues: {
        ...selectedModuleData,
        ...(moduleName === 'batch' ? { start_date: selectedModuleData?.start_date ? moment(selectedModuleData?.start_date) : moment() } : {}),
        ...(moduleName === 'batch' ? { end_date: selectedModuleData?.end_date ? moment(selectedModuleData?.end_date) : moment() } : {})
      },
      setValues: formValues,
      formElements:
        [
          {
            name: "center_id",
            colSpan: colSpan,
            label: "Center Name",
            placeholder: "Select Center Name",
            type: "Select",
            options: centerList,
            allowClear: true,
            rules: [
              {
                required: true,
                message: "Required",
              },
            ]
          },
          ...(moduleName !== 'venue-relationship' ? [{
            name: "course_id",
            colSpan: colSpan,
            label: "Course Name",
            placeholder: "Select Course Name",
            type: "Select",
            options: courseList,
            allowClear: true,
            rules: [
              {
                required: true,
                message: "Required",
              },
            ]
          }] : []),
          ...(moduleName === 'course-relationship' ? [
            {
              name: "adm_company",
              colSpan: colSpan,
              label: "ADMISSION & TUITION COMPANY",
              placeholder: "Select Admission & Tuition Company",
              type: "Select",
              options: companyList,
              allowClear: true,
              rules: [
                {
                  required: true,
                  message: "Required",
                },
              ]
            },
            {
              name: "book_company",
              colSpan: colSpan,
              label: "Book COMPANY",
              placeholder: "Select Book Company",
              type: "Select",
              options: companyList,
              allowClear: true
            },
            {
              name: "",
              colSpan: 24,
              textlabel: "Admission & Tuition Tax Rates",
              extra: "if not required then 0",
              type: "TextLabel"
            },
            {
              name: "at_tax_cgst",
              colSpan: 8,
              label: "CGST",
              placeholder: "",
              type: "Input",
              rules: [
                {
                  required: true,
                  message: "Required",
                },
              ]
            },
            {
              name: "at_tax_sgst",
              colSpan: 8,
              label: "SGST",
              placeholder: "",
              type: "Input",
              rules: [
                {
                  required: true,
                  message: "Required",
                },
              ]
            },
            {
              name: "at_tax_igst",
              colSpan: 8,
              label: "IGST",
              placeholder: "",
              type: "Input",
              rules: [
                {
                  required: true,
                  message: "Required",
                },
              ]
            },
            ...(formValues?.book_company ? [{
              name: "",
              colSpan: 24,
              textlabel: "Book Tax Rates",
              extra: "if not required then 0",
              type: "TextLabel"
            },
            {
              name: "bk_tax_cgst",
              colSpan: 8,
              label: "CGST",
              placeholder: "",
              type: "Input",
              rules: [
                {
                  required: true,
                  message: "Required",
                },
              ]
            },
            {
              name: "bk_tax_sgst",
              colSpan: 8,
              label: "SGST",
              placeholder: "",
              type: "Input",
              rules: [
                {
                  required: true,
                  message: "Required",
                },
              ]
            },
            {
              name: "bk_tax_igst",
              colSpan: 8,
              label: "IGST",
              placeholder: "",
              type: "Input",
              rules: [
                {
                  required: true,
                  message: "Required",
                },
              ]
            }] : [])
          ] : []),
          ...((moduleName === 'batch-type-relationship' || moduleName === 'exam-type-relationship' || moduleName === 'stream-relationship' || moduleName === 'batch' || moduleName === 'fee') ? [{
            name: "batch_type_id",
            colSpan: colSpan,
            label: "Batch Type",
            placeholder: "Select Batch Type",
            type: "Select",
            options: batchTypeList,
            allowClear: true,
            rules: [
              {
                required: true,
                message: "Required",
              },
            ]
          }] : []),
          ...((moduleName === 'exam-type-relationship' || moduleName === 'stream-relationship' || moduleName === 'batch' || moduleName === 'fee') ? [{
            name: "exam_id",
            colSpan: colSpan,
            label: "Exam",
            placeholder: "Select Exam",
            type: "Select",
            options: examList,
            allowClear: true,
            rules: [
              {
                required: true,
                message: "Required",
              },
            ]
          }] : []),
          ...((moduleName === 'stream-relationship' || moduleName === 'batch' || moduleName === 'fee') ? [{
            name: "stream_id",
            colSpan: colSpan,
            label: "Stream",
            placeholder: "Select Stream",
            type: "Select",
            options: streamList,
            allowClear: true,
            rules: [
              {
                required: true,
                message: "Required",
              },
            ]
          }] : []),
          ...((moduleName === 'venue-relationship' || moduleName === 'batch') ? [{
            name: "venue_id",
            colSpan: colSpan,
            label: "Venue Name",
            placeholder: "Select Venue",
            type: "Select",
            options: venueList,
            allowClear: true,
            rules: [
              {
                required: true,
                message: "Required",
              },
            ]
          }] : []),
          ...(moduleName === 'batch' ? [
            {
              name: "session_id",
              colSpan: colSpan,
              label: "Session",
              placeholder: "Select Session",
              type: "Select",
              options: sessionList,
              allowClear: true,
              rules: [
                {
                  required: true,
                  message: "Required",
                },
              ]
            },
            {
              name: "batch_code_id",
              colSpan: colSpan,
              label: "Batch Code",
              placeholder: "Select Batch Code",
              type: "Select",
              options: batchCodeList,
              allowClear: true,
              rules: [
                {
                  required: true,
                  message: "Required",
                },
              ]
            },
            {
              name: "batch_time_id",
              colSpan: colSpan,
              label: "Batch Time",
              placeholder: "Select Batch Time",
              type: "Select",
              options: batchTimeList,
              allowClear: true,
              rules: [
                {
                  required: true,
                  message: "Required",
                },
              ]
            },
            {
              name: "permission_other_file_upload",
              colSpan: colSpan,
              label: "Permission For Other File Uploaded",
              type: "Checkbox",
            },
            {
              name: "roll_no_prefix",
              colSpan: colSpan == 12 ? 12 : 24,
              label: "Roll No. Prefix",
              type: "Input",
              rules: [
                {
                  required: true,
                  message: "Required",
                },
              ]
            },
            // {
            //   name: "roll_no_suffix_online",
            //   colSpan: colSpan == 12 ? 8 : 24,
            //   label: "Suffix - Online",
            //   type: "Input",
            //   rules: [
            //     {
            //       required: true,
            //       message: "Required",
            //     },
            //   ]
            // },
            {
              name: "roll_no_suffix_fs",
              colSpan: colSpan == 12 ? 6 : 24,
              label: "FS",
              type: "Input",
              rules: [
                {
                  required: true,
                  message: "Required",
                },
              ]
            },
            // {
            //   name: "roll_no_suffix_ss",
            //   colSpan: colSpan == 12 ? 4 : 24,
            //   label: "SS",
            //   type: "Input",
            //   rules: [
            //     {
            //       required: true,
            //       message: "Required",
            //     },
            //   ]
            // },
            {
              name: "start_date",
              colSpan: colSpan,
              label: "Start Date",
              type: "DatePicker",
              rules: [
                {
                  required: true,
                  message: "Required",
                },
              ]
            },
            {
              name: "end_date",
              colSpan: colSpan,
              label: "End Date",
              type: "DatePicker",
              rules: [
                {
                  required: true,
                  message: "Required",
                },
              ]
            },
            {
              name: "online_seat",
              colSpan: colSpan == 12 ? 8 : 24,
              label: "Online Seat",
              type: "InputNumber",
              rules: [
                {
                  required: true,
                  message: "Required",
                },
              ]
            },
            {
              name: "offline_seat_fs",
              colSpan: colSpan == 12 ? 8 : 24,
              label: "Offline Seat",
              type: "InputNumber",
              rules: [
                {
                  required: true,
                  message: "Required",
                },
              ]
            },
            // {
            //   name: "offline_seat_ss",
            //   colSpan: colSpan == 12 ? 8 : 24,
            //   label: "Offline Seat (SS)",
            //   type: "InputNumber",
            //   rules: [
            //     {
            //       required: true,
            //       message: "Required",
            //     },
            //   ]
            // },
            {
              colSpan: 24,
              label: "Total Seats",
              textlabel: parseInt(formValues?.online_seat || 0) + parseInt(formValues?.offline_seat_fs || 0) + parseInt(formValues?.offline_seat_ss || 0),
              type: "TextLabel",
              rules: [
                {
                  required: true,
                  message: "Required",
                },
              ]
            },
            {
              name: "i_card_template",
              colSpan: colSpan,
              label: "I-Card Template",
              type: "Select",
              options: [
                { value: "Blue Template", name: "Blue Template" },
                { value: "Yellow Template", name: "Yellow Template" }
              ],
              rules: [
                {
                  required: true,
                  message: "Required",
                },
              ]
            },
            // {
            //   name: "pay_online_allowed",
            //   colSpan: colSpan,
            //   label: "Pay Online Allowed?",
            //   type: "Checkbox",
            // },

          ] : []),
          ...(moduleName === 'fee' ? [
            {
              name: "session_id",
              colSpan: colSpan,
              label: "Session",
              placeholder: "Select Session",
              type: "Select",
              options: sessionList,
              allowClear: true,
              rules: [
                {
                  required: true,
                  message: "Required",
                },
              ]
            },
            {
              name: "duration",
              colSpan: colSpan == 8 ? 4 : colSpan,
              label: "Duration",
              placeholder: "In Days",
              type: "Input",
              rules: [
                {
                  required: true,
                  message: "Required",
                },
              ]
            },
            {
              name: "installment_charges",
              colSpan: colSpan == 8 ? 5 : colSpan,
              label: "Installment Charges",
              type: "InputNumber"
            },
            {
              name: "duplicate_icard_fee",
              colSpan: colSpan == 8 ? 5 : colSpan,
              label: "Duplicate I-card Fee",
              type: "InputNumber"
            },
            {
              name: "transfer_fee",
              colSpan: colSpan == 8 ? 5 : colSpan,
              label: "Transfer Fee",
              type: "InputNumber"
            },
            {
              name: "replacement_fee",
              colSpan: colSpan == 8 ? 5 : colSpan,
              label: "Replacement Fee",
              type: "InputNumber"
            },
            {
              colSpan: 24,
              textlabel: "EDC Charges",
              type: "TextLabel"
            },
            {
              name: "edc_credit",
              colSpan: colSpan == 8 ? 3 : colSpan,
              label: "Credit",
              type: "InputNumber"
            },
            {
              name: "edc_debit",
              colSpan: colSpan == 8 ? 3 : colSpan,
              label: "Debit",
              type: "InputNumber"
            },
            {
              name: "edc_qr",
              colSpan: colSpan == 8 ? 3 : colSpan,
              label: "QR",
              type: "InputNumber"
            },
            {
              colSpan: 24,
              textlabel: <><div><Divider /><p className="text-center">Main Fee Details with Installments</p></div></>,
              type: "TextLabel"
            },
            {
              name: "general_admission_fee",
              colSpan: colSpan,
              label: "Admission Fee",
              type: "InputNumber",
              rules: [
                {
                  required: true,
                  message: "Required",
                },
              ]
            },
            {
              name: "general_tuition_fee",
              colSpan: colSpan,
              label: "Tuition Fee",
              type: "InputNumber",
              rules: [
                {
                  required: true,
                  message: "Required",
                },
              ]
            },
            {
              name: "general_books_fee",
              colSpan: colSpan,
              label: "Books Fee",
              type: "InputNumber",
              rules: [
                {
                  required: true,
                  message: "Required",
                },
              ]
            },
            {
              name: "general_total_fee",
              colSpan: colSpan == 8 ? 12 : colSpan,
              label: "Total Fee",
              type: "Input",
              disabled: true
            },
            {
              name: "general_installments_count",
              colSpan: colSpan == 8 ? 12 : colSpan,
              label: "No of Installment (Main Fee)",
              type: "Select",
              allowClear: true,
              options: [
                { name: 'No Instalments', value: 0 },
                // { name: 'Instalment 1', value: 1 },
                { name: 'Instalment 2', value: 2 },
                { name: 'Instalment 3', value: 3 },
                { name: 'Instalment 4', value: 4 },
                { name: 'Instalment 5', value: 5 }
              ]
            },
            ...(formValues?.general_installments_count && formValues?.general_installments_count > 0 ?
              [{
                name: "general_instalment_structure",
                colSpan: 24,
                rows: formValues?.general_installments_count,
                elementPrefix: "general_",
                titles: ["Installment No", "Admission Ins. Amount", "Tuition Ins. Amount", "Book Ins. Amount", "Ins. Amount", "Ins. Sub. Day After"],
                type: "GridData"
              }]
              : []),


            {
              colSpan: 24,
              textlabel: <><div><Divider /><p className="text-center">Physically Handicapped Fee, Discount & Installments</p></div></>,
              type: "TextLabel"
            },
            {
              name: "ph_admission_discount",
              colSpan: colSpan == 8 ? 6 : colSpan,
              label: "Admission Discount",
              type: "InputNumber",
            },
            {
              name: "ph_tuition_discount",
              colSpan: colSpan == 8 ? 6 : colSpan,
              label: "Tuition Discount",
              type: "InputNumber",
            },
            {
              name: "ph_books_discount",
              colSpan: colSpan == 8 ? 6 : colSpan,
              label: "Books Discount",
              type: "InputNumber",
            },
            {
              name: "ph_total_discount",
              colSpan: colSpan == 8 ? 6 : colSpan,
              label: "Total Dicount",
              type: "Input",
              disabled: true
            },
            {
              name: "ph_admission_fee",
              colSpan: colSpan == 8 ? 6 : colSpan,
              label: "Admission Fee",
              type: "Input",
              disabled: true
            },
            {
              name: "ph_tuition_fee",
              colSpan: colSpan == 8 ? 6 : colSpan,
              label: "Tuition Fee",
              type: "Input",
              disabled: true
            },
            {
              name: "ph_books_fee",
              colSpan: colSpan == 8 ? 6 : colSpan,
              label: "Books Fee",
              type: "Input",
              disabled: true
            },
            {
              name: "ph_total_fee",
              colSpan: colSpan == 8 ? 6 : colSpan,
              label: "Total Fee",
              type: "Input",
              disabled: true
            },
            {
              name: "ph_installments_count",
              colSpan: colSpan,
              label: "No of Installment (Main Fee)",
              type: "Select",
              options: [
                { name: 'No Instalments', value: 0 },
                // { name: 'Instalment 1', value: 1 },
                { name: 'Instalment 2', value: 2 },
                { name: 'Instalment 3', value: 3 },
                { name: 'Instalment 4', value: 4 },
                { name: 'Instalment 5', value: 5 }
              ]
            },
            ...(formValues?.ph_installments_count && formValues?.ph_installments_count > 0 ?
              [{
                name: "ph_instalment_structure",
                colSpan: 24,
                rows: formValues?.ph_installments_count,
                elementPrefix: "ph_",
                titles: ["Installment No", "Admission Ins. Amount", "Tuition Ins. Amount", "Book Ins. Amount", "Ins. Amount", "Ins. Sub. Day After"],
                type: "GridData"
              }]
              : []),
            {
              colSpan: 24,
              type: "TextLabel"
            },
            // {
            //   name: "min_percent_first_ins_book_fee",
            //   colSpan: colSpan == 8 ? 12 : colSpan,
            //   label: "Min. % of First Ins. for Book Fee",
            //   type: "InputNumber",
            //   rules: [
            //     {
            //       required: true,
            //       message: "Required",
            //     },
            //   ]
            // },
            // {
            //   name: "is_short_receipt",
            //   colSpan: colSpan == 8 ? 12 : colSpan,
            //   label: "IS Short Receipt",
            //   type: "Checkbox"
            // },

          ] : [])
        ],
      extraButtons: [
        {
          // htmlType: "button",
          colSpan: 5,
          loading: false,
          submitButtonText: "Cancel",
          onClick: onClose,
        },
        {
          // htmlType: "button",
          colSpan: 5,
          loading: false,
          submitButtonText: "Clear",
          type: "clearForm",
        },
        {
          htmlType: "submit",
          colSpan: 5,
          loading: false,
          submitButtonText: "Save",
          submitButtonType: "primary",
        },
      ],
      onFinish: onFinish,
      onFinishFailed: onFinishFailed,
      onValuesChange: onValuesChange,
    });
  }, [centerList, courseList, batchTypeList, examList, streamList, venueList, companyList, formValues]);

  console.log(formValues);
  return (
    <>
      {formProps?.heading && (
        <Drawer
          maskClosable={false}
          title={<strong>{formProps.heading}</strong>}
          className="form-drawer"
          width={
            screenType.isPortrait
              ? "90%"
              : screenType.isLg || screenType.isXl
                ? (moduleName == 'fee' ? 1024 : 720)
                : 500
          }
          onClose={onClose}
          open={visible}
        >
          <GenericFormComponent {...formProps} />
        </Drawer>
      )}
    </>
  );
};

export default CreateEditBatchTypeRelationship;
