import { Button, Col, Row, Space, Table } from "antd";
import { useCallback, useEffect, useState } from "react";
import { BiFilterAlt } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { feeClosingApporval, requestCoursePayments, setFilters } from "../../../core/store/reducers/admission.slice";
import { getMasterDropdownData } from "../../../core/store/reducers/masters.slice";
import ContentHeaderComponent from "../../../shared/components/content-header/content-header.component";
import GenericFormComponent from "../../../shared/components/generic-form/generic-form.component";
import { RootState } from "../../../shared/types/common";
import { formatDate, showNotification } from "../../../shared/utils/utils";


function FeeClosingApprovalList() {
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [filterVisible, setFilterVisible] = useState(true);
    const [paymentsList, setPaymentsList] = useState([] as any);
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { payments, filters, total } = useSelector((state: RootState) => state.admission);
    const [sorter, setSorter] = useState({ sort_by: "", sort_type: "" });
    const [pagination, setPagination] = useState({ page: 1, limit: 1000 });
    const [centerList, setCenterList] = useState([] as any);
    const [sessionList, setSessionList] = useState([] as any);
    const { user } = useSelector((state: RootState) => state.auth);
    const role = user.user_role;
    useEffect(() => {
        loadList();
        dispatch(getMasterDropdownData({ schemaKey: "session", labelKey: "session" })).then((val: any) => {
            if (val?.payload?.data?.success) {
                setSessionList(val?.payload?.data?.data?.map((val: any) => {
                    return { label: val.session, value: val._id }
                }))
            }
        })
        dispatch(getMasterDropdownData({ schemaKey: "center", labelKey: "center_name" })).then((val: any) => {
            if (val?.payload?.data?.success) {
                setCenterList(val?.payload?.data?.data?.map((val: any) => {
                    return { label: val.center_name, value: val._id }
                }))
            }
        })
    }, [])

    const loadList = useCallback(
        (_page: any = 1) => {
            if (Object.keys(filters)?.length == 0) {
                return
            }
            const data = {
                ...pagination,
                ...sorter,
            };
            // const filters1 = {...filters, }
            let filters1 = { ...filters };
            if (filters1.created_on?.length == 2) {
                filters1 = { ...filters1, created_on: [formatDate(filters1.created_on[0], 'YYYY-MM-DD 00:00'), formatDate(filters1.created_on[1], 'YYYY-MM-DD 23:59')] }
            }
            dispatch(requestCoursePayments({ params: data, body: { ...filters1 } }));
        },
        [filters, dispatch, sorter, pagination]
    );
    useEffect(() => {
        loadList();
    }, [loadList]);

    useEffect(() => {
        if (payments?.length > 0) {
            setPaymentsList(
                payments.map((obj: any, index: number) => {
                    return { key: obj._id, ...obj };
                })
            );
        } else {
            setPaymentsList([])

        }
    }, [payments]);
    const onPageChange = (pageNumber: number) => {
        setPagination({
            page: pageNumber,
            limit: pagination.limit,
        });
    };

    const columns = [
        {
            title: 'Collection Date',
            render: (data: any) => {
                return formatDate(data.actual_payment_date, "DD MMM, YYYY")
            },
            key: 'actual_payment_date',
        },
        {
            title: 'Roll. No.',
            dataIndex: ['course_enrolment_id', 'roll_no'],
            key: 'candidate_name',
        },
        {
            title: 'Name',
            dataIndex: ['candidate_id', 'candidate_name'],
            key: 'candidate_name',
        },
        {
            title: 'Amount',
            dataIndex: 'total_amount',
            key: 'total_amount',
        },
        {
            title: 'Mode',
            dataIndex: 'payment_mode',
            key: 'payment_mode',
        },
        {
            title: 'Center',
            dataIndex: ["course_enrolment_id", 'batch_id', 'center_id', 'center_name'],
            key: 'center_name',
        },
        {
            title: 'Collection For',
            dataIndex: ["course_enrolment_id", 'batch_id', 'course_id', 'course_name'],
            key: 'course_name',
        },
        {
            title: 'Remark',
            dataIndex: "remarks",
            key: 'remarks',
        },
        {
            title: 'Collector Name',
            dataIndex: ["created_by", "name"],
            key: 'name_admin',
        },
        {
            title: 'Session',
            dataIndex: ["course_enrolment_id", 'batch_id', 'session_id', 'session'],
            key: 'session',
        }
    ];
    const headerButtons = (
        <Space>
            <Button
                shape="circle"
                type="primary"
                icon={
                    <span role="img" className="anticon">
                        <BiFilterAlt />
                    </span>
                }
                onClick={() => {
                    setFilterVisible(!filterVisible);
                }}
            ></Button>
        </Space>
    );
    const onFilterApply = (values: any) => {
        let arrFilter = [];
        for (let [key, value] of Object.entries(values)) {
            if (value !== "") {
                arrFilter.push({ key: key, value: value });
            }
        }
        let refinedArray = arrFilter.filter((data) => {
            return data.value !== undefined;
        });
        let obj = {} as any;
        refinedArray.forEach((data: any) => {
            obj[data.key] = data.value;
        });
        setPagination({
            page: 1,
            limit: pagination.limit,
        });
        dispatch(setFilters(obj)); //filters
    };
    const formProps = {
        name: "fee-filters",
        enableEnterSubmit: true,
        formElements: [
            {
                name: "center_id",
                label: "",
                placeholder: "Center Name",
                type: "Select",
                options: centerList,
                allowClear: true,
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 6 },
            },
            {
                name: "session_id",
                label: "",
                placeholder: "Session",
                type: "Select",
                options: sessionList,
                allowClear: true,
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 6 },
            },
            {
                name: "fee_closing_approval",
                label: "",
                placeholder: "Approval Status",
                type: "Select",
                options: [
                    { value: true, name: 'Approved' },
                    { value: false, name: 'Pending' },
                ],
                allowClear: true,
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 6 },
            },
            {
                name: "is_book_fee",
                label: "",
                placeholder: "Report Type",
                type: "Select",
                options: [
                    { value: false, name: 'Education' },
                    { value: true, name: 'Publication' },
                ],
                allowClear: true,
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 6 },
            },
            {
                name: "created_on",
                label: "Collection Date Range",
                type: "RangePicker",
                allowClear: true,
                colSpanResponsive: { xs: 12, sm: 8, md: 8, lg: 8 },
            },
        ],
        initialValues: {},
        extraButtons: [
            {
                htmlType: "submit",
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 3 },
                loading: false,
                submitButtonText: "Clear",
                type: "clearForm",
            },
            {
                htmlType: "submit",
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 3 },
                loading: false,
                submitButtonText: "Apply",
                submitButtonType: "primary",
            },
        ],
        onFinish: onFilterApply,
        onFinishFailed: null,
        onValuesChange: null,
    };
    const onChangeHandler = (_pagination: any, _filters: any, _sorter: any) => {
        if (_sorter.order === undefined) {
            setSorter({ sort_by: "", sort_type: "" });
        } else {
            let order = "";
            let columnKey = "";
            if (_sorter.order === "ascend") order = "asc";
            else order = "desc";

            if (_sorter.columnKey === "enabled") {
                columnKey = "status";
            } else if (_sorter.columnKey === "displayName") {
                columnKey = "name";
            } else {
                columnKey = _sorter.columnKey;
            }
            setSorter({ sort_by: columnKey, sort_type: order });
        }
    };
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;
    const approveSelected = () => {
        if (selectedRowKeys.length > 0) {
            // let paymentIds: any = [];
            // paymentsList.forEach((payment: any) => {
            //     paymentIds.push(payment._id)
            // });
            dispatch(feeClosingApporval({ ids: selectedRowKeys })).then((val: any) => {
                if (val?.payload?.data?.success) {
                    showNotification('Approved Successfully', 'success');
                    loadList()
                }
            })
        }
    }
    return <>
        <div
            className="mx-6 mt-2 bg-primary-white-blueish border-primary-gray-02"
            style={{ borderBottom: "none!important" }}
        >
            <Row>
                <Col span={24}>
                    <ContentHeaderComponent
                        title="Fee Closing Approval"
                        subtitle=""
                        buttons={headerButtons}
                    />
                </Col>
            </Row>

            {filterVisible && (
                <Row className="bg-body">
                    <Col span={24} className="py-1">
                        <div className="px-3 table-filter-form">
                            <GenericFormComponent {...formProps} />
                        </div>
                    </Col>
                </Row>
            )}
        </div>
        <div className="mx-6 bg-primary-white-blueish border-primary-gray-02">
            <Table
                className="app-table"
                tableLayout="auto"
                pagination={false}
                loading={false}
                scroll={{ x: '1800px' }}
                dataSource={paymentsList}
                columns={columns}
                rowSelection={rowSelection}
                onChange={onChangeHandler}
                summary={(pageData: any) => {
                    console.log(pageData);
                    let totalSum = 0;
                    pageData.map((val: any) => {
                        totalSum += val.total_amount
                    })
                    return <>
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0}></Table.Summary.Cell>
                            <Table.Summary.Cell index={1}></Table.Summary.Cell>
                            <Table.Summary.Cell index={2} colSpan={2}><strong>Total</strong></Table.Summary.Cell>
                            <Table.Summary.Cell index={3} colSpan={2}>
                                {totalSum}
                            </Table.Summary.Cell>
                        </Table.Summary.Row>

                    </>
                }}
            />
            {(role == 'accounts' || role == 'admin') && <Row justify="end">
                <Col span={6}>
                    <Button type="primary" onClick={approveSelected} disabled={!hasSelected}>Approve</Button>
                </Col>
            </Row>}
        </div>
        {/* <DataTableComponent
            loading={false}
            dataSource={paymentsList}
            columns={columns}
            onChange={onChangeHandler}
            title={"Payment List"}
            defaultPage={0}
            currentPage={pagination.page}
            totalRows={total}
            onPageChange={onPageChange}
            maxWidth="2000px"
        /> */}


    </>


}
export default FeeClosingApprovalList;


