import { Button, Col, Form, Row, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBatch, getBatchType, getCenterList, getCourseListByCenter, getExams, getMasterDataList, getStreams } from "../../../core/store/reducers/masters.slice";
import { RootState } from "../../../shared/types/common";
const { Option } = Select
var _ = require("lodash")
function CourseCardCreate({ setCourseData, candidateData }: any) {

    const dispatch = useDispatch();
    const { masterData, centers, courses, batchType, exams, streams, batch } = useSelector((state: RootState) => state.masters);
    const [sessionId, setSessionId] = useState(null)
    const [courseId, setCourseId] = useState(null)
    const [centerId, setCenterId] = useState(null)
    const [batchTypeId, setBatchTypeId] = useState(null)
    const [examId, setExamId] = useState(null)
    const [streamId, setStreamId] = useState(null)
    const [batchDetails, setBatchDetails] = useState(null as any)
    const [form] = useForm();
    useEffect(() => {
        dispatch(getMasterDataList({ path: "session", params: {}, body: {} }))
        dispatch(getCenterList())
    }, [])


    const getRelevantData = (event: any, key: any) => {
        if (event === "center") {
            setSessionId(key)
            form.setFieldsValue({
                batch_id: null
            })
            setBatchDetails(null)
            if (centerId && courseId && batchTypeId && examId && streamId) {
                dispatch(getBatch({ session_id: key, center_id: centerId, course_id: courseId, batch_type_id: batchTypeId, exam_id: examId, stream_id: streamId }))
            }
        }
        if (event === "course") {
            setCenterId(key)
            dispatch(getCourseListByCenter({ center_id: key }))
            form.setFieldsValue({
                course_id: null,
                batch_type_id: null,
                exam_id: null,
                stream_id: null,
                batch_id: null
            })
            setCourseId(null)
            setBatchTypeId(null)
            setExamId(null)
            setStreamId(null)
            setBatchDetails(null)
        }
        if (event === "batchType") {
            setCourseId(key)
            dispatch(getBatchType({ center_id: centerId, course_id: key }))
            form.setFieldsValue({
                batch_type_id: null,
                exam_id: null,
                stream_id: null,
                batch_id: null
            })
            setBatchTypeId(null)
            setExamId(null)
            setStreamId(null)
            setBatchDetails(null)
        }
        if (event === "exam") {
            setBatchTypeId(key)
            dispatch(getExams({ center_id: centerId, course_id: courseId, batch_type_id: key }))
            form.setFieldsValue({
                exam_id: null,
                stream_id: null,
                batch_id: null
            })
            setExamId(null)
            setStreamId(null)
            setBatchDetails(null)
        }
        if (event === "stream") {
            setExamId(key)
            dispatch(getStreams({ center_id: centerId, course_id: courseId, batch_type_id: batchTypeId, exam_id: key }))
            form.setFieldsValue({
                stream_id: null,
                batch_id: null
            })
            setStreamId(null)
            setBatchDetails(null)
        }
        if (event === "batch") {
            setStreamId(key)
            dispatch(getBatch({ session_id: sessionId, center_id: centerId, course_id: courseId, batch_type_id: batchTypeId, exam_id: examId, stream_id: key }))
            form.setFieldsValue({
                batch_id: null
            })
            setBatchDetails(null)
        }
        if (event === "details") {
            setBatchDetails(_.find(batch, function (n: any) {
                if (n?._id === key) {
                    return n;
                }
            }))
        }
    }
    return <Form form={form} onFinish={(values: any) => {
        setCourseData(values)
    }}>
        <Row gutter={50}>
            <Col span={12}>
                <Form.Item label={"Session"} rules={[{ required: true }]} name={"session_id"}>
                    <Select placeholder={"Choose Session"} onChange={(evt) => getRelevantData("center", evt)}>
                        {
                            masterData.map((item: any) => {
                                return <Option value={item?._id}>{item?.session}</Option>
                            })
                        }
                    </Select>
                </Form.Item>
            </Col>

            <Col span={12}>
                <Form.Item label={"Centre"} rules={[{ required: true }]} name={"center_id"}>
                    <Select placeholder={"Choose Center"} onChange={(evt) => getRelevantData("course", evt)}>
                        {
                            centers.map((item: any) => {
                                return <Option value={item?._id}>{item?.center_name}</Option>
                            })
                        }
                    </Select>
                </Form.Item>
            </Col>

            <Col span={12}>
                <Form.Item label={"Course"} rules={[{ required: true }]} name={"course_id"}>
                    <Select placeholder={"Choose Course"} onChange={(evt) => getRelevantData("batchType", evt)}>
                        {
                            courses.map((item: any) => {
                                return <Option value={item?.course_ref?._id}>{item?.course_ref?.course_name}</Option>
                            })
                        }
                    </Select>
                </Form.Item>
            </Col>


            <Col span={12}>
                <Form.Item label={"Batch Type"} rules={[{ required: true }]} name={"batch_type_id"}>
                    <Select placeholder={"Choose Batch Type"} onChange={(evt) => getRelevantData("exam", evt)}>
                        {
                            batchType.map((item: any) => {
                                return <Option value={item?.batch_type_id?._id}>{item?.batch_type_id?.batch_type_name}</Option>
                            })
                        }
                    </Select>
                </Form.Item>
            </Col>

            <Col span={12}>
                <Form.Item label={"Exam"} rules={[{ required: true }]} name={"exam_id"}>
                    <Select placeholder={"Choose Exam"} onChange={(evt) => getRelevantData("stream", evt)}>
                        {
                            exams.map((item: any) => {
                                return <Option value={item?.exam_id?._id}>{item?.exam_id?.exam_name}</Option>
                            })
                        }
                    </Select>
                </Form.Item>
            </Col>

            <Col span={12}>
                <Form.Item label={"Stream"} rules={[{ required: true }]} name={"stream_id"}>
                    <Select placeholder={"Choose Stream"} onChange={(evt) => getRelevantData("batch", evt)}>
                        {
                            streams.map((item: any) => {
                                return <Option value={item?.stream_id?._id}>{item?.stream_id?.stream_name}</Option>
                            })
                        }
                    </Select>
                </Form.Item>
            </Col>

            <Col span={12}>
                <Form.Item label={"Batch"} rules={[{ required: true }]} name={"batch_id"}>
                    <Select placeholder={"Choose Batch"} onChange={(evt) => getRelevantData("details", evt)}>
                        {
                            batch.map((item: any) => {
                                return <Option value={item?._id}>{item?.batch_code_id?.batch_code}</Option>
                            })
                        }
                    </Select>
                </Form.Item>
            </Col>
            {/* <Col span={6}>
                <Form.Item name={"is_old_student"} label={""} style={{ marginTop: "30px" }} valuePropName={"checked"}>
                    <Checkbox>IS OLD STUDENT</Checkbox>
                </Form.Item>
            </Col> */}

            <Col span={18}>
                {
                    batchDetails && <Form.Item label={"Batch Seat Details"}>
                        <p>
                            Total : {batchDetails?.total_seat} {" "}
                            Online : {batchDetails?.online_seat} {" "}
                            Online Filled : 0 {" "}
                            Online Remaining : 0 {" "}
                            Offline: {batchDetails?.offline_seat_fs} {" "}
                            Offline Filled : {batchDetails?.offline_seat_ss} {" "}
                            Offline Remaining : 0 {" "}
                        </p>
                    </Form.Item>
                }
            </Col>


            <Col span={6}>
                <Form.Item label={""} style={{ marginTop: "30px" }}>
                    <Button htmlType="submit" type="primary">Add Payment Details</Button>
                </Form.Item>
            </Col>


        </Row>
    </Form>
}
export default CourseCardCreate;


