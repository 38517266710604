import { Form, Select } from "antd";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getBatch, getBatchType, getCenterList, getCourseListByCenter, getExams, getMasterDropdownData, getStreams } from "../../../core/store/reducers/masters.slice";
import GenericFormComponent from "../../../shared/components/generic-form/generic-form.component";
import { RootState } from "../../../shared/types/common";
const { Option } = Select
const FilterRelationship = ({ type, pagination, setPagination, setFilters }: any) => {
    const dispatch = useDispatch();
    const { centers, courses, batchType, exams, streams, batch } = useSelector((state: RootState) => state.masters);
    const [courseId, setCourseId] = useState(null)
    const [centerId, setCenterId] = useState(null)
    const [batchTypeId, setBatchTypeId] = useState(null)
    const [examId, setExamId] = useState(null)
    const [sessions, setSessions] = useState([] as any);
    const [form] = Form.useForm();
    useEffect(() => {
        dispatch(getCenterList())
        dispatch(getMasterDropdownData({ schemaKey: "session", labelKey: "session" })).then((val: any) => {
            if (val?.payload?.data?.success) {
                setSessions(val?.payload?.data?.data?.map((val: any) => {
                    return { label: val.session, value: val._id }
                }))
                // if (val?.payload?.data?.data?.length > 0) {
                //     onFilterApply({ session_id: val?.payload?.data?.data?.[val?.payload?.data?.data?.length - 1]?._id })
                //     form.setFieldValue('session_id', val?.payload?.data?.data?.[val?.payload?.data?.data?.length - 1]?._id)
                // }
                // setSessionId(val?.payload?.data?.data?.[val?.payload?.data?.data?.length - 1]?._id)

            }
        })
    }, [])
    // useEffect(() => {
    //     if (centers?.length > 0) {
    //         let selC = centers?.filter((val: any) => val.center_code = "CR")
    //         if (selC?.length > 0) {
    //             getRelevantData('course', selC[0]?._id);
    //             setFilters({ center_id: selC[0]?._id });
    //         }
    //     }
    // }, [centers])

    const getRelevantData = (event: any, key: any) => {
        if (event === "course") {
            setCenterId(key)
            dispatch(getCourseListByCenter({ center_id: key }))
        }
        if (event === "batchType") {
            setCourseId(key)
            dispatch(getBatchType({ center_id: centerId, course_id: key }))
        }
        if (event === "exam") {
            setBatchTypeId(key)
            dispatch(getExams({ center_id: centerId, course_id: courseId, batch_type_id: key }))
        }
        if (event === "stream") {
            setExamId(key)
            dispatch(getStreams({ center_id: centerId, course_id: courseId, batch_type_id: batchTypeId, exam_id: key }))
        }
        if (event === "batch") {
            dispatch(getBatch({ center_id: centerId, course_id: courseId, batch_type_id: batchTypeId, exam_id: examId, stream_id: key }))
        }
    }
    const onFilterApply = (values: any) => {
        let arrFilter = [];
        for (let [key, value] of Object.entries(values)) {
            if (value !== "") {
                arrFilter.push({ key: key, value: value });
            }
        }
        let refinedArray = arrFilter.filter((data) => {
            return data.value !== undefined;
        });
        let obj = {} as any;
        refinedArray.forEach((data: any) => {
            obj[data.key] = data.value;
        });
        setPagination({
            page: 1,
            limit: pagination.limit,
        });
        setFilters(obj); //filters
    };
    const formProps = {
        name: "candidate-filters",
        enableEnterSubmit: true,
        formElements: [
            ...(type?.includes('session') ? [{
                name: "session_id",
                label: "",
                placeholder: "Search Session",
                type: "Select",
                options: sessions?.map((item: any) => {
                    return {
                        name: item?.label, value: item?.value
                    }
                }),
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
            }] : []),
            ...(type?.includes('center') ? [{
                name: "center_id",
                label: "",
                placeholder: "Search Center",
                type: "Select",
                options: centers?.map((val: any) => {
                    return {
                        name: val.center_name, value: val._id
                    }
                }),
                onChange: (evt: any) => getRelevantData('course', evt),
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
            }] : []),
            ...(type?.includes('course') ? [{
                name: "course_id",
                label: "",
                placeholder: "Search Course",
                type: "Select",
                options: courses?.map((val: any) => {
                    return {
                        name: val?.course_ref?.course_name, value: val?.course_ref?._id
                    }
                }),
                onChange: (evt: any) => getRelevantData('batchType', evt),
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
            }] : []),
            ...(type?.includes('batch_type') ? [{
                name: "batch_type_id",
                label: "",
                placeholder: "Search Batch Type",
                type: "Select",
                options: batchType?.map((val: any) => {
                    return {
                        name: val?.batch_type_id?.batch_type_name, value: val?.batch_type_id?._id
                    }
                }),
                onChange: (evt: any) => getRelevantData('exam', evt),
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
            }] : []),
            ...(type?.includes('exam') ? [{
                name: "exam_id",
                label: "",
                placeholder: "Search Exam",
                type: "Select",
                options: exams?.map((item: any) => {
                    return {
                        name: item?.exam_id?.exam_name, value: item?.exam_id?._id
                    }
                }),
                onChange: (evt: any) => getRelevantData('stream', evt),
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
            }] : []),
            ...(type?.includes('stream') ? [{
                name: "stream_id",
                label: "",
                placeholder: "Search Stream",
                type: "Select",
                options: streams?.map((item: any) => {
                    return {
                        name: item?.stream_id?.stream_name, value: item?.stream_id?._id
                    }
                }),
                onChange: (evt: any) => getRelevantData('batch', evt),
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
            }] : []),
            ...(type?.includes('batch') ? [{
                name: "batch_id",
                label: "",
                placeholder: "Search Batch",
                type: "Select",
                options: batch?.map((item: any) => {
                    return {
                        name: item?.batch_code_id?.batch_code, value: item?._id
                    }
                }),
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
            }] : []),

        ],
        initialValues: {},
        extraButtons: [
            {
                htmlType: "submit",
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 3 },
                loading: false,
                submitButtonText: "Clear",
                type: "clearForm",
            },
            {
                htmlType: "submit",
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 3 },
                loading: false,
                submitButtonText: "Apply",
                submitButtonType: "primary",
            },
        ],
        onFinish: onFilterApply,
        onFinishFailed: null,
        onValuesChange: null,
    };

    return (
        <GenericFormComponent {...formProps} />
    );
};

export default FilterRelationship;