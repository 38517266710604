import { CheckCircleOutlined, CloseCircleOutlined, InboxOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Col, Form, Row, Spin, UploadProps, message, notification } from "antd";
import { useForm } from "antd/lib/form/Form";
import Dragger from "antd/lib/upload/Dragger";
import Papa from "papaparse";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createStudentWithLoginCreds } from "../../../core/store/reducers/user-management.slice";

const COLUMNS = [
    "NAME",
    "PHONE",
    "EMAIL"
]

function BulkImportCandidatesWebsite() {
    const dispatch = useDispatch();
    const [uploadForm] = useForm();
    const [csvFile, setCsvFile] = useState(null as any);
    const [records, setRecords] = useState([] as any);
    const [recordFields, setRecordFields] = useState([] as any);
    const [processing, setProcessing] = useState(false);
    const [completed, setCompleted] = useState(false);

    const props: UploadProps = {
        name: "file",
        beforeUpload: (file) => {
            // setProcessing(true);
            setCsvFile(file);
            return false;
        },
        onRemove: () => {
            setCsvFile(null as any);
        },
    };
    useEffect(() => {
        if (csvFile) {
            Papa.parse(csvFile, {
                header: true,
                skipEmptyLines: true,
                complete: (results: any) => {
                    if (results?.data?.length > 0 && results?.meta?.fields?.length > 0) {
                        if (results.data?.length > 5000) {
                            message.error("Number of records should not exceed 5000");
                        } else {
                            processRecords(results.data, results.meta.fields);
                        }
                    } else {
                        message.error("Invalid CSV file");
                    }
                    uploadForm.resetFields();
                },
            });
        }
    }, [csvFile]);
    useEffect(() => {
        if (records.length > 0 && recordFields?.length > 0) {
            console.log(records);
            console.log(recordFields);
        }
    }, [records, recordFields]);
    const processRecords = (records: any, columns: any) => {
        console.log(records)
        console.log(columns)

        if (!((columns.length === COLUMNS.length) && columns.every((value: string, index: number) => value === COLUMNS[index]))) {
            notification.error({ message: 'Columns incorrect' });
            uploadForm.resetFields();
            setCsvFile(null as any);
            return;
        }
        const tableColumns = [
            {
                title: 'S. No.',
                dataIndex: 'sno',
                key: 'sno',
            },
            {
                title: 'NAME',
                dataIndex: 'NAME',
                key: 'NAME',
            },
            {
                title: 'PHONE',
                dataIndex: 'PHONE',
                key: 'PHONE',
            },
            {
                title: 'EMAIL',
                dataIndex: 'EMAIL',
                key: 'EMAIL',
            },
            {
                title: 'DETAILS',
                key: 'DETAILS',
                render: (record: any) => {
                    return <>
                        {record.processing && <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />}
                        {record.success && <CheckCircleOutlined style={{ fontSize: 18, color: 'green' }} />}
                        {record.error && <CloseCircleOutlined style={{ fontSize: 18, color: 'red' }} />}
                        {record.message && <>{" "} {record.message}</>}
                    </>
                }
            }
        ]

        records = records?.map((val: any, index: number) => {
            return { sno: index + 1, ...val, processing: false, success: false, error: false, message: "" }
        })
        setRecordFields(tableColumns)
        setRecords(records)
    }
    async function startUpload() {
        setProcessing(true)
        let recordsData = [...records];
        for (let k = 0; k < records?.length; ++k) {
            recordsData[k].processing = true;
        }
        setRecords(recordsData)
        for (let k = 0; k < records?.length; ++k) {
            const ret = await dispatch(createStudentWithLoginCreds(
                {
                    name: recordsData[k]['NAME'],
                    contact: recordsData[k]['PHONE'],
                    email: recordsData[k]['EMAIL'],
                    password: recordsData[k]['PHONE'],
                    source: 'backend',
                    sendmail: false
                }
            ))
            recordsData[k].processing = false;
            if (ret?.payload?.data?.success) {
                recordsData[k].success = true;
            } else {
                recordsData[k].error = true;
                recordsData[k].message = ret?.payload?.data?.message;
            }
            setRecords(recordsData)
        }
        // setTimeout(() => {
        setProcessing(false);
        setCompleted(true);
        setRecords(recordsData);
        // }, 10000);
    }
    return <>
        <div
            className="mx-6 mt-2 bg-primary-white-blueish border-primary-gray-02"
            style={{ borderBottom: "none!important" }}
        >
            <Row>
                <Col span={24}>
                    <div className="my-3 mx-4">
                        <Row align="middle">
                            <Col flex="auto">
                                <span className="weight-300 font-size-24">Bulk Import Candidates (w/ Login Create)</span>
                            </Col>
                            <Col>

                            </Col>
                        </Row>
                    </div>
                    <div className="my-3 mx-4">
                        <Row align="middle" justify="center">
                            <Col span={24}>
                                {records?.length === 0 && (
                                    <Form form={uploadForm}>
                                        <Row justify="center">
                                            <Col span={6}>
                                                <Form.Item>
                                                    <Dragger {...props}>
                                                        <p className="ant-upload-drag-icon">
                                                            <InboxOutlined />
                                                        </p>
                                                        <p className="ant-upload-text">
                                                            Click or drag csv file this area to upload
                                                        </p>
                                                    </Dragger>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </Col>
                        </Row>
                    </div>
                    {records?.length > 0 && <div className="my-3 mx-4">
                        <Row align="middle" justify="center">
                            <Col span={18}>
                                <h2>Preview Records</h2>
                            </Col>
                            <Col span={6}>
                                {!completed && <Button disabled={processing} loading={processing} block onClick={() => startUpload()}>Start Upload</Button>}
                                {completed && <>Upload Complete</>}
                            </Col>
                            <Col span={24}>
                                {completed && <>
                                    Records have been uploaded. <br />
                                    Success: {records?.filter((val: any) => val.success)?.length}<br />
                                    Failed: {records?.filter((val: any) => val.error)?.length}<br />
                                </>}
                            </Col>
                            <Col span={24}>
                                <div className="ant-table">
                                    <div className="ant-table-container">
                                        <div className="ant-table-content">
                                            <table style={{ tableLayout: "auto" }}>
                                                <thead className="ant-table-thead">
                                                    <tr>{recordFields?.map((column: any) => { return <th className="ant-table-cell">{column.title}</th> })}</tr>
                                                </thead>
                                                <tbody className="ant-table-tbody">
                                                    {records?.map((val: any) => { return <tr>{recordFields?.map((column: any) => { return <td className="ant-table-cell">{column.dataIndex ? val[column.dataIndex] : column.render(val)}</td> })}</tr> })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                {/* <Table
                                    columns={recordFields}
                                    dataSource={records}
                                    pagination={{ pageSize: records.length }}
                                /> */}
                            </Col>
                        </Row>
                    </div>}
                </Col>
            </Row>
        </div>
    </>
}
export default BulkImportCandidatesWebsite;