import { Button, Col, DatePicker, Form, Input, Row, Select, Table, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPurchasedOnlinePackages, paymentSubmit } from "../../../core/store/reducers/admission.slice";
import { getGenericList } from "../../../core/store/reducers/website.slice";
import { RootState } from "../../../shared/types/common";

function ManualCourseAllotment({ candidateData, setModalOpen }: any) {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [formData, setFormData] = useState(null as any)
    const [purchasedPackages, setPurchasedPackages] = useState([] as any)
    const { genericList } = useSelector(
        (state: RootState) => state.website
    );
    const { user } = useSelector((state: RootState) => state.auth);
    useEffect(() => {
        form.setFieldsValue(formData);
    }, [formData])
    useEffect(() => {
        dispatch(getPurchasedOnlinePackages(candidateData?._id)).then((val: any) => {
            if (val?.payload?.data?.success) {
                setPurchasedPackages(val?.payload?.data?.data)
            }
        })
    }, [])
    const onValuesChange = (value: any, values: any) => {
        setFormData({ ...formData, ...values })
        if (value?.course_type) {
            dispatch(getGenericList({ params: { offset: 0, limit: 10000 }, body: { course_type: value?.course_type }, module: 'course' }));
            form.resetFields()
            form.setFieldValue('course_type', value?.course_type)
        }
        if (value?.website_course_id) {
            let selectedCourse = genericList.filter((val: any) => val?._id == value?.website_course_id)
            setFormData({
                ...values,
                payment_mode: 'Online',
                bank_name: '',
                ref_no: '',
                total_amount: selectedCourse[0].discounted_fee,
                remarks: '',
                validity: selectedCourse[0]?.validity
                    ? moment().add(selectedCourse[0]?.validity, "d").format("YYYY-MM-DD")
                    : null,
            })
        }

    }
    const onFinish = (values: any) => {
        const payload = {
            ...formData, candidate_id: candidateData?._id,
            created_by: user._id
        };
        console.log(payload)
        dispatch(paymentSubmit({ body: payload, params: {} })).then((val: any) => {
            if (val?.payload?.data?.success) {
                message.success("Package allotted successfully")
                setModalOpen(false)
            }
        })
    }
    return <>
        <Form
            form={form}
            onValuesChange={onValuesChange}
            className="paddingx20 app-form full-width pb-30"
            onFinish={onFinish}
            initialValues={{
                purchased_date: moment()
            }}
        >
            <Row gutter={16}>
                <Col span={8}>
                    <Form.Item name="course_type" label="Course Type" rules={[{ required: true }]}>
                        <Select showSearch={true} optionFilterProp="children"
                        >
                            <Select.Option
                                key={"live"}
                                value={"live"}
                            >
                                {"Live"}
                            </Select.Option>
                            <Select.Option
                                key={"recorded"}
                                value={"recorded"}
                            >
                                {"Recorded"}
                            </Select.Option>
                            <Select.Option
                                key={"test-series"}
                                value={"test-series"}
                            >
                                {"Test Series"}
                            </Select.Option>

                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name="website_course_id" label="Select Course" rules={[{ required: true }]}>
                        <Select showSearch={true} optionFilterProp="children">
                            {genericList?.map((val: any) => <Select.Option
                                key={val._id}
                                value={val?._id}
                            >
                                {val?.course_name}
                            </Select.Option>)}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name="roll_no" label="Old Roll No">
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name="bank_name" label="Bank Name">
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name="ref_no" label="Transaction Name">
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name="total_amount" label="Total Amount" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name="purchased_date" label="Purchased Date" rules={[{ required: true }]}>
                        <DatePicker />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name="remarks" label="Remarks">
                        <TextArea />
                    </Form.Item>
                </Col>
            </Row>
            <Row >
                <Col span={24}>
                    <Form.Item>
                        <Button
                            className={"mt20"}
                            type="primary"
                            htmlType="submit"

                        >
                            Submit
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
        <Row>
            <Col span={24}>
                <h3>Alloted Packages</h3>
                <Table
                    columns={
                        [
                            {
                                title: 'Roll No',
                                dataIndex: 'roll_no',
                                key: 'roll_no',
                            },
                            {
                                title: 'Added Date',
                                dataIndex: 'created_on',
                                render: (val: any) => moment(val).format('DD MMM, YYYY hh:mm A'),
                                key: 'created_on',
                            },
                            {
                                title: 'Course',
                                dataIndex: ['website_course_id', 'course_name'],
                                key: 'course_name',
                            },
                        ]
                    }
                    dataSource={purchasedPackages}
                />
            </Col>
        </Row>
    </>
}
export default ManualCourseAllotment;