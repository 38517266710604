import {
    Button,
    Checkbox,
    Col,
    Divider,
    Form,
    Input,
    Row,
    Select,
    Space
} from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../shared/types/common";
import States from "../../../shared/utils/states.json";
const { Option } = Select;

const AdmissionFormPersonalDetails = ({ next, setData, prev, data }: any) => {
    const [educationDetails, setEducationDetails] = useState([
        {
            educational_qualification_name: "",
            stream: "",
            college: "",
            passing_year: "",
            marks_percent: "",
        },
        {
            educational_qualification_name: "",
            stream: "",
            college: "",
            passing_year: "",
            marks_percent: "",
        }
    ]);
    const [isSameAddress, setSameAddress] = useState(false);
    const { loading } = useSelector((state: RootState) => state.admission);
    const setvalues = (item: string, index: number, name: string) => {
        let data = [...educationDetails] as any;
        data[index][name] = item;
        setEducationDetails(data);
    };
    useEffect(() => {
        if (data?.educational_qualification?.length > 0) {
            setEducationDetails(data?.educational_qualification);
        }
    }, [data]);
    console.log("educationDetails", educationDetails)
    return (
        <>
            <Form
                layout="horizontal"
                onFinish={async (values) => {
                    if (isSameAddress) {
                        values.permanent_address = {
                            permanent_address_line_1:
                                values.present_address.present_address_line_1,
                            permanent_address_line_2:
                                values.present_address.present_address_line_2,
                            permanent_city: values.present_address.present_city,
                            permanent_district: values.present_address.present_district,
                            permanent_state: values.present_address.present_state,
                            permanent_pincode: values.present_address.present_pincode,
                        };
                    }
                    await setData({
                        ...values,
                        educational_qualification: educationDetails,
                    });
                    next({ ...values, educational_qualification: educationDetails });
                }}
                initialValues={data}
            >
                <Row className="mx-6 mt-2" gutter={50}>
                    <Col span={24} className={"text-left"} style={{ marginTop: "20px" }}>
                        <p>Father's Details</p>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Father's Name" name={["father", "father_name"]}>
                            <Input placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Occupation"
                            name={["father", "father_occupation"]}
                        >
                            <Input placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Mobile Number"
                            name={["father", "father_mobile"]}
                            rules={[
                                {
                                    min: 10,
                                    message: "Length should be 10 digits",
                                },
                                {
                                    max: 10,
                                    message: "Length should be 10 digits",
                                },
                            ]}
                        >
                            <Input placeholder="" />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="Residence No"
                            name={["father", "father_other_contact"]}
                        >
                            <Input placeholder="" />
                        </Form.Item>
                    </Col>

                    <Divider></Divider>

                    <Col span={24} className={"text-left"} style={{ marginTop: "20px" }}>
                        <p>Present Address</p>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="Line 1"
                            name={["present_address", "present_address_line_1"]}
                        >
                            <Input placeholder="" />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="Line 2"
                            name={["present_address", "present_address_line_2"]}
                        >
                            <Input placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="City & Town"
                            name={["present_address", "present_city"]}
                        >
                            <Input placeholder="" />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="District"
                            name={["present_address", "present_district"]}
                        >
                            <Input placeholder="" />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="State"
                            name={["present_address", "present_state"]}
                        >
                            <Select>
                                {States.map((item: any) => {
                                    return <Option value={item?.name}>{item?.name}</Option>
                                })}

                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="Pincode"
                            name={["present_address", "present_pincode"]}
                        >
                            <Input placeholder="" />
                        </Form.Item>
                    </Col>

                    <Divider></Divider>
                    <Col span={24} className={"text-left"}>
                        <Checkbox onChange={(evt) => setSameAddress(evt.target.checked)}>
                            Is Permanent Address same as present address?
                        </Checkbox>
                    </Col>

                    {!isSameAddress && (
                        <>
                            <Col
                                span={24}
                                className={"text-left"}
                                style={{ marginTop: "20px" }}
                            >
                                <p>Permanent Address</p>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label="Line 1"
                                    name={["permanent_address", "permanent_address_line_1"]}
                                >
                                    <Input placeholder="" disabled={isSameAddress} />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label="Line 2"
                                    name={["permanent_address", "permanent_address_line_2"]}
                                >
                                    <Input placeholder="" disabled={isSameAddress} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="City & Town"
                                    name={["permanent_address", "permanent_city"]}
                                >
                                    <Input placeholder="" disabled={isSameAddress} />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label="District"
                                    name={["permanent_address", "permanent_district"]}
                                >
                                    <Input placeholder="" disabled={isSameAddress} />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label="State"
                                    name={["permanent_address", "permanent_state"]}
                                >
                                    <Select disabled={isSameAddress}>
                                        <Option value={"a"}>Delhi</Option>
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label="Pincode"
                                    name={["permanent_address", "permanent_pincode"]}
                                >
                                    <Input placeholder="" disabled={isSameAddress} />
                                </Form.Item>
                            </Col>
                        </>
                    )}

                    <Divider></Divider>
                </Row>
                <Row gutter={8}>
                    <Col span={24} className={"text-left"} style={{ marginTop: "20px" }}>
                        <p>Education Details</p>
                    </Col>

                    <Col
                        span={6}
                        className={"text-left"}
                        style={{ marginBottom: "-15px" }}
                    >
                        <p>Qualification</p>
                    </Col>
                    <Col
                        span={4}
                        className={"text-left"}
                        style={{ marginBottom: "-15px" }}
                    >
                        <p>Steam</p>
                    </Col>
                    <Col
                        span={6}
                        className={"text-left"}
                        style={{ marginBottom: "-15px" }}
                    >
                        <p>Name of College (with City)</p>
                    </Col>
                    <Col
                        span={5}
                        className={"text-left"}
                        style={{ marginBottom: "-15px" }}
                    >
                        <p>Passing Year</p>
                    </Col>
                    <Col
                        span={3}
                        className={"text-left"}
                        style={{ marginBottom: "-15px" }}
                    >
                        <p>Marks in %</p>
                    </Col>
                    {educationDetails?.map((item, index) => {
                        return (
                            <>
                                <Col
                                    span={6}
                                    className={"text-left"}
                                    style={{ marginTop: "10px" }}
                                >
                                    <Input
                                        value={item?.educational_qualification_name}
                                        name={"educational_qualification_name"}
                                        onChange={(evt) =>
                                            setvalues(
                                                evt.target.value,
                                                index,
                                                "educational_qualification_name"
                                            )
                                        }
                                    ></Input>
                                </Col>
                                <Col
                                    span={4}
                                    className={"text-left"}
                                    style={{ marginTop: "10px" }}
                                >
                                    <Input
                                        value={item?.stream}
                                        name={"stream"}
                                        onChange={(evt) =>
                                            setvalues(evt.target.value, index, "stream")
                                        }
                                    ></Input>
                                </Col>
                                <Col
                                    span={6}
                                    className={"text-left"}
                                    style={{ marginTop: "10px" }}
                                >
                                    <Input
                                        value={item?.college}
                                        name={"college"}
                                        onChange={(evt) =>
                                            setvalues(evt.target.value, index, "college")
                                        }
                                    ></Input>
                                </Col>
                                <Col
                                    span={5}
                                    className={"text-left"}
                                    style={{ marginTop: "10px" }}
                                >
                                    <Input
                                        value={item?.passing_year}
                                        name={"passing_year"}
                                        onChange={(evt) =>
                                            setvalues(evt.target.value, index, "passing_year")
                                        }
                                    ></Input>
                                </Col>
                                <Col
                                    span={3}
                                    className={"text-left"}
                                    style={{ marginTop: "10px" }}
                                >
                                    <Input
                                        value={item?.marks_percent}
                                        name={"marks_percent"}
                                        onChange={(evt) =>
                                            setvalues(evt.target.value, index, "marks_percent")
                                        }
                                    ></Input>
                                </Col>
                            </>
                        );
                    })}

                    <Col span={24} className={"text-left"} style={{ marginTop: "50px" }}>
                        <Space>
                            <Button type="primary" htmlType="submit" loading={loading}>
                                Register
                            </Button>
                            <Button type="default" onClick={() => prev()}>
                                Prev
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default AdmissionFormPersonalDetails;
