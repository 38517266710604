import { Breadcrumb, Button, Col, Divider, Form, Input, Row, message, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { requestResetPassword } from "../../core/store/reducers/auth.slice";
import { RootState } from "../../shared/types/common";

const ProfilePage: React.FC = () => {
    const { user } = useSelector((state: RootState) => state.auth);
    const dispatch = useDispatch();
    return <>
        <Row className="mx-6 mt-2">
            <Col span={24}>
                <Breadcrumb>
                    <Breadcrumb.Item key="details">My Profile</Breadcrumb.Item>
                </Breadcrumb>
            </Col>
        </Row>
        <Row
            className="mt-4 mx-4"
            style={{ maxHeight: "calc( 100vh - 140px )", overflow: "auto" }}
        >
            <Col span={8}>
                <div
                    className=" mx-1 bg-primary-white-blueish border-primary-gray-02 p-3"
                    style={{ borderBottom: "none!important" }}
                >
                    <Row align="middle" className="mb-2">
                        <Col span={18}>
                            <h3 className="m-0 weight-700">
                                {user?.name
                                    ? user.name
                                    : "Name not given"}
                            </h3>
                            <p className="mb-0 weight-700">Code: {user?.emp_code}</p>
                            <p>
                                {user?.email
                                    ? user?.email
                                    : "Email not given"}
                                <Divider type="vertical"></Divider>
                                {user?.phone
                                    ? user?.phone
                                    : "Mobile Number not given"}
                            </p>
                        </Col>
                    </Row>
                </div>
            </Col>
            <Col span={8}>
                <div
                    className=" mx-1 bg-primary-white-blueish border-primary-gray-02 p-3"
                    style={{ borderBottom: "none!important" }}
                >
                    <h3>Change Password</h3>
                    <Form onValuesChange={(value, allValues) => {

                    }}
                        onFinish={async (values: any) => {
                            if (values.password !== values.confirm_password) {
                                message.error("Both passwords should be same!")
                                return;
                            }
                            const payload = {
                                password: values.password
                            }
                            const res = await dispatch(requestResetPassword(payload));
                            if (res?.payload?.data?.success) {
                                notification.success({ message: "Password changed successfully." })
                            }
                            console.log(res)
                        }}
                    >
                        <Form.Item name="password" label="Enter Password"
                            rules={[
                                {
                                    required: true,
                                    message: "Enter Password"
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item name="confirm_password" label="Re-enter Password"
                            rules={[
                                {
                                    required: true,
                                    message: "Re-enter Password"
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">Submit</Button>
                        </Form.Item>
                    </Form>
                </div>
            </Col>
        </Row>
    </>
}
export default ProfilePage;