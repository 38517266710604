import {
    CloseCircleOutlined, EditOutlined, PlusOutlined, YoutubeFilled
} from "@ant-design/icons";
import { Button, Col, Row, Space, Tooltip } from "antd";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { BiFilterAlt } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { getGenericList, getGenericListMinimal, setFilters } from "../../../core/store/reducers/website.slice";
import ContentHeaderComponent from "../../../shared/components/content-header/content-header.component";
import DataTableComponent from "../../../shared/components/data-table/data-table.component";
import GenericFormComponent from "../../../shared/components/generic-form/generic-form.component";
import { TABLE_ROWS_COUNT } from "../../../shared/constants";
import { RootState } from "../../../shared/types/common";

import { RiMicrosoftFill } from "react-icons/ri";
import { getUserList } from "../../../core/store/reducers/user-management.slice";
import CreateEditClassComponent from "./create-edit-class";


const ManageClassPage: React.FC = () => {
    const dispatch = useDispatch();

    // List states

    const { screenType } = useSelector((state: RootState) => state.settings);
    const { loading, genericList, filters, total } = useSelector(
        (state: RootState) => state.website
    );
    const [entityListData, setEntityListData] = useState([] as any);
    // Flag to open create/edit form
    const [createEditOpen, setCreateEditOpen] = useState(false);
    const [selectedEntity, setSelectedEntity] = useState(null as any);
    const [filterVisible, setFilterVisible] = useState(false);
    const [sorter, setSorter] = useState({ sort_by: "", sort_type: "" });
    const [pagination, setPagination] = useState({ page: 1, limit: TABLE_ROWS_COUNT });
    const [courseList, setCourseList] = useState([] as any)
    const [facultyList, setFacultyList] = useState([] as any)

    const loadList = useCallback(
        (_page: any = 1) => {
            const data = {
                ...pagination,
                ...sorter,
            };
            dispatch(getGenericList({ params: data, body: filters, module: 'course-class' }));
        },
        [filters, dispatch, sorter, pagination]
    );

    useEffect(() => {
        dispatch(getGenericListMinimal({ module: 'course', params: { keys: 'course_name,course_type', } })).then((val: any) => {
            if (val?.payload?.data?.data) {
                setCourseList(val?.payload?.data?.data?.map((val: any) => {
                    return {
                        value: val._id, label: val.course_name
                    }
                }))
            }
        });
        dispatch(getUserList({ params: { page: 1, limit: 1000 }, body: { role: 'faculty' } })).then((val: any) => {
            if (val?.payload?.data?.data) {
                setFacultyList(val?.payload?.data?.data?.map((val: any) => {
                    return {
                        value: val._id, label: val.name
                    }
                }))
            }
        });
    }, [])
    // useEffect Hooks
    useEffect(() => {
        loadList();
    }, [loadList]);

    //filters, loadList
    useEffect(() => {
        genericList?.length >= 0 &&
            setEntityListData(
                genericList.map((obj: any, index: number) => {
                    return { key: index, ...obj };
                })
            );
    }, [genericList]);
    const onFilterApply = (values: any) => {
        let arrFilter = [];
        for (let [key, value] of Object.entries(values)) {
            if (value !== "") {
                arrFilter.push({ key: key, value: value });
            }
        }
        let refinedArray = arrFilter.filter((data) => {
            return data.value !== undefined;
        });
        let obj = {} as any;
        refinedArray.forEach((data: any) => {
            obj[data.key] = data.value;
        });
        setPagination({
            page: 1,
            limit: pagination.limit,
        });
        dispatch(setFilters(obj)); //filters
    };

    const onPageChange = (pageNumber: number) => {
        setPagination({
            page: pageNumber,
            limit: pagination.limit,
        });
    };




    const editEntityListner = (entity: any) => {
        setSelectedEntity(entity);
        setCreateEditOpen(true);
    };

    const columns = [
        {
            title: "Class Date",
            dataIndex: "start_time",
            key: "start_time",
            render: (_: any) => moment(_).format("DD MMM, YYYY")
        },
        {
            title: "URL",
            dataIndex: "video_url",
            render: (a: any) => {
                let data = <></>;
                if (a?.indexOf('youtu') !== -1) {
                    data = <a href={a} target="_blank"><span style={{ color: '#CD201F' }}><YoutubeFilled /> Youtube</span></a>;
                }
                if (a?.indexOf('microsoft') !== -1) {
                    data = <a href={a} target="_blank"><span style={{ color: '#464EB8' }}><RiMicrosoftFill /> Teams</span></a>;
                }
                return data
            },
            key: "video_url",
        },
        {
            title: "Class Title",
            dataIndex: "class_title",
            sorter: true,
            key: "class_title",
        },
        {
            title: "Course",
            dataIndex: 'course_id',
            className: 'capitalize',
            render: (a: any) => {
                return `${a?.course_name} (${a?.course_type})`
            },
            key: "course",
        },
        {
            title: "Faculty",
            dataIndex: ['faculty_id', 'name'],
            key: "faculty_id",
        },
        {
            title: "Subject",
            dataIndex: ['subject_id', 'name'],
            key: "subject_id",
        },
        {
            title: "Topic",
            dataIndex: ['topic_id', 'name'],
            key: "topic_id",
        },

        {
            title: "Class Time",
            dataIndex: "start_time",
            key: "start_time",
            render: (_: any) => moment(_).format("hh:mm A")
        },
        {
            title: "In Time",
            dataIndex: "in_time",
            key: "in_time",
            render: (_: any) => _ ? moment(_).format("hh:mm A") : '------'
        },
        {
            title: "Out Time",
            dataIndex: "out_time",
            key: "out_time",
            render: (_: any) => _ ? moment(_).format("hh:mm A") : '------'
        },
        {
            title: "Duration",
            key: "out_time",
            render: (_: any) => _?.in_time && _?.out_time ? moment(_?.out_time).diff(_?.in_time, 'm') + ' min' : '------'
        },
        {
            title: "Class Type",
            dataIndex: "class_type",
            className: 'capitalize',
            key: "class_type",
        },
        {
            title: "Class Annotation PDF",
            dataIndex: "class_pdf_url",
            render: (a: any) => {
                return a ? <a href={a} target="_blank">Open</a> : <>NA</>
            },
            key: "class_pdf_url",
        },
        {
            title: "status",
            dataIndex: "is_cancelled",
            key: "is_cancelled",
            render: (a: any) => {
                return a ? "Cancelled" : "Active";
            },
        },
        {
            title: "Created Date",
            dataIndex: "created_at",
            sorter: true,
            key: "created_at",
            render: (a: any) => {
                return a ? moment(a).format("MMM DD, Y LT") : "";
            },
        },
        {
            title: "Updated Date",
            dataIndex: "updated_at",
            sorter: true,
            key: "updated_at",
            render: (a: any) => {
                return a ? moment(a).format("MMM DD, Y LT") : "";
            },
        },
        {
            title: "Actions",
            width: "160px",
            fixed: "right",
            render: (a: any) => {
                return (
                    <>
                        <Tooltip title={"Edit"}>
                            <Button
                                type="link"
                                onClick={() => editEntityListner(a)}
                                className="table-button"
                                icon={<EditOutlined />}
                            ></Button>
                        </Tooltip>
                        <Tooltip title={"Mark Inactive"}>
                            <Button
                                // onClick={() => toggleStatus(a)}
                                type="link"
                                className="table-button"
                                icon={<CloseCircleOutlined />}
                            ></Button>
                        </Tooltip>
                    </>
                );
            },
            key: "actions",
        },
    ];

    const onChangeHandler = (_pagination: any, _filters: any, _sorter: any) => {
        if (_sorter.order === undefined) {
            setSorter({ sort_by: "", sort_type: "" });
        } else {
            let order = "";
            let columnKey = "";
            if (_sorter.order === "ascend") order = "asc";
            else order = "desc";

            if (_sorter.columnKey === "enabled") {
                columnKey = "status";
            } else if (_sorter.columnKey === "displayName") {
                columnKey = "name";
            } else {
                columnKey = _sorter.columnKey;
            }
            setSorter({ sort_by: columnKey, sort_type: order });
        }
    };

    const formProps = {
        name: "class-filters",
        enableEnterSubmit: true,
        formElements: [
            {
                name: "class_title",
                label: "",
                placeholder: "Class Title",
                type: "Input",
                allowCleart: true,
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
            },
            {
                name: "course_id",
                label: "",
                placeholder: "Course",
                type: "Select",
                options: courseList,
                showSearch: true,
                allowCleart: true,
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
            },
            {
                name: "faculty_id",
                label: "",
                placeholder: "Faculty",
                type: "Select",
                options: facultyList,
                showSearch: true,
                allowCleart: true,
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
            },
            {
                name: "video_url",
                label: "",
                placeholder: "Video",
                type: "Select",
                options: [
                    { value: 'Youtube', name: 'youtu' },
                    { value: 'Teams', name: 'microsoft' }
                ],
                showSearch: true,
                allowCleart: true,
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
            }
        ],
        initialValues: {

        },
        extraButtons: [
            {
                htmlType: "submit",
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 3 },
                loading: false,
                submitButtonText: "Clear",
                type: "clearForm",
            },
            {
                htmlType: "submit",
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 3 },
                loading: false,
                submitButtonText: "Apply",
                submitButtonType: "primary",
            },
        ],
        onFinish: onFilterApply,
        onFinishFailed: null,
        onValuesChange: null,
    };

    const createEditSubmit = () => {
        setCreateEditOpen(false);
        loadList()
    };

    const headerButtons = (
        <Space>
            <Button
                type="primary"
                // size="large"
                icon={<PlusOutlined />}
                onClick={() => setCreateEditOpen(true)}
            >
                <span className="font-size-14">New {!screenType?.isPortrait && "Class"}</span>
            </Button>
            <Button
                shape="circle"
                type="primary"
                icon={
                    <span role="img" className="anticon">
                        <BiFilterAlt />
                    </span>
                }
                onClick={() => {
                    setFilterVisible(!filterVisible);
                }}
            ></Button>
        </Space>
    );
    return (
        <>
            <div
                className="mx-6 mt-2 bg-primary-white-blueish border-primary-gray-02"
                style={{ borderBottom: "none!important" }}
            >
                <Row>
                    <Col span={24}>
                        <ContentHeaderComponent
                            title="Manage Classes"
                            subtitle=""
                            buttons={headerButtons}
                        />
                    </Col>
                </Row>
                {filterVisible && (
                    <Row className="bg-body">
                        <Col span={24} className="py-1">
                            <div className="px-3 table-filter-form">
                                <GenericFormComponent {...formProps} />
                            </div>
                        </Col>
                    </Row>
                )}
            </div>

            <DataTableComponent
                loading={loading}
                dataSource={genericList}
                columns={columns}
                onChange={onChangeHandler}
                title={"Class List"}
                defaultPage={0}
                maxWidth="2500px"
                currentPage={pagination.page}
                totalRows={total}
                onPageChange={onPageChange}

            />
            {createEditOpen && (
                <CreateEditClassComponent
                    onClose={() => setCreateEditOpen(false)}
                    visible={createEditOpen}
                    selectedEntity={selectedEntity}
                    onSubmit={createEditSubmit}
                />
            )}

        </>
    );
};
export default ManageClassPage;
