import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  API_FEE_CLEARANCE,
  API_FEE_CLEARANCE_ONLINE,
} from "../../../shared/routes/accounts.routes";
import {
  API_ADMISSION_STATUS_REPORT,
  API_CANDIDATE_LIST,
  API_CANDIDATE_LIST_MINIMAL,
  API_CANDIDATE_REGISTRATION,
  API_CHECK_PAYMENT_STATUS,
  API_COMPLETE_REFUND,
  API_COURSE_ENROLMENT,
  API_COURSE_ENROLMENT_ALL_DETAILS,
  API_COURSE_ENROLMENT_LIST,
  API_COURSE_ENROLMENT_PUBLIC,
  API_COURSE_INSTALMENTS_BY_EN_ID,
  API_COURSE_INSTALMENTS_LIST,
  API_COURSE_PAYMENTS_BY_EN_ID,
  API_COURSE_PAYMENT_DETAIL_ID,
  API_COURSE_PAYMENT_LIST,
  API_CREATE_PAYMENT_ORDER,
  API_FEE_CLOSING_APPROVAL,
  API_FEE_CLOSING_APPROVAL_ONLINE,
  API_GET_PURCHASED_PACKAGES_BY_CANDIDATE_ID,
  API_INITIATE_REFUND,
  API_MODIFY_INSTALMENT_STRUCTURE,
  API_ONLINE_COURSE_ENROLMENT_LIST,
  API_ONLINE_PAYMENT_FOR_INVOICE,
  API_PAYMENT_SUBMIT,
  API_REALIZE_INSTALMENT,
  API_REFUND_DETAILS_BY_ID,
  API_REFUND_LIST,
} from "../../../shared/routes/admission.routes";
import { Client } from "../../api-client";

const config = {
  name: "admission",
};

export const getCandidateRegistration: any = createAsyncThunk(
  `${config.name}/getCandidateRegistration`,
  async (params: any) => {
    return Client.get(API_CANDIDATE_REGISTRATION, { params });
  }
);
export const requestCandidateRegistration: any = createAsyncThunk(
  `${config.name}/requestCandidateRegistration`,
  async (data: any) => {
    return Client.post(API_CANDIDATE_REGISTRATION, data);
  }
);

export const requestPaymentDetail: any = createAsyncThunk(
  `${config.name}/requestPaymentDetail`,
  async (id: any) => {
    return Client.get(API_COURSE_PAYMENT_DETAIL_ID(id));
  }
);
export const requestRefundDetailsById: any = createAsyncThunk(
  `${config.name}/requestRefundDetailsById`,
  async (id: any) => {
    return Client.get(API_REFUND_DETAILS_BY_ID(id));
  }
);

export const editCandidateRegistration: any = createAsyncThunk(
  `${config.name}/requestCandidateRegistration`,
  async ({ data, regNo }: any) => {
    return Client.put(API_CANDIDATE_REGISTRATION + "?regno=" + regNo, data);
  }
);

export const requestCandidateList: any = createAsyncThunk(
  `${config.name}/requestCandidateList`,
  async (data: any) => {
    return Client.post(API_CANDIDATE_LIST, data.body, { params: data.params });
  }
);
export const requestCandidateListMinimal: any = createAsyncThunk(
  `${config.name}/requestCandidateListMinimal`,
  async (data: any) => {
    return Client.post(API_CANDIDATE_LIST_MINIMAL, data.body, {
      params: data.params,
    });
  }
);

export const requestCourseInstalments: any = createAsyncThunk(
  `${config.name}/requestCourseInstalments`,
  async (data: any) => {
    return Client.post(API_COURSE_INSTALMENTS_LIST, data.body, {
      params: data.params,
    });
  }
);

export const requestCoursePayments: any = createAsyncThunk(
  `${config.name}/requestCoursePayments`,
  async (data: any) => {
    return Client.post(API_COURSE_PAYMENT_LIST, data.body, {
      params: data.params,
    });
  }
);
export const feeClosingApporval: any = createAsyncThunk(
  `${config.name}/feeClosingApporval`,
  async (data: any) => {
    return Client.post(API_FEE_CLOSING_APPROVAL, data);
  }
);
export const feeClearanceAccounts: any = createAsyncThunk(
  `${config.name}/feeClearanceAccounts`,
  async (data: any) => {
    return Client.post(API_FEE_CLEARANCE, data);
  }
);

export const feeClosingApporvalOnline: any = createAsyncThunk(
  `${config.name}/feeClosingApporvalOnline`,
  async (data: any) => {
    return Client.post(API_FEE_CLOSING_APPROVAL_ONLINE, data);
  }
);
export const feeClearanceAccountsOnline: any = createAsyncThunk(
  `${config.name}/feeClearanceAccountsOnline`,
  async (data: any) => {
    return Client.post(API_FEE_CLEARANCE_ONLINE, data);
  }
);
export const admissionStatusReport: any = createAsyncThunk(
  `${config.name}/admissionStatusReport`,
  async (data: any) => {
    return Client.post(API_ADMISSION_STATUS_REPORT, data.body, {
      params: data.params,
    });
  }
);
export const requestCourseEnrolments: any = createAsyncThunk(
  `${config.name}/requestCourseEnrolments`,
  async (data: any) => {
    return Client.post(API_COURSE_ENROLMENT_LIST, data.body, {
      params: data.params,
    });
  }
);
export const requestCourseEnrolmentListNew: any = createAsyncThunk(
  `${config.name}/requestCourseEnrolmentListNew`,
  async (data: any) => {
    return Client.post(API_COURSE_ENROLMENT_LIST, data.body, {
      params: data.params,
    });
  }
);
export const requestCourseEnrolmentsAllDetails: any = createAsyncThunk(
  `${config.name}/requestCourseEnrolmentsAllDetails`,
  async (data: any) => {
    return Client.post(API_COURSE_ENROLMENT_ALL_DETAILS, data.body, {
      params: data.params,
    });
  }
);
export const requestOnlineCourseEnrolments: any = createAsyncThunk(
  `${config.name}/requestOnlineCourseEnrolments`,
  async (data: any) => {
    return Client.post(API_ONLINE_COURSE_ENROLMENT_LIST, data.body, {
      params: data.params,
    });
  }
);

export const requestCourseEnrolment: any = createAsyncThunk(
  `${config.name}/requestCourseEnrolment`,
  async (data: any) => {
    return Client.post(API_COURSE_ENROLMENT, data);
  }
);
export const requestCourseEnrolmentPublic: any = createAsyncThunk(
  `${config.name}/requestCourseEnrolmentPublic`,
  async (data: any) => {
    return Client.post(API_COURSE_ENROLMENT_PUBLIC, data);
  }
);
export const requestInstalmentsByEnrol: any = createAsyncThunk(
  `${config.name}/requestInstalmentsByEnrol`,
  async (id: any) => {
    return Client.get(API_COURSE_INSTALMENTS_BY_EN_ID(id));
  }
);
export const requestPaymentsByEnrol: any = createAsyncThunk(
  `${config.name}/requestPaymentsByEnrol`,
  async (id: any) => {
    return Client.get(API_COURSE_PAYMENTS_BY_EN_ID(id));
  }
);
export const realizeNextInstalment: any = createAsyncThunk(
  `${config.name}/realizeNextInstalment`,
  async ({ id, payload }: any) => {
    return Client.post(API_REALIZE_INSTALMENT(id), payload);
  }
);
export const initiateRefund: any = createAsyncThunk(
  `${config.name}/initiateRefund`,
  async (payload: any) => {
    return Client.post(API_INITIATE_REFUND, payload);
  }
);
export const completeRefund: any = createAsyncThunk(
  `${config.name}/completeRefund`,
  async ({ payload, id }: any) => {
    return Client.post(API_COMPLETE_REFUND(id), payload);
  }
);
export const getRefundList: any = createAsyncThunk(
  `${config.name}/completeRefund`,
  async (data: any) => {
    return Client.post(API_REFUND_LIST, data.body, { params: data.params });
  }
);

export const initiatePaymentOrder: any = createAsyncThunk(
  `${config.name}/initiatePaymentOrder`,
  async (data: any) => {
    return Client.post(API_CREATE_PAYMENT_ORDER, data.body, {
      params: data.params,
    });
  }
);
export const checkPaymentOrderStatus: any = createAsyncThunk(
  `${config.name}/checkPaymentOrderStatus`,
  async (data: any) => {
    return Client.post(API_CHECK_PAYMENT_STATUS, data.body, {
      params: data.params,
    });
  }
);
export const paymentSubmit: any = createAsyncThunk(
  `${config.name}/paymentSubmit`,
  async (data: any) => {
    return Client.post(API_PAYMENT_SUBMIT, data.body, {
      params: data.params,
    });
  }
);
export const onlinePaymentForInvoice: any = createAsyncThunk(
  `${config.name}/onlinePaymentForInvoice`,
  async (id: any) => {
    return Client.get(API_ONLINE_PAYMENT_FOR_INVOICE(id));
  }
);
export const getPurchasedOnlinePackages: any = createAsyncThunk(
  `${config.name}/getPurchasedOnlinePackages`,
  async (id: any) => {
    return Client.get(API_GET_PURCHASED_PACKAGES_BY_CANDIDATE_ID(id));
  }
);
export const requestModifyInstalmentStructure: any = createAsyncThunk(
  `${config.name}/requestModifyInstalmentStructure`,
  async ({ id, body }: any) => {
    return Client.post(API_MODIFY_INSTALMENT_STRUCTURE(id), body);
  }
);
export const AdmissionReducer: any = createSlice({
  name: config.name,
  initialState: {
    admissiondetails: [],
    candidates: [],
    enrolments: [],
    instalments: [],
    payments: [],
    loadingCandidates: false,
    total: 0 as number,
    filters: {} as any,
    payment: null,
    refunds: [],
  },
  reducers: {
    resetCandidateList: (state) => {
      state.loadingCandidates = false;
      state.candidates = [];
      state.total = 0;
      state.filters = {};
    },
    setAdmissionDetails: (state, action) => {
      state.admissiondetails = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(requestCandidateRegistration.pending, (state) => {
        state.loadingCandidates = true;
        state.admissiondetails = [] as any;
      })
      .addCase(requestCandidateRegistration.fulfilled, (state, action) => {
        state.loadingCandidates = false;
        state.admissiondetails = action?.payload?.data?.data;
      })
      .addCase(requestCandidateRegistration.rejected, (state) => {
        state.loadingCandidates = false;
        state.admissiondetails = [] as any;
      })

      .addCase(requestCandidateList.pending, (state) => {
        state.loadingCandidates = true;
        state.candidates = [] as any;
      })
      .addCase(requestCandidateList.fulfilled, (state, action) => {
        state.loadingCandidates = false;
        state.candidates = action?.payload?.data?.data;
        state.total = action?.payload?.data?.total;
      })
      .addCase(requestCandidateList.rejected, (state) => {
        state.loadingCandidates = false;
        state.candidates = [] as any;
      })

      .addCase(requestCourseEnrolments.pending, (state) => {
        state.loadingCandidates = true;
        state.enrolments = [] as any;
      })
      .addCase(requestCourseEnrolments.fulfilled, (state, action) => {
        state.loadingCandidates = false;
        state.enrolments = action?.payload?.data?.data;
        state.total = action?.payload?.data?.total;
      })
      .addCase(requestCourseEnrolments.rejected, (state) => {
        state.loadingCandidates = false;
        state.enrolments = [] as any;
      })
      .addCase(requestOnlineCourseEnrolments.pending, (state) => {
        state.loadingCandidates = true;
        state.enrolments = [] as any;
      })
      .addCase(requestOnlineCourseEnrolments.fulfilled, (state, action) => {
        state.loadingCandidates = false;
        state.enrolments = action?.payload?.data?.data;
        state.total = action?.payload?.data?.total;
      })
      .addCase(requestOnlineCourseEnrolments.rejected, (state) => {
        state.loadingCandidates = false;
        state.enrolments = [] as any;
      })

      .addCase(requestCourseInstalments.pending, (state) => {
        state.loadingCandidates = true;
        state.instalments = [] as any;
      })
      .addCase(requestCourseInstalments.fulfilled, (state, action) => {
        state.loadingCandidates = false;
        state.instalments = action?.payload?.data?.data;
        state.total = action?.payload?.data?.total;
      })
      .addCase(requestCourseInstalments.rejected, (state) => {
        state.loadingCandidates = false;
        state.instalments = [] as any;
      })

      .addCase(requestCoursePayments.pending, (state) => {
        state.loadingCandidates = true;
        state.payments = [] as any;
      })
      .addCase(requestCoursePayments.fulfilled, (state, action) => {
        state.loadingCandidates = false;
        state.payments = action?.payload?.data?.data;
        state.total = action?.payload?.data?.total;
      })
      .addCase(requestCoursePayments.rejected, (state) => {
        state.loadingCandidates = false;
        state.payments = [] as any;
      })

      .addCase(requestPaymentDetail.pending, (state) => {
        state.loadingCandidates = true;
        state.payment = null as any;
      })
      .addCase(requestPaymentDetail.fulfilled, (state, action) => {
        state.loadingCandidates = false;
        state.payment = {
          ...action?.payload?.data?.data,
          instalmentData: action?.payload?.data?.instalment_data,
        };
      })
      .addCase(requestPaymentDetail.rejected, (state) => {
        state.loadingCandidates = false;
        state.payment = null as any;
      });
    builder
      .addCase(getRefundList.pending, (state) => {
        state.loadingCandidates = true;
        state.payment = null as any;
      })
      .addCase(getRefundList.fulfilled, (state, action) => {
        state.loadingCandidates = false;
        state.refunds = action?.payload?.data?.data;
      })
      .addCase(getRefundList.rejected, (state) => {
        state.loadingCandidates = false;
        state.payment = null as any;
      });
  },
});

export const { setAdmissionDetails, setFilters } = AdmissionReducer.actions;
export default AdmissionReducer.reducer;
