import { Button, Checkbox, Col, DatePicker, Drawer, Form, Input, Row, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCenterList, getMasterDropdownData, getTopicBySubject } from "../../../core/store/reducers/masters.slice";
import { getUserList } from "../../../core/store/reducers/user-management.slice";
import { genericWebsiteCreate, genericWebsiteUpdate, getGenericListMinimal } from "../../../core/store/reducers/website.slice";
import { RootState } from "../../../shared/types/common";
import { showNotification } from "../../../shared/utils/utils";
declare var CKFinder: any;
const { Option } = Select;
const CreateEditClassComponent = ({ onClose, visible, onSubmit, selectedEntity }: any) => {


    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const { screenType } = useSelector((state: RootState) => state.settings);
    const { user } = useSelector((state: RootState) => state.auth);
    const [courseList, setCourseList] = useState([] as any)
    const [facultyList, setFacultyList] = useState([] as any)
    const [courseId, setCourseId] = useState(selectedEntity?.course_id?._id || null)
    const [centerId, setCenterId] = useState(selectedEntity?.center_id?._id || null)
    const [batchTypeId, setBatchTypeId] = useState(selectedEntity?.batch_type_id?._id || null)
    const [examId, setExamId] = useState(selectedEntity?.exam_id?._id || null)
    const [classPdfUrl, setClassPdfUrl] = useState(selectedEntity?.class_pdf_url || "")
    const [assignmentPdfUrl, setAssignmentPdfUrl] = useState(selectedEntity?.assignment_pdf_url || "")
    const [subjectList, setSubjectList] = useState([] as any)
    const [topics, setTopics] = useState([] as any);


    useEffect(() => {
        // if (selectedEntity?.center_id) {
        //     setCenterId(selectedEntity?.center_id?._id)
        //     getRelevantData("course", selectedEntity?.center_id?._id)
        // }
        // if (selectedEntity?.course_id) {
        //     setCourseId(selectedEntity?.course_id._id)
        //     getRelevantData("batchType", selectedEntity?.course_id?._id)
        // }
        // if (selectedEntity?.batch_type_id) {
        //     setBatchTypeId(selectedEntity?.batch_type_id._id)
        //     getRelevantData("exam", selectedEntity?.batch_type_id?._id)
        // }
        // if (selectedEntity?.exam_id) {
        //     setExamId(selectedEntity?.exam_id?._id)
        //     getRelevantData("stream", selectedEntity?.exam_id?._id)
        // }
        // if (selectedEntity?.batch_id) {
        //     // set(selectedEntity?.exam_id?._id)
        //     getRelevantData("batch", selectedEntity?.batch_id?.stream_id)
        // }
        dispatch(getGenericListMinimal({ module: 'course', params: { keys: 'course_name,course_type', } })).then((val: any) => {

            if (val?.payload?.data?.data) {
                setCourseList(val?.payload?.data?.data?.map((val: any) => {
                    return {
                        value: val._id, label: val.course_name
                    }
                }))
            }
        });
        dispatch(getMasterDropdownData({ schemaKey: "exam-subject", labelKey: "name" })).then((val: any) => {
            if (val?.payload?.data?.success) {
                setSubjectList(val?.payload?.data?.data)

            }
        })
        if (selectedEntity?.subject_id) {
            // set(selectedEntity?.exam_id?._id)
            getRelevantData("subject", selectedEntity?.subject_id?._id)
        }

    }, [selectedEntity])


    useEffect(() => {
        form.setFieldValue('class_pdf_url', classPdfUrl)
    }, [classPdfUrl])
    useEffect(() => {
        form.setFieldValue('assignment_pdf_url', assignmentPdfUrl)
    }, [assignmentPdfUrl])


    const onFinish = (values: any) => {
        if (selectedEntity) {
            const payload = {
                ...values,
                _id: selectedEntity._id,
                updated_by: user._id,
            };
            dispatch(genericWebsiteUpdate({ payload: payload, module: 'course-class' })).then((val: any) => {
                if (val?.payload?.data) {
                    showNotification("Class Updated Successfully", "success");
                    onSubmit();
                }
            });
        } else {
            const payload = {
                ...values,
                created_by: user._id,
                updated_by: user._id,
            }
            dispatch(genericWebsiteCreate({ payload: payload, module: 'course-class' })).then((val: any) => {
                if (val.payload?.data?.success) {
                    showNotification("Class Saved Successfully", "success");
                    onSubmit();
                }
            })
        }
    }


    useEffect(() => {
        dispatch(getCenterList())

    }, [])

    const getRelevantData = (event: any, key: any) => {


        if (event === "subject") {
            dispatch(getTopicBySubject(key)).then((val: any) => {
                if (val?.payload?.data?.success) {
                    setTopics(val?.payload?.data?.data
                        //     ?.map((val: any) => {
                        //     return { name: val.name, value: val._id }
                        // })
                    )
                }
            })
            dispatch(getUserList({ params: { page: 1, limit: 1000 }, body: { subject_id: key, role: 'faculty' } })).then((val: any) => {
                if (val?.payload?.data?.data) {
                    setFacultyList(val?.payload?.data?.data)
                }
            });
        }
    }



    const extraButtons = [
        {
            htmlType: "button",
            colSpan: 5,
            loading: false,
            submitButtonText: "Cancel",
            onClick: onClose,
        },
        {
            htmlType: "submit",
            colSpan: 5,
            loading: false,
            submitButtonText: "Clear",
            type: "clearForm",
        },
        {
            htmlType: "submit",
            colSpan: 5,
            loading: false,
            submitButtonText: "Save",
            submitButtonType: "primary",
        }
    ]
    const openPopup = (ref: string, setData: any) => {
        CKFinder.popup({
            chooseFiles: true,
            onInit: (finder: any) => {
                finder.on('files:choose', function (evt: any) {
                    var file = evt.data.files.first();
                    form.setFieldValue(ref, file.getUrl())
                    setData(file.getUrl())
                });
                finder.on('file:choose:resizedImage', function (evt: any) {
                    form.setFieldValue(ref, evt.data.resizedUrl())
                    setData(evt.data.resizedUrl())
                });
            }
        });
    }
    const onValuesChange = (value: any, values: any) => {
        if (value?.subject_id) {
            getRelevantData("subject", value.subject_id)


        }
    }
    console.log(selectedEntity)
    return <>
        <Drawer
            maskClosable={false}
            title="Manage Class"
            className="form-drawer"
            width={screenType.isPortrait ? '90%' : '80%'}
            onClose={onClose}
            open={visible}
        >
            <Form
                form={form}
                initialValues={{
                    ...selectedEntity,

                    center_id: selectedEntity?.center_id?._id,
                    course_id: selectedEntity?.course_id?._id,
                    batch_type_id: selectedEntity?.batch_type_id?._id,
                    exam_id: selectedEntity?.exam_id?._id,
                    stream_id: selectedEntity?.batch_id?.stream_id,
                    batch_id: selectedEntity?.batch_id?._id,
                    subject_id: selectedEntity?.subject_id?._id,
                    topic_id: selectedEntity?.topic_id?._id, ...(selectedEntity?.end_time ? { end_time: moment(selectedEntity?.end_time) } : {}), ...(selectedEntity?.start_time ? { start_time: moment(selectedEntity?.start_time) } : {})
                }}
                onValuesChange={onValuesChange}
                className="paddingx20 app-form full-width"
                onFinish={onFinish}
            >
                <Row gutter={20} >

                    <Col span={12} className="gutter-row">
                        <Form.Item name="class_title" label="Class Title">
                            <Input onChange={(e) => {
                                let data = e.target.value;
                                data = data.replace(/[^a-zA-Z0-9 -]/g, '').split("  ").join(" ").split(" ").join("-").toLowerCase()
                                form.setFieldValue("seo_url", data);
                            }} />
                        </Form.Item>
                    </Col>
                    <Col span={12} className="gutter-row">
                        <Form.Item name="class_type" label="Class Type">
                            <Select style={{ width: "100%" }} placeholder={"Choose Class Type"}>
                                <Option value={"concept"}>Concept</Option>
                                <Option value={"Discussion"}>Discussion</Option>
                                <Option value={"Doubt-clearing"}>Doubt-clearing</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24} className="gutter-row pl-4">
                        <Form.Item name="video_url" label="Video URL">
                            <Input />
                        </Form.Item>

                    </Col>

                    <Col span={12} className="gutter-row">
                        <Form.Item name="class_description" label="Class Description">
                            <TextArea />
                        </Form.Item>
                    </Col>

                    <Col span={6} className="gutter-row">
                        {classPdfUrl != '' && <>
                            <a href={classPdfUrl} target="_blank">View PDF</a>
                        </>}
                        <Form.Item name="class_pdf_url" label="Class Annotation PDF">
                            <Input disabled hidden />
                        </Form.Item>
                        <Button type="primary" onClick={() => openPopup('class_pdf_url', setClassPdfUrl)}>
                            Select/Upload Class PDF
                        </Button>
                    </Col>
                    <Col span={6} className="gutter-row">
                        {assignmentPdfUrl != '' && <>
                            <a href={assignmentPdfUrl} target="_blank">View PDF</a>
                        </>}
                        <Form.Item name="assignment_pdf_url" label="Assignment PDF">
                            <Input disabled hidden />
                        </Form.Item>
                        <Button type="primary" onClick={() => openPopup('assignment_pdf_url', setAssignmentPdfUrl)}>
                            Select/Upload Assignment PDF
                        </Button>
                    </Col>




                    {/* <Col span={12}>
                        <Form.Item label={"Centre"} rules={[{ required: true }]} name={"center_id"}>
                            <Select placeholder={"Choose Center"} onChange={(evt) => getRelevantData("course", evt)}>
                                {
                                    centers.map((item: any) => {
                                        return <Option value={item?._id}>{item?.center_name}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label={"Course"} rules={[{ required: true }]} name={"course_id"}>
                            <Select placeholder={"Choose Course"} onChange={(evt) => getRelevantData("batchType", evt)}>
                                {
                                    courses.map((item: any) => {
                                        return <Option value={item?.course_ref?._id}>{item?.course_ref?.course_name}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>


                    <Col span={12}>
                        <Form.Item label={"Batch Type"} rules={[{ required: true }]} name={"batch_type_id"}>
                            <Select placeholder={"Choose Batch Type"} onChange={(evt) => getRelevantData("exam", evt)}>
                                {
                                    batchType.map((item: any) => {
                                        return <Option value={item?.batch_type_id?._id}>{item?.batch_type_id?.batch_type_name}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col> */}

                    {/* <Col span={12}>
                        <Form.Item label={"Exam"} rules={[{ required: true }]} name={"exam_id"}>
                            <Select placeholder={"Choose Exam"} onChange={(evt) => getRelevantData("stream", evt)}>
                                {
                                    exams.map((item: any) => {
                                        return <Option value={item?.exam_id?._id}>{item?.exam_id?.exam_name}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label={"Stream"} rules={[{ required: true }]} name={"stream_id"}>
                            <Select placeholder={"Choose Stream"} onChange={(evt) => getRelevantData("batch", evt)}>
                                {
                                    streams.map((item: any) => {
                                        return <Option value={item?.stream_id?._id}>{item?.stream_id?.stream_name}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label={"Batch"} rules={[{ required: true }]} name={"batch_id"}>
                            <Select placeholder={"Choose Batch"} onChange={(evt) => getRelevantData("details", evt)}>
                                {
                                    batch.map((item: any) => {
                                        return <Option value={item?._id}>{item?.batch_code_id?.batch_code}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col> */}

                    <Col span={8}>
                        <Form.Item label={"Course"} rules={[{ required: true }]} name={"course_id"}>
                            <Select placeholder={"Choose Course"} >
                                {
                                    courseList.map((item: any) => {
                                        return <Option value={item?.value}>{item?.label}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>



                    <Col span={8} className="gutter-row">
                        <Form.Item name="subject_id" label="Select Subject">
                            <Select style={{ width: "100%" }} placeholder={"Choose Subject "}>
                                {
                                    subjectList.map((item: any) => {
                                        return <Option value={item?._id}>{item?.name}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>



                    <Col span={8} className="gutter-row">
                        <Form.Item name="topic_id" label="Select Topic">
                            <Select style={{ width: "100%" }} placeholder={"Choose Topic "}>
                                {
                                    topics.map((item: any) => {
                                        return <Option value={item?._id}>{item?.name}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>




                    <Col span={8} className="gutter-row">
                        <Form.Item name="start_time" label="Start Time">
                            <DatePicker showTime style={{ width: "100%" }}></DatePicker>
                        </Form.Item>
                    </Col>

                    <Col span={8} className="gutter-row">
                        <Form.Item name="end_time" label="End Time">
                            <DatePicker showTime style={{ width: "100%" }}></DatePicker>
                        </Form.Item>
                    </Col>
                    <Col span={6} className="gutter-row">
                        <Form.Item name="special_day" label="Sunday/Special Day" valuePropName="checked">
                            <Checkbox>Sunday/Special Day</Checkbox>
                        </Form.Item>
                    </Col>

                    <Col span={6} className="gutter-row">
                        <Form.Item valuePropName='checked' name="is_cancelled" label="Is Class Cancelled">
                            <Checkbox>Is Class Cancelled</Checkbox>
                        </Form.Item>
                    </Col>

                    <Col span={12} className="gutter-row">
                        <Form.Item name="cancel_reason" label="Cancel Reason">
                            <Input></Input>
                        </Form.Item>
                    </Col>
                    <Col span={6} className="gutter-row">
                        <Form.Item name="max_views" label="Max Views Allowed">
                            <Input />
                        </Form.Item>
                    </Col>


                    <Col span={8} className="gutter-row">
                        <Form.Item name="faculty_id" label="Select Faculty">
                            <Select style={{ width: "100%" }} placeholder={"Choose Faculty "}>
                                {
                                    facultyList.map((item: any) => {
                                        return <Option value={item?._id}>{item?.name}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8} className="gutter-row">
                        <Form.Item name="in_time" label="In Time">
                            <DatePicker style={{ width: "100%" }} showTime></DatePicker>
                        </Form.Item>
                    </Col>

                    <Col span={8} className="gutter-row">
                        <Form.Item name="out_time" label="Out Time">
                            <DatePicker style={{ width: "100%" }} showTime></DatePicker>
                        </Form.Item>
                    </Col>

                </Row>
                <Row className="button-row" justify="end" gutter={16}>

                    {extraButtons.map((btn: any, index: number) => {
                        return (
                            <Col
                                {...btn?.colSpanResponsive}
                                span={btn.colSpan}
                                key={index}
                                className="gutter-row"
                            >
                                <Form.Item>
                                    <Button
                                        loading={btn.loading}
                                        // size="large"
                                        {...(btn.onClick ? { onClick: btn.onClick } : {})}
                                        {...(btn.type === "clearForm"
                                            ? {
                                                onClick: () => {
                                                    form.resetFields();
                                                    form.submit();
                                                },
                                            }
                                            : {})}
                                        className={"mt20"}
                                        type={btn.submitButtonType}
                                        htmlType={
                                            btn.type === "clearForm" ? "button" : btn.htmlType
                                        }
                                        block
                                    >
                                        {btn.submitButtonText}
                                    </Button>
                                </Form.Item>
                            </Col>
                        );
                    })}
                </Row>
            </Form>
        </Drawer>
    </>
}

export default CreateEditClassComponent;