import { Avatar, Button, List, Popover } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as UserIcon } from "../../../assets/graphics/icons/other/icon-user.svg";
import { logout, requestLogout } from "../../../core/store/reducers/auth.slice";
import { ROUTE_PROFILE } from "../../routes/auth.routes";
import { RootState } from "../../types/common";

const ProfileMenuComponent: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state.auth);
  const logoutUser = () => {
    dispatch(requestLogout());
    dispatch(logout());
    window.location.reload();
  };
  const text = (
    <span>
      <List itemLayout="horizontal" style={{ width: "280px" }}>
        <List.Item>
          <List.Item.Meta
            avatar={
              <Avatar
                size={42}
                icon={<UserIcon />}
                className="header-button-round"
              />
            }
            title={user?.name}
            description={user.user_name}
          />
        </List.Item>
      </List>
    </span>
  );
  const content = (
    <div>
      <p>
        <Button type="link" onClick={() => navigate(ROUTE_PROFILE)}>
          My Profile
        </Button>
      </p>
      <p>
        <Button type="link" onClick={logoutUser}>
          Logout
        </Button>
      </p>
    </div>
  );
  return (
    <>
      <Popover
        placement="bottomRight"
        title={text}
        content={content}
        className="header-popover"
      >
        <Button
          type="primary"
          className="mr-4 header-button-round"
          shape="circle"
          icon={<UserIcon className="text-white" />}
        />
      </Popover>
    </>
  );
};
export default ProfileMenuComponent;
