import { UserOutlined } from "@ant-design/icons";
import { ManageUsersComponent } from "../../modules";
import CandidatesList from "../../modules/admission/candidates";
import ForgotPasswordPage from "../../modules/auth/forgot-password.page";
import LoginPage from "../../modules/auth/login.page";
import ResetPasswordPage from "../../modules/auth/reset-password.page";
import CounsellorDashboardPage from "../../modules/lms/enquiry/counselor-dashboard.page";
import EnquiryDetailsPage from "../../modules/lms/enquiry/enquiry-details.page";
import ManageEnquiriesPage from "../../modules/lms/enquiry/manage-enquiries.page";
import MastersForm from "../../modules/master";
import ManageMasterModulePage from "../../modules/master/manage-master-module.page";
import {
  ROUTE_BANK_DEPOSIT,
  ROUTE_DAILY_EXPENSES,
  ROUTE_EDUCATION_DEBIT_TALLY,
  ROUTE_EDUCATION_INCOME_TALLY,
  ROUTE_EDUCATION_INCOME_TALLY_ONLINE,
  ROUTE_FEE_CLEARANCE,
  ROUTE_FEE_CLEARANCE_ONLINE,
  ROUTE_PUBLICATION_DEBIT_TALLY,
  ROUTE_PUBLICATION_INCOME_TALLY
} from "./accounts.routes";
import {
  ROUTE_ADMISSION_STATUS_REPORT,
  ROUTE_BULK_ALLOT_PACKAGE,
  ROUTE_CANDIDATES_IMPORT,
  ROUTE_CANDIDATES_LIST, ROUTE_ENROLMENTS_ALL_DETAILS, ROUTE_ENROLMENTS_LIST,
  ROUTE_FEE_CLOSING_APPROVAL,
  ROUTE_FEE_CLOSING_APPROVAL_ONLINE,
  ROUTE_INSTALMENTS_LIST, ROUTE_ONLINE_ENROLMENTS_LIST, ROUTE_PAYMENTS_LIST,
  ROUTE_PRINT_ONLINE_RECEIPT,
  ROUTE_PRINT_RECEIPT, ROUTE_PRINT_REFUND_RECEIPT, ROUTE_PRINT_TRANSFER_RECEIPT, ROUTE_REFUNDS_LIST
} from "./admission.routes";


import FeeClearanceList from "../../modules/accounts/fee-clearance";
import FeeClearanceListOnline from "../../modules/accounts/fee-clearance/online";
import AdmissionStatusReport from "../../modules/admission/admission-status-report";
import BulkImportCandidatesWebsite from "../../modules/admission/candidates/bulk-import-candidates-website.page";
import BulkImportEnrolmentWebsite from "../../modules/admission/candidates/bulk-upload-enrolment-website.page";
import EducationIncomeReportTally from "../../modules/admission/education-income-report-tally";
import EducationIncomeReportTallyOnline from "../../modules/admission/education-income-report-tally/online";
import EnrolmentsList from "../../modules/admission/enrolments";
import AllDetailsEnrolmentsList from "../../modules/admission/enrolments/all-details";
import OnlineEnrolmentsList from "../../modules/admission/enrolments/online-enrolments";
import FeeClosingApprovalList from "../../modules/admission/fee-closing-approval";
import FeeClosingApprovalListOnline from "../../modules/admission/fee-closing-approval/online";
import InstalmentList from "../../modules/admission/instalments";
import PaymentList from "../../modules/admission/payments";
import PrintOnlineReceipt from "../../modules/admission/payments/print-online-reciept.component";
import PrintReceipt from "../../modules/admission/payments/print-reciept.component";
import PrintTransferReceipt from "../../modules/admission/payments/print-transfer-reciept.component";
import RefundList from "../../modules/admission/refund";
import PrintRefundReceipt from "../../modules/admission/refund/print-refund-reciept.component";
import ProfilePage from "../../modules/auth/profile.page";
import ManageQuizPage from "../../modules/quiz-question/quiz/manage-quiz.page";
import ManageBannersPage from "../../modules/website/banners/manage-banners.page";
import ManageClassPage from "../../modules/website/class";
import ManageCourseExamsPage from "../../modules/website/course-and-exams-and-content/manage-course-exams.page";
import FileManagerPage from "../../modules/website/file-manager/file-manager.page";
import ManageTagsPage from "../../modules/website/others/manage-tags.page";
import ManageProductsPage from "../../modules/website/products/manage-products.page";
import ManageTestimonialsPage from "../../modules/website/testimonials/manage-testimonials.page";
import Topics from "../../modules/website/topics";
import {
  ROUTE_FORGET_PASSWORD,
  ROUTE_LOGIN,
  ROUTE_PROFILE,
  ROUTE_RESET_PASSWORD
} from "./auth.routes";
import { ROUTE_DASHBOARD } from "./dashboard.routes";
import {
  ROUTE_ENQUIRY_DASHBOARD,
  ROUTE_ENQUIRY_DETAILS,
  ROUTE_ENQUIRY_LIST
} from "./lms.routes";
import { ROUTE_MASTER_LIST, ROUTE_MASTER_LIST_APP } from "./masters.routes";
import { ROUTE_MANAGE_USERS } from "./user-management.routes";
import {
  ROUTE_MANAGE_CLASS,
  ROUTE_MANAGE_COURSE_EXAMS, ROUTE_MANAGE_FILES,
  ROUTE_MANAGE_HOME_BANNERS, ROUTE_MANAGE_PRODUCTS,
  ROUTE_MANAGE_QUIZ,
  ROUTE_MANAGE_TAGS,
  ROUTE_MANAGE_TESTIMONIALS,
  ROUTE_MANAGE_TOPIC
} from "./website.routes";

export const ROUTES_OPEN: any[] = [
  {
    name: "Login",
    route: ROUTE_LOGIN,
    component: <LoginPage />,
  },
  {
    name: "Reset Pasword",
    route: ROUTE_RESET_PASSWORD,
    component: <ResetPasswordPage />,
  },
  {
    name: "Forget Password",
    route: ROUTE_FORGET_PASSWORD,
    component: <ForgotPasswordPage />,
  },
  {
    name: "Print Page",
    pagename: "Print Page",
    module: "Print Page",
    route: ROUTE_PRINT_RECEIPT(":id"),
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <PrintReceipt />,
  },
];

export const ROUTES_PROTECTED: any[] = [
  {
    name: "Dashboard",
    // pagename: "DASHBOARD",
    module: "DASHBOARD",
    route: ROUTE_DASHBOARD,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: (
      <>
        Dashboard
        <UserOutlined />
      </>
    ),
  },
  {
    name: "Profile",
    pagename: "Profile",
    module: "Profile",
    route: ROUTE_PROFILE,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <ProfilePage />,
  },
  {
    name: "Manage Users",
    pagename: "USER MANAGEMENT",
    module: "MANAGE USERS",
    route: ROUTE_MANAGE_USERS,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <ManageUsersComponent />,
  },
  {
    name: "enquiry-dashboard",
    pagename: "enquiry-dashboard",
    module: "ENQUIRY",
    route: ROUTE_ENQUIRY_DASHBOARD,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <CounsellorDashboardPage />,
  },
  {
    name: "All Enquiries",
    pagename: "ALL ENQUIRIES",
    module: "ENQUIRY",
    route: ROUTE_ENQUIRY_LIST,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <ManageEnquiriesPage />,
  },
  {
    name: "Enquiry Details",
    pagename: "Enquiry Details",
    module: "Enquiry Details",
    route: ROUTE_ENQUIRY_DETAILS(":id"),
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <EnquiryDetailsPage />,
  },
  {
    name: "Master List",
    pagename: "Master List",
    module: "Master",
    route: ROUTE_MASTER_LIST,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <MastersForm />,
  },

  {
    name: "Master Details",
    pagename: "Master Details",
    module: "Master",
    route: ROUTE_MASTER_LIST_APP(":app"),
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <ManageMasterModulePage />,
  },

  {
    name: "All Candidates List",
    pagename: "ALL CANDIDATES",
    module: "ADMISSION",
    route: ROUTE_CANDIDATES_LIST,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <CandidatesList />,
  },
  {
    name: "Candidates Import",
    pagename: "CANDIDATES IMPORT",
    module: "ADMISSION",
    route: ROUTE_CANDIDATES_IMPORT,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <BulkImportCandidatesWebsite />,
  },
  {
    name: "Bulk Allot Package",
    pagename: "BULK ALLOT PACKAGE",
    module: "ADMISSION",
    route: ROUTE_BULK_ALLOT_PACKAGE,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <BulkImportEnrolmentWebsite />,
  },
  {
    name: "All Enrolments List",
    pagename: "ALL ENROLMENTS",
    module: "ADMISSION",
    route: ROUTE_ENROLMENTS_LIST,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <EnrolmentsList />,
  },
  {
    name: "All Instalment List",
    pagename: "ALL INSTALMENTS",
    module: "ADMISSION",
    route: ROUTE_INSTALMENTS_LIST,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <InstalmentList />,
  },
  {
    name: "All Payments List",
    pagename: "ALL PAYMENTS",
    module: "ADMISSION",
    route: ROUTE_PAYMENTS_LIST,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <PaymentList />,
  },
  {
    name: "Other Payments List",
    pagename: "OTHER PAYMENTS",
    module: "ADMISSION",
    route: ROUTE_ONLINE_ENROLMENTS_LIST,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <OnlineEnrolmentsList />,
  },
  {
    name: "ROUTE_ENROLMENTS_ALL_DETAILS",
    pagename: "ROUTE_ENROLMENTS_ALL_DETAILS",
    module: "ADMISSION",
    route: ROUTE_ENROLMENTS_ALL_DETAILS,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <AllDetailsEnrolmentsList />,
  },
  {
    name: "Print Page",
    pagename: "Print Page",
    module: "Print Page",
    route: ROUTE_PRINT_RECEIPT(":id"),
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <PrintReceipt />,
  },
  {
    name: "Print Transfer Receipt",
    pagename: "Print Transfer Receipt",
    module: "Print Transfer Receipt",
    route: ROUTE_PRINT_TRANSFER_RECEIPT(":id"),
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <PrintTransferReceipt />,
  },
  {
    name: "ROUTE_PRINT_REFUND_RECEIPT",
    pagename: "ROUTE_PRINT_REFUND_RECEIPT",
    module: "ROUTE_PRINT_REFUND_RECEIPT",
    route: ROUTE_PRINT_REFUND_RECEIPT(":id"),
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <PrintRefundReceipt />,
  },
  {
    name: "Print Online Page",
    pagename: "Print Online Page",
    module: "Print Online Page",
    route: ROUTE_PRINT_ONLINE_RECEIPT(":id"),
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <PrintOnlineReceipt />,
  },
  {
    name: "Fee Closing Approval",
    pagename: "Fee Closing Approval",
    module: "Admission",
    route: ROUTE_FEE_CLOSING_APPROVAL,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <FeeClosingApprovalList />,
  },
  {
    name: "Fee Closing Approval Online",
    pagename: "Fee Closing Approval Online",
    module: "Admission",
    route: ROUTE_FEE_CLOSING_APPROVAL_ONLINE,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <FeeClosingApprovalListOnline />,
  },
  {
    name: "Admission Status Report",
    pagename: "Admission Status Report",
    module: "Admission",
    route: ROUTE_ADMISSION_STATUS_REPORT,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <AdmissionStatusReport />,
  },
  {
    name: "Fee Clearance",
    pagename: "Fee Clearance",
    module: "Accounts",
    route: ROUTE_FEE_CLEARANCE,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <FeeClearanceList />,
  },
  {
    name: "Fee Clearance Online",
    pagename: "Fee Clearance Online",
    module: "Accounts",
    route: ROUTE_FEE_CLEARANCE_ONLINE,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <FeeClearanceListOnline />,
  },
  {
    name: "Refunds",
    pagename: "Refunds",
    module: "Refunds",
    route: ROUTE_REFUNDS_LIST,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <RefundList />,
  },
  {
    name: "Bank Deposit",
    pagename: "Bank Deposit",
    module: "Accounts",
    route: ROUTE_BANK_DEPOSIT,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    //    component: <ComponentBankDeposit />,
  },
  {
    name: "Daily Expenses",
    pagename: "Daily Expenses",
    module: "Accounts",
    route: ROUTE_DAILY_EXPENSES,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    //    component: <ComponentDailyExpenses />,
  },
  {
    name: "Education Income Report for Tally",
    pagename: "Education Income Report for Tally",
    module: "Accounts",
    route: ROUTE_EDUCATION_INCOME_TALLY,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <EducationIncomeReportTally />,
  },
  {
    name: "Education Income Report for Tally",
    pagename: "Education Income Report for Tally",
    module: "Accounts",
    route: ROUTE_EDUCATION_INCOME_TALLY_ONLINE,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <EducationIncomeReportTallyOnline />,
  },
  {
    name: "Education Debit Report for Tally",
    pagename: "Education Debit Report for Tally",
    module: "Accounts",
    route: ROUTE_EDUCATION_DEBIT_TALLY,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    //    component: <ComponentEducationDebitTally />,
  },
  {
    name: "Publication Income Report for Tally",
    pagename: "Publication Income Report for Tally",
    module: "Accounts",
    route: ROUTE_PUBLICATION_INCOME_TALLY,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    //    component: <ComponentPublicationIncomeTally />,
  },
  {
    name: "Publication Debiut Report for Tally",
    pagename: "Publication Debit Report for Tally",
    module: "Accounts",
    route: ROUTE_PUBLICATION_DEBIT_TALLY,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    //    component: <ComponentPublicationDebitTally />,
  },
  {
    name: "File Manager",
    pagename: "File Manager",
    module: "Website",
    route: ROUTE_MANAGE_FILES,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <FileManagerPage />,
  },
  {
    name: "ROUTE_MANAGE_HOME_BANNERS",
    pagename: "ROUTE_MANAGE_HOME_BANNERS",
    module: "Website",
    route: ROUTE_MANAGE_HOME_BANNERS,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <ManageBannersPage />,
  },
  {
    name: "ROUTE_MANAGE_PRODUCTS",
    pagename: "ROUTE_MANAGE_PRODUCTS",
    module: "Website",
    route: ROUTE_MANAGE_PRODUCTS,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <ManageProductsPage />,
  },
  {
    name: "ROUTE_MANAGE_CLASS",
    pagename: "ROUTE_MANAGE_CLASS",
    module: "Website",
    route: ROUTE_MANAGE_CLASS,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <ManageClassPage />,
  },
  {
    name: "ROUTE_MANAGE_TAGS",
    pagename: "ROUTE_MANAGE_TAGS",
    module: "Website",
    route: ROUTE_MANAGE_TAGS,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <ManageTagsPage />,
  },
  {
    name: "ROUTE_MANAGE_TESTIMONIALS",
    pagename: "ROUTE_MANAGE_TESTIMONIALS",
    module: "Website",
    route: ROUTE_MANAGE_TESTIMONIALS,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <ManageTestimonialsPage />,
  },
  {
    name: "ROUTE_MANAGE_COURSE_EXAMS",
    pagename: "ROUTE_MANAGE_COURSE_EXAMS",
    module: "Website",
    route: ROUTE_MANAGE_COURSE_EXAMS(':app'),
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <ManageCourseExamsPage />,
  },
  {
    name: "ROUTE_MANAGE_TOPIC",
    pagename: "ROUTE_MANAGE_TOPIC",
    module: "Website",
    route: ROUTE_MANAGE_TOPIC(':app'),
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <Topics />,
  },
  {
    name: "ROUTE_MANAGE_QUIZ",
    pagename: "ROUTE_MANAGE_QUIZ",
    module: "Website",
    route: ROUTE_MANAGE_QUIZ,
    icon: (
      <span role="img" className="bread-crumbs-users-icon text-white">
        <UserOutlined />
      </span>
    ),
    component: <ManageQuizPage />,
  },

];
