import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Drawer, Form, Image, Input, Row, Select, Space } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { CKEditor } from 'ckeditor4-react';
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBatch, getBatchType, getCenterList, getCourseListByCenter, getExams, getStreams } from "../../../core/store/reducers/masters.slice";
import { genericWebsiteCreate, genericWebsiteUpdate, getAllSubExamsByExamMulti, getContentSubCategoriesByContentCategoryId, getGenericListMinimal } from "../../../core/store/reducers/website.slice";
import { WEB_URL } from "../../../shared/constants";
import { RootState } from "../../../shared/types/common";
import { showNotification } from "../../../shared/utils/utils";
import DragTabs from "./drag-tabs";
import IconCardTabs from "./icon-card-tabs";
var _ = require("lodash")
declare var CKFinder: any;
const Option = Select;
const CreateEditComponent = ({ onClose, visible, onSubmit, selectedEntity, moduleData }: any) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [bannerUrl, setBannerUrl] = useState(selectedEntity ? selectedEntity?.banner_url : '' as string)
    const [iconUrl, setIconUrl] = useState(selectedEntity ? selectedEntity?.icon_url : '' as string)
    const [courseCategoryList, setCourseCategoryList] = useState([] as any)
    const [examCategoryList, setExamCategoryList] = useState([] as any)
    const [examList, setExamList] = useState([] as any)
    const [subExamList, setSubExamList] = useState([] as any)
    const [productList, setProductList] = useState([] as any)
    const [contentCategoryList, setContentCategoryList] = useState([] as any)
    const [contentSubCategoryList, setContentSubCategoryList] = useState([] as any)
    const [tagsList, setTagsList] = useState([] as any)
    const [tabsStructure, setTabsStructure] = useState(selectedEntity?.tabs as any)
    const [infoCardsStructure, setInfoCardsStructure] = useState(selectedEntity?.info_cards as any)
    const { screenType } = useSelector((state: RootState) => state.settings);
    const { user } = useSelector((state: RootState) => state.auth);
    const { masterData, centers, courses, batchType, exams, streams, batch } = useSelector((state: RootState) => state.masters);
    const [courseId, setCourseId] = useState(selectedEntity?.course_id?._id || null)
    const [centerId, setCenterId] = useState(selectedEntity?.center_id?._id || null)
    const [batchTypeId, setBatchTypeId] = useState(selectedEntity?.batch_type_id?._id || null)
    const [examId, setExamId] = useState(selectedEntity?.exam_id?._id || null)
    const [batchDetails, setBatchDetails] = useState(null as any)
    const [course_type, setCourse_Type] = useState(null as any)

    useEffect(() => {
        dispatch(getGenericListMinimal({ module: 'tags', params: { keys: 'tag_name' } })).then((val: any) => {

            if (val?.payload?.data?.data) {
                setTagsList(val?.payload?.data?.data?.map((val: any) => {
                    return {
                        value: val._id, label: val.tag_name
                    }
                }))
            }
        });

        if (moduleData?.key === 'course-category' || moduleData?.key === 'exam') {

            dispatch(getGenericListMinimal({ module: 'exam-category', params: { keys: 'exam_category_name' } })).then((val: any) => {

                if (val?.payload?.data?.data) {
                    setExamCategoryList(val?.payload?.data?.data?.map((val: any) => {
                        return {
                            value: val._id, label: val.exam_category_name
                        }
                    }))
                }
            });
        }
        if (moduleData?.key === 'content-item' || moduleData?.key === 'content-sub-category') {

            dispatch(getGenericListMinimal({ module: 'content-category', params: { keys: 'content_category_name' } })).then((val: any) => {

                if (val?.payload?.data?.data) {
                    setContentCategoryList(val?.payload?.data?.data?.map((val: any) => {
                        return {
                            value: val._id, label: val.content_category_name
                        }
                    }))
                }
            });
        }
        if (moduleData?.key === 'course' || moduleData?.key === 'sub-exam') {

            dispatch(getGenericListMinimal({ module: 'course-category', params: { keys: 'course_category_name' } })).then((val: any) => {

                if (val?.payload?.data?.data) {
                    setCourseCategoryList(val?.payload?.data?.data?.map((val: any) => {
                        return {
                            value: val._id, label: val.course_category_name
                        }
                    }))
                }
            });
            dispatch(getGenericListMinimal({ module: 'exam', params: { keys: 'exam_name' } })).then((val: any) => {

                if (val?.payload?.data?.data) {
                    setExamList(val?.payload?.data?.data?.map((val: any) => {
                        return {
                            value: val._id, label: val.exam_name
                        }
                    }))
                }
            });
            dispatch(getGenericListMinimal({ module: 'products', params: { keys: 'product_name' } })).then((val: any) => {

                if (val?.payload?.data?.data) {
                    setProductList(val?.payload?.data?.data?.map((val: any) => {
                        return {
                            value: val._id, label: val.product_name
                        }
                    }))
                }
            });

        }
    }, [moduleData])
    const onFinish = (values: any) => {

        if (selectedEntity) {
            const payload = {
                ...values,
                tabs: tabsStructure,
                info_cards: infoCardsStructure,
                _id: selectedEntity._id,
                updated_by: user._id,
            };
            dispatch(genericWebsiteUpdate({ payload: payload, module: moduleData.key })).then((val: any) => {
                if (val?.payload?.data) {
                    showNotification(moduleData.label + " Updated Successfully", "success");
                    onSubmit();
                }
            });
        } else {
            const payload = {
                ...values,
                tabs: tabsStructure,
                info_cards: infoCardsStructure,
                created_by: user._id,
                updated_by: user._id,
            }
            console.log(payload);
            dispatch(genericWebsiteCreate({ payload: payload, module: moduleData.key })).then((val: any) => {
                if (val.payload?.data?.success) {
                    showNotification(moduleData.label + " Saved Successfully", "success");
                    onSubmit();
                }
            })
        }
    }
    const extraButtons = [
        {
            htmlType: "button",
            colSpan: 5,
            loading: false,
            submitButtonText: "Cancel",
            onClick: onClose,
        },
        {
            htmlType: "submit",
            colSpan: 5,
            loading: false,
            submitButtonText: "Clear",
            type: "clearForm",
        },
        {
            htmlType: "submit",
            colSpan: 5,
            loading: false,
            submitButtonText: "Save",
            submitButtonType: "primary",
        }
    ]
    const openPopup = (ref: string, setData: any) => {
        CKFinder.popup({
            chooseFiles: true,
            onInit: (finder: any) => {
                finder.on('files:choose', function (evt: any) {
                    var file = evt.data.files.first();
                    form.setFieldValue(ref, file.getUrl())
                    setData(file.getUrl())
                });
                finder.on('file:choose:resizedImage', function (evt: any) {
                    form.setFieldValue(ref, evt.data.resizedUrl())
                    setData(evt.data.resizedUrl())
                });
            }
        });
    }

    useEffect(() => {
        dispatch(getCenterList())
    }, [])



    useEffect(() => {
        if (selectedEntity?.center_id) {
            setCenterId(selectedEntity?.center_id?._id)
            getRelevantData("course", selectedEntity?.center_id?._id)
        }
        if (selectedEntity?.course_id) {
            setCourseId(selectedEntity?.course_id._id)
            getRelevantData("batchType", selectedEntity?.course_id?._id)
        }
        if (selectedEntity?.batch_type_id) {
            setBatchTypeId(selectedEntity?.batch_type_id._id)
            getRelevantData("exam", selectedEntity?.batch_type_id?._id)
        }
        if (selectedEntity?.exam_id) {
            setExamId(selectedEntity?.exam_id?._id)
            getRelevantData("stream", selectedEntity?.exam_id?._id)
        }
        if (selectedEntity?.batch_id) {
            // set(selectedEntity?.exam_id?._id)
            getRelevantData("batch", selectedEntity?.batch_id?.stream_id)
        }
        if (selectedEntity?.course_type) {
            setCourse_Type(selectedEntity.course_type)
        }
        if (moduleData?.key == 'course') {
            onValuesChange({
                exam_ids: selectedEntity?.exam_ids?.map((val: any) => val?._id)
            }, {})
        }
    }, [selectedEntity])
    const getContentSubCategories = (content_category_id: string) => {
        if (content_category_id) {
            dispatch(getContentSubCategoriesByContentCategoryId(content_category_id)).then((val: any) => {
                if (val?.payload?.data?.data) {
                    setContentSubCategoryList(val?.payload?.data?.data?.map((val: any) => {
                        return {
                            value: val._id, label: val.content_sub_category_name
                        }
                    }))
                    form.setFieldValue('content_sub_category_id', null)
                }
            })
        }

    }
    const getRelevantData = (event: any, key: any) => {
        // if (event === "center") {
        //     dispatch(getCenterList())
        // }
        if (event === "course") {
            setCenterId(key)
            dispatch(getCourseListByCenter({ center_id: key }))
        }
        if (event === "batchType") {
            setCourseId(key)
            dispatch(getBatchType({ center_id: centerId, course_id: key }))
        }
        if (event === "exam") {
            setBatchTypeId(key)
            dispatch(getExams({ center_id: centerId, course_id: courseId, batch_type_id: key }))
        }
        if (event === "stream") {
            setExamId(key)
            dispatch(getStreams({ center_id: centerId, course_id: courseId, batch_type_id: batchTypeId, exam_id: key }))
        }
        if (event === "batch") {
            dispatch(getBatch({ center_id: centerId, course_id: courseId, batch_type_id: batchTypeId, exam_id: examId, stream_id: key }))
        }
        if (event === "details") {
            setBatchDetails(_.find(batch, function (n: any) {
                if (n?.batch_code_id?._id === key) {
                    return n;
                }
            }))
        }
    }
    function onValuesChange(currentValue: any, allValues: any) {
        if (currentValue?.exam_ids?.length > 0) {
            dispatch(getAllSubExamsByExamMulti({ exam_ids: currentValue?.exam_ids })).then((val: any) => {
                if (val?.payload?.data?.success) {
                    setSubExamList(val?.payload?.data?.data)
                }
            })
        }
    }
    return <>
        <Drawer
            maskClosable={false}
            title={"Manage " + moduleData?.label}
            className="form-drawer"
            width={screenType.isPortrait ? '90%' : '80%'}
            onClose={onClose}
            open={visible}
        >
            <Form
                form={form}
                initialValues={{
                    ...selectedEntity,
                    shipping_address_required: selectedEntity?.shipping_address_required === false ? false : true,
                    content_category_id: selectedEntity?.content_category_id?._id,
                    center_id: selectedEntity?.center_id?._id,
                    course_id: selectedEntity?.course_id?._id,
                    batch_type_id: selectedEntity?.batch_type_id?._id,
                    exam_id: selectedEntity?.exam_id?._id,
                    stream_id: selectedEntity?.batch_id?.stream_id,
                    batch_id: selectedEntity?.batch_id?._id,
                    product_ids: selectedEntity?.product_ids?.map((val: any) => val?._id),
                    course_category_ids: selectedEntity?.course_category_ids?.map((val: any) => val?._id),
                    exam_category_ids: selectedEntity?.exam_category_ids?.map((val: any) => val?._id),
                    tags: selectedEntity?.tags?.map((val: any) => val?._id),
                    exam_ids: selectedEntity?.exam_ids?.map((val: any) => val?._id),
                    sub_exam_ids: selectedEntity?.sub_exam_ids?.map((val: any) => val?._id),
                    ...(selectedEntity?.date_publish ? { date_publish: moment(selectedEntity?.date_publish) } : {}),
                }}
                className="paddingx20 app-form full-width"
                onFinish={onFinish}
                onValuesChange={onValuesChange}
            >
                <Row gutter={20}>
                    <Col span={12} className="gutter-row pr-2">
                        <Form.Item name="seo_url" label="SEO URL" >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12} className="gutter-row pl-2">
                        <Form.Item name={moduleData.name_key} label={moduleData?.label + " Name"}>
                            <Input onChange={(e) => {
                                let data = e.target.value;
                                data = data.replace(/[^a-zA-Z0-9 -]/g, '').split("  ").join(" ").split(" ").join("-").toLowerCase()
                                form.setFieldValue("seo_url", data);
                            }} />
                        </Form.Item>
                    </Col>
                    {
                        moduleData.key === 'course' && <>
                            <Col span={8} className="gutter-row">
                                <Form.Item name="course_type" label="Course Type">
                                    <Select showSearch={true} optionFilterProp="children"
                                        onChange={(evt: any) => setCourse_Type(evt)}
                                    >

                                        <Select.Option
                                            key={"classroom"}
                                            value={"classroom"}
                                        // className={"capitalize"}
                                        >
                                            {"Classroom"}
                                        </Select.Option>
                                        <Select.Option
                                            key={"live"}
                                            value={"live"}
                                        // className={"capitalize"}
                                        >
                                            {"Live"}
                                        </Select.Option>
                                        <Select.Option
                                            key={"recorded"}
                                            value={"recorded"}
                                        // className={"capitalize"}
                                        >
                                            {"Recorded"}
                                        </Select.Option>
                                        <Select.Option
                                            key={"test-series"}
                                            value={"test-series"}
                                        // className={"capitalize"}
                                        >
                                            {"Test Series"}
                                        </Select.Option>
                                        <Select.Option
                                            key={"publications"}
                                            value={"publications"}
                                        // className={"capitalize"}
                                        >
                                            {"Publications"}
                                        </Select.Option>

                                    </Select>
                                </Form.Item>
                            </Col><Col span={16}>
                                <Form.Item name="sub_title" label="Sub Title">
                                    <Input />
                                </Form.Item>
                            </Col></>
                    }
                    {moduleData.key === 'content-item' &&
                        <Col span={24} className="gutter-row">
                            <Form.Item name="content_item_sub_title" label="Content Sub Title">
                                <TextArea />
                            </Form.Item>
                        </Col>
                    }

                    {moduleData?.key !== 'content-category' && moduleData.key !== 'content-sub-category' && <Col span={12} className="gutter-row">
                        {bannerUrl != '' && <><Image src={bannerUrl} /><br /><Button type="link" onClick={() => setBannerUrl("")}>Clear</Button></>}
                        <Form.Item name="banner_url" label="Banner">
                            <Input disabled hidden />
                        </Form.Item>
                        <Button type="primary" onClick={() => openPopup('banner_url', setBannerUrl)}>
                            Select/Upload Image
                        </Button>
                    </Col>}

                    {(moduleData?.key === 'content-category') && <Col span={12} className="gutter-row">
                        <Form.Item name="display_position" label="Type" rules={[{ required: true, message: "Required" }]}>
                            <Select>
                                <Option value={'result'}>Results</Option>
                                <Option value={'notification'}>Notifications</Option>
                                <Option value={'free-downloads'}>Free Downloads</Option>
                                <Option value={'time-table'}>Time Table</Option>
                                <Option value={'whats-new'}>What's New</Option>
                            </Select>
                        </Form.Item>
                    </Col>}

                    {
                        moduleData?.key === "content-sub-category" && <Col span={12} className="gutter-row">
                            <Form.Item name="content_category_id" label="Content Categories">
                                <Select showSearch={true} optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option?.children
                                            ?.toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {contentCategoryList?.map((option: any, seli: number) => {
                                        return (
                                            <Select.Option
                                                key={seli}
                                                value={option.value}
                                            // className={"capitalize"}
                                            >
                                                {option.label}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    }

                    <Col span={12} className="gutter-row pl-4">
                        {iconUrl != '' && <><Image src={iconUrl} /><br /><Button type="link" onClick={() => setIconUrl("")}>Clear</Button></>}
                        <Form.Item name="icon_url" label="Icon">
                            <Input disabled hidden />
                        </Form.Item>
                        <Button type="primary" onClick={() => openPopup('icon_url', setIconUrl)}>
                            Select/Upload Image
                        </Button>

                    </Col>
                    {moduleData.key !== 'content-sub-category' && moduleData.key !== 'content-category' && <Col span={24} className="gutter-row">
                        <Form.Item name="content">
                            <Input disabled hidden />
                        </Form.Item>
                        <CKEditor
                            name={'content'}

                            editorUrl={'https://cdn.ckeditor.com/4.21.0/full-all/ckeditor.js'}
                            config={{
                                // extraPlugins: 'ckfinder',
                                filebrowserBrowseUrl: WEB_URL + 'ckfinder/ckfinder.html',
                                filebrowserUploadUrl: WEB_URL + 'ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Files',
                                allowedContent: true,
                            }}
                            initData={selectedEntity ? selectedEntity?.content : <p>Add content</p>}
                            onChange={(event) => {
                                console.log(event.editor.getData());
                                form.setFieldValue('content', event.editor.getData())
                            }}
                        />
                    </Col>}
                    {(moduleData.key === 'course-category' || moduleData.key === 'exam') &&
                        <Col span={12} className="gutter-row">
                            <Form.Item name="exam_category_ids" label="Exam Categories">
                                <Select mode="multiple" showSearch={true} optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option?.children
                                            ?.toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {examCategoryList?.map((option: any, seli: number) => {
                                        return (
                                            <Select.Option
                                                key={seli}
                                                value={option.value}
                                            // className={"capitalize"}
                                            >
                                                {option.label}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    }



                    {
                        moduleData.key === 'sub-exam' && <>
                            <Col span={8} className="gutter-row">
                                <Form.Item name="exam_ids" label="Exams">
                                    <Select mode="multiple" showSearch={true} optionFilterProp="children"
                                        filterOption={(input, option: any) =>
                                            option?.children
                                                ?.toLowerCase()
                                                .indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {examList?.map((option: any, seli: number) => {
                                            return (
                                                <Select.Option
                                                    key={seli}
                                                    value={option.value}
                                                // className={"capitalize"}
                                                >
                                                    {option.label}
                                                </Select.Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col></>
                    }
                    {moduleData.key === 'course' && <>
                        <Col span={8} className="gutter-row">
                            <Form.Item name="course_category_ids" label="Course Categories">
                                <Select mode="multiple" showSearch={true} optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option?.children
                                            ?.toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {courseCategoryList?.map((option: any, seli: number) => {
                                        return (
                                            <Select.Option
                                                key={seli}
                                                value={option.value}
                                            // className={"capitalize"}
                                            >
                                                {option.label}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8} className="gutter-row">
                            <Form.Item name="exam_ids" label="Exams">
                                <Select mode="multiple" showSearch={true} optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option?.children
                                            ?.toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {examList?.map((option: any, seli: number) => {
                                        return (
                                            <Select.Option
                                                key={seli}
                                                value={option.value}
                                            // className={"capitalize"}
                                            >
                                                {option.label}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8} className="gutter-row">
                            <Form.Item name="sub_exam_ids" label="Sub Exams">
                                <Select mode="multiple" showSearch={true} optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option?.children
                                            ?.toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {subExamList?.map((option: any, seli: number) => {
                                        return (
                                            <Select.Option
                                                key={seli}
                                                value={option._id}
                                            // className={"capitalize"}
                                            >
                                                {option.sub_exam_name}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8} className="gutter-row">
                            <Form.Item name="product_ids" label="Products">
                                <Select mode="multiple" showSearch={true} optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option?.children
                                            ?.toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {productList?.map((option: any, seli: number) => {
                                        return (
                                            <Select.Option
                                                key={seli}
                                                value={option.value}
                                            // className={"capitalize"}
                                            >
                                                {option.label}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col></>
                    }

                    {(moduleData.key === "course" || moduleData.key === "test-series") && (course_type === "classroom" || course_type === "live") &&
                        <>

                            <Col span={8}>
                                <Form.Item label={"Centre"} rules={[{ required: true }]} name={"center_id"}>
                                    <Select placeholder={"Choose Center"} onChange={(evt) => getRelevantData("course", evt)}>
                                        {
                                            centers.map((item: any) => {
                                                return <Option value={item?._id}>{item?.center_name}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item label={"Course"} rules={[{ required: true }]} name={"course_id"}>
                                    <Select placeholder={"Choose Course"} onChange={(evt) => getRelevantData("batchType", evt)}>
                                        {
                                            courses.map((item: any) => {
                                                return <Option value={item?.course_ref?._id}>{item?.course_ref?.course_name}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>


                            <Col span={8}>
                                <Form.Item label={"Batch Type"} rules={[{ required: true }]} name={"batch_type_id"}>
                                    <Select placeholder={"Choose Batch Type"} onChange={(evt) => getRelevantData("exam", evt)}>
                                        {
                                            batchType.map((item: any) => {
                                                return <Option value={item?.batch_type_id?._id}>{item?.batch_type_id?.batch_type_name}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item label={"Exam"} rules={[{ required: true }]} name={"exam_id"}>
                                    <Select placeholder={"Choose Exam"} onChange={(evt) => getRelevantData("stream", evt)}>
                                        {
                                            exams.map((item: any) => {
                                                return <Option value={item?.exam_id?._id}>{item?.exam_id?.exam_name}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item label={"Stream"} rules={[{ required: true }]} name={"stream_id"}>
                                    <Select placeholder={"Choose Stream"} onChange={(evt) => getRelevantData("batch", evt)}>
                                        {
                                            streams.map((item: any) => {
                                                return <Option value={item?.stream_id?._id}>{item?.stream_id?.stream_name}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item label={"Batch"} rules={[{ required: true }]} name={"batch_id"}>
                                    <Select placeholder={"Choose Batch"} onChange={(evt) => getRelevantData("details", evt)}>
                                        {
                                            batch.map((item: any) => {
                                                return <Option value={item?._id}>{item?.batch_code_id?.batch_code}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </>}


                    {(moduleData.key === "course" || moduleData.key === "test-series") &&
                        <>

                            <Col span={4}>
                                <Form.Item label={"Mock Test Count"} rules={[{ required: true }]} name={"mock_test_count"}>
                                    <Input></Input>
                                </Form.Item>
                            </Col>

                            <Col span={4}>
                                <Form.Item label={"Subject Test Count"} rules={[{ required: true }]} name={"subject_test_count"}>
                                    <Input></Input>
                                </Form.Item>
                            </Col>

                            <Col span={4}>
                                <Form.Item label={"Topic Test Count"} rules={[{ required: true }]} name={"topic_test_count"}>
                                    <Input></Input>
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <Form.Item label={"Base Fee (including Book Fee)"} rules={[{ required: true }]} name={"base_fee"}>
                                    <Input></Input>
                                </Form.Item>
                            </Col>

                            <Col span={4}>
                                <Form.Item label={"Discounted Fee"} rules={[{ required: true }]} name={"discounted_fee"}>
                                    <Input></Input>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label={"Book Fee"} name={"book_fee"}>
                                    <Input></Input>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label={"Shipping Address?"} name={"shipping_address_required"}>
                                    <Select>
                                        <Option value={true}>Yes</Option>
                                        <Option value={false}>No</Option>
                                    </Select>
                                </Form.Item>
                            </Col>

                        </>}


                    {
                        (course_type === "recorded" || course_type === "test-series") && <Col span={4} className="gutter-row">
                            <Form.Item name="validity" label="Validity (Days)">
                                <Input type="number"></Input>
                            </Form.Item>
                        </Col>
                    }



                    {moduleData.key === 'content-item' &&
                        <><Col span={12} className="gutter-row">
                            <Form.Item name="content_category_id" label="Content Categories">
                                <Select showSearch={true} optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option?.children
                                            ?.toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={getContentSubCategories}
                                >
                                    {contentCategoryList?.map((option: any, seli: number) => {
                                        return (
                                            <Select.Option
                                                key={seli}
                                                value={option.value}
                                            // className={"capitalize"}
                                            >
                                                {option.label}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                            <Col span={12} className="gutter-row">
                                <Form.Item name="content_sub_category_id" label="Content Sub Categories">
                                    <Select showSearch={true} optionFilterProp="children"
                                        filterOption={(input, option: any) =>
                                            option?.children
                                                ?.toLowerCase()
                                                .indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {contentSubCategoryList?.map((option: any, seli: number) => {
                                            return (
                                                <Select.Option
                                                    key={seli}
                                                    value={option.value}
                                                // className={"capitalize"}
                                                >
                                                    {option.label}
                                                </Select.Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </>
                    }
                    {(moduleData.key !== 'content-category' && moduleData.key !== 'content-sub-category') && <Col span={12} className="gutter-row">
                        <Form.Item name="tags" label="Tags">
                            <Select mode="multiple" showSearch={true} optionFilterProp="children"
                                filterOption={(input, option: any) =>
                                    option?.children
                                        ?.toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {tagsList?.map((option: any, seli: number) => {
                                    return (
                                        <Select.Option
                                            key={seli}
                                            value={option.value}
                                        // className={"capitalize"}
                                        >
                                            {option.label}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>}
                    {moduleData.key === 'course' &&
                        <Col span={6} className="gutter-row">
                            <Form.Item name="published" label="Publish on Website">
                                <Select>
                                    <Option value={true}>Yes</Option>
                                    <Option value={false}>No</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    }
                    {moduleData.key === 'content-item' &&
                        <Col span={12} className="gutter-row">
                            <Form.Item name="date_publish" label="Publishing Start Date">
                                <DatePicker style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                    }

                    {moduleData.key == 'sub-exam' && <Col span={24} className="gutter-row">
                        <Row>
                            <Col span={24} className="p-3 mb-3" style={{ border: '1px solid #aaa', borderRadius: '5px' }}>
                                <div style={{ marginBottom: 16 }} className="ant-form-item-label">
                                    <label className="w500">EXAM SECTIONS STRUCTURE</label><br />
                                </div>
                                <Form.List name="sections_structure">
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map(({ key, name, ...restField }) => (
                                                <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'section_id']}
                                                        rules={[{ required: true, message: 'Section No. is required' }]}
                                                    >
                                                        <Input placeholder="Section No." />
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'section_name']}
                                                        rules={[{ required: true, message: 'Section Name is required' }]}
                                                    >
                                                        <Input placeholder="Section Name" />
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'time']}
                                                    >
                                                        <Input placeholder="Section Time (min)" />
                                                    </Form.Item>
                                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                                </Space>
                                            ))}
                                            <Form.Item>
                                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                    Add field
                                                </Button>
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>
                            </Col></Row>
                    </Col>}

                    {(moduleData.key !== 'content-category' && moduleData.key !== 'content-sub-category') && moduleData.key !== 'content-item' && <Col span={24} className="gutter-row">
                        <DragTabs tabsStructure={tabsStructure} setTabsStructure={setTabsStructure}></DragTabs>
                    </Col>}

                    {(moduleData.key !== 'content-category' && moduleData.key !== 'content-sub-category') && moduleData.key !== 'content-item' && <Col span={24} className="gutter-row">
                        <IconCardTabs infoCardsStructure={infoCardsStructure} setInfoCardsStructure={setInfoCardsStructure}></IconCardTabs>
                    </Col>}
                </Row>
                <Row className="button-row" justify="end" gutter={16}>

                    {extraButtons.map((btn: any, index: number) => {
                        return (
                            <Col
                                {...btn?.colSpanResponsive}
                                span={btn.colSpan}
                                key={index}
                                className="gutter-row"
                            >
                                <Form.Item>
                                    <Button
                                        loading={btn.loading}
                                        // size="large"
                                        {...(btn.onClick ? { onClick: btn.onClick } : {})}
                                        {...(btn.type === "clearForm"
                                            ? {
                                                onClick: () => {
                                                    form.resetFields();
                                                    form.submit();
                                                },
                                            }
                                            : {})}
                                        className={"mt20"}
                                        type={btn.submitButtonType}
                                        htmlType={
                                            btn.type === "clearForm" ? "button" : btn.htmlType
                                        }
                                        block
                                    >
                                        {btn.submitButtonText}
                                    </Button>
                                </Form.Item>
                            </Col>
                        );
                    })}
                </Row>
            </Form>
        </Drawer >
    </>
}

export default CreateEditComponent;