import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, PointerSensor, useSensor } from '@dnd-kit/core';
import {
    SortableContext,
    arrayMove,
    horizontalListSortingStrategy,
    useSortable
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { css } from '@emotion/css';

import { Button, Col, Form, Input, Row, Tabs } from 'antd';
import { CKEditor } from 'ckeditor4-react';
import React, { useEffect, useRef, useState } from 'react';
import { WEB_URL } from '../../../shared/constants';

type TargetKey = React.MouseEvent | React.KeyboardEvent | string;



interface DraggableTabPaneProps extends React.HTMLAttributes<HTMLDivElement> {
    'data-node-key': string;
    onActiveBarTransform: (className: string) => void;
}

const DraggableTabNode = ({ className, onActiveBarTransform, ...props }: DraggableTabPaneProps) => {
    const { attributes, listeners, setNodeRef, transform, transition, isSorting } = useSortable({
        id: props['data-node-key'],
    });

    const style: React.CSSProperties = {
        ...props.style,
        transform: CSS.Transform.toString(transform),
        transition,
        cursor: 'move',
    };

    useEffect(() => {
        if (!isSorting) {
            onActiveBarTransform('');
        } else if (className?.includes('ant-tabs-tab-active')) {
            onActiveBarTransform(
                css`
          .ant-tabs-ink-bar {
            transform: ${CSS.Transform.toString(transform)};
            transition: ${transition} !important;
          }
        `,
            );
        }
    }, [className, isSorting, transform]);

    return React.cloneElement(props.children as React.ReactElement, {
        ref: setNodeRef,
        style,
        ...attributes,
        ...listeners,
    });
};

const DragTabs = ({ tabsStructure, setTabsStructure }: any) => {

    const [className, setClassName] = useState('');
    const defaultPanes = [] as any;
    const sensor = useSensor(PointerSensor, { activationConstraint: { distance: 10 } });

    const onDragEnd = ({ active, over }: DragEndEvent) => {
        if (active.id !== over?.id) {
            setItems((prev: any) => {
                const activeIndex = prev.findIndex((i: any) => i.key === active.id);
                const overIndex = prev.findIndex((i: any) => i.key === over?.id);
                return arrayMove(prev, activeIndex, overIndex);
            });
        }
    };

    const [activeKey, setActiveKey] = useState("0");
    const [items, setItems] = useState(defaultPanes);
    const newTabIndex = useRef(0);

    const onChange = (key: string) => {
        setActiveKey(key);
    };
    useEffect(() => {
        const oldItems = [...items] as any
        tabsStructure?.forEach((val: any, index: number) => {
            oldItems.push({
                label: val.tab_title,
                children: tabData(`${index}`, val),
                key: `${index}`,
                content: val.tab_content
            })
        })
        setItems(oldItems);
    }, [])
    useEffect(() => {
        if (items?.length > 0) {
            const str = items.map((val: any, index: number) => {
                return {
                    order: index,
                    tab_title: val?.label,
                    tab_content: val?.content,
                }
            })
            setTabsStructure(str)
        }
    }, [items])
    const add = () => {
        const newActiveKey = `${newTabIndex.current++}`;
        const oldItems = [...items] as any
        oldItems.push({
            label: `Tab Name`,
            children: tabData(newActiveKey),
            key: newActiveKey,
            content: ''
        })
        setItems(oldItems);
        setActiveKey(newActiveKey);
    };
    const tabData = (newActiveKey: any, val = {
        tab_title: `Tab Title`,
        tab_content: <p>Add content</p>,
    }) => {
        return <Row className='p-3'>
            <Col span={24}>
                <Row>
                    <Col span={24}>
                        <Form.Item name={'tab_title_' + newActiveKey} initialValue={items[newActiveKey]?.label ? items[newActiveKey]?.label : val.tab_title} label="Tab Title">
                            <Input
                                onChange={
                                    (e) => {
                                        setItems((prev: any) => {
                                            return prev.map((val: any) => {
                                                return val.key === newActiveKey ? {
                                                    ...val, label: e.target.value
                                                } : val
                                            })
                                        })
                                    }
                                }></Input>
                        </Form.Item>
                    </Col>
                    <Col span={24} style={{ marginTop: "10px" }}>
                        <CKEditor
                            name={'content'}
                            editorUrl={'https://cdn.ckeditor.com/4.21.0/full-all/ckeditor.js'}
                            config={{
                                // extraPlugins: 'ckfinder',
                                filebrowserBrowseUrl: WEB_URL + 'ckfinder/ckfinder.html',
                                filebrowserUploadUrl: WEB_URL + 'ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Files'
                            }}
                            initData={items[parseInt(newActiveKey)]?.content ? items[parseInt(newActiveKey)]?.content : val?.tab_content}
                            onChange={(event) => {
                                console.log(event.editor.getData());
                                setItems((prev: any) => {
                                    return prev.map((val: any) => {
                                        return val.key === newActiveKey ? {
                                            ...val, content: event.editor.getData()
                                        } : val
                                    })
                                })
                                // form.setFieldValue('content', event.editor.getData())
                            }}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    }
    const remove = (targetKey: TargetKey) => {
        const targetIndex = items.findIndex((pane: any) => pane.key === targetKey);
        const newPanes = items.filter((pane: any) => pane.key !== targetKey);
        if (newPanes.length && targetKey === activeKey) {
            const { key } = newPanes[targetIndex === newPanes.length ? targetIndex - 1 : targetIndex];
            setActiveKey(key);
        }
        setItems(newPanes);
    };

    const onEdit = (targetKey: TargetKey, action: 'add' | 'remove') => {
        if (action === 'add') {
            add();
        } else {
            remove(targetKey);
        }
    };
    console.log(items)
    return (
        <Row>
            <Col span={24} className="p-3 mb-3" style={{ border: '1px solid #aaa', borderRadius: '5px' }}>
                <div style={{ marginBottom: 16 }} className="ant-form-item-label">
                    <label className="w500">TABS STRUCTURE</label><br />
                    <Button type='primary' onClick={add}>Add New Tab</Button>
                </div>
                <Tabs
                    hideAdd
                    onChange={onChange}
                    activeKey={activeKey}
                    type="editable-card"
                    onEdit={onEdit}
                    className={className}
                    items={items}
                    renderTabBar={(tabBarProps, DefaultTabBar) => (
                        <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
                            <SortableContext items={items.map((i: any) => i.key)} strategy={horizontalListSortingStrategy}>
                                <DefaultTabBar {...tabBarProps}>
                                    {(node) => (
                                        <DraggableTabNode
                                            {...node.props}
                                            key={node.key}
                                            onActiveBarTransform={setClassName}
                                        >
                                            {node}
                                        </DraggableTabNode>
                                    )}
                                </DefaultTabBar>
                            </SortableContext>
                        </DndContext>
                    )}
                />
            </Col>

        </Row>

    );
};

export default DragTabs;