import { Spin } from "antd";
import { Suspense, useEffect } from "react";
import { Provider, useDispatch } from "react-redux";
import { useMediaQuery } from 'react-responsive';
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import "./App.less";
import RouteGuard from "./core/guard/route.guard";
import { persistor, store } from "./core/store";
import { setScreenType } from "./core/store/reducers/settings.slice";
import { WEB_URL } from "./shared/constants";

function App() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = WEB_URL + "ckfinder/ckfinder.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    }
  }, []);
  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <AppRouted />
          </Router>
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
export function AppRouted() {
  const dispatch = useDispatch()
  const screenType = {
    isXl: useMediaQuery({ minWidth: 1201 }),
    isLg: useMediaQuery({ minWidth: 992, maxWidth: 1200 }),
    isMd: useMediaQuery({ minWidth: 768, maxWidth: 991 }),
    isSm: useMediaQuery({ minWidth: 576, maxWidth: 767 }),
    isXs: useMediaQuery({ maxWidth: 575 }),
    isPortrait: useMediaQuery({ query: '(orientation: portrait)' })
  }
  useEffect(() => {
    dispatch(setScreenType(screenType))
  }, [])
  return (
    <>
      <Suspense
        fallback={
          <div>
            <Spin />
          </div>
        }
      >
        <Routes>
          <Route path="*" element={<RouteGuard />} />
        </Routes>
      </Suspense>
    </>
  );
}
