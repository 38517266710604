import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { onlinePaymentForInvoice } from "../../../core/store/reducers/admission.slice";
import "./print-reciept.style.less";

const PrintOnlineReceipt = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const [payment, setPayment] = useState(null as any);

    useEffect(() => {
        dispatch(onlinePaymentForInvoice(params?.id)).then((val: any) => {
            if (val?.payload?.data?.success) {
                setPayment(val?.payload?.data?.data)
                setTimeout(() => {
                    Print();
                }, 1000);
            }
        });
    }, []);


    const Print = () => {
        // let printContents: any = document.getElementById("report-view")?.innerHTML;
        // let originalContents = document.body.innerHTML;
        // document.body.innerHTML = printContents;
        window.print();
        // document.body.innerHTML = originalContents;
    };
    let sgst = 0;
    let cgst = 0;
    let igst = 0;
    let sgstp = 9;
    let cgstp = 9;
    let igstp = 0;
    if (payment?.total_amount) {
        sgst = Math.round(payment?.total_amount * (sgstp)) / 100
        cgst = Math.round(payment?.total_amount * (cgstp)) / 100
        igst = Math.round(payment?.total_amount * (igstp)) / 100
    }
    return (
        <>
            {/* <Button onClick={Print}>Print</Button> */}
            <div className="report-view-container">
                <div className="report-view" id="report-view">
                    <div>
                        <p style={{ color: "black", margin: "0", fontWeight: "bold", float: "right", padding: "5px", background: "#00ffff !important" }}>GSTIN - 21AAFCV2531H1ZQ</p>
                        <p style={{ color: "black", margin: "0", fontWeight: "bold", fontSize: '18px' }}>VANIK EDUCATION PVT LTD</p>
                        <p style={{ color: "black", margin: "0", fontWeight: "bold", fontSize: '14px', maxWidth: '70%' }}>Address : {payment?.website_course_id?.batch_id?.center_id?.center_address || 'BHUBANESWAR, ODISHA'}</p>
                        <p style={{ color: "black", margin: "0", fontWeight: "bold", fontSize: '14px' }}>Mobile : {payment?.website_course_id?.batch_id?.center_id?.phone || '8093556677'} &nbsp;&nbsp;&nbsp;&nbsp; Email ID : {payment?.website_course_id?.batch_id?.center_id?.email || 'vanikbbsr@gmail.com'}</p>
                        <table cellSpacing={0} border={1} style={{ width: '100%', fontSize: '14px', fontWeight: 'bold' }}>
                            <tbody>
                                <tr>
                                    <td style={{ width: '80%', verticalAlign: 'center' }}>
                                        <p
                                            style={{ color: "black", margin: "0" }}
                                            className="text-center">TAX INVOICE</p>
                                    </td>
                                    <td style={{ padding: '3px 7px 1px 7px' }}>
                                        <p style={{
                                            color: "black",
                                            margin: "0pt"
                                        }}>Fee Receipt</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table cellSpacing={0} border={1} style={{ width: '100%', fontSize: '14px' }}>
                            <tbody>
                                <tr>
                                    <td style={{ padding: '20px', fontWeight: "bold" }}>
                                        <table style={{ width: '100%' }}>
                                            <tr>
                                                <td style={{ width: '50%' }}>Reverse Charge</td><td>Nil</td>
                                            </tr>
                                            <tr>
                                                <td>Invoice No.</td><td>{payment?.invoice_number}</td>
                                            </tr>
                                            <tr>
                                                <td>Invoice Date</td><td>{moment(payment?.created_on).format("DD MMM YYYY")}</td>
                                            </tr>
                                        </table>

                                    </td>
                                    <td style={{ padding: '3px 7px 1px 7px', fontWeight: "bold" }}>
                                        <table style={{ width: '100%' }}>
                                            <tr>
                                                <td style={{ width: '50%' }}>Place of delivery</td><td>{payment?.candidate_id?.present_address?.present_city}</td>
                                            </tr>
                                            <tr>
                                                <td>Date of supply</td><td>{moment(payment?.created_on).format("DD MMM YYYY")}</td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table cellSpacing={0} style={{ width: '100%', fontSize: '14px' }}>
                            <tbody>
                                <tr style={{ border: "1px solid #000", fontWeight: "bold" }}>
                                    <td colSpan={4} style={{ padding: '3px 7px 1px 7px' }}>
                                        <p style={{ color: "black", margin: "0" }} className="text-center">Details of Receiver | Billed to</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: '40%', padding: '20px', borderLeft: "1px solid #000", fontWeight: "bold" }}>
                                        <table style={{ width: '100%' }}>
                                            <tr>
                                                <td style={{ width: '50%' }}>Roll Number</td><td className="b400">{payment?.roll_no}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '50%' }}>Student Name</td><td className="b400">{payment?.candidate_id?.candidate_name}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '50%' }}>Course</td><td className="b400">{payment?.website_course_id?.course_name}</td>
                                            </tr>
                                            {payment?.website_course_id?.batch_id && <>    <tr>
                                                <td style={{ width: '50%' }}>Exam</td><td className="b400">{payment?.website_course_id?.batch_id?.exam_id?.exam_name}</td>
                                            </tr>
                                                <tr>
                                                    <td style={{ width: '50%' }}>Batch Code</td><td className="b400">{payment?.website_course_id?.batch_id?.batch_code_id?.batch_code}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: '50%' }}>Batch Type</td><td className="b400">{payment?.website_course_id?.batch_id?.batch_type_id?.batch_type_name}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: '50%' }}>Batch St. Date</td><td className="b400">{payment?.website_course_id?.batch_id?.batch_time_id?.batch_time_from}  {payment?.website_course_id?.batch_id?.batch_time_id?.batch_time_to}</td>
                                                </tr></>}
                                        </table>
                                    </td>

                                    <td style={{ width: '45%', padding: '20px', borderLeft: "1px solid #000" }}>
                                        <p style={{ color: "black" }}><span style={{ fontWeight: "bold" }}>Stream :</span>  <span>{payment?.website_course_id?.batch_id?.stream_id?.stream_name}</span></p>
                                        <p style={{ color: "black" }}>
                                            <span style={{ fontWeight: "bold" }}>Venue</span> :

                                            Online
                                        </p>
                                        {payment?.shipping_address?.address_line_1 ? <>
                                            <p style={{ color: "black", fontWeight: "bold" }} className="mb-0">Shipping Address :</p>
                                            <p style={{ color: "black" }} className="mb-0">
                                                {payment?.shipping_address?.name}<br />
                                                {payment?.shipping_address?.mobile}<br />
                                                {payment?.shipping_address?.address_line_1}{", "}
                                                {payment?.shipping_address?.address_line_2}<br />
                                                {payment?.shipping_address?.state} - {payment?.shipping_address?.pincode}
                                            </p>
                                        </> : <>
                                            <p style={{ color: "black", fontWeight: "bold" }} className="mb-0">Student Address :</p>
                                            <p style={{ color: "black" }} className="mb-0">
                                                {payment?.candidate_id?.present_address?.present_address_line_1}{" "}
                                                {payment?.candidate_id?.present_address?.present_address_line_2}{" "}
                                                {payment?.candidate_id?.present_address?.present_city}{" "}
                                                {payment?.candidate_id?.present_address?.present_district}{" "}
                                                {payment?.candidate_id?.present_address?.present_state}{" "}
                                                {payment?.candidate_id?.present_address?.present_pincode}
                                            </p>
                                        </>}
                                    </td>
                                    <td style={{ width: '15%', padding: '3px 7px 1px 7px', borderLeft: "1px solid #000", borderRight: "1px solid #000" }}>
                                        <p style={{ color: "black", margin: "0", fontWeight: "bold" }}>Student Photo :</p>
                                        <table border={0} cellSpacing={0} cellPadding={0}>
                                            <tbody>
                                                <tr>
                                                    <td style={{ padding: '3px 7px 1px 7px' }}>
                                                        <img
                                                            src={payment?.candidate_id?.photo}
                                                            alt=""
                                                            width={136}
                                                            height={137}
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <table cellSpacing={0} border={1} style={{ width: '100%', fontSize: '14px' }}>
                            <tbody>

                                <tr>
                                    <td style={{ padding: '20px' }}>
                                        <p style={{ color: "black", margin: "0", fontWeight: "bold" }}>Mode Of Payment :</p>
                                    </td>
                                    <td colSpan={7} style={{ padding: '20px' }}>
                                        <p style={{ color: "black", margin: "0" }}>{payment?.payment_mode}</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table cellSpacing={0} border={1} style={{ width: '100%', fontSize: '14px' }}>
                            <tbody>
                                <tr>
                                    <td style={{ width: '60%', padding: '7px 20px 5px 20px' }}>
                                        <p style={{ color: "black", margin: "0", fontWeight: "bold" }} className="text-center">Remarks</p>
                                    </td>
                                    <td style={{ width: '25%', padding: '7px 20px 5px 20px' }}>
                                        <p style={{ color: "black", margin: "0", fontWeight: "bold" }}>Total Amount Before Tax</p>
                                    </td>
                                    <td style={{ width: '15%', padding: '7px 20px 5px 20px' }}>
                                        <p style={{ color: "black", margin: "0" }}>₹ {payment?.total_amount - sgst - cgst - igst}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td rowSpan={5} style={{ padding: '7px 20px 5px 20px' }}>
                                        <p style={{ color: "black", margin: "0" }}>
                                            {payment?.remarks?.indexOf('{"') == -1 ? payment?.remarks : "Full Paid Online"}
                                            {payment?.book_fee > 0 && payment?.books_included ?
                                                <>Books included</>
                                                : <></>}
                                        </p>
                                    </td>
                                    <td style={{ padding: '7px 20px 5px 20px' }}>
                                        <p style={{ color: "black", margin: "0", fontWeight: "bold" }}>Add: CGST</p>
                                    </td>
                                    <td colSpan={2} style={{ padding: '7px 20px 5px 20px' }}>
                                        <p style={{ color: "black", margin: "0" }}>₹ {cgst}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '7px 20px 5px 20px' }}>
                                        <p style={{ color: "black", margin: "0", fontWeight: "bold" }}>Add: SGST</p>
                                    </td>
                                    <td colSpan={2} style={{ padding: '7px 20px 5px 20px' }}>
                                        <p style={{ color: "black", margin: "0" }}>₹ {sgst}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '7px 20px 5px 20px' }}>
                                        <p style={{ color: "black", margin: "0", fontWeight: "bold" }}>Add: IGST</p>
                                    </td>
                                    <td colSpan={2} style={{ padding: '7px 20px 5px 20px' }}>
                                        <p style={{ color: "black", margin: "0" }}>₹ {igst}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '7px 20px 5px 20px' }}>
                                        <p style={{ color: "black", margin: "0", fontWeight: "bold" }}>Tax Amount: GST</p>
                                    </td>
                                    <td colSpan={2} style={{ padding: '7px 20px 5px 20px' }}>
                                        <p style={{ color: "black", margin: "0" }}>₹ {sgst + cgst + igst}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '7px 20px 5px 20px' }}>
                                        <p style={{ color: "black", margin: "0", fontWeight: "bold" }}>Total Amount After Tax</p>
                                    </td>
                                    <td colSpan={2} style={{ padding: '7px 20px 5px 20px' }}>
                                        <p style={{ color: "black", margin: "0" }}>₹ {payment?.total_amount}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td rowSpan={3} style={{ padding: '7px 20px 5px 20px' }}>
                                        <p style={{ color: "black", margin: "0" }}>• VANIK reserves all the rights to make any alterations in
                                            its programs/fee/faculty/venue of classes without any
                                            prior notice to anybody under unavoidable circumstances.
                                            However, all such changes will be
                                            well-notified to the students. The decision of VANIK shall
                                            be final and abiding. </p><p style={{ color: "black", margin: "0" }}>• Student can avail the fee refund
                                                facility within first five days for Regular batches &amp;
                                                first 3 days for Weekend batches from the date of admission
                                                batch or course.Fee will be refunded after deducting initial
                                                processing and admission charges, equivalent to admission
                                                fee (As mentioned in fee receipt). Refund amount will be
                                                paid through account payee cheque only, which will be in the
                                                name of the candidate. </p><p style={{ color: "black", margin: "0" }}>• Instalment charge / late fee will
                                                    applied in case of payment not received before due date
                                        </p>
                                    </td>
                                    <td style={{ padding: '7px 20px 5px 20px', height: '20px' }}>
                                        <p style={{ color: "black", margin: "0", fontWeight: "bold" }}>GST Payable on Reverse Charge</p>
                                    </td>
                                    <td colSpan={2} style={{ padding: '7px 20px 5px 20px' }}>
                                        <p style={{ color: "black", margin: "0" }}>Nil</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '7px 20px 5px 20px', height: '20px' }} colSpan={2}>
                                        <p style={{ color: "black", margin: "0", fontWeight: "bold" }}>Authorized Signatory &amp; Seal</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ height: '150px' }} colSpan={2}></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PrintOnlineReceipt;
