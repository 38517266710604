import { ReconciliationOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Row, Space, Tabs, Tooltip } from "antd";
import { useCallback, useEffect, useState } from "react";
import { BiFilterAlt, BiReceipt } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { getRefundList } from "../../../core/store/reducers/admission.slice";
import ContentHeaderComponent from "../../../shared/components/content-header/content-header.component";
import DataTableComponent from "../../../shared/components/data-table/data-table.component";
import GenericFormComponent from "../../../shared/components/generic-form/generic-form.component";
import { TABLE_ROWS_COUNT } from "../../../shared/constants";
import { ROUTE_PRINT_REFUND_RECEIPT } from "../../../shared/routes/admission.routes";
import { RootState } from "../../../shared/types/common";
import { formatDate } from "../../../shared/utils/utils";
import { RefundComponent } from "../refund/refund.component";


function RefundList() {
    const [filterVisible, setFilterVisible] = useState(false);
    const [refundList, setrefundList] = useState([] as any);
    const dispatch = useDispatch();
    const { refunds, filters, total } = useSelector((state: RootState) => state.admission);
    // Actions and selections
    const [sorter, setSorter] = useState({ sort_by: "", sort_type: "" });
    const [pagination, setPagination] = useState({ page: 1, limit: TABLE_ROWS_COUNT });
    const [paidType, setPaidType] = useState('initiated' as any);
    const [refund, setRefund] = useState(null as any)

    useEffect(() => {
        loadList();
    }, [])

    const loadList = useCallback(
        (_page: any = 1) => {
            const data = {
                ...pagination,
                ...sorter,
                status: paidType
            };
            dispatch(getRefundList({ params: data, body: filters }));
        },
        [filters, dispatch, sorter, pagination, paidType]
    );
    useEffect(() => {
        loadList();
    }, [loadList]);

    useEffect(() => {
        // if (enrolments?.length > 0) {
        setrefundList(
            refunds?.map((obj: any, index: number) => {
                return { key: index, ...obj };
            })
        );
        // }
    }, [refunds]);
    const onPageChange = (pageNumber: number) => {
        setPagination({
            page: pageNumber,
            limit: pagination.limit,
        });
    };

    const columns = [
        {
            title: 'Reg. No.',
            dataIndex: ['candidate_id', 'registration_number'],
            key: 'candidatssse_name',
        },
        {
            title: 'Type',
            dataIndex: 'refund_type',
            key: 'candidafeqwgte_name',
            className: 'capitalize'
        },
        {
            title: 'Initiation Date',
            dataIndex: 'created_on',
            key: 'dsdef',
            render: (dat: any) => formatDate(dat, "DD MMM, YY")
        },
        {
            title: 'Processed Date',
            key: 'feqfw',
            render: (dat: any) => dat.status == 'done' ? formatDate(dat, "DD MMM, YY") : '--'
        },
        {
            title: 'Name',
            dataIndex: ['candidate_id', 'candidate_name'],
            key: 'candidate_name',
        },
        {
            title: 'Mobile',
            dataIndex: ['candidate_id', 'contact'],
            key: 'contact',
        },
        {
            title: 'Email',
            dataIndex: ['candidate_id', 'email'],
            key: 'email',
        },
        {
            title: 'Center',
            dataIndex: ['course_enrolment_id', 'batch_id', 'center_id', 'center_name'],
            key: 'center_name',
        },
        {
            title: 'Course',
            dataIndex: ['course_enrolment_id', 'batch_id', 'course_id', 'course_name'],
            key: 'course_name',
        },
        {
            title: 'Batch Type',
            dataIndex: ['course_enrolment_id', 'batch_id', 'batch_type_id', 'batch_type_name'],
            key: 'batch_type_name',
        },
        {
            title: 'Exam',
            dataIndex: ['course_enrolment_id', 'batch_id', 'exam_id', 'exam_name'],
            key: 'exam_name',
        },
        {
            title: 'Stream',
            dataIndex: ['course_enrolment_id', 'batch_id', 'stream_id', 'stream_name'],
            key: 'stream_name',
        },
        {
            title: 'Session',
            dataIndex: ['course_enrolment_id', 'batch_id', 'session_id', 'session'],
            key: 'session',
        },
        {
            title: 'Action',
            // dataIndex: 'address',
            fixed: 'right',
            render: (a: any, record: any) => {
                return (
                    <>
                        {/* <Tooltip title={"Edit"}>
                            <Button
                                type="link"
                                className="table-button"
                                icon={<EditOutlined />}
                            // onClick={() => openAddEditPanel(a)}
                            ></Button>
                        </Tooltip>
                        <Tooltip title={"Details"}>
                            <Button
                                // onClick={() => navigate("/enquiry-details/" + a._id)}
                                type="link"
                                className="table-button"
                                icon={<ArrowRightOutlined />}
                            ></Button>
                        </Tooltip> */}

                        {paidType === 'initiated' && <Tooltip title={"Process Refund/Cancellation"}>
                            <Button
                                onClick={() => setRefund(record)}
                                type="link"
                                className="table-button"
                            ><ReconciliationOutlined /></Button>
                        </Tooltip>}
                        {paidType !== 'initiated' && <Tooltip title={"Credit Note"}>
                            <a href={ROUTE_PRINT_REFUND_RECEIPT(record?._id)} target="_blank"><Button

                                type="link"
                                className="table-button"
                            ><BiReceipt /></Button></a>
                        </Tooltip>}
                    </>
                );
            },
            key: "actions",
        },
    ];
    const headerButtons = (
        <Space>
            <Button
                shape="circle"
                type="primary"
                icon={
                    <span role="img" className="anticon">
                        <BiFilterAlt />
                    </span>
                }
                onClick={() => {
                    setFilterVisible(!filterVisible);
                }}
            ></Button>
        </Space>
    );
    const onFilterApply = (values: any) => {
        // let arrFilter = [];
        // for (let [key, value] of Object.entries(values)) {
        //   if (value !== "") {
        //     arrFilter.push({ key: key, value: value });
        //   }
        // }
        // let refinedArray = arrFilter.filter((data) => {
        //   return data.value !== undefined;
        // });
        // let obj = {} as any;
        // refinedArray.forEach((data: any) => {
        //   obj[data.key] = data.value;
        // });
        // setPagination({
        //   page: 1,
        //   limit: pagination.limit,
        // });
        // dispatch(setFilters(obj)); //filters
    };
    const formProps = {
        name: "candidate-filters",
        enableEnterSubmit: true,
        formElements: [
            {
                name: "condidate_name",
                label: "",
                placeholder: "Candidate Name Search",
                type: "Input",
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
            },
        ],
        initialValues: {},
        extraButtons: [
            {
                htmlType: "submit",
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 3 },
                loading: false,
                submitButtonText: "Clear",
                type: "clearForm",
            },
            {
                htmlType: "submit",
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 3 },
                loading: false,
                submitButtonText: "Apply",
                submitButtonType: "primary",
            },
        ],
        onFinish: onFilterApply,
        onFinishFailed: null,
        onValuesChange: null,
    };
    const onChangeHandler = (_pagination: any, _filters: any, _sorter: any) => {
        if (_sorter.order === undefined) {
            setSorter({ sort_by: "", sort_type: "" });
        } else {
            let order = "";
            let columnKey = "";
            if (_sorter.order === "ascend") order = "asc";
            else order = "desc";

            if (_sorter.columnKey === "enabled") {
                columnKey = "status";
            } else if (_sorter.columnKey === "displayName") {
                columnKey = "name";
            } else {
                columnKey = _sorter.columnKey;
            }
            setSorter({ sort_by: columnKey, sort_type: order });
        }
    };
    const onChangeTab = (key: string) => {
        setPaidType(key)
    }
    return <>
        <div
            className="mx-6 mt-2 bg-primary-white-blueish border-primary-gray-02"
            style={{ borderBottom: "none!important" }}
        >
            <Row>
                <Col span={24}>
                    <ContentHeaderComponent
                        title="Manage Refunds"
                        subtitle=""
                        buttons={headerButtons}
                    />
                </Col>
            </Row>

            {filterVisible && (
                <Row className="bg-body">
                    <Col span={24} className="py-1">
                        <div className="px-3 table-filter-form">
                            <GenericFormComponent {...formProps} />
                        </div>
                    </Col>
                </Row>
            )}
            <Row>
                <Col span={24}>
                    <Tabs className="table-status-tabs mx-3" defaultActiveKey={paidType} onChange={(onChangeTab)}>
                        <Tabs.TabPane tab="Not Processed" key="initiated"></Tabs.TabPane>
                        <Tabs.TabPane tab="Processed" key="done"></Tabs.TabPane>
                    </Tabs>
                </Col>
            </Row>
        </div>
        <DataTableComponent
            loading={false}
            dataSource={refundList}
            columns={columns}
            onChange={onChangeHandler}
            title={"Refunds List"}
            defaultPage={0}
            currentPage={pagination.page}
            totalRows={total}
            onPageChange={onPageChange}
            maxWidth="1500px"
        />

        {
            refund && <Drawer width={"80%"} open={refund != null} title={"Refund/Cancellation"} onClose={() => setRefund(null)}>
                <RefundComponent accountsView={true} refundData={refund} courseDetails={refund.course_enrolment_id} setRefund={() => { setRefund(null) }} />
            </Drawer>
        }

    </>


}
export default RefundList;