const prefix = "/website";
const api_prefix = "api/website";
export const ROUTE_MANAGE_FILES = prefix + "/manage-files";
export const ROUTE_MANAGE_HOME_BANNERS = prefix + "/manage-banners";
// home slider banner (top)
// home info banners (square)

export const ROUTE_MANAGE_PRODUCTS = prefix + "/manage-products";

export const ROUTE_MANAGE_CLASS = prefix + "/manage-class";

export const ROUTE_MANAGE_COURSE_EXAMS = (app: string) =>
  prefix + "/course-exams/" + app;

export const ROUTE_MANAGE_TOPIC = (app: string) => prefix + "/topic/" + app;

export const ROUTE_MANAGE_COURSE_CATEGORY = prefix + "/manage-course-category";
export const ROUTE_MANAGE_COURSES = prefix + "/manage-courses";

export const ROUTE_MANAGE_EXAM_CATEGORY = prefix + "/manage-exam-category";
export const ROUTE_MANAGE_EXAMS = prefix + "/manage-exams";

export const ROUTE_MANAGE_QUIZ = prefix + "/manage-quiz";

// MASTERS
export const ROUTE_MANAGE_TAGS = prefix + "/manage-tags";

export const ROUTE_MANAGE_CONTENT_CATEGORY =
  prefix + "/manage-content-category";
export const ROUTE_MANAGE_CONTENT = prefix + "/manage-content";

// export const ROUTE_MANAGE_DOWNLOAD_CATEGORY =
//   prefix + "/manage-download-category";
// export const ROUTE_MANAGE_DOWNLOADS = prefix + "/manage-downloads";

export const ROUTE_MANAGE_GALLERY = prefix + "/manage-gallery";
export const ROUTE_MANAGE_TESTIMONIALS = prefix + "/manage-testimonials";

// export const ROUTE_MANAGE_CENTERS = "/user-profile";

// APIS
export const API_HOME_BANNERS = api_prefix + "/list/home-banners";
// home slider banner (top)
// home info banners (square)

export const API_PRODUCTS = api_prefix + "/products";

export const API_COURSE_CATEGORY = api_prefix + "/course-category";
export const API_COURSES = api_prefix + "/courses";

export const API_EXAM_CATEGORY = api_prefix + "/exam-category";
export const API_EXAMS = api_prefix + "/exams";
export const API_ALL_EXAM_CATEGORY =
  api_prefix + "/list/all-exams-and-categories";

// MASTERS
export const API_TAGS = api_prefix + "/tags";

export const API_CONTENT_CATEGORY = api_prefix + "/content-category";
export const API_CONTENT_ITEMS = api_prefix + "/content-items";

// export const API_DOWNLOAD_CATEGORY = prefix + "/download-category";
// export const API_DOWNLOADS = prefix + "/downloads";

export const API_GALLERY = api_prefix + "/gallery";
export const API_CREATE_QUIZ = api_prefix + "/quiz";
export const API_UPDATE_QUIZ = (id: string) => api_prefix + "/quiz/" + id;
export const API_SAVE_NEW_DESCRIPTION = api_prefix + "/new-description";
export const API_QUIZ_DETAILS_BY_ID = (id: string) =>
  api_prefix + "/quiz-details/" + id;
export const API_QUESTION_DETAILS_BY_ID = (id: string) =>
  api_prefix + "/question-details/" + id;
export const API_UPDATE_QUESTION_BY_ID = (id: string) =>
  api_prefix + "/update-question/" + id;
export const API_GET_DESCRIPTION_BY_QUIZ_ID = (id: string) =>
  api_prefix + "/description-by-quiz-id/" + id;
export const API_GET_ALL_CATEGORIES_AND_EXAMS =
  api_prefix + "/web/all-exams-and-categories";
export const API_WEBSITE_SUB_EXAM_BY_EXAM = (id: string) =>
  api_prefix + "/web/sub-exams-by-exam/" + id;
export const API_WEBSITE_SUB_EXAM_BY_EXAM_MULTI =
  api_prefix + "/web/sub-exams-by-exam-multi";

export const API_WEBSITE_GENERIC_LIST_MINIMAL = (module: string) =>
  api_prefix + "/list/" + module + "/all";
export const API_WEBSITE_GENERIC_LIST_W_PAGE = (module: string) =>
  api_prefix + "/list/" + module;
export const API_WEBSITE_GENERIC_CREATE = (module: string) =>
  api_prefix + "/create/" + module;
export const API_WEBSITE_GENERIC_UPDATE = (module: string, id: string) =>
  api_prefix + "/update/" + module + "/" + id;
export const API_WEBSITE_CONTENT_SUB_CAT_BY_CAT_ID = (id: string) =>
  api_prefix + "/list/content-sub-category-by-category-id/" + id;
export const API_DOWNLOAD_FTD_REGISTRATIONS_BY_ID = (id: string) =>
  "api/student-portal/download-ftd-registration-data/" + id;
export const API_DOWNLOAD_QUIZ_SUBMISSION_BY_ID = (id: string) =>
  "api/student-portal/download-quiz-submission-data/" + id;
