import { Button, Col, Row, Space, Tooltip } from "antd";
import { useCallback, useEffect, useState } from "react";
import { BiFilterAlt, BiReceipt } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { requestCandidateListMinimal, requestCoursePayments, setFilters } from "../../../core/store/reducers/admission.slice";
import { getBatch, getBatchType, getCenterList, getCourseListByCenter, getExams, getStreams } from "../../../core/store/reducers/masters.slice";
import ContentHeaderComponent from "../../../shared/components/content-header/content-header.component";
import DataTableComponent from "../../../shared/components/data-table/data-table.component";
import GenericFormComponent from "../../../shared/components/generic-form/generic-form.component";
import { TABLE_ROWS_COUNT } from "../../../shared/constants";
import { RootState } from "../../../shared/types/common";
import { formatDate } from "../../../shared/utils/utils";


function PaymentList() {
    const [filterVisible, setFilterVisible] = useState(false);
    const [paymentsList, setPaymentsList] = useState([] as any);
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { payments, filters, total } = useSelector((state: RootState) => state.admission);
    // Actions and selections
    const [sorter, setSorter] = useState({ sort_by: "", sort_type: "" });
    const [pagination, setPagination] = useState({ page: 1, limit: TABLE_ROWS_COUNT });

    useEffect(() => {
        loadList();
    }, [])

    const loadList = useCallback(
        (_page: any = 1) => {
            const data = {
                ...pagination,
                ...sorter,
            };
            dispatch(requestCoursePayments({ params: data, body: filters }));
        },
        [filters, dispatch, sorter, pagination]
    );
    useEffect(() => {
        loadList();
    }, [loadList]);

    useEffect(() => {
        if (payments?.length > 0) {
            setPaymentsList(
                payments.map((obj: any, index: number) => {
                    return { key: index, ...obj };
                })
            );
        }
    }, [payments]);
    const onPageChange = (pageNumber: number) => {
        setPagination({
            page: pageNumber,
            limit: pagination.limit,
        });
    };

    const columns = [
        {
            title: 'Reg. No.',
            dataIndex: ['candidate_id', 'registration_number'],
            key: 'candidate_name',
        },
        {
            title: 'Roll no',
            dataIndex: ['course_enrolment_id', 'roll_no'],
            key: 'candidate_name',
        },
        {
            title: 'Name',
            dataIndex: ['candidate_id', 'candidate_name'],
            key: 'candidate_name',
        },
        {
            title: 'Mobile',
            dataIndex: ['candidate_id', 'contact'],
            key: 'contact',
        },
        {
            title: 'Email',
            dataIndex: ['candidate_id', 'email'],
            key: 'email',
        },
        {
            title: 'Payment Date',
            render: (data: any) => {
                return formatDate(data.actual_payment_date, "DD MMM, YYYY")
            },
            key: 'actual_payment_date',
            width: '100px'
        },
        {
            title: 'Pay Type',
            render: (data: any) => {
                return data.is_book_fee ? "Books" : "Adm/Tuition"
            },
            key: 'type',
        },
        {
            title: 'Adm. Amt.',
            // dataIndex: ['instalment_id', 'admission_amount'],
            key: 'admission_amount',
            render: (data: any) => {
                let admFee = data.adm_fee;
                if (data?.adm_fee === null || data?.adm_fee === undefined) {
                    admFee = data?.instalment_id?.admission_amount;
                }
                return admFee;
            },
            width: '70px'
        },
        {
            title: 'Adm. Discount',
            dataIndex: ['instalment_id', 'admission_discount'],
            key: 'admission_discount',
            width: '100px'
        },
        {
            title: 'Tuition Amount',
            // dataIndex: ['instalment_id', 'tuition_amount'],
            key: 'tuition_amount',
            render: (data: any) => {
                let tFee = data.tuition_fee;
                if (data?.tuition_fee === null || data?.tuition_fee === undefined) {
                    tFee = data?.instalment_id?.tuition_amount;
                }
                return tFee;
            },
            width: '100px'
        },
        {
            title: 'Tuition Discount',
            dataIndex: ['instalment_id', 'tuition_discount'],
            key: 'tuition_discount',
            width: '100px'
        },
        {
            title: 'Amount',
            dataIndex: 'total_amount',
            key: 'total_amount',
            width: '100px'
        },
        {
            title: 'Invoice No.',
            dataIndex: 'invoice_number',
            key: 'invoice_number'
        },
        {
            title: 'Mode',
            dataIndex: 'payment_mode',
            key: 'payment_mode',
        },
        {
            title: 'Ref. No',
            dataIndex: 'ref_no',
            key: 'ref_no'
        },
        {
            title: 'Center',
            dataIndex: ["course_enrolment_id", 'batch_id', 'center_id', 'center_name'],
            key: 'center_name',
        },
        {
            title: 'Course',
            dataIndex: ["course_enrolment_id", 'batch_id', 'course_id', 'course_name'],
            key: 'course_name',
        },
        {
            title: 'Batch Type',
            dataIndex: ["course_enrolment_id", 'batch_id', 'batch_type_id', 'batch_type_name'],
            key: 'batch_type_name',
        },
        {
            title: 'Exam',
            dataIndex: ["course_enrolment_id", 'batch_id', 'exam_id', 'exam_name'],
            key: 'exam_name',
        },
        {
            title: 'Stream',
            dataIndex: ["course_enrolment_id", 'batch_id', 'stream_id', 'stream_name'],
            key: 'stream_name',
        },
        {
            title: 'Session',
            dataIndex: ["course_enrolment_id", 'batch_id', 'session_id', 'session'],
            key: 'session',
        },
        {
            title: 'Remarks',
            dataIndex: "remarks",
            key: 'remarks',
        },
        {
            title: 'Action',
            dataIndex: 'address',
            fixed: 'right',
            render: (a: any, record: any) => {
                return (
                    <>
                        {/* <Tooltip title={"Edit"}>
                            <Button
                                type="link"
                                className="table-button"
                                icon={<EditOutlined />}
                            // onClick={() => openAddEditPanel(a)}
                            ></Button>
                        </Tooltip>
                        <Tooltip title={"Details"}>
                            <Button
                                // onClick={() => navigate("/enquiry-details/" + a._id)}
                                type="link"
                                className="table-button"
                                icon={<ArrowRightOutlined />}
                            ></Button>
                        </Tooltip> */}
                        <Tooltip title={"Print Receipt"}>
                            <Button
                                onClick={() => {
                                    window.open("/print-receipt/" + record._id)
                                }}
                                type="link"
                                className="table-button"
                                icon={<BiReceipt />}
                            ></Button>
                        </Tooltip>
                    </>
                );
            },
            key: "actions",
        },
    ];
    const headerButtons = (
        <Space>
            <Button
                shape="circle"
                type="primary"
                icon={
                    <span role="img" className="anticon">
                        <BiFilterAlt />
                    </span>
                }
                onClick={() => {
                    setFilterVisible(!filterVisible);
                }}
            ></Button>
        </Space>
    );
    const onFilterApply = (values: any) => {
        let arrFilter = [];
        for (let [key, value] of Object.entries(values)) {
            if (value !== "") {
                arrFilter.push({ key: key, value: value });
            }
        }
        let refinedArray = arrFilter.filter((data) => {
            return data.value !== undefined;
        });
        let obj = {} as any;
        refinedArray.forEach((data: any) => {
            obj[data.key] = data.value;
        });
        setPagination({
            page: 1,
            limit: pagination.limit,
        });
        if (candidate_id && obj.candidate_name) {
            obj = { ...obj, candidate_id }
        }
        delete obj.candidate_name
        dispatch(setFilters(obj)); //filters
    };
    const [courseId, setCourseId] = useState(null)
    const [centerId, setCenterId] = useState(null)
    const [batchTypeId, setBatchTypeId] = useState(null)
    const [examId, setExamId] = useState(null)
    const { centers, courses, batchType, exams, streams, batch } = useSelector((state: RootState) => state.masters);
    useEffect(() => {
        dispatch(getCenterList())
    }, [])
    const getRelevantData = (event: any, key: any) => {
        if (event === "course") {
            setCenterId(key)
            dispatch(getCourseListByCenter({ center_id: key }))
        }
        if (event === "batchType") {
            setCourseId(key)
            dispatch(getBatchType({ center_id: centerId, course_id: key }))
        }
        if (event === "exam") {
            setBatchTypeId(key)
            dispatch(getExams({ center_id: centerId, course_id: courseId, batch_type_id: key }))
        }
        if (event === "stream") {
            setExamId(key)
            dispatch(getStreams({ center_id: centerId, course_id: courseId, batch_type_id: batchTypeId, exam_id: key }))
        }
        if (event === "batch") {
            dispatch(getBatch({ center_id: centerId, course_id: courseId, batch_type_id: batchTypeId, exam_id: examId, stream_id: key }))
        }
    }
    const [candNames, setCandNames] = useState([] as any)
    const [candidate_id, set_candidate_id] = useState(null as any)

    const formProps = {
        name: "candidate-filters",
        enableEnterSubmit: true,
        formElements: [
            {
                name: "candidate_name",
                label: "",
                placeholder: "Search Candidate Name",
                type: "AutoComplete",
                options: candNames,
                allowClear: true,
                onSearch: (e: any) => {
                    if (e?.length > 2) {
                        dispatch(requestCandidateListMinimal({ body: { candidate_name: e } })).then((val: any) => {
                            if (val?.payload?.data?.data) {
                                setCandNames(val?.payload?.data?.data?.map((name: any) => {
                                    return { key: name._id, value: name.candidate_name }
                                }))
                            }
                        })
                    }
                },
                onSelect: (e: any, option: any) => {
                    if (option?.key) {
                        set_candidate_id(option.key)
                    } else {
                        set_candidate_id(null)
                    }
                },
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
            },
            {
                name: "roll_no",
                label: "",
                placeholder: "Search Roll Number",
                type: "Input",
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
            },
            {
                name: "center_id",
                label: "",
                placeholder: "Search Center",
                type: "Select",
                options: centers?.map((val: any) => {
                    return {
                        name: val.center_name, value: val._id
                    }
                }),
                onChange: (evt: any) => getRelevantData('course', evt),
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
            },
            {
                name: "course_id",
                label: "",
                placeholder: "Search Course",
                type: "Select",
                options: courses?.map((val: any) => {
                    return {
                        name: val?.course_ref?.course_name, value: val?.course_ref?._id
                    }
                }),
                onChange: (evt: any) => getRelevantData('batchType', evt),
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
            },
            {
                name: "batch_type_id",
                label: "",
                placeholder: "Search Batch Type",
                type: "Select",
                options: batchType?.map((val: any) => {
                    return {
                        name: val?.batch_type_id?.batch_type_name, value: val?.batch_type_id?._id
                    }
                }),
                onChange: (evt: any) => getRelevantData('exam', evt),
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
            },
            {
                name: "exam_id",
                label: "",
                placeholder: "Search Exam",
                type: "Select",
                options: exams?.map((item: any) => {
                    return {
                        name: item?.exam_id?.exam_name, value: item?.exam_id?._id
                    }
                }),
                onChange: (evt: any) => getRelevantData('stream', evt),
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
            },
            {
                name: "stream_id",
                label: "",
                placeholder: "Search Stream",
                type: "Select",
                options: streams?.map((item: any) => {
                    return {
                        name: item?.stream_id?.stream_name, value: item?.stream_id?._id
                    }
                }),
                onChange: (evt: any) => getRelevantData('batch', evt),
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
            },
            {
                name: "batch_id",
                label: "",
                placeholder: "Search Batch",
                type: "Select",
                options: batch?.map((item: any) => {
                    return {
                        name: item?.batch_code_id?.batch_code, value: item?._id
                    }
                }),
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
            },
        ],
        initialValues: {},
        extraButtons: [
            {
                htmlType: "submit",
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 3 },
                loading: false,
                submitButtonText: "Clear",
                type: "clearForm",
            },
            {
                htmlType: "submit",
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 3 },
                loading: false,
                submitButtonText: "Apply",
                submitButtonType: "primary",
            },
        ],
        onFinish: onFilterApply,
        onFinishFailed: null,
        onValuesChange: null,
    };
    const onChangeHandler = (_pagination: any, _filters: any, _sorter: any) => {
        if (_sorter.order === undefined) {
            setSorter({ sort_by: "", sort_type: "" });
        } else {
            let order = "";
            let columnKey = "";
            if (_sorter.order === "ascend") order = "asc";
            else order = "desc";

            if (_sorter.columnKey === "enabled") {
                columnKey = "status";
            } else if (_sorter.columnKey === "displayName") {
                columnKey = "name";
            } else {
                columnKey = _sorter.columnKey;
            }
            setSorter({ sort_by: columnKey, sort_type: order });
        }
    };
    return <>
        <div
            className="mx-6 mt-2 bg-primary-white-blueish border-primary-gray-02"
            style={{ borderBottom: "none!important" }}
        >
            <Row>
                <Col span={24}>
                    <ContentHeaderComponent
                        title="Manage Payments"
                        subtitle=""
                        buttons={headerButtons}
                    />
                </Col>
            </Row>

            {filterVisible && (
                <Row className="bg-body">
                    <Col span={24} className="py-1">
                        <div className="px-3 table-filter-form">
                            <GenericFormComponent {...formProps} />
                        </div>
                    </Col>
                </Row>
            )}
        </div>
        <DataTableComponent
            loading={false}
            dataSource={paymentsList}
            columns={columns}
            onChange={onChangeHandler}
            title={"Payment List"}
            defaultPage={0}
            currentPage={pagination.page}
            totalRows={total}
            onPageChange={onPageChange}
            maxWidth="2600px"
        />


    </>


}
export default PaymentList;


