import {
  CloseCircleOutlined,
  EditOutlined,
  PlusOutlined
} from "@ant-design/icons";
import { Button, Col, Row, Space, Tooltip } from "antd";
import { useCallback, useEffect, useState } from "react";
import { BiFilterAlt } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Client } from "../../core/api-client";
import { clearMasters, getMasterDataList, getMasterDropdownData, updateMasterData } from "../../core/store/reducers/masters.slice";
import ContentHeaderComponent from "../../shared/components/content-header/content-header.component";
import DataTableComponent from "../../shared/components/data-table/data-table.component";
import GenericFormComponent from "../../shared/components/generic-form/generic-form.component";

import { TABLE_ROWS_COUNT } from "../../shared/constants";
import { ROUTE_DASHBOARD } from "../../shared/routes/dashboard.routes";
import { API_GET_MASTER_DROPDOWN } from "../../shared/routes/masters.routes";
import { RootState } from "../../shared/types/common";
import { showNotification } from "../../shared/utils/utils";
import CreateEditMaster from "./create-edit-master.component";
import MastersDataTableSortable from "./master-datatable-sortable.component";
import { masterModules, masterModulesKeys } from "./master.utils";
import CreateEditBatchTypeRelationship from "./relationships/create-edit-batch-type-relationship.component";
import FilterRelationship from "./relationships/filter";

const ManageMasterModulePage: React.FC = () => {
  const dispatch = useDispatch();
  const { app } = useParams();
  const navigate = useNavigate();

  if (masterModulesKeys.indexOf(app) === -1) {
    navigate(ROUTE_DASHBOARD)
  }
  const moduleData = masterModules[masterModulesKeys.indexOf(app)];



  // List states
  const { screenType } = useSelector((state: RootState) => state.settings);

  // List local state
  const [masterListData, setMasterListData] = useState([] as any);

  // // Module Data
  const [loading, setLoading] = useState(false as any);

  // Flag to open create/edit form
  const [createEditOpen, setCreateEditOpen] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(null as any);
  const [filterVisible, setFilterVisible] = useState(false);
  // Actions and selections
  const [filtersForm, setFilterForm] = useState({} as any);
  const [filters, setFilters] = useState({} as any);
  const [total, setTotal] = useState(0 as any);
  const [sorter, setSorter] = useState({ sort_by: "", sort_type: "" });
  const [pagination, setPagination] = useState({ page: 1, limit: TABLE_ROWS_COUNT });

  // Functions and Constants
  const loadList = useCallback(
    (_page: any = 1) => {
      setLoading(true)
      const data = {
        ...pagination,
        ...sorter,
      };
      app && dispatch(getMasterDataList({ path: app, params: data, body: filters })).then((val: any) => {
        if (val?.payload?.data?.success) {
          setMasterListData(val.payload.data.data.map((val: any) => {
            return { ...val, key: val._id }
          }))
          setTotal(val.payload.data.total)
        }
        setLoading(false)
      });
    },
    [filters, dispatch, sorter, pagination, app]
  );
  const createEntityListner = (key: any) => {
    setCreateEditOpen(true);
    setSelectedEntity(null);
  };
  useEffect(() => {
    if (app === "batch" || app == 'fee') {
      dispatch(getMasterDropdownData({ schemaKey: "session", labelKey: "session" })).then((val: any) => {
        if (val?.payload?.data?.success) {
          setFilters({ session_id: val?.payload?.data?.data?.[val?.payload?.data?.data?.length - 1]?._id })
        }
      })
    }
  }, [app])
  // useEffect Hooks
  useEffect(() => {
    loadList();
  }, [loadList]);

  useEffect(() => {
    return () => {
      dispatch(clearMasters())
    }
  }, []);

  const onFilterApply = (values: any) => {
    let arrFilter = [];
    for (let [key, value] of Object.entries(values)) {
      if (value !== "") {
        arrFilter.push({ key: key, value: value });
      }
    }
    let refinedArray = arrFilter.filter((data) => {
      return data.value !== undefined;
    });
    let obj = {} as any;
    refinedArray.forEach((data: any) => {
      obj[data.key] = data.value;
    });
    setPagination({
      page: 1,
      limit: pagination.limit,
    });
    setFilters(obj); //filters
  };

  const onPageChange = (pageNumber: number) => {
    setPagination({
      page: pageNumber,
      limit: pagination.limit,
    });
  };

  const openEditPanel = (entity: any) => {
    setSelectedEntity(entity);
    setCreateEditOpen(true);
  };
  const onChangeHandler = (_pagination: any, _filters: any, _sorter: any) => {
    if (_sorter.order === undefined) {
      setSorter({ sort_by: "", sort_type: "" });
    } else {
      let order = "";
      let columnKey = "";
      if (_sorter.order === "ascend") order = "asc";
      else order = "desc";

      if (_sorter.columnKey === "enabled") {
        columnKey = "status";
      } else if (_sorter.columnKey === "displayName") {
        columnKey = "name";
      } else {
        columnKey = _sorter.columnKey;
      }
      setSorter({ sort_by: columnKey, sort_type: order });
    }
  };
  useEffect(() => {
    if (moduleData.filterForm) {
      prepareFilters(moduleData.filterForm)
    }
  }, [moduleData])
  const prepareFilters = async (filtersFormData: any) => {
    for (let k = 0; k < filtersFormData.length; ++k) {
      if (filtersFormData[k].api === true) {
        console.log(filtersFormData[k])
        const ddData = await Client.get(API_GET_MASTER_DROPDOWN(filtersFormData[k].schemaKey), { params: { key: filtersFormData[k].labelKey } })
        if (ddData?.data?.success) {
          filtersFormData[k].options = ddData.data.data.map((val: any) => {
            return { value: val[filtersFormData[k].valKey], label: <span className="capitalize">{val[filtersFormData[k].labelKey]}</span> }
          })
        }
      }
    }
    setFilterForm(filtersFormData)
  }
  const formProps = {
    name: moduleData.key + "-filters",
    enableEnterSubmit: true,
    formElements: filtersForm,
    initialValues: {},
    extraButtons: [
      {
        htmlType: "submit",
        colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 3 },
        loading: false,
        submitButtonText: "Clear",
        type: "clearForm",
      },
      {
        htmlType: "submit",
        colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 3 },
        loading: false,
        submitButtonText: "Apply",
        submitButtonType: "primary",
      },
    ],
    onFinish: onFilterApply,
    onFinishFailed: null,
    onValuesChange: null,
  };

  const createEditSubmit = () => {
    setCreateEditOpen(false);
    loadList();
  };

  const headerButtons: any = (
    <Space>
      <Button
        type="primary"
        // size="large"
        icon={<PlusOutlined />}
        onClick={() => createEntityListner(app)}
      >
        <span className="font-size-14">New {!screenType?.isPortrait && (" " + moduleData.label)}</span>
      </Button>
      <Button
        shape="circle"
        type="primary"
        icon={
          <span role="img" className="anticon">
            <BiFilterAlt />
          </span>
        }
        onClick={() => {
          setFilterVisible(!filterVisible);
        }}
      ></Button>
    </Space>
  );
  const toggleStatus = (data: any) => {
    dispatch(updateMasterData({ path: app, body: { _id: data._id, status: !data.status } })).then((val: any) => {
      if (val?.payload?.data?.success) {
        showNotification("Status Updated Successfully", "success");
        loadList();
      }
    })
  }
  return (
    <>
      <div
        className="mx-6 mt-2 bg-primary-white-blueish border-primary-gray-02"
        style={{ borderBottom: "none!important" }}
      >
        <Row>
          <Col span={24}>
            <ContentHeaderComponent
              title={"Manage " + moduleData.label}
              subtitle=""
              buttons={headerButtons}
            />
          </Col>
        </Row>
        {filterVisible && (
          <Row className="bg-body">
            <Col span={24} className="py-1">
              <div className="px-3 table-filter-form">
                {
                  app === "course-relationship" ? <FilterRelationship setFilters={setFilters} setPagination={setPagination} pagination={pagination} type={["center"]} ></FilterRelationship>
                    : app == "stream-relationship" ? <FilterRelationship setFilters={setFilters} setPagination={setPagination} pagination={pagination} type={["center", "course", "batch_type", "exam"]} ></FilterRelationship>
                      : app === "exam-type-relationship" ? <FilterRelationship setFilters={setFilters} setPagination={setPagination} pagination={pagination} type={["center", "course", "batch_type"]} ></FilterRelationship>
                        : app === "venue-relationship" ? <FilterRelationship setFilters={setFilters} setPagination={setPagination} pagination={pagination} type={["center"]} ></FilterRelationship>
                          : app === "batch-type-relationship" ? <FilterRelationship setFilters={setFilters} setPagination={setPagination} pagination={pagination} type={["center", "course"]} ></FilterRelationship>
                            : app === "batch" ? <FilterRelationship setFilters={setFilters} setPagination={setPagination} pagination={pagination} type={["session", "center", "course", "batch_type", "exam", "stream", "session"]} ></FilterRelationship>
                              : app === "fee" ? <FilterRelationship setFilters={setFilters} setPagination={setPagination} pagination={pagination} type={["session", "center", "course", "batch_type", "exam", "stream", "session"]} ></FilterRelationship>
                                : <GenericFormComponent {...formProps} />
                }
              </div>
            </Col>
          </Row>
        )}
      </div>
      {moduleData?.actions?.indexOf('reorder') === -1 &&
        <DataTableComponent
          loading={loading}
          dataSource={masterListData}
          columns={[...moduleData.schema, {
            title: "Actions",
            width: "160px",
            fixed: "right",
            render: (a: any) => {
              return (
                <>
                  <Tooltip title={"Edit"}>
                    <Button
                      type="link"
                      className="table-button"
                      icon={<EditOutlined />}
                      onClick={() => openEditPanel(a)}
                    ></Button>
                  </Tooltip>
                  {moduleData?.allowStatusChange === true && <Tooltip title={"Change Status"}>
                    <Button
                      onClick={() => toggleStatus(a)}
                      type="link"
                      className="table-button"
                      icon={<CloseCircleOutlined />}
                    ></Button>
                  </Tooltip>}
                </>
              )
            }
          }]}
          onChange={onChangeHandler}
          title={moduleData.label}
          defaultPage={0}
          currentPage={pagination.page}
          totalRows={total}
          onPageChange={onPageChange}
          maxWidth={moduleData.tableWidth ? moduleData.tableWidth : "1600px"}
        />}
      {moduleData?.actions?.indexOf('reorder') !== -1 &&
        <MastersDataTableSortable
          loading={loading}
          dataSource={masterListData}
          columns={[...moduleData.schema, {
            title: "Actions",
            fixed: "right",
            render: (a: any) => {
              return (
                <>
                  <Tooltip title={"Edit"}>
                    <Button
                      type="link"
                      className="table-button"
                      icon={<EditOutlined />}
                      onClick={() => openEditPanel(a)}
                    ></Button>
                  </Tooltip>
                  {moduleData?.allowStatusChange === true && <Tooltip title={"Change Status"}>
                    <Button
                      onClick={() => toggleStatus(a)}
                      type="link"
                      className="table-button"
                      icon={<CloseCircleOutlined />}
                    ></Button>
                  </Tooltip>}
                </>
              )
            }
          }]}
          onChange={onChangeHandler}
          title={moduleData.label}
          defaultPage={0}
          currentPage={pagination.page}
          totalRows={total}
          onPageChange={onPageChange}
          maxWidth={moduleData.tableWidth ? moduleData.tableWidth : "1600px"}
        />}
      {createEditOpen &&
        <>
          {
            // app === "course-relationship" ?
            //   <Drawer
            //     open={createEditOpen}
            //     width={
            //       screenType.isPortrait
            //         ? "90%"
            //         : screenType.isLg || screenType.isXl
            //           ? 720
            //           : 500
            //     }
            //     maskClosable={false}
            //     className="form-drawer"
            //     title={<strong>{"Create New " + moduleData.label}</strong>}
            //     onClose={() => setCreateEditOpen(false)}
            //     footer={<Space><Button type="primary">Submit</Button> <Button type="default" onClick={() => setCreateEditOpen(false)}>Cancel</Button></Space>} >
            //     <CreateRelationship initialValues={selectedEntity} centers={centers} companies={companies} courses={courses} getRelevantData={(key: any, id: any) => getRelevantData(key, id)} type={["center", "course"]} app={app}></CreateRelationship>
            //   </Drawer>
            //   : 
            ["course-relationship", "batch-type-relationship", "exam-type-relationship", "stream-relationship", "venue-relationship", "batch", "fee"].indexOf(app || '') !== -1 ?
              <CreateEditBatchTypeRelationship
                moduleName={app || ''}
                onClose={() => setCreateEditOpen(false)}
                visible={createEditOpen}
                selectedModuleData={selectedEntity}
                moduleData={moduleData}
                onSubmit={createEditSubmit}
              />
              // : app === "batch" ?
              //   <Drawer
              //     open={createEditOpen}
              //     maskClosable={false}
              //     title={<strong>{"Create New " + moduleData.label}</strong>}
              //     className="form-drawer"
              //     width={
              //       screenType.isPortrait
              //         ? "90%"
              //         : screenType.isLg || screenType.isXl
              //           ? 720
              //           : 500
              //     }
              //     footer={<Space><Button type="primary">Submit</Button> <Button type="default" onClick={() => setCreateEditOpen(false)}>Cancel</Button></Space>}
              //     onClose={() => setCreateEditOpen(false)}>
              //     <CreateRelationship type={["center", "course", "batch", "exam", "stream", "venue", "session", "batch-code", "batch-time"]} app={app}></CreateRelationship>
              //   </Drawer>
              :
              (
                <CreateEditMaster
                  onClose={() => setCreateEditOpen(false)}
                  visible={createEditOpen}
                  selectedModuleData={selectedEntity}
                  moduleData={moduleData}
                  onSubmit={createEditSubmit}
                />
              )
          }
        </>
      }
    </>
  );
};
export default ManageMasterModulePage;
