import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, DatePicker, Divider, Form, Input, Radio, Row, Select, Upload, message } from "antd";

import type { RcFile } from 'antd/es/upload/interface';
import { useForm } from "antd/lib/form/Form";
import moment from "moment";
import { useEffect, useState } from "react";
import { disabledFutureDates, getBase64 } from "../../../shared/utils/utils";
const { Option } = Select

const AdmissionForm = ({ next, data, setData }: any) => {
    const [form] = useForm();
    const [photo, setPhotoUrl] = useState<string>();
    const [signature, setSignatureUrl] = useState<string>();
    const [loadingphoto, setLoadingPhoto] = useState(false);
    const [loadingsignature, setLoadingSignature] = useState(false);


    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
            setLoadingPhoto(false);
            setLoadingSignature(false);
        }
        const isLt2M = file.size / 1024 < 150;
        if (!isLt2M) {
            message.error('Image must smaller than 150 KB!');
            setLoadingPhoto(false);
            setLoadingSignature(false);
        }
        if (isJpgOrPng && isLt2M) {
            return true
        }
        return Upload.LIST_IGNORE;
    };



    const handleChangePhoto = (info: any) => {
        if (info.file.status === 'uploading') {
            getBase64(info.file.originFileObj).then((url) => {
                setLoadingPhoto(false);
                setPhotoUrl(url);
            })
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj).then((url) => {
                setLoadingPhoto(false);
                setPhotoUrl(url);
            })
            // return;
        }

    };

    const handleChangeSignature = (info: any) => {
        if (info.file.status === 'uploading') {
            getBase64(info.file.originFileObj).then((url) => {
                setLoadingSignature(false);
                setSignatureUrl(url);
            })
            return
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj).then((url) => {
                setLoadingSignature(false);
                setSignatureUrl(url);
            })
        }
    };

    useEffect(() => {
        if (data.length || Object.keys(data)?.length > 0) {
            form.setFieldsValue({
                ...data, ...(data?.dob ? { dob: moment(data.dob) } : {})
            });
            setPhotoUrl(data.photo)
            setSignatureUrl(data.signature)
        }
    }, [data])
    return (
        <>
            <Form
                form={form}
                layout="horizontal"
                initialValues={{ ...data, dob: moment(data.dob) }}
                onFinish={(values) => {
                    values.dob = moment(values.dob).format("YYYY-MM-DD")
                    values.photo = photo
                    values.signature = signature
                    setData(values)
                    next()
                }}>
                <Row className="mx-6 mt-2" gutter={50}>
                    {/* <Col span={24} className={"text-left"} style={{ marginTop: "20px" }}>
                        <h4>Registration Details</h4>
                    </Col> */}
                    <Col span={12}>
                        <Form.Item label="Registration Number" name={"registration_number"} >
                            <Input placeholder="" disabled />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Candidate Name" name={"candidate_name"} rules={[{ required: true, message: 'Please Enter  Candidate Name!' }]}>
                            <Input placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="DOB" name={"dob"} rules={[{ required: true, message: 'Please Choose  Candidate DOB!' }]}>
                            <DatePicker disabledDate={disabledFutureDates} format="MMM DD, Y" style={{ width: "100%" }}></DatePicker>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label="Gender" name={"gender"} rules={[{ required: true, message: 'Please Choose Candidate Gender!' }]}>
                            <Radio.Group>
                                <Radio value={"male"}>Male</Radio>
                                <Radio value={"female"}>Female</Radio>
                                <Radio value={"other"}>Other</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label="Category" name={"category"} rules={[{ required: true, message: 'Please Choose Candidate Category!' }]}>
                            <Select>
                                <Option value={"General"}>General</Option>
                                <Option value={"SC"}>SC</Option>
                                <Option value={"ST"}>ST</Option>
                                <Option value={"OBC"}>OBC</Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label="Candidate Mobile Number" name={"contact"} rules={[

                            {
                                required: true,
                                message: 'Please input candidate Mobile Number!',
                            },
                            {
                                min: 10,
                                message: "Length should be 10 digits",
                            },
                            {
                                max: 10,
                                message: "Length should be 10 digits",
                            },
                        ]}>
                            <Input placeholder="" />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label="Candidate EmailID" name={"email"} rules={[
                            {
                                type: 'email',
                                message: 'The input is not valid E-mail!',
                            },
                            {
                                required: true,
                                message: 'Please input your E-mail!',
                            },
                        ]}>
                            <Input placeholder="" />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label="Is Handicapped?" name={"handicapped"} valuePropName="checked">
                            <Checkbox></Checkbox>
                        </Form.Item>
                    </Col>
                    <Divider></Divider>


                    <Col span={24} className={"text-left"} style={{ marginTop: "20px" }}>
                        <p>ID Proof Details</p>
                    </Col>


                    <Col span={24}>
                        <strong>Original copy of id showed at the of admission. In the absense of candidates ID Proof, Father's Identity/Address proof can be submitted.</strong>
                        <Form.Item rules={[{ required: true, message: 'Please Choose Valid Option!' }]} name={"id_type"}>
                            <Radio.Group>
                                <Radio value={"Aadhaar Card"}>Aadhar Card</Radio>
                                <Radio value={"Votar ID"}>Voter ID</Radio>
                                <Radio value={"Pan Card"}>Pan Card</Radio>
                                <Radio value={"Driving Licence"}>Driving Licence</Radio>
                                <Radio value={"College ID"}>College ID</Radio>
                                <Radio value={"Others"}>Others</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>


                    <Col span={12}>
                        <Form.Item rules={[{ required: true, message: 'Please Enter Name!' }]} label="Name as mentioned on Card" name={"id_name"}>
                            <Input></Input>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item rules={[{ required: true, message: 'Please Enter Document Relation' }]} label="Relation" name={"id_relation"}>
                            <Input></Input>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item rules={[{ required: true, message: 'Please Enter Document Number!' }]} label="ID Proof Number" name={"id_number"}>
                            <Input></Input>
                        </Form.Item>
                    </Col>

                    <Divider></Divider>

                    <Col span={24} className={"text-left"} style={{ marginTop: "20px" }}>
                        <p>Upload Documents</p>
                    </Col>


                    <Col span={12}>
                        <Form.Item rules={[{ required: true, message: 'Please Upload Photo' }]} label="Upload Photo" name="photo">
                            <Upload beforeUpload={beforeUpload} accept={".png,.jpeg,.jpg"} maxCount={1}
                                onChange={handleChangePhoto} showUploadList={false} listType="picture-card">

                                {photo ? (
                                    <img
                                        src={photo}
                                        alt="avatar"
                                        style={{
                                            width: '100%',
                                        }}
                                    />
                                ) : (
                                    <div>
                                        {loadingphoto ? <LoadingOutlined /> : <PlusOutlined />}
                                        <div style={{ marginTop: 8 }}>Upload</div>
                                    </div>
                                )}


                            </Upload>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item rules={[{ required: true, message: 'Please Upload Signature!' }]} label="Upload Signature" name="signature">
                            <Upload beforeUpload={beforeUpload} accept={".png,.jpeg,.jpg"} maxCount={1}
                                onChange={handleChangeSignature} showUploadList={false} listType="picture-card">
                                {signature ? (
                                    <img
                                        src={signature}
                                        alt="avatar"
                                        style={{
                                            width: '100%',
                                        }}
                                    />
                                ) : (
                                    <div>
                                        {loadingsignature ? <LoadingOutlined /> : <PlusOutlined />}
                                        <div style={{ marginTop: 8 }}>Upload</div>
                                    </div>
                                )}
                            </Upload>
                        </Form.Item>
                    </Col>


                    <Col span={12} className={"text-left"} style={{ marginTop: "20px" }}>
                        <Button type="primary" htmlType="submit">
                            Save and Next
                        </Button>
                    </Col>
                </Row>
            </Form>

        </>
    );
};

export default AdmissionForm;


