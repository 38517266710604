import { Button, Col, Row, Space, Table } from "antd";
import { useCallback, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { BiFilterAlt } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { requestOnlineCourseEnrolments } from "../../../core/store/reducers/admission.slice";
import ContentHeaderComponent from "../../../shared/components/content-header/content-header.component";
import GenericFormComponent from "../../../shared/components/generic-form/generic-form.component";
import { formatDate } from "../../../shared/utils/utils";


function EducationIncomeReportTallyOnline() {
    const [filterVisible, setFilterVisible] = useState(true);
    const dispatch = useDispatch();

    const [sorter, setSorter] = useState({ sort_by: "", sort_type: "" });
    const [pagination, setPagination] = useState({ page: 1, limit: 1000 });
    const [filters, setFilters] = useState({} as any);
    const [centerList, setCenterList] = useState([] as any);
    const [sessionList, setSessionList] = useState([] as any);
    const [listData, setListData] = useState([] as any);
    useEffect(() => {
        loadList();
        //     dispatch(getMasterDropdownData({ schemaKey: "session", labelKey: "session" })).then((val: any) => {
        //         if (val?.payload?.data?.success) {
        //             setSessionList(val?.payload?.data?.data?.map((val: any) => {
        //                 return { label: val.session, value: val._id }
        //             }))
        //         }
        //     })
        //     dispatch(getMasterDropdownData({ schemaKey: "center", labelKey: "center_name" })).then((val: any) => {
        //         if (val?.payload?.data?.success) {
        //             setCenterList(val?.payload?.data?.data?.map((val: any) => {
        //                 return { label: val.center_name, value: val._id }
        //             }))
        //         }
        //     })
    }, [])

    const loadList = useCallback(
        async (_page: any = 1) => {
            if (Object.keys(filters)?.length == 0) {
                return
            }
            setListData([])
            const data = {
                ...pagination,
                ...sorter,
            };
            // const filters1 = {...filters, }
            let filters1 = { ...filters, old_purchase: { $ne: true }, fee_closing_approval: true, fee_cleared: true }
            if (filters1.created_on?.length == 2) {
                filters1 = { ...filters1, created_on: [formatDate(filters1.created_on[0], 'YYYY-MM-DD 00:00'), formatDate(filters1.created_on[1], 'YYYY-MM-DD 23:59')] }
            }
            const retData = await dispatch(requestOnlineCourseEnrolments({ params: data, body: filters1 }))


            // let filters1 = { ...filters };
            // if (filters1.created_on?.length == 2) {
            //     filters1 = { ...filters1, created_on: [formatDate(filters1.created_on[0], 'YYYY-MM-DD 00:00'), formatDate(filters1.created_on[1], 'YYYY-MM-DD 23:59')], fee_closing_approval: true }
            // }
            // const retData = await dispatch(requestCoursePayments({ params: data, body: { ...filters1 } }));
            if (retData?.payload?.data?.data?.length > 0) {
                console.log(retData?.payload?.data?.data)
                setListData(retData?.payload?.data?.data)
            }
        },
        [filters, dispatch, sorter, pagination]
    );
    useEffect(() => {
        loadList();
    }, [loadList]);

    const columns = [
        {
            title: 'Sr No. 1',
            render: (_: any, __: any, index: number) => index + 1,
            key: 'sno1'
        },
        {
            title: 'DATE 2',
            render: (data: any) => {
                return formatDate(data.actual_payment_date, "DD-MM-YYYY")
            },
            key: 'actual_payment_date2',
        },
        {
            title: 'Student Name 3',
            key: 'candidate_name3',
            render: (data: any) => {
                return data?.candidate_id?.candidate_name
            },
        },
        {
            title: 'Roll No 4',
            key: 'roll_no4',
            render: (data: any) => {
                return data?.roll_no
            },
        },
        {
            title: '(A) Admission Fee 5',
            key: 'admFee5',
            render: (data: any) => {

                return 0;
            },
        },
        {
            title: '(B) Tuition Fee Ledger 6',
            key: 'tfee6',
            render: (data: any) => "Income (Fees)"
        },
        {
            title: '(B) Tuition Fee 7',
            key: 'tFee7',
            render: (data: any) => {
                let tFee = data.tuition_fee;
                if (data?.tuition_fee === null || data?.tuition_fee === undefined) {
                    tFee = data?.instalment_id?.tuition_amount;
                }
                return 0;
            },
        },
        {
            title: 'Tution Fee with Extra Amount 8',
            key: 'tFee8',
            render: (data: any) => {
                let tFee = data.tuition_fee;
                if (data?.tuition_fee === null || data?.tuition_fee === undefined) {
                    tFee = data?.instalment_id?.tuition_amount;
                }
                return 0;
            },
        },
        {
            title: 'Actual Fee Total 9',
            key: 'total_amount9',
            render: (data: any) => {
                return data?.total_amount;
            },
        },
        {
            title: 'Total Fee with EDC Charges (A+B) 10',
            key: 'total_amount10',
            render: (data: any) => {
                return data?.total_amount;
            },
        },
        {
            title: 'Receipt No. 11',
            key: 'invoice_number11',
            render: (data: any) => data?.invoice_number,
        },
        {
            title: 'Mode of Payment 12',
            key: 'payment_mode12',
            render: (data: any) => data?.payment_mode,
        },
        {
            title: 'SGST Tax Ledger 13',
            key: 'ledger13',
            render: (data: any) => "SGST Payable",
        },
        {
            title: 'Bank Name 14',
            key: 'ledger14',
            render: (data: any) => "ICICI bank collection A/C No. 7002",
        },
        {
            title: 'Transaction Type 15',
            key: 'payment_mode15',
            render: (data: any) => data?.payment_mode,
        },
        {
            title: 'Cheque No. 16',
            key: 'ref_no16',
            render: (data: any) => data?.ref_no,
        },
        {
            title: 'Cheque Date 17',
            render: (data: any) => {
                return formatDate(data.actual_payment_date, "DD-MM-YYYY")
            },
            key: 'actual_payment_date17',
        },
        {
            title: 'Cash Ledger 18',
            render: (data: any) => {
                return data?.payment_mode?.toLowerCase() == 'cash' ? 'Cash' : '';
            },
            key: 'payment_mode18',
        },
        {
            title: 'CGST Tax Ledger 19',
            render: (data: any) => "CGST Payable",
            key: 'tax_led19',
        },
        {
            title: 'Blank 20',
            render: (data: any) => "",
            key: 'b120',
        },
        {
            title: 'Blank 21',
            render: (data: any) => "",
            key: 'b221',
        },
        {
            title: 'Remarks 22',
            render: (data: any) => data?.remarks,
            key: 'remarks22',
        },
        {
            title: 'Extra Amount 23',
            render: (data: any) => "0",
            key: 'extraamt23',
        },
        {
            title: 'Paid Against Roll No 24',
            key: 'roll_no124',
            render: (data: any) => {
                return data?.roll_no
            },
        }
    ];
    const headerButtons = (
        <Space>
            <Button
                shape="circle"
                type="primary"
                icon={
                    <span role="img" className="anticon">
                        <BiFilterAlt />
                    </span>
                }
                onClick={() => {
                    setFilterVisible(!filterVisible);
                }}
            ></Button>
            <CSVLink filename="education_income_report_for_tally_online.csv"
                data={listData?.map((row: any) => {
                    let retData = {} as any
                    columns.map((val: any, index: number) => {
                        retData[val.key] = val.render(row, row, index)
                    })
                    return retData
                })} headers={columns.map((val: any) => {
                    return { label: val.title, key: val.key }
                })}>
                <Button type="primary" disabled={!listData || listData?.length == 0}>
                    Download Data
                </Button>
            </CSVLink>;
        </Space>
    );
    const onFilterApply = (values: any) => {
        let arrFilter = [];
        for (let [key, value] of Object.entries(values)) {
            if (value !== "") {
                arrFilter.push({ key: key, value: value });
            }
        }
        let refinedArray = arrFilter.filter((data) => {
            return data.value !== undefined;
        });
        let obj = {} as any;
        refinedArray.forEach((data: any) => {
            obj[data.key] = data.value;
        });
        setPagination({
            page: 1,
            limit: pagination.limit,
        });
        setFilters(obj);
    };
    const formProps = {
        name: "fee-filters",
        enableEnterSubmit: true,
        formElements: [
            {
                name: "course_type",
                label: "Course Type",
                placeholder: "Course Type",
                type: "Select",
                options: [
                    { label: 'Live', value: 'live' },
                    { label: 'Recorded', value: 'recorded' },
                    { label: 'Test Series', value: 'test-series' },
                ],
                allowClear: true,
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 6 },
                rules: [{ required: true }]
            },
            {
                name: "created_on",
                label: "Date Range",
                type: "RangePicker",
                allowClear: true,
                colSpanResponsive: { xs: 12, sm: 8, md: 8, lg: 8 },
            },
        ],
        initialValues: {},
        extraButtons: [
            {
                htmlType: "submit",
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 3 },
                loading: false,
                submitButtonText: "Clear",
                type: "clearForm",
            },
            {
                htmlType: "submit",
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 3 },
                loading: false,
                submitButtonText: "Apply",
                submitButtonType: "primary",
            },
        ],
        onFinish: onFilterApply,
        onFinishFailed: null,
        onValuesChange: null,
    };
    const onChangeHandler = (_pagination: any, _filters: any, _sorter: any) => {
        if (_sorter.order === undefined) {
            setSorter({ sort_by: "", sort_type: "" });
        } else {
            let order = "";
            let columnKey = "";
            if (_sorter.order === "ascend") order = "asc";
            else order = "desc";

            if (_sorter.columnKey === "enabled") {
                columnKey = "status";
            } else if (_sorter.columnKey === "displayName") {
                columnKey = "name";
            } else {
                columnKey = _sorter.columnKey;
            }
            setSorter({ sort_by: columnKey, sort_type: order });
        }
    };

    return <>
        <div
            className="mx-6 mt-2 bg-primary-white-blueish border-primary-gray-02"
            style={{ borderBottom: "none!important" }}
        >
            <Row>
                <Col span={24}>
                    <ContentHeaderComponent
                        title="Education Income Report (Tally)"
                        subtitle=""
                        buttons={headerButtons}
                    />
                </Col>
            </Row>

            {filterVisible && (
                <Row className="bg-body">
                    <Col span={24} className="py-1">
                        <div className="px-3 table-filter-form">
                            <GenericFormComponent {...formProps} />
                        </div>
                    </Col>
                </Row>
            )}
        </div>
        <div className="mx-6 bg-primary-white-blueish border-primary-gray-02">
            <Table
                className="app-table"
                tableLayout="auto"
                pagination={false}
                loading={false}
                scroll={{ x: '5000px' }}
                dataSource={listData}
                columns={columns}
                onChange={onChangeHandler}
            />
        </div>
    </>


}
export default EducationIncomeReportTallyOnline;


