import { CloseCircleOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Row, Space, Tooltip } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getGenericList } from "../../../core/store/reducers/website.slice";
import ContentHeaderComponent from "../../../shared/components/content-header/content-header.component";
import DataTableComponent from "../../../shared/components/data-table/data-table.component";
import { TABLE_ROWS_COUNT } from "../../../shared/constants";
import { RootState } from "../../../shared/types/common";
import CreateEditTopics from "./create-topic";



const Topics = () => {
    const dispatch = useDispatch();
    const { app } = useParams();

    const [createEditOpen, setCreateEditOpen] = useState(false);
    const { loading, genericList, filters, total } = useSelector(
        (state: RootState) => state.website
    );
    const [sorter, setSorter] = useState({ sort_by: "", sort_type: "" });

    const [selectedEntity, setSelectedEntity] = useState(null as any);

    const [pagination, setPagination] = useState({ page: 1, limit: TABLE_ROWS_COUNT });

    const loadList = useCallback(
        (_page: any = 1) => {
            const data = {
                ...pagination,
                ...sorter,
            };
            dispatch(getGenericList({ params: data, body: { ...filters, module_type: app }, module: 'module-data' }));
        },
        [filters, dispatch, sorter, pagination, app]
    );

    useEffect(() => {
        return () => {
            setSelectedEntity(null)
        }
    })


    // useEffect Hooks
    useEffect(() => {
        loadList();
    }, [loadList, app]);

    //filters, loadList

    const createEditSubmit = () => {
        setCreateEditOpen(false);
        loadList();
    };

    const onPageChange = (pageNumber: number) => {
        setPagination({
            page: pageNumber,
            limit: pagination.limit,
        });
    };

    const titles = () => {
        if (app === "concept-videos") {
            return "Concept Videos"
        }

        if (app === "assignment_videos") {
            return "Assignment Videos"
        }
        if (app === "class_annotation_pdf") {
            return "Class Annotation Pdf"
        }
        if (app === "class_test") {
            return "Class Test"
        }
        if (app === "practice_test") {
            return "Practice Test"
        }

    }



    const headerButtons = (
        <Space>
            <Button
                type="primary"
                // size="large"
                icon={<PlusOutlined />}
                onClick={() => setCreateEditOpen(true)}
            >
                <span className="font-size-14">New {titles()}</span>
            </Button>

        </Space>
    );

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (a: any) => {
                return a ? "Active" : "Disabled";
            },
        },
        {
            title: 'Exam Name',
            dataIndex: ["exam_id", "exam_name"],


        },
        {
            title: 'Subject Name',
            dataIndex: ["subject_id", "name"],


        },
        {
            title: 'Topic Name',
            dataIndex: ["topic_id", "name"],

        },
        {
            title: "Actions",
            width: "160px",
            fixed: "right",
            render: (a: any) => {
                return (
                    <>
                        <Tooltip title={"Edit"}>
                            <Button
                                type="link"
                                onClick={() => editEntityListner(a)}
                                className="table-button"
                                icon={<EditOutlined />}
                            ></Button>
                        </Tooltip>
                        <Tooltip title={"Mark Inactive"}>
                            <Button
                                // onClick={() => toggleStatus(a)}
                                type="link"
                                className="table-button"
                                icon={<CloseCircleOutlined />}
                            ></Button>
                        </Tooltip>
                    </>
                );
            },
            key: "actions",
        },
    ];


    const onChangeHandler = (_pagination: any, _filters: any, _sorter: any) => {
        if (_sorter.order === undefined) {
            setSorter({ sort_by: "", sort_type: "" });
        } else {
            let order = "";
            let columnKey = "";
            if (_sorter.order === "ascend") order = "asc";
            else order = "desc";

            if (_sorter.columnKey === "enabled") {
                columnKey = "status";
            } else if (_sorter.columnKey === "displayName") {
                columnKey = "name";
            } else {
                columnKey = _sorter.columnKey;
            }
            setSorter({ sort_by: columnKey, sort_type: order });
        }
    };




    const editEntityListner = (entity: any) => {
        setSelectedEntity(entity);
        setCreateEditOpen(true);
    };


    return <>
        <div
            className="mx-6 mt-2 bg-primary-white-blueish border-primary-gray-02"
            style={{ borderBottom: "none!important" }}
        >
            <Row>
                <Col span={24}>
                    <ContentHeaderComponent
                        title={"Manage " + titles()}
                        subtitle=""
                        buttons={headerButtons}
                    />
                </Col>
            </Row>

        </div>

        <DataTableComponent
            loading={loading}
            dataSource={genericList}
            columns={columns}
            onChange={onChangeHandler}
            title={titles() + " List"}
            defaultPage={0}
            currentPage={pagination.page}
            totalRows={total}
            onPageChange={onPageChange}

        />
        {createEditOpen && (
            <CreateEditTopics selectedEntity={selectedEntity} app={app} title={titles()} visible={createEditOpen} onClose={() => setCreateEditOpen(false)} onSubmit={createEditSubmit}></CreateEditTopics>
        )}

    </>
}

export default Topics