// App
export const ROUTE_CANDIDATES_LIST = `/candidates/list`;
export const ROUTE_CANDIDATES_IMPORT = `/candidates/import`;
export const ROUTE_BULK_ALLOT_PACKAGE = `/online-enrolments/import`;
export const ROUTE_ENROLMENTS_LIST = `/enrolments/list`;
export const ROUTE_ENROLMENTS_ALL_DETAILS = `/enrolments/all-details`;
export const ROUTE_ONLINE_ENROLMENTS_LIST = `/online-enrolments/list`;
export const ROUTE_INSTALMENTS_LIST = `/instalments/list`;
export const ROUTE_PAYMENTS_LIST = `/payments/list`;
export const ROUTE_REFUNDS_LIST = `/refunds/list`;
export const ROUTE_FEE_CLOSING_APPROVAL = `/fee-closing-approval/list`;
export const ROUTE_FEE_CLOSING_APPROVAL_ONLINE = `/fee-closing-approval-online/list`;
export const ROUTE_ADMISSION_STATUS_REPORT = `/admission-status-report/list`;
export const ROUTE_PRINT_RECEIPT = (id: any) => `/print-receipt/${id}`;
export const ROUTE_PRINT_TRANSFER_RECEIPT = (id: any) =>
  `/print-transfer-receipt/${id}`;
export const ROUTE_PRINT_REFUND_RECEIPT = (id: any) =>
  `/print-refund-receipt/${id}`;
export const ROUTE_PRINT_ONLINE_RECEIPT = (id: any) =>
  `/print-online-receipt/${id}`;
export const ROUTE_NEW_ADMISSION = "/online-registration";

// API
export const API_CANDIDATE_REGISTRATION =
  "api/admission/candidate-registration";
export const API_CANDIDATE_REGISTRATION_DETAILS = (query: any) =>
  `api/admission/candidate-registration/${query}`;
export const API_CANDIDATE_LIST = "api/admission/candidate-registration/all";
export const API_CANDIDATE_LIST_MINIMAL =
  "api/admission/candidate-registration/all/minimal";

export const API_COURSE_ENROLMENT = "api/admission/course-enrolment";
export const API_MODIFY_INSTALMENT_STRUCTURE = (id: string) =>
  "api/admission/modify-instalment-structure/" + id;
export const API_COURSE_ENROLMENT_PUBLIC =
  "api/admission/course-enrolment-public";
export const API_COURSE_ENROLMENT_LIST = "api/admission/course-enrolment/all";
export const API_COURSE_ENROLMENT_ALL_DETAILS =
  "api/admission/course-enrolment/all-details";
export const API_ONLINE_COURSE_ENROLMENT_LIST =
  "api/admission/online-course-enrolment/all";

export const API_COURSE_INSTALMENT_PAYMENT = "api/admission/course-payment";
export const API_COURSE_PAYMENT_LIST = "api/admission/course-payment/all";
export const API_COURSE_PAYMENT_DETAIL_ID = (id: any) =>
  `api/admission/course-payment/byid/${id}`;

export const API_COURSE_INSTALMENTS_LIST =
  "api/admission/course-instalments/all";
export const API_COURSE_INSTALMENTS_BY_EN_ID = (id: string) =>
  "api/admission/course-instalments-by-enrolment-id/" + id;
export const API_COURSE_PAYMENTS_BY_EN_ID = (id: string) =>
  "api/admission/course-payments-by-enrolment-id/" + id;
export const API_REALIZE_INSTALMENT = (id: string) =>
  "api/admission/realize-instalment/" + id;
export const API_COMPLETE_REFUND = (id: string) =>
  "api/admission/complete-refund/" + id;
export const API_REFUND_DETAILS_BY_ID = (id: string) =>
  "api/admission/get-refund-details-by-id/" + id;
export const API_INITIATE_REFUND = "api/admission/initiate-refund";
export const API_REFUND_LIST = "api/admission/refunds";
export const API_FEE_CLOSING_APPROVAL = "api/admission/fee-closing-approval";
export const API_FEE_CLOSING_APPROVAL_ONLINE =
  "api/admission/fee-closing-approval-online";
export const API_ADMISSION_STATUS_REPORT =
  "api/admission/admission-status-report";
export const API_CREATE_PAYMENT_ORDER = "api/admission/create-payment-order";
export const API_CHECK_PAYMENT_STATUS = "api/admission/check-payment-status";

export const API_PAYMENT_SUBMIT = "api/admission/payment-submission";
export const API_GET_PURCHASED_PACKAGES_BY_CANDIDATE_ID = (
  candidate_id: string
) => "api/admission/get-purchashed-packages-by-candidate-id/" + candidate_id;
export const API_ONLINE_PAYMENT_FOR_INVOICE = (id: string) =>
  "api/admission/get-online-payment-for-invoice/" + id;
