import { InboxOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Drawer, Form, Input, Row, Select, Upload, message } from "antd";
import { UploadProps } from "antd/es/upload";
import moment from "moment";
import Papa from "papaparse";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCenterList, getMasterDropdownData, getTopicBySubject } from "../../../core/store/reducers/masters.slice";
import { createQuiz, getAllExamsAndCategories, getAllSubExamsByExam, updateQuiz } from "../../../core/store/reducers/website.slice";
import { RootState } from "../../../shared/types/common";
import { showNotification } from "../../../shared/utils/utils";

const Options = Select;
const { Dragger } = Upload;
const CreateEditQuizComponent = ({ onClose, visible, onSubmit, selectedQuiz }: any) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { centers } = useSelector((state: RootState) => state.masters);
    const [formValues, setFormValues] = useState({} as any)
    const [csvFile, setCsvFile] = useState(null as any);
    const [examCategories, setExamCategories] = useState([] as any);
    const [exams, setExams] = useState([] as any);
    const [subExams, setSubExams] = useState([] as any);
    const [subjects, setSubjects] = useState([] as any);
    const [topics, setTopics] = useState([] as any);
    const [csvFileData, setCsvFileData] = useState(null as any);
    const { screenType } = useSelector((state: RootState) => state.settings);
    const { user } = useSelector((state: RootState) => state.auth);
    const onFinish = (values: any) => {
        if (!selectedQuiz) {
            let processedData = processRecords(csvFileData.data, csvFileData.columns);
            const payload = {
                ...values,
                created_by: user._id,
                updated_by: user._id,
                question_pool_data: processedData?.question_pool_data,
                questions: processedData?.questions,
            }
            dispatch(createQuiz(payload)).then((val: any) => {
                if (val.payload?.data?.success) {
                    showNotification("Quiz Saved Successfully", "success");
                    onSubmit();
                }
            })
        } else {
            const payload = {
                ...values,
                updated_by: user._id,
            }
            dispatch(updateQuiz({ payload, id: selectedQuiz?._id })).then((val: any) => {
                if (val.payload?.data?.success) {
                    showNotification("Quiz Updated Successfully", "success");
                    onSubmit();
                }
            })
        }

    }
    useEffect(() => {
        dispatch(getCenterList())
        if (selectedQuiz) {
            setFormValues({ quiz_type: selectedQuiz?.quiz_type })
            // getData({ quiz_type: selectedQuiz?.quiz_type })
        }
    }, [])
    const extraButtons = [
        {
            htmlType: "button",
            colSpan: 5,
            loading: false,
            submitButtonText: "Cancel",
            onClick: onClose,
        },
        {
            htmlType: "submit",
            colSpan: 5,
            loading: false,
            submitButtonText: "Clear",
            type: "clearForm",
        },
        {
            htmlType: "submit",
            colSpan: 5,
            loading: false,
            submitButtonText: "Save",
            submitButtonType: "primary",
        }
    ]
    const props: UploadProps = {
        name: "file",
        beforeUpload: (file) => {
            setCsvFile(file);
            return false;
        },
        onRemove: (file) => {
            setCsvFile(null as any);
        },
    };
    useEffect(() => {
        if (csvFile) {
            Papa.parse(csvFile, {
                header: true,
                skipEmptyLines: true,
                complete: (results: any) => {
                    // [
                    //     s_no,
                    //     section_no,
                    //     correct_option,
                    //     plus_mark,
                    //     minus_mark
                    // ]

                    if (results?.data?.length > 0 && results?.meta?.fields?.length > 0) {
                        if (results.data?.length > 500) {
                            message.error("Number of records should not exceed 500");
                        } else {
                            // processRecords(results.data, results.meta.fields);
                            setCsvFileData({ data: results.data, columns: results.meta.fields })
                            console.log({ data: results.data, headers: results.meta.fields })
                        }
                    } else {
                        message.error("Invalid CSV file");
                    }
                },
            });
        }
    }, [csvFile]);
    function processRecords(data: any, columns: any) {
        let questions: any = [], question_pool_data: any = [];
        data.map((_: any, idx: number) => {
            question_pool_data.push({
                qu: Array(formValues.languages?.length).fill(''),
                options: Array(5).fill(Array(formValues.languages?.length).fill('')),
                solution: Array(formValues.languages?.length).fill(''),
                correct_option: parseFloat(data[idx]['correct_option']),
                section: parseFloat(data[idx]['section']),
                minus_mark: parseFloat(data[idx]['minus_mark']),
                plus_mark: parseFloat(data[idx]['plus_mark']),
                created_by: user._id,
                modified_by: user._id
            })
            questions[idx] = { sno: idx + 1 };
        })

        return { questions, question_pool_data }
    }
    function getData(currentValue: any) {
        if (currentValue?.quiz_type == 'mock-flt' || currentValue?.quiz_type == 'ftd' || currentValue?.quiz_type == 'scholarship') {
            // get exam categories
            examCategories?.length == 0 && dispatch(getAllExamsAndCategories()).then((val: any) => {
                if (val?.payload?.data?.success) {
                    setExamCategories(val?.payload?.data?.data)
                }
            })
        }
        if (currentValue?.quiz_type == 'subject' || currentValue?.quiz_type == 'topic' || currentValue?.quiz_type == 'class') {
            // get subjects
            dispatch(getMasterDropdownData({ schemaKey: "exam-subject", labelKey: "name" })).then((val: any) => {
                if (val?.payload?.data?.success) {
                    setSubjects(val?.payload?.data?.data)
                }
            })
        }
    }
    function onValuesChange(currentValue: any, allValues: any) {
        setFormValues(allValues)
        if (currentValue?.quiz_type) {
            getData(currentValue)
        }
        if (currentValue?.website_exam_category_id) {
            const exams = examCategories.filter((val: any) => val._id == currentValue?.website_exam_category_id)[0]?.items;
            setExams(exams)
        }
        if (currentValue?.website_exam_id) {
            dispatch(getAllSubExamsByExam(currentValue?.website_exam_id)).then((val: any) => {
                if (val?.payload?.data?.success) {
                    setSubExams(val?.payload?.data?.data)
                }
            })
        }
        if (currentValue?.subject_id && (allValues?.quiz_type == 'topic' || allValues?.quiz_type == 'class')) {
            // get topics by subject
            dispatch(getTopicBySubject(currentValue?.subject_id)).then((val: any) => {
                if (val?.payload?.data?.success) {
                    setTopics(val?.payload?.data?.data)
                }
            })
        }

    }
    return <>
        <Drawer
            maskClosable={false}
            title="Manage Quiz"
            className="form-drawer"
            width={screenType.isPortrait ? '90%' : (screenType.isLg || screenType.isXl ? '80%' : '90%')}
            onClose={onClose}
            open={visible}
        >
            <Form
                form={form}
                initialValues={selectedQuiz ? {
                    ...selectedQuiz,
                    allowed_centers: selectedQuiz?.allowed_centers ? selectedQuiz?.allowed_centers?.map((val: any) => val?._id) : null,
                    publish_date: selectedQuiz?.publish_date ? moment(selectedQuiz?.publish_date) : null,
                    section_timer: selectedQuiz?.section_timer ? 'true' : 'false',
                    ...(selectedQuiz?.discussion_url?.length > 0 ? {
                        discussion_url: selectedQuiz?.discussion_url?.map((val: any) => {
                            return { ...val, time: val.time ? moment(val.time) : null }
                        })
                    } : {})
                } : {}}
                className="paddingx20 app-form full-width"
                onFinish={onFinish}
                onValuesChange={onValuesChange}
            >
                <Row gutter={16}>
                    {!selectedQuiz && <Col span={8} className="gutter-row">
                        <Form.Item name="quiz_type" label="Quiz Type">
                            <Select>
                                <Options value="mock-flt">Mock FLT</Options>
                                <Options value="subject">Subject</Options>
                                <Options value="topic">Topic/Practice Test</Options>
                                <Options value="class">Class Test</Options>
                                <Options value="ftd">FTD Test</Options>
                                <Options value="scholarship">Scholarship Test</Options>
                            </Select>
                        </Form.Item>
                    </Col>}
                    {selectedQuiz && <Col span={8} className="gutter-row">
                        <Form.Item name="quiz_type" label="Quiz Type" style={{ display: 'none' }}>
                            <Input />
                        </Form.Item>
                    </Col>}
                    {!selectedQuiz && (formValues?.quiz_type == 'mock-flt' || formValues?.quiz_type == 'ftd' || formValues?.quiz_type == 'scholarship') && <Col span={6} className="gutter-row">
                        <Form.Item name="website_exam_category_id" label="Exam Category">
                            <Select>
                                {examCategories?.length > 0 && examCategories?.map((val: any) => <Options value={val?._id}>{val?.exam_category_name}</Options>)}
                            </Select>
                        </Form.Item>
                    </Col>}


                    {!selectedQuiz && (formValues?.quiz_type == 'mock-flt' || formValues?.quiz_type == 'ftd' || formValues?.quiz_type == 'scholarship') && <Col span={5} className="gutter-row">
                        <Form.Item name="website_exam_id" label="Exam">
                            <Select>
                                {exams?.length > 0 && exams?.map((val: any) => <Options value={val?._id}>{val?.exam_name}</Options>)}
                            </Select>
                        </Form.Item>
                    </Col>}
                    {!selectedQuiz && (formValues?.quiz_type == 'mock-flt' || formValues?.quiz_type == 'ftd' || formValues?.quiz_type == 'scholarship') && <Col span={5} className="gutter-row">
                        <Form.Item name="website_sub_exam_id" label="Sub Exam">
                            <Select>
                                {subExams?.length > 0 && subExams?.map((val: any) => <Options value={val?._id}>{val?.sub_exam_name}</Options>)}
                            </Select>
                        </Form.Item>
                    </Col>}

                    {!selectedQuiz && (formValues?.quiz_type == 'subject' || formValues?.quiz_type == 'topic' || formValues?.quiz_type == 'class') && <Col span={8} className="gutter-row">
                        <Form.Item name="subject_id" label="Subject">
                            <Select>
                                {subjects?.length > 0 && subjects?.map((val: any) => <Options value={val?._id}>{val?.name}</Options>)}
                            </Select>
                        </Form.Item>
                    </Col>}
                    {!selectedQuiz && (formValues?.quiz_type == 'topic' || formValues?.quiz_type == 'class') && <Col span={8} className="gutter-row">
                        <Form.Item name="topic_id" label="Topic">
                            <Select>
                                {topics?.length > 0 && topics?.map((val: any) => <Options value={val?._id}>{val?.name}</Options>)}
                            </Select>
                        </Form.Item>
                    </Col>}
                    {(formValues?.quiz_type == 'ftd' || formValues?.quiz_type == 'scholarship') && <Col span={6} className="gutter-row">
                        <Form.Item name="modes_allowed" label="Mode Of Exam">
                            <Select mode="multiple">
                                {['Online', 'Offline']?.map((val: any) => <Options value={val}>{val}</Options>)}
                            </Select>
                        </Form.Item>
                    </Col>}
                    {(formValues?.quiz_type == 'ftd' || formValues?.quiz_type == 'scholarship') && <Col span={6} className="gutter-row">
                        <Form.Item name="allowed_centers" label="Choose Centers">
                            <Select mode="multiple">
                                {
                                    centers.map((item: any) => {
                                        return <Options value={item?._id}>{item?.center_name}</Options>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>}
                </Row>
                <Row gutter={16}>
                    <Col span={8} className="gutter-row">
                        <Form.Item name="quiz_name" label="Quiz Name">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8} className="gutter-row">
                        <Form.Item name="section_timer" label="Section Timer">
                            <Select>
                                <Options value="false">Disabled</Options>
                                <Options value="true">Enabled</Options>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8} className="gutter-row">
                        <Form.Item name="duration" label="Duration">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8} className="gutter-row">
                        <Form.Item name="questions_count" label="Questions Count">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8} className="gutter-row">
                        <Form.Item name="sections" label="Sections">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8} className="gutter-row">
                        <Form.Item name="mm" label="Max Marks">
                            <Input />
                        </Form.Item>
                    </Col>
                    {!selectedQuiz && <Col span={8} className="gutter-row">
                        <Form.Item name="languages" label="Languages">
                            <Select mode="multiple">
                                <Options value="english">English</Options>
                                <Options value="oriya">Oriya</Options>
                                <Options value="hindi">Hindi</Options>
                            </Select>
                        </Form.Item>
                    </Col>}
                    <Col span={8} className="gutter-row">
                        <Form.Item name="diff_level" label="Difficulty Level">
                            <Select>
                                <Options value="high">High</Options>
                                <Options value="medium">Medium</Options>
                                <Options value="low">Low</Options>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8} className="gutter-row">
                        <Form.Item name="is_free" label="Is Free?">
                            <Select>
                                <Options value={false}>No</Options>
                                <Options value={true}>Yes</Options>
                            </Select>
                        </Form.Item>
                    </Col>


                    <Col span={24} className="gutter-row p-3 mb-3" style={{ border: '1px solid #aaa', borderRadius: '5px' }}>
                        <div style={{ marginBottom: 16 }} className="ant-form-item-label">
                            <label className="w500">DISCUSSION URLs</label><br />
                        </div>
                        <Form.List name="discussion_url">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        // <Space key={key} style={{ display: 'flex', marginBottom: 8, width: '100%' }} align="baseline">
                                        <Row gutter={16}>
                                            <Col span={5}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'section_name']}
                                                    rules={[{ required: true, message: 'Section Name is required' }]}
                                                >
                                                    <Input placeholder="Section Name" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={10}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'url']}
                                                >
                                                    <Input placeholder="Link" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={4}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'time']}
                                                >
                                                    <DatePicker showTime={true} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={3}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'max_views']}
                                                >
                                                    <Input placeholder="Max. Views Allowed" />
                                                </Form.Item>
                                            </Col>
                                            <Col>
                                                <Button type="link" icon={<MinusCircleOutlined />} onClick={() => remove(name)} />
                                            </Col>
                                        </Row>
                                        // </Space>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            Add field
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                        {/* <Form.Item name="discussion_url" label="Discussion Link">
                            <Input />
                        </Form.Item> */}
                    </Col>
                    <Col span={8} className="gutter-row">
                        <Form.Item name="publish_date" label="Publish Date">
                            <DatePicker showTime={true} format={"MMM DD, YYYY hh:mm A"} />
                        </Form.Item>
                    </Col>

                    {!selectedQuiz && <Col span={24} className="gutter-row">
                        <Row>
                            <Col span={8}>
                                <Form.Item>
                                    <Dragger {...props}>
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">
                                            Click or drag csv file this area to upload
                                        </p>
                                    </Dragger>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>}



                </Row>
                <Row className="button-row" justify="end" gutter={16}>

                    {extraButtons.map((btn: any, index: number) => {
                        return (
                            <Col
                                {...btn?.colSpanResponsive}
                                span={btn.colSpan}
                                key={index}
                                className="gutter-row"
                            >
                                <Form.Item>
                                    <Button
                                        loading={btn.loading}
                                        // size="large"
                                        {...(btn.onClick ? { onClick: btn.onClick } : {})}
                                        {...(btn.type === "clearForm"
                                            ? {
                                                onClick: () => {
                                                    form.resetFields();
                                                    form.submit();
                                                },
                                            }
                                            : {})}
                                        className={"mt20"}
                                        type={btn.submitButtonType}
                                        htmlType={
                                            btn.type === "clearForm" ? "button" : btn.htmlType
                                        }
                                        block
                                    >
                                        {btn.submitButtonText}
                                    </Button>
                                </Form.Item>
                            </Col>
                        );
                    })}
                </Row>
            </Form>
        </Drawer >
    </>
}

export default CreateEditQuizComponent;