import { Button, Col, DatePicker, Descriptions, Form, Input, Row, Select, Space, Table, message } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { requestCourseEnrolmentListNew, requestInstalmentsByEnrol, requestModifyInstalmentStructure } from "../../../core/store/reducers/admission.slice";
import { RootState } from "../../../shared/types/common";

function ModifyInstalments({ courseEnrolmentId, rollNo, close }: any) {
    const { user } = useSelector((state: RootState) => state.auth);

    const dispatch = useDispatch();
    const [enrolmentDetails, setEnrolmentDetails] = useState({} as any);
    const [candidateData, setCandidateData] = useState({} as any);
    const [pendingInstalments, setPendingInstalments] = useState([] as any);
    const [instalments, setInstalments] = useState(0 as any);
    const [instalmentData, setInstalmentData] = useState([] as any);
    const [pendingFee, setPendingFee] = useState({} as any);
    useEffect(() => {
        if (instalments !== instalmentData?.length && instalments > 0 && instalmentData?.length > 0) {
            let _instalmentData = JSON.parse(JSON.stringify(instalmentData))
            if (instalments < instalmentData?.length) {
                let _insData = _instalmentData.splice(0, instalments);
                setInstalmentStructureData("", "", 0, _insData)
            }
            if (instalments > instalmentData?.length) {
                _instalmentData = [..._instalmentData, ...Array(instalments - instalmentData?.length).fill({
                    ...pendingInstalments[0],
                    admission_amount: 0,
                    admission_discount: 0,
                    tuition_amount: 0,
                    tuition_discount: 0,
                    books_amount: 0,
                    books_discount: 0,
                    gross_instalment_amount: 0,
                    instalment_discount: 0,
                    net_instalment_amount: 0,
                })];
                setInstalmentStructureData("", "", 0, _instalmentData)
            }

        }
    }, [instalments])
    useEffect(() => {
        if (courseEnrolmentId && rollNo) {
            let filters = { roll_no: rollNo };
            dispatch(requestCourseEnrolmentListNew({ body: filters, params: { page: 1, limit: 1 } }))?.then((val: any) => {
                if (val?.payload?.data?.data?.[0]) {
                    setEnrolmentDetails(val?.payload?.data?.data?.[0])
                    setCandidateData(val?.payload?.data?.data?.[0]?.candidate_id)
                }
            });
            dispatch(requestInstalmentsByEnrol(courseEnrolmentId)).then((val: any) => {
                if (val?.payload?.data?.success) {
                    let pendingIns = val?.payload?.data?.data?.filter((val: any) => {
                        return val?.realized === false
                    })
                    pendingIns = pendingIns?.map((val: any) => {
                        delete val?.created_on;
                        delete val?.updated_on;
                        delete val?._v;
                        delete val?._id;
                        val.created_by = user._id;
                        return val;
                    })
                    setInstalmentData(pendingIns)
                    setPendingInstalments(pendingIns)
                    setInstalments(pendingIns?.length)
                    let admission_amount = 0;
                    let tuition_amount = 0;
                    let books_amount = 0;
                    let ins_charges = 0;
                    let net_instalment_amount = 0;
                    pendingIns.map((val: any) => {
                        admission_amount += val.admission_amount
                        tuition_amount += val.tuition_amount
                        books_amount += val.books_amount
                        net_instalment_amount += val.net_instalment_amount
                    })
                    ins_charges = net_instalment_amount - admission_amount - tuition_amount - books_amount;
                    setPendingFee({ admission_amount, tuition_amount, books_amount, ins_charges, net_instalment_amount })
                }
            })
        }
    }, [courseEnrolmentId, rollNo])
    const installmentColumns = [
        {
            title: "",
            key: "label",
            render: () => ""
        },
        {
            title: "Ins. Admission Amount",
            dataIndex: "admission_amount",
            key: "admission_amount",
        },
        {
            title: "Ins. Tuition Amount",
            dataIndex: "tuition_amount",
            key: "tuition_amount",
        },
        {
            title: "Ins. Book Amount",
            dataIndex: "books_amount",
            key: "books_amount",
            // className: "capitalize",
        },
        {
            title: "Ins. Charges",
            key: "ins_charges",
            render: (rec: any) => rec.net_instalment_amount - rec.admission_amount - rec.tuition_amount - rec.books_amount
            // className: "capitalize",
        },
        {
            title: "Ins. Amount",
            dataIndex: "net_instalment_amount",
            key: "net_amt",
            // className: "capitalize",
        },
        // {
        //     title: "Paid",
        //     dataIndex: "realized",
        //     key: "net_amt",
        //     render: (a: any) => a ? 'Yes' : 'No'
        //     // className: "capitalize",
        // },
        // {
        //     title: "Installment Date",
        //     dataIndex: "prefered_instalment_date",
        //     key: "prefered_instalment_date",
        //     render: (key: any) => moment(key).format("Do-MMM-YYYY"),
        // },
    ];
    const installmentColumns1 = [
        {
            title: "",
            dataIndex: "sno",
            key: "sno",
            render: (a: any) => ""
        },
        {
            title: "Adm Ins. Amount/Discount",
            key: "adm",
            render: (a: any, _: any, index: any) => <Space split={"/"}>
                <Input value={a.admission_amount} onChange={(e: any) => setInstalmentStructureData('admission_amount', e.target.value, index)} disabled={index == instalments - 1 || pendingFee?.admission_amount == 0}></Input>
                {/* <Input value={a.admission_discount} onChange={(e: any) => setInstalmentStructureData('admission_discount', e.target.value, index)} disabled={index == instalments - 1}></Input> */}
            </Space>
        },
        {
            title: "Tuition Ins. Amount/Discount",
            key: "amount",
            render: (a: any, _: any, index: any) => {
                return <Space split={"/"}>
                    <Input value={a.tuition_amount} onChange={(e: any) => setInstalmentStructureData('tuition_amount', e.target.value, index)} disabled={index == instalments - 1 || pendingFee?.tuition_amount == 0}></Input>
                    {/* <Input value={a.tuition_discount} onChange={(e: any) => setInstalmentStructureData('tuition_discount', e.target.value, index)} disabled={index == instalments - 1 }></Input> */}
                </Space>
            }
        },
        {
            title: "Book Ins. Amount/Discount",
            key: "payment_type",
            render: (a: any, _: any, index: any) => <Space split={"/"}>
                <Input value={a.books_amount} onChange={(e: any) => setInstalmentStructureData('books_amount', e.target.value, index)} disabled={index == instalments - 1 || pendingFee?.books_amount == 0}></Input>
                {/* <Input value={a.books_discount} onChange={(e: any) => setInstalmentStructureData('books_discount', e.target.value, index)} disabled={index == instalments - 1}></Input> */}
            </Space>
        },
        {
            title: "Ins. Amount/Discount",
            key: "actual_payment_date",
            render: (a: any, _: any, index: any) => <Space split={"/"}>
                <Input value={a.net_instalment_amount} disabled></Input>
                {/* <Input value={a.instalment_discount_display} disabled></Input> */}
            </Space>
        },
        {
            title: "Installment Date",
            key: "prefered_instalment_date",
            render: (a: any, _: any, index: any) =>
                <DatePicker value={moment(a.prefered_instalment_date)} onChange={(e: any) => setInstalmentStructureData('prefered_instalment_date', e, index)}></DatePicker>

        }

    ]
    const setInstalmentStructureData = (key: string, value: any, index: number, instalmentDataToProcess: any = null) => {
        let _instalmentData = JSON.parse(JSON.stringify(instalmentDataToProcess || instalmentData))
        if (!instalmentDataToProcess) {
            if (key == 'prefered_instalment_date') {
                _instalmentData[index][key] = moment(value).format('YYYY-MM-DD');
                setInstalmentData(_instalmentData)
                return;
            }
            _instalmentData[index][key] = parseFloat(value);

            _instalmentData[index].net_instalment_amount =
                _instalmentData[index].admission_amount +
                _instalmentData[index].tuition_amount +
                _instalmentData[index].books_amount
            _instalmentData[index].gross_instalment_amount = _instalmentData[index].net_instalment_amount;
        }


        let sumData = {
            "admission_amount": 0,
            "tuition_amount": 0,
            "books_amount": 0,
            "net_amt": 0,
        }
        for (let k = 0; k < _instalmentData?.length - 1; ++k) {
            let fee = _instalmentData[k];
            sumData.admission_amount += fee.admission_amount
            sumData.tuition_amount += fee.tuition_amount
            sumData.books_amount += fee.books_amount
        }
        sumData.net_amt = sumData.admission_amount + sumData.tuition_amount + sumData.books_amount;
        _instalmentData[_instalmentData?.length - 1] = {
            ..._instalmentData[_instalmentData?.length - 1],
            "admission_amount": pendingFee?.admission_amount - sumData.admission_amount,
            "tuition_amount": pendingFee?.tuition_amount - sumData.tuition_amount,
            "books_amount": pendingFee?.books_amount - sumData.books_amount,
            "gross_instalment_amount": pendingFee?.net_instalment_amount - sumData.net_amt,
            "net_instalment_amount": pendingFee?.net_instalment_amount - sumData.net_amt,
        }

        setInstalmentData(_instalmentData)
    }
    function buttonDisabled() {
        let sumData = {
            "admission_amount": 0,
            "tuition_amount": 0,
            "books_amount": 0,
            "net_amt": 0,
        }
        for (let k = 0; k <= instalmentData?.length - 1; ++k) {
            let fee = instalmentData[k];
            if (fee.admission_amount < 0 || fee.tuition_amount < 0 || fee.books_amount < 0) {
                return true;
            }
            if (fee.admission_amount + fee.tuition_amount + fee.books_amount <= 0) {
                return true;
            }

            sumData.admission_amount += fee.admission_amount
            sumData.tuition_amount += fee.tuition_amount
            sumData.books_amount += fee.books_amount

        }
        sumData.net_amt = sumData.admission_amount + sumData.tuition_amount + sumData.books_amount;
        if (sumData.admission_amount != pendingFee?.admission_amount || sumData.tuition_amount != pendingFee?.tuition_amount || sumData.books_amount != pendingFee?.books_amount || sumData.net_amt != pendingFee?.net_instalment_amount - pendingFee?.ins_charges) {
            return true;
        }
        return false;
    }
    function submitFeeStructure() {
        if (instalmentData?.length > 0) {
            dispatch(requestModifyInstalmentStructure({ id: courseEnrolmentId, body: instalmentData })).then((val: any) => {
                if (val?.payload?.data?.success) {
                    message.success("Instalments updated successfully");
                    close();
                }
            })
        }
    }
    return <>
        <Row gutter={10}>
            <Col span={24}>
                <Descriptions title="Student Details" className="table-description">
                    <Descriptions.Item label="Registration Number">{candidateData?.registration_number}</Descriptions.Item>
                    <Descriptions.Item label="Roll No">{enrolmentDetails?.roll_no}</Descriptions.Item>
                    <Descriptions.Item label="Candidate Name">{candidateData?.candidate_name}</Descriptions.Item>
                    <Descriptions.Item label="Contact">{candidateData?.contact}</Descriptions.Item>
                    <Descriptions.Item label="Email">{candidateData?.email}</Descriptions.Item>
                </Descriptions>
            </Col>
        </Row>
        <Row gutter={10} className="mt-4">
            <Col span={24}>
                <Descriptions title="Batch Details" className="table-description">
                    <Descriptions.Item label="Session">{enrolmentDetails?.batch_id?.session_id?.session}</Descriptions.Item>
                    <Descriptions.Item label="Center">{enrolmentDetails?.batch_id?.center_id?.center_name}</Descriptions.Item>
                    <Descriptions.Item label="Course">{enrolmentDetails?.batch_id?.course_id?.course_name}</Descriptions.Item>
                    <Descriptions.Item label="Batch Type">{enrolmentDetails?.batch_id?.batch_type_id?.batch_type_name}</Descriptions.Item>
                    <Descriptions.Item label="Exam">{enrolmentDetails?.batch_id?.exam_id?.exam_name}</Descriptions.Item>
                    <Descriptions.Item label="Stream">{enrolmentDetails?.batch_id?.stream_id?.stream_name}</Descriptions.Item>
                    <Descriptions.Item label="Batch">{enrolmentDetails?.batch_id?.batch_code_id?.batch_code}</Descriptions.Item>
                    {enrolmentDetails?.transferred && <Descriptions.Item className="transferbg" label="Transfer Status">{enrolmentDetails?.remarks}</Descriptions.Item>}
                </Descriptions>
            </Col>
        </Row>
        <Row>
            <Descriptions title="Pending Instalment Details" className="table-description mt-4"></Descriptions>
            <Col span={24}>
                <Table
                    loading={false}
                    dataSource={pendingInstalments}
                    columns={installmentColumns}
                    size="small"
                    pagination={false}
                    summary={(pageData: any) => {
                        console.log(pageData);
                        let admission_amount = 0;
                        let tuition_amount = 0;
                        let books_amount = 0;
                        let ins_charges = 0;
                        let net_instalment_amount = 0;
                        pageData.map((val: any) => {
                            admission_amount += val.admission_amount
                            tuition_amount += val.tuition_amount
                            books_amount += val.books_amount
                            net_instalment_amount += val.net_instalment_amount
                        })
                        ins_charges = net_instalment_amount - admission_amount - tuition_amount - books_amount;
                        return <>
                            <Table.Summary.Row style={{ background: 'rgba(0,0,0,0.1)' }}>
                                <Table.Summary.Cell index={0}><strong>Total</strong></Table.Summary.Cell>
                                <Table.Summary.Cell index={1}>{admission_amount}</Table.Summary.Cell>
                                <Table.Summary.Cell index={2}>{tuition_amount}</Table.Summary.Cell>
                                <Table.Summary.Cell index={3}>{books_amount}</Table.Summary.Cell>
                                <Table.Summary.Cell index={4}>{ins_charges}</Table.Summary.Cell>
                                <Table.Summary.Cell index={5}>
                                    {net_instalment_amount}
                                </Table.Summary.Cell>
                            </Table.Summary.Row>

                        </>
                    }}
                />
            </Col>
        </Row>
        <Row className="mt-4">
            <Col>
                <Form.Item label="Chnage Installment Count">
                    <Select value={instalments} style={{ width: "100%" }} onChange={setInstalments}>
                        {[
                            // { name: 'No Instalments', value: 1 },
                            { name: 'Instalment 1', value: 1 },
                            { name: 'Instalment 2', value: 2 },
                            { name: 'Instalment 3', value: 3 },
                            { name: 'Instalment 4', value: 4 },
                            { name: 'Instalment 5', value: 5 }
                        ].map((val: any) => {
                            return <Select.Option value={val.value}>{val.name}</Select.Option>
                        })}
                    </Select>
                </Form.Item>
            </Col>
        </Row>
        <Row>
            <Col span={24}>

                {instalmentData?.length > 0 &&
                    <Table

                        loading={false}
                        dataSource={instalmentData}
                        columns={installmentColumns1}
                        size="small"
                        pagination={false}
                        summary={() => (
                            <Table.Summary>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0}><strong>Total</strong></Table.Summary.Cell>
                                    <Table.Summary.Cell index={1}>{pendingFee?.admission_amount}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={1}>{pendingFee?.tuition_amount}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={1}>{pendingFee?.books_amount}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={1}>{pendingFee?.net_instalment_amount}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={5}>{pendingFee?.ins_charges} Instalment Charges</Table.Summary.Cell>
                                </Table.Summary.Row>
                            </Table.Summary>
                        )}
                    />
                }
            </Col>
        </Row>
        <Row>
            <Col>
                <Button
                    loading={false}
                    onClick={submitFeeStructure}
                    className={"px20"}
                    type={'primary'}
                    disabled={buttonDisabled()}
                >
                    Submit
                </Button>
            </Col>
        </Row>
    </>
}
export default ModifyInstalments;