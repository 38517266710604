// App
export const ROUTE_FEE_CLEARANCE = `/accounts/fee-clearance`;
export const ROUTE_FEE_CLEARANCE_ONLINE = `/accounts/fee-clearance-online`;
export const ROUTE_BANK_DEPOSIT = `/accounts/bank-deposit`;
export const ROUTE_DAILY_EXPENSES = `/accounts/daily-expenses`;
export const ROUTE_EDUCATION_INCOME_TALLY = `/accounts/education-income-tally`;
export const ROUTE_EDUCATION_INCOME_TALLY_ONLINE = `/accounts/education-income-tally-online`;
export const ROUTE_EDUCATION_DEBIT_TALLY = `/accounts/education-debit-tally`;
export const ROUTE_PUBLICATION_INCOME_TALLY = `/accounts/publication-income-tally`;
export const ROUTE_PUBLICATION_DEBIT_TALLY = `/accounts/publication-debit-tally`;

// API
export const API_FEE_CLEARANCE = "api/admission/fee-clearance";
export const API_FEE_CLEARANCE_ONLINE = "api/admission/fee-clearance-online";
export const API_BANK_DEPOSIT = "api/accounts/bank-deposit";
export const API_DAILY_EXPENSES = "api/accounts/daily-expenses";
export const API_EDUCATION_INCOME_TALLY = "api/accounts/education-income-tally";
export const API_EDUCATION_DEBIT_TALLY = "api/accounts/education-debit-tally";
export const API_PUBLICATION_INCOME_TALLY =
  "api/accounts/publication-income-tally";
export const API_PUBLICATION_DEBIT_TALLY =
  "api/accounts/publication-debit-tally";
