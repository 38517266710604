import { Drawer } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCenterList, getMasterDropdownData } from "../../../core/store/reducers/masters.slice";
import {
  createNewUser
} from "../../../core/store/reducers/user-management.slice";
import GenericFormComponent, {
  GenericFormProps
} from "../../../shared/components/generic-form/generic-form.component";
import { RootState } from "../../../shared/types/common";
import { showNotification } from "../../../shared/utils/utils";

interface Props {
  onClose: () => void;
  visible: boolean;
  selectedUser?: any;
  onSubmit?: () => void;
}
const CreateEditUser = ({
  onClose,
  visible,
  selectedUser = null,
  onSubmit = () => {
    return;
  },
}: Props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth);
  const [userRoles, setUserRoles] = useState([
    { value: 'admin' },
    { value: 'counsellor' },
    { value: 'accounts' },
    { value: 'webmaster' },
    { value: 'center-head' },
    { value: 'lms-admin' },
    { value: 'faculty' },
    { value: 'live-coordinator' },
    { value: 'marketing' },
  ]);
  const [centers, setCenters] = useState([] as any);
  const [formValues, setFormValues] = useState(null as any);
  const [subjects, setSubjects] = useState([] as any);
  useEffect(() => {
    dispatch(getCenterList()).then((val: any) => {
      if (val?.payload?.data?.data?.length > 0) {
        setCenters(
          val.payload.data.data.map((val: any) => {
            return { label: val.center_name, value: val._id };
          })
        );
      }
    });
    dispatch(getMasterDropdownData({ schemaKey: "exam-subject", labelKey: "name" })).then((val: any) => {
      if (val?.payload?.data?.success) {
        setSubjects(val?.payload?.data?.data?.map((val: any) => {
          return { name: val.name, value: val._id }
        }))
      }
    })
  }, [])

  const onFinish = (values: any) => {
    const payload = {
      "user_type": "employee",
      "user_name": values.username,
      "password": values.password,
      "created_by": user._id,
      "modified_by": user._id,
      "profile_data": {
        "emp_code": values.emp_code,
        "name": values.name,
        "email": values.username,
        "phone": values.phone,
        "user_role": values.role,
        "center": values.center,
        "subject_id": values.subject_id
      }
    };
    dispatch(createNewUser(payload)).then((val: any) => {
      if (val?.payload?.data) {
        showNotification("USER.USER_CREATED_SUCCESSFULLY", "success");
        onSubmit();
      }
    });
  };

  const onFinishFailed = (_errorInfo: any) => {
    return;
  };
  const onValuesChange = (value: any, values: any) => {
    setFormValues(values)
  }
  const formProps: GenericFormProps = {
    name: "creat-edit-user",
    heading: "",
    formElements: [
      {
        name: "username",
        label: "Username",
        placeholder: "Username",
        type: "Input",
        colSpan: 12,
        rules: [
          {
            required: true,
            message: "Required",
          },
          {
            type: "email",
            message: "Should be valid Email ID",
          },
          {
            max: 50,
            message: "Max length is 50",
          },
        ],
      },
      {
        name: "password",
        label: "Password",
        placeholder: "Password",
        type: "Input",
        colSpan: 12,
        rules: [
          {
            required: true,
            message: "Required",
          },
          {
            max: 16,
            message: "Max length is 16",
          },
        ],
      },
      {
        name: "emp_code",
        label: "Employee Code",
        placeholder: "Employee Code",
        type: "Input",
        colSpan: 12,
        rules: [
          {
            required: true,
            message: "Required",
          },
          {
            max: 50,
            message: "Max length is 50",
          },
        ],
      },
      {
        name: "name",
        label: "Name",
        placeholder: "Name",
        type: "Input",
        colSpan: 12,
        rules: [
          {
            required: true,
            message: "Required",
          },
          {
            max: 50,
            message: "Max length is 50",
          },
        ],
      },
      {
        name: "phone",
        label: "Phone Number",
        placeholder: "Phone Number",
        type: "InputNumber",
        colSpan: 12,
        controls: false,
        rules: [
          () => ({
            validator(_: any, value: any) {
              if (value && `${value}`.length > 20) {
                return Promise.reject(new Error("COMMON.ERROR.MAX_LENGTH"));
              }
              if (value && value <= 0) {
                return Promise.reject(new Error("COMMON.ERROR.NEGATIVE_VALUE"));
              }
              if (value && value.indexOf(".") !== -1) {
                return Promise.reject(new Error("COMMON.ERROR.DECIMAL_VALUE"));
              }
              return Promise.resolve();
            },
          }),
        ],
      },
      {
        name: "role",
        label: "User Role",
        placeholder: "User Role",
        type: "Select",
        colSpan: 12,
        showSearch: true,
        allowClear: true,
        options: userRoles,
      },
      {
        name: "center",
        label: "Center",
        placeholder: "Center",
        type: "Select",
        colSpan: 12,
        showSearch: true,
        allowClear: true,
        options: centers,
      },
      ...(
        formValues?.role === 'faculty' ? [{
          name: "subject_id",
          label: "Subject",
          placeholder: "Subject",
          type: "Select",
          colSpan: 12,
          showSearch: true,
          mode: 'multiple',
          allowClear: true,
          options: subjects,
        }] : [{}]
      )

    ],
    initialValues: {
      // username: selectedUser?.username ? selectedUser?.username : "",
      // firstName: selectedUser?.firstName ? selectedUser?.firstName : "",
      // lastName: selectedUser?.lastName ? selectedUser?.lastName : "",
      // phoneNumber: selectedUser?.phoneNumber ? selectedUser?.phoneNumber : "",
      // startDate: selectedUser?.startDate ? moment(selectedUser?.startDate) : "",
      // endDate: selectedUser?.endDate ? moment(selectedUser?.endDate) : "",
      // roles: selectedUser?.roles ? selectedUser?.roles : "",
      // groups: [],
      // enabled: true,
    },
    extraButtons: [
      {
        htmlType: "button",
        colSpan: 5,
        loading: false,
        submitButtonText: "CANCEL",
        onClick: onClose,
      },
      {
        htmlType: "button",
        colSpan: 5,
        loading: false,
        submitButtonText: "CLEAR",
        type: "clearForm",
      },
      {
        htmlType: "submit",
        colSpan: 5,
        loading: false,
        submitButtonText: "SAVE",
        submitButtonType: "primary",
      },
    ],
    onFinish: onFinish,
    onFinishFailed: onFinishFailed,
    onValuesChange: onValuesChange,
  };
  return (
    <>
      <Drawer
        maskClosable={false}
        title="Manage User"
        className="form-drawer"
        width={720}
        onClose={onClose}
        visible={visible}
      >
        <GenericFormComponent {...formProps} />
      </Drawer>
    </>
  );
};

export default CreateEditUser;
