import {
  CloseCircleOutlined, EditOutlined, PlusOutlined
} from "@ant-design/icons";
import { Button, Col, Row, Space, Tooltip } from "antd";
import { useCallback, useEffect, useState } from "react";
import { BiFilterAlt } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { genericWebsiteUpdate, getContentSubCategoriesByContentCategoryId, getGenericList, getGenericListMinimal, resetFilters, setFilters } from "../../../core/store/reducers/website.slice";
import ContentHeaderComponent from "../../../shared/components/content-header/content-header.component";
import DataTableComponent from "../../../shared/components/data-table/data-table.component";
import GenericFormComponent from "../../../shared/components/generic-form/generic-form.component";
import { TABLE_ROWS_COUNT } from "../../../shared/constants";
import { ROUTE_DASHBOARD } from "../../../shared/routes/dashboard.routes";
import { RootState } from "../../../shared/types/common";
import { showNotification } from "../../../shared/utils/utils";
import CreateEditComponent from "./create-edit.component";
import { allModules, modulesKeys } from "./utils";
// import CreateEditCourseExamsComponent from "./create-edit-products.component";

const ManageCourseExamsPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { app } = useParams();
  const [contentCategoryList, setContentCategoryList] = useState([] as any)
  const [contentSubCategoryList, setContentSubCategoryList] = useState([] as any)
  if (modulesKeys.indexOf(app) === -1) {
    navigate(ROUTE_DASHBOARD)
  }
  const moduleData = allModules[modulesKeys.indexOf(app)];
  // List states
  const { loading, genericList, filters, total } = useSelector(
    (state: RootState) => state.website
  );
  const { screenType } = useSelector((state: RootState) => state.settings);
  const { user } = useSelector((state: RootState) => state.auth);

  // List local state
  const [entityListData, setEntityListData] = useState([] as any);

  // Flag to open create/edit form
  const [createEditOpen, setCreateEditOpen] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(null as any);
  const [filterVisible, setFilterVisible] = useState(false);

  // Actions and selections
  const [sorter, setSorter] = useState({ sort_by: "", sort_type: "" });
  const [pagination, setPagination] = useState({ page: 1, limit: TABLE_ROWS_COUNT });

  useEffect(() => {
    setEntityListData([])
    dispatch(resetFilters())
    setSorter({ sort_by: "", sort_type: "" })
    setPagination({ page: 1, limit: TABLE_ROWS_COUNT })
    setFilterVisible(false)

    if (app == 'content-item') {
      dispatch(getGenericListMinimal({ module: 'content-category', params: { keys: 'content_category_name' } })).then((val: any) => {

        if (val?.payload?.data?.data) {
          setContentCategoryList(val?.payload?.data?.data?.map((val: any) => {
            return {
              value: val._id, label: val.content_category_name
            }
          }))
        }
      });
    }
  }, [app])

  // Functions and Constants
  const loadList = useCallback(
    (_page: any = 1) => {
      const data = {
        ...pagination,
        ...sorter,
      };
      dispatch(getGenericList({ params: data, body: filters, module: app }));
    },
    [filters, dispatch, sorter, pagination]
  );

  const createEntityListner = () => {
    setCreateEditOpen(true);
    setSelectedEntity(null);
  };

  // useEffect Hooks
  useEffect(() => {
    loadList();
  }, [loadList]);

  //filters, loadList
  useEffect(() => {
    genericList?.length >= 0 &&
      setEntityListData(
        genericList.map((obj: any, index: number) => {
          return { key: index, ...obj };
        })
      );
  }, [genericList]);
  const toggleStatus = (data: any) => {
    dispatch(genericWebsiteUpdate({ payload: { _id: data._id, status: !data.status }, module: app })).then((val: any) => {
      if (val?.payload?.data?.success) {
        showNotification("Status Updated Successfully", "success");
        loadList();
      }
    })
  }
  const onFilterApply = (values: any) => {
    let arrFilter = [];
    for (let [key, value] of Object.entries(values)) {
      if (value !== "") {
        arrFilter.push({ key: key, value: value });
      }
    }
    let refinedArray = arrFilter.filter((data) => {
      return data.value !== undefined;
    });
    let obj = {} as any;
    refinedArray.forEach((data: any) => {
      obj[data.key] = data.value;
    });
    setPagination({
      page: 1,
      limit: pagination.limit,
    });
    dispatch(setFilters(obj)); //filters
  };

  const onPageChange = (pageNumber: number) => {
    setPagination({
      page: pageNumber,
      limit: pagination.limit,
    });
  };

  const editEntityListner = (entity: any) => {
    setSelectedEntity(entity);
    setCreateEditOpen(true);
  };

  const columns = [
    ...moduleData.schema,
    {
      title: "Actions",
      width: "160px",
      fixed: "right",
      render: (a: any) => {
        return (
          <>
            <Tooltip title={"Edit"}>
              <Button
                type="link"
                onClick={() => editEntityListner(a)}
                className="table-button"
                icon={<EditOutlined />}
              ></Button>
            </Tooltip>
            <Tooltip title={"Mark Inactive"}>
              <Button
                onClick={() => toggleStatus(a)}
                type="link"
                className="table-button"
                icon={<CloseCircleOutlined />}
              ></Button>
            </Tooltip>
          </>
        );
      },
      key: "actions",
    },
  ];

  const onChangeHandler = (_pagination: any, _filters: any, _sorter: any) => {
    if (_sorter.order === undefined) {
      setSorter({ sort_by: "", sort_type: "" });
    } else {
      let order = "";
      let columnKey = "";
      if (_sorter.order === "ascend") order = "asc";
      else order = "desc";

      if (_sorter.columnKey === "enabled") {
        columnKey = "status";
      } else if (_sorter.columnKey === "displayName") {
        columnKey = "name";
      } else {
        columnKey = _sorter.columnKey;
      }
      setSorter({ sort_by: columnKey, sort_type: order });
    }
  };
  const onValuesChange = (value: any, _allValues: any) => {
    if (value.content_category_id) {
      dispatch(getContentSubCategoriesByContentCategoryId(value.content_category_id)).then((val: any) => {
        if (val?.payload?.data?.data) {
          setContentSubCategoryList(val?.payload?.data?.data?.map((val: any) => {
            return {
              value: val._id, label: val.content_sub_category_name
            }
          }))
        }
      })
    }
  };
  const formProps = {
    name: moduleData?.key + "-filters",
    enableEnterSubmit: true,
    formElements: [
      ...moduleData?.filterForm,
      ...(app == 'content-item' ? [
        {
          name: "content_category_id",
          label: "",
          placeholder: "Search Content Category",
          colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
          type: "Select",
          colSpan: 6,
          showSearch: true,
          allowClear: true,
          options: contentCategoryList
        },
        {
          name: "content_sub_category_id",
          label: "",
          placeholder: "Search Content Sub Category",
          colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
          type: "Select",
          colSpan: 6,
          showSearch: true,
          allowClear: true,
          options: contentSubCategoryList
        },
      ] : [])
    ],
    initialValues: {

    },
    extraButtons: [
      {
        htmlType: "submit",
        colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 3 },
        loading: false,
        submitButtonText: "Clear",
        type: "clearForm",
      },
      {
        htmlType: "submit",
        colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 3 },
        loading: false,
        submitButtonText: "Apply",
        submitButtonType: "primary",
      },
    ],
    onFinish: onFilterApply,
    onFinishFailed: null,
    onValuesChange: onValuesChange,
  };

  const createEditSubmit = () => {
    setCreateEditOpen(false);
    loadList();
  };

  const headerButtons = (
    <Space>
      <Button
        type="primary"
        // size="large"
        icon={<PlusOutlined />}
        onClick={createEntityListner}
      >
        <span className="font-size-14">New {!screenType?.isPortrait && moduleData?.label}</span>
      </Button>
      <Button
        shape="circle"
        type="primary"
        icon={
          <span role="img" className="anticon">
            <BiFilterAlt />
          </span>
        }
        onClick={() => {
          setFilterVisible(!filterVisible);
        }}
      ></Button>
    </Space>
  );
  return (
    <>
      <div
        className="mx-6 mt-2 bg-primary-white-blueish border-primary-gray-02"
        style={{ borderBottom: "none!important" }}
      >
        <Row>
          <Col span={24}>
            <ContentHeaderComponent
              title={"Manage " + moduleData?.label}
              subtitle=""
              buttons={headerButtons}
            />
          </Col>
        </Row>
        {filterVisible && (
          <Row className="bg-body">
            <Col span={24} className="py-1">
              <div className="px-3 table-filter-form">
                <GenericFormComponent {...formProps} />
              </div>
            </Col>
          </Row>
        )}
      </div>

      <DataTableComponent
        loading={loading}
        dataSource={entityListData}
        columns={columns}
        onChange={onChangeHandler}
        title={moduleData?.label + " List"}
        defaultPage={0}
        currentPage={pagination.page}
        totalRows={total}
        onPageChange={onPageChange}

      />
      {createEditOpen && (
        <CreateEditComponent
          onClose={() => setCreateEditOpen(false)}
          visible={createEditOpen}
          moduleData={moduleData}
          selectedEntity={selectedEntity}
          onSubmit={createEditSubmit}
        />
      )}

    </>
  );
};
export default ManageCourseExamsPage;
