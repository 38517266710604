import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Form, Input, Row, Select } from "antd";
import { CKEditor } from "ckeditor4-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMasterDropdownData, getTopicBySubject } from "../../../core/store/reducers/masters.slice";
import { getAllDescriptionByQuiz, getQuestionDetails, saveNewDescription, updateQuestion } from "../../../core/store/reducers/website.slice";
import { RootState } from "../../../shared/types/common";
import { showNotification } from "../../../shared/utils/utils";

const Options = Select;

const CreateEditQuestionComponent = ({ onClose, visible, onSubmit, selectedQuestion, quizData, questionList }: any) => {
    console.log(onClose, visible, onSubmit, selectedQuestion, quizData, questionList)
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [formValues, setFormValues] = useState({} as any)
    const [questionListData, setQuestionListData] = useState(questionList as any);
    const [subjects, setSubjects] = useState([] as any);
    const [topics, setTopics] = useState([] as any);
    const [description, setDescription] = useState([] as any);
    const [questionData, setQuestionData] = useState(null as any);
    const [addDescription, setAddDescription] = useState(false as any);
    const { screenType } = useSelector((state: RootState) => state.settings);
    const { user } = useSelector((state: RootState) => state.auth);
    const onFinish = async (values: any) => {
        console.log(values)
        // let processedData = processRecords(csvFileData.data, csvFileData.columns);
        const nLangs = questionData?.qu?.length;
        if (values?.description) {
            if (values?.description[0]) {
                for (let k = 0; k < nLangs; ++k) {
                    if (!values?.description[k]) {
                        values.description[k] = '';
                    }
                }
            }
            //save description and get description_id
            const res = await dispatch(saveNewDescription({ quiz_id: quizData?._id, description: values.description }));
            if (res?.payload?.data?.data?._id)
                values.description_id = res?.payload?.data?.data?._id;
        }
        delete values.description
        const payload = {
            ...values,
            updated_by: user._id,
        }
        dispatch(updateQuestion({ id: questionData?._id, payload: payload })).then((val: any) => {
            if (val.payload?.data?.success) {
                showNotification("Question Saved Successfully", "success");
                onSubmit();
            }
        })
    }
    useEffect(() => {
        dispatch(getQuestionDetails(selectedQuestion?.q_id?._id)).then((val: any) => {
            setQuestionData(val.payload?.data?.data)
            setFormValues(val.payload?.data?.data)
        })
        dispatch(getMasterDropdownData({ schemaKey: "exam-subject", labelKey: "name" })).then((val: any) => {
            if (val?.payload?.data?.success) {
                setSubjects(val?.payload?.data?.data)
            }
        })
        dispatch(getAllDescriptionByQuiz(quizData?._id)).then((val: any) => {
            if (val?.payload?.data?.success) {
                const descriptionAll = val?.payload?.data?.data;
                for (let k = 0; k < descriptionAll?.length; ++k) {
                    descriptionAll[k].questionList = [];
                    setQuestionListData(questionList.map((val: any) => {
                        if (val?.q_id?.description_id === descriptionAll[k]._id) {
                            descriptionAll[k].questionList.push(val.sno)
                        }
                    }))
                }
                setDescription(descriptionAll)
            }
        })
    }, [])
    useEffect(() => {
        questionData?.subject_id && dispatch(getTopicBySubject(questionData?.subject_id)).then((val: any) => {
            if (val?.payload?.data?.success) {
                setTopics(val?.payload?.data?.data)
            }
        })
    }, [questionData])
    function onValuesChange(currentValue: any, allValues: any) {
        setFormValues(allValues)
        if (currentValue?.subject_id) {
            // get topics by subject
            dispatch(getTopicBySubject(currentValue?.subject_id)).then((val: any) => {
                if (val?.payload?.data?.success) {
                    setTopics(val?.payload?.data?.data)
                }
            })
        }
    }


    const extraButtons = [
        {
            htmlType: "button",
            colSpan: 5,
            loading: false,
            submitButtonText: "Cancel",
            onClick: onClose,
        },
        {
            htmlType: "submit",
            colSpan: 5,
            loading: false,
            submitButtonText: "Save",
            submitButtonType: "primary",
        }
    ]
    function addNewDescription() {
        setAddDescription(true)
    }

    return <>
        <Drawer
            maskClosable={false}
            title={"Question Edit - Q" + selectedQuestion.sno}
            className="form-drawer"
            width={screenType.isPortrait ? '90%' : (screenType.isLg || screenType.isXl ? '99%' : '99%')}
            onClose={onClose}
            open={visible}
        >

            {questionData && <Form
                form={form}
                initialValues={{
                    ...questionData
                }}
                className="paddingx20 app-form full-width"
                onFinish={onFinish}
                onValuesChange={onValuesChange}
            >
                <Row gutter={16}>
                    <Col span={8} className="gutter-row">
                        <Form.Item name="subject_id" label="Subject">
                            <Select>
                                {subjects?.length > 0 && subjects?.map((val: any) => <Options value={val?._id}>{val?.name}</Options>)}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8} className="gutter-row">
                        <Form.Item name="topic_id" label="Topic">
                            <Select>
                                {topics?.length > 0 && topics?.map((val: any) => <Options value={val?._id}>{val?.name}</Options>)}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8} className="gutter-row">
                        <Form.Item name="diff_level" label="Difficulty Level">
                            <Select>
                                <Options value="high">High</Options>
                                <Options value="medium">Medium</Options>
                                <Options value="low">Low</Options>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8} className="gutter-row">
                        <Form.Item name="plus_mark" label="Marks">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8} className="gutter-row">
                        <Form.Item name="minus_mark" label="Negative marks">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8} className="gutter-row">
                        <Form.Item name="correct_option" label="Correct Option">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                {!addDescription && <Row gutter={16} align={"middle"}>
                    <Col span={8}>
                        <Form.Item name="description_id" label="Select Description">
                            <Select>
                                {description.map((val: any) => <Options value={val?._id}>Description {val.questionList?.length > 0 ? `for Q${val.questionList.join(", ")}` : 'Not Used'}</Options>)}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Button onClick={() => setAddDescription(true)} type="primary" icon={<PlusOutlined />}>Add New Description</Button>
                    </Col>
                </Row>}
                {addDescription && <Row gutter={16} align={"middle"}>
                    <Col>
                        <Button onClick={() => setAddDescription(false)} type="primary">Cancel</Button>
                    </Col>
                </Row>}
                {formValues?.description_id && description?.length > 0 && !addDescription && <Row gutter={16}>
                    {questionData?.qu?.length > 0 && questionData?.qu?.map((val: any, index: number) => {
                        return <Col span={24 / questionData?.qu?.length} className="ant-form-item-label">
                            <label>DESCRIPTION</label>
                            <div dangerouslySetInnerHTML={{ __html: description?.filter((val: any) => val?._id == formValues?.description_id)[0].description[index] }} />
                        </Col>
                    })}
                </Row>}
                {addDescription && <Row gutter={16}>
                    {questionData?.qu?.length > 0 && questionData?.qu?.map((val: any, index: number) => {
                        return <Col span={24 / questionData?.qu?.length}>
                            <Form.Item name={["description", index]} label="Description" className="hidden-input">
                                <Input disabled hidden />
                            </Form.Item>
                            <CKEditor
                                name={'content'}
                                editorUrl={'https://bw.vanikonline.in/_ckeditor/ckeditor.js'}
                                config={
                                    {
                                        extraPlugins: 'mathjax',
                                        mathJaxLib: 'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/MathJax.js?config=TeX-AMS_HTML',
                                    }
                                }
                                onChange={(event) => {
                                    console.log(event.editor.getData());
                                    form.setFieldValue(["description", index], event.editor.getData())
                                }}
                            />
                        </Col>
                    })}
                </Row>}

                <Row gutter={16}>
                    {questionData?.qu?.length > 0 && questionData?.qu?.map((val: any, index: number) => {
                        return <Col span={24 / questionData?.qu?.length}>
                            <Form.Item name={["qu", index]} label="Question" className="hidden-input">
                                <Input disabled hidden />
                            </Form.Item>
                            <CKEditor
                                name={'content'}
                                editorUrl={'https://bw.vanikonline.in/_ckeditor/ckeditor.js'}
                                config={
                                    {
                                        extraPlugins: 'mathjax',
                                        mathJaxLib: 'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/MathJax.js?config=TeX-AMS_HTML',
                                    }
                                }
                                initData={questionData ? questionData?.qu[index] : <p>Add content</p>}
                                onChange={(event) => {
                                    console.log(event.editor.getData());
                                    form.setFieldValue(["qu", index], event.editor.getData())
                                }}
                            />
                        </Col>
                    })}
                </Row>
                {questionData?.options?.length > 0 && questionData?.options?.map((option: any, index: number) => {
                    return <Row gutter={16}>
                        {option?.length > 0 && option?.map((val: any, index2: number) => {
                            return <Col span={24 / option?.length}>
                                <Form.Item name={["options", index, index2]} label={`Option ${index + 1}`} className="hidden-input">
                                    <Input disabled hidden />
                                </Form.Item>
                                <CKEditor
                                    name={'content'}
                                    editorUrl={'https://bw.vanikonline.in/_ckeditor/ckeditor.js'}
                                    config={
                                        {
                                            extraPlugins: 'mathjax',
                                            mathJaxLib: 'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/MathJax.js?config=TeX-AMS_HTML',
                                        }
                                    }
                                    initData={questionData ? questionData?.options[index][index2] : <p>Add content</p>}
                                    onChange={(event) => {
                                        console.log(event.editor.getData());
                                        form.setFieldValue(["options", index, index2], event.editor.getData())
                                    }}
                                />
                            </Col>
                        })}
                    </Row>

                })}
                <Row gutter={16}>
                    {questionData?.solution?.length > 0 && questionData?.solution?.map((val: any, index: number) => {
                        return <Col span={24 / questionData?.solution?.length}>
                            <Form.Item name={["solution", index]} label="Solution" className="hidden-input">
                                <Input disabled hidden />
                            </Form.Item>
                            <CKEditor
                                name={'content'}
                                editorUrl={'https://bw.vanikonline.in/_ckeditor/ckeditor.js'}
                                config={
                                    {
                                        extraPlugins: 'mathjax',
                                        mathJaxLib: 'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/MathJax.js?config=TeX-AMS_HTML',
                                    }
                                }
                                initData={questionData ? questionData?.solution[index] : <p>Add content</p>}
                                onChange={(event) => {
                                    console.log(event.editor.getData());
                                    form.setFieldValue(["solution", index], event.editor.getData())
                                }}
                            />
                        </Col>
                    })}
                </Row>

                <Row className="button-row" justify="end" gutter={16}>

                    {extraButtons.map((btn: any, index: number) => {
                        return (
                            <Col
                                {...btn?.colSpanResponsive}
                                span={btn.colSpan}
                                key={index}
                                className="gutter-row"
                            >
                                <Form.Item>
                                    <Button
                                        loading={btn.loading}
                                        // size="large"
                                        {...(btn.onClick ? { onClick: btn.onClick } : {})}
                                        {...(btn.type === "clearForm"
                                            ? {
                                                onClick: () => {
                                                    form.resetFields();
                                                    form.submit();
                                                },
                                            }
                                            : {})}
                                        className={"mt20"}
                                        type={btn.submitButtonType}
                                        htmlType={
                                            btn.type === "clearForm" ? "button" : btn.htmlType
                                        }
                                        block
                                    >
                                        {btn.submitButtonText}
                                    </Button>
                                </Form.Item>
                            </Col>
                        );
                    })}
                </Row>
            </Form>}
        </Drawer>
    </>
}

export default CreateEditQuestionComponent;