import { Button, Col, Row, Space, Table } from "antd";
import { useCallback, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { BiFilterAlt } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { admissionStatusReport } from "../../../core/store/reducers/admission.slice";
import { getMasterDropdownData } from "../../../core/store/reducers/masters.slice";
import ContentHeaderComponent from "../../../shared/components/content-header/content-header.component";
import GenericFormComponent from "../../../shared/components/generic-form/generic-form.component";
import { formatDate } from "../../../shared/utils/utils";


function AdmissionStatusReport() {
    const [filterVisible, setFilterVisible] = useState(true);
    const dispatch = useDispatch();

    const [sorter, setSorter] = useState({ sort_by: "", sort_type: "" });
    const [pagination, setPagination] = useState({ page: 1, limit: 1000 });
    const [filters, setFilters] = useState({} as any);
    const [centerList, setCenterList] = useState([] as any);
    const [sessionList, setSessionList] = useState([] as any);
    const [listData, setListData] = useState([] as any);
    useEffect(() => {
        loadList();
        dispatch(getMasterDropdownData({ schemaKey: "session", labelKey: "session" })).then((val: any) => {
            if (val?.payload?.data?.success) {
                setSessionList(val?.payload?.data?.data?.map((val: any) => {
                    return { label: val.session, value: val._id }
                }))
            }
        })
        dispatch(getMasterDropdownData({ schemaKey: "center", labelKey: "center_name" })).then((val: any) => {
            if (val?.payload?.data?.success) {
                setCenterList(val?.payload?.data?.data?.map((val: any) => {
                    return { label: val.center_name, value: val._id }
                }))
            }
        })
    }, [])

    const loadList = useCallback(
        async (_page: any = 1) => {
            if (Object.keys(filters)?.length == 0) {
                return
            }
            setListData([])
            const data = {
                ...pagination,
                ...sorter,
            };
            // const filters1 = {...filters, }
            let filters1 = { ...filters };
            if (filters1.created_on?.length == 2) {
                filters1 = { ...filters1, created_on: [formatDate(filters1.created_on[0], 'YYYY-MM-DD 00:00'), formatDate(filters1.created_on[1], 'YYYY-MM-DD 23:59')] }
            }
            const retData = await dispatch(admissionStatusReport({ params: data, body: { ...filters1 } }));
            if (retData?.payload?.data?.data?.length > 0) {
                console.log(retData?.payload?.data?.data)
                setListData(retData?.payload?.data?.data)
            }
        },
        [filters, dispatch, sorter, pagination]
    );
    useEffect(() => {
        loadList();
    }, [loadList]);
    const onPageChange = (pageNumber: number) => {
        setPagination({
            page: pageNumber,
            limit: pagination.limit,
        });
    };

    const columns = [
        {
            title: 'S. No.',
            render: (data: any, row: any, index: any) => {
                return index + 1
            },
            key: 'sno',
        },
        {
            title: 'Batch Type',
            render: (data: any) => {
                return data?.batch_details?.batch_type_id?.batch_type_name
            },
            key: 'batchtype',
        },
        {
            title: 'Batch Code',
            render: (data: any) => {
                return data?.batch_details?.batch_code_id?.batch_code
            },
            key: 'batchtype',
        },
        {
            title: 'Exam Name',
            render: (data: any) => {
                return data?.batch_details?.exam_id?.exam_name
            },
            key: 'batchtype',
        },
        {
            title: 'Total Adms.',
            dataIndex: "count",
            key: 'count',
        }
    ];
    const headerButtons = (
        <Space>
            <Button
                shape="circle"
                type="primary"
                icon={
                    <span role="img" className="anticon">
                        <BiFilterAlt />
                    </span>
                }
                onClick={() => {
                    setFilterVisible(!filterVisible);
                }}
            ></Button>
            <CSVLink filename="admission_status_report.csv"
                data={listData?.map((val: any, index: number) => {
                    return {
                        sno: index + 1,
                        batch_type: val.batch_details?.batch_type_id?.batch_type_name,
                        batch_code: val.batch_details?.batch_code_id?.batch_code,
                        exam_name: val.batch_details?.exam_id?.exam_name,
                        count: val.count
                    }
                })} headers={[
                    { label: "S. No.", key: "sno" },
                    { label: "Batch Type", key: "batch_type" },
                    { label: "Batch Code", key: "batch_code" },
                    { label: "Exam Name", key: "exam_name" },
                    { label: "Total Admissions", key: "count" },
                ]}>
                <Button
                    type="primary" disabled={!listData || listData?.length == 0}>
                    Download Data
                </Button>
            </CSVLink>;
        </Space>
    );
    const onFilterApply = (values: any) => {
        let arrFilter = [];
        for (let [key, value] of Object.entries(values)) {
            if (value !== "") {
                arrFilter.push({ key: key, value: value });
            }
        }
        let refinedArray = arrFilter.filter((data) => {
            return data.value !== undefined;
        });
        let obj = {} as any;
        refinedArray.forEach((data: any) => {
            obj[data.key] = data.value;
        });
        setPagination({
            page: 1,
            limit: pagination.limit,
        });
        setFilters(obj);
    };
    const formProps = {
        name: "fee-filters",
        enableEnterSubmit: true,
        formElements: [
            {
                name: "center_id",
                label: "",
                placeholder: "Center Name",
                type: "Select",
                options: centerList,
                allowClear: true,
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 6 },
            },
            {
                name: "session_id",
                label: "",
                placeholder: "Session",
                type: "Select",
                options: sessionList,
                allowClear: true,
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 6 },
            },
            {
                name: "created_on",
                label: "Date Range",
                type: "RangePicker",
                allowClear: true,
                colSpanResponsive: { xs: 12, sm: 8, md: 8, lg: 8 },
            },
        ],
        initialValues: {},
        extraButtons: [
            {
                htmlType: "submit",
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 3 },
                loading: false,
                submitButtonText: "Clear",
                type: "clearForm",
            },
            {
                htmlType: "submit",
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 3 },
                loading: false,
                submitButtonText: "Apply",
                submitButtonType: "primary",
            },
        ],
        onFinish: onFilterApply,
        onFinishFailed: null,
        onValuesChange: null,
    };
    const onChangeHandler = (_pagination: any, _filters: any, _sorter: any) => {
        if (_sorter.order === undefined) {
            setSorter({ sort_by: "", sort_type: "" });
        } else {
            let order = "";
            let columnKey = "";
            if (_sorter.order === "ascend") order = "asc";
            else order = "desc";

            if (_sorter.columnKey === "enabled") {
                columnKey = "status";
            } else if (_sorter.columnKey === "displayName") {
                columnKey = "name";
            } else {
                columnKey = _sorter.columnKey;
            }
            setSorter({ sort_by: columnKey, sort_type: order });
        }
    };

    return <>
        <div
            className="mx-6 mt-2 bg-primary-white-blueish border-primary-gray-02"
            style={{ borderBottom: "none!important" }}
        >
            <Row>
                <Col span={24}>
                    <ContentHeaderComponent
                        title="Admission Status Report"
                        subtitle=""
                        buttons={headerButtons}
                    />
                </Col>
            </Row>

            {filterVisible && (
                <Row className="bg-body">
                    <Col span={24} className="py-1">
                        <div className="px-3 table-filter-form">
                            <GenericFormComponent {...formProps} />
                        </div>
                    </Col>
                </Row>
            )}
        </div>
        <div className="mx-6 bg-primary-white-blueish border-primary-gray-02">
            <Table
                className="app-table"
                tableLayout="auto"
                pagination={false}
                loading={false}
                // scroll={{ x: '1800px' }}
                dataSource={listData}
                columns={columns}
                onChange={onChangeHandler}
                summary={(pageData: any) => {
                    let totalSum = 0;
                    pageData.map((val: any) => {
                        totalSum += val.count
                    })
                    return <>
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0}></Table.Summary.Cell>
                            <Table.Summary.Cell index={1}></Table.Summary.Cell>
                            <Table.Summary.Cell index={2}></Table.Summary.Cell>
                            <Table.Summary.Cell index={3}><strong>Total</strong></Table.Summary.Cell>
                            <Table.Summary.Cell index={4}>
                                <strong>{totalSum}</strong>
                            </Table.Summary.Cell>
                        </Table.Summary.Row>

                    </>
                }}
            />
        </div>
    </>


}
export default AdmissionStatusReport;


