export const ROUTE_MASTER_LIST = "/master";
export const ROUTE_MASTER_LIST_APP = (app: any) => `/master/${app}`;

const PREFIX = "api/masters";
export const API_GET_CENTER_LIST = `${PREFIX}/center`;
export const API_GET_COURSES = `${PREFIX}/courses`;
export const API_GET_COURSE_RELATIONSHIP_BY_CENTER_COURSE_ID = `${PREFIX}/course-relationship`;

export const API_GET_BATCH_TYPE_RELATION = `${PREFIX}/batchtype-by-relation`;
export const API_GET_EXAMS_RELATION = `${PREFIX}/exam-by-relation`;
export const API_GET_STREAMS_RELATION = `${PREFIX}/stream-by-relation`;
export const API_GET_VENUE_RELATION = `${PREFIX}/venue-by-relation`;
export const API_GET_BATCH_RELATION = `${PREFIX}/batch-by-relation`;
export const API_GET_FEE_STRUCTURE_RELATION = `${PREFIX}/fee-by-relation`;
export const API_GET_BATCH_AND_FEE_CLASSROOM = (batch_id: string) =>
  `${PREFIX}/get_batch_and_fee/` + batch_id;
export const API_GET_COURSES_BY_CENTER_RELATION = `${PREFIX}/courses-by-relation`;
export const API_GET_SUBJECT_BY_EXAM = `${PREFIX}/get-subject-by-exam`;
export const API_GET_TOPIC_BY_SUBJECT = `${PREFIX}/get-topic-by-subject`;

//incorrect
export const API_POST_FEE_STRUCTURE = `${PREFIX}/fee-by-batchtype`;


export const API_GET_MASTER_DROPDOWN = (type: any) =>
  `${PREFIX}/list-drop-down/${type}`;
export const API_GET_MASTER_DATA = (type: any) => `${PREFIX}/list/${type}`;
export const API_CREATE_MASTER_DATA = (type: any) => `${PREFIX}/create/${type}`;
export const API_UPDATE_MASTER_DATA = (type: any, id: string) =>
  `${PREFIX}/update/${type}/${id}`;
