import {
    Button,
    Checkbox,
    Col,
    DatePicker,
    Descriptions,
    Divider,
    Form,
    Input,
    InputNumber,
    Popconfirm,
    Radio,
    Row, Space, Table, Tooltip, notification
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { requestCourseEnrolment, requestInstalmentsByEnrol, requestPaymentsByEnrol } from "../../../core/store/reducers/admission.slice";
import { RootState } from "../../../shared/types/common";

import { DeleteOutlined } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import moment from "moment";
import { getBatchAndFeeClassroom, getFeeStructure } from "../../../core/store/reducers/masters.slice";
import { formatDate } from "../../../shared/utils/utils";
import CourseCardCreateTransfer from "./course-card-create-transfer";

export function TransfersComponent({ courseDetails, mode, close }: any) {
    const dispatch = useDispatch()
    const { user } = useSelector((state: RootState) => state.auth);
    const candidateData = courseDetails?.candidate_id;
    const batchDetails = courseDetails?.batch_id;
    const [step, setStep] = useState(1 as any);
    // const [loadingApplication, setLoadingApplication] = useState(false as any);
    const [loadingCheque, setLoadingCheque] = useState(false);
    // const [applicationUrl, setApplicationUrl] = useState("" as any);
    // const [chequeUrl, setChequeUrl] = useState("" as any);
    const [paymentMode, setPaymentMode] = useState("Cash" as any);
    // const [admin_cheque, setAdminCheque] = useState("" as any);
    const [remarks, setRemarks] = useState("" as any);
    // const [account_remarks, setAccountRemarks] = useState("" as any);
    const [allPayments, setAllPayments] = useState([] as any);
    const [oldFeeStructure, setOldFeeStructure] = useState(null as any);
    const [allInstalments, setAllInstalments] = useState([] as any);
    const [oldBalanceDetails, setOldBalanceDetails] = useState({} as any);

    const [paidData, setPaidData] = useState([] as any);
    // const [accountFormData, setAccountFormData] = useState([] as any);
    const [newBatchData, setNewBatchData] = useState(batchDetails as any);
    const [transferCharges, setTransferCharges] = useState(0 as any);
    const [transferChargesDiscount, setTransferChargesDiscount] = useState(0 as any);
    const [transferChargesWaivedOff, setTransferChargesWaivedOff] = useState(false as any);
    const [addedTransferFee, setAddedTransferFee] = useState(0 as any);

    const [courseEnrolmentData, setCourseEnrolmentData] = useState([] as any);
    const [instalmentEntries, setInstalmentEntries] = useState([] as any);
    const [paymentEntries, setPaymentEntries] = useState([] as any);

    useEffect(() => {
        if (courseDetails?._id) {
            dispatch(requestPaymentsByEnrol(courseDetails?._id)).then((val: any) => {
                if (val?.payload?.data?.success) {
                    setAllPayments(val?.payload?.data?.data?.map((data: any, index: number) => {
                        return {
                            ...data
                        }
                    }))
                }
            })
            dispatch(requestInstalmentsByEnrol(courseDetails?._id)).then((val: any) => {
                if (val?.payload?.data?.success) {
                    setAllInstalments(val?.payload?.data?.data?.map((data: any, index: number) => {
                        return {
                            ...data
                        }
                    }))
                }
            })
            dispatch(getFeeStructure({
                session_id: batchDetails?.session_id?._id,
                center_id: batchDetails?.center_id?._id,
                course_id: batchDetails?.course_id?._id,
                batch_type_id: batchDetails?.batch_type_id?._id,
                exam_id: batchDetails?.exam_id?._id,
                stream_id: batchDetails?.stream_id?._id
            })).then((val: any) => {
                if (val?.payload?.data?.data?.length > 0) {
                    setOldFeeStructure(val?.payload?.data?.data[0])
                    setTransferCharges(val?.payload?.data?.data?.[0]?.transfer_fee)
                }
            });
        }
    }, [courseDetails])
    useEffect(() => {
        if (allPayments?.length > 0) {
            console.log(allPayments)
            let admission_fee = 0
            let book_fee = 0
            let tuition_fee = 0
            let ins_charges = 0
            let total_submitted = 0
            allPayments?.forEach((pay: any) => {
                if (pay?.is_book_fee) {
                    book_fee += pay?.instalment_id?.books_amount
                } else {
                    admission_fee += pay?.instalment_id?.admission_amount
                    tuition_fee += pay?.instalment_id?.tuition_amount
                }
                total_submitted += pay?.amount
            })
            setPaidData({
                admission_fee: admission_fee,
                book_fee: book_fee,
                tuition_fee: tuition_fee,
                ins_charges: 0,
                total_submitted: total_submitted,
                admission_deduction_amount: 0,
                book_deduction_amount: 0,
                tuition_deduction_amount: 0,
                ins_charges_deduction_amount: 0,
                total_deduction: 0,
                admission_refund_amount: 0,
                book_refund_amount: 0,
                tuition_refund_amount: 0,
                ins_charges_refund_amount: 0,
                total_refund: 0,
            });
        }

    }, [allPayments])
    useEffect(() => {
        if (allInstalments?.length > 0 && paidData?.total_submitted) {
            let balances = {
                n_instalments: allInstalments?.length,
                paid_instalments: allInstalments?.filter((val: any) => val.realized == true),
                unpaid_instalments: allInstalments?.filter((val: any) => val.realized != true),
                paid_amt: 0,
                balance_amt: 0
            }
            if (balances?.n_instalments == 1) {
                balances.paid_amt = paidData?.total_submitted;
            } else {
                balances.paid_instalments?.map((val: any) => {
                    balances.paid_amt += val?.net_instalment_amount
                })
                balances.unpaid_instalments?.map((val: any) => {
                    balances.balance_amt += val?.net_instalment_amount
                })
            }
            setOldBalanceDetails(balances)
        }
    }, [allInstalments, paidData])

    // useEffect(() => {
    //     // processAmt()
    // }, [amountsData])

    // const beforeUpload = (file: RcFile) => {
    //     const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    //     if (!isJpgOrPng) {
    //         message.error("You can only upload JPG/PNG file!");
    //         setLoadingApplication(false);
    //         setLoadingCheque(false);
    //         return;
    //     }
    //     const isLt2M = file.size / 1024 / 1024 < 2;
    //     if (!isLt2M) {
    //         message.error("Image must smaller than 2MB!");
    //         setLoadingApplication(false);
    //         setLoadingCheque(false);
    //         return;
    //     }
    //     return isJpgOrPng && isLt2M;
    // };

    // const handleChangeApplication = (info: any) => {
    //     if (info.file.status === "uploading") {
    //         getBase64(info.file.originFileObj).then((url) => {
    //             setLoadingApplication(false);
    //             setApplicationUrl(url);
    //         });
    //         return;
    //     }
    //     if (info.file.status === "done") {
    //         // Get this url from response in real world.
    //         getBase64(info.file.originFileObj).then((url) => {
    //             setLoadingApplication(false);
    //             setApplicationUrl(url);
    //         });
    //         // return;
    //     }
    // };

    // const handleChangeCheque = (info: any) => {
    //     if (info.file.status === "uploading") {
    //         getBase64(info.file.originFileObj).then((url) => {
    //             setLoadingCheque(false);
    //             setChequeUrl(url);
    //         });
    //         return;
    //     }
    //     if (info.file.status === "done") {
    //         // Get this url from response in real world.
    //         getBase64(info.file.originFileObj).then((url) => {
    //             setLoadingCheque(false);
    //             setChequeUrl(url);
    //         });
    //     }
    // };




    const payColumns = [
        {
            title: "S. No.",
            dataIndex: "sno",
            key: "sno",
        },
        {
            title: "Payment Mode",
            dataIndex: "payment_mode",
            key: "payment_mode",
        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
        },
        {
            title: "Payment Type",
            dataIndex: "payment_type",
            key: "payment_type",
            className: "capitalize",
        },
        {
            title: "Cheque/DD/Pay Date",
            dataIndex: "actual_payment_date",
            key: "actual_payment_date",
        },
        {
            title: "EDC Charges",
            dataIndex: "edc_charges",
            key: "edc_charges",
        },
        {
            title: "QR Charges",
            dataIndex: "qr_charges",
            key: "qr_charges",
        },
        {
            title: "Total Amount",
            dataIndex: "total_amount",
            key: "total_amount",
        },
        {
            title: "Ins. Charges",
            dataIndex: "instalment_charges",
            key: "instalment_charges",
        },
        {
            title: "In Favor Of",
            dataIndex: "in_favor_of",
            key: "in_favor_of",
        },
        {
            title: "Bank Name",
            dataIndex: "bank_name",
            key: "bank_name",
        },
        {
            title: "Branch Name",
            dataIndex: "branch_name",
            key: "branch_name",
        },
        {
            title: "Trans / DD / Cheque No",
            dataIndex: "ref_no",
            key: "ref_no",
        },
        {
            title: "Action",
            render: (a: any) => {
                return (
                    <>
                        <Tooltip title={"Delete"}>
                            <Button
                                type="link"
                                className="table-button"
                                icon={<DeleteOutlined />}
                                onClick={() => removePaymentEntry(a.sno)}
                            ></Button>
                        </Tooltip>
                    </>
                );
            },
            key: "actions",
        },
    ];
    function removePaymentEntry(sno: any) {
        setPaymentEntries(
            paymentEntries
                .filter((val: any) => {
                    return val.sno != sno;
                })
                .map((val: any, index: number) => {
                    return { ...val, sno: index + 1 };
                })
        );
    }
    function setNewBatchDataAndFee(batchData: any) {
        dispatch(getBatchAndFeeClassroom(batchData?.batch_id)).then((val: any) => {
            if (val?.payload?.data?.success) {
                setNewBatchData(val?.payload?.data?.data)
                setStep(2)
                let batchDetails = val?.payload?.data?.data?.batchDetails;
                let feeDetails = val?.payload?.data?.data?.feeDetails?.[0];
                setCourseEnrolmentData({
                    candidate_id: candidateData._id,
                    batch_id: batchData.batch_id,
                    batch_type_id: batchDetails?.batch_type_id?._id,
                    center_id: batchDetails?.center_id?._id,
                    course_id: batchDetails?.course_id?._id,
                    exam_id: batchDetails?.exam_id?._id,
                    session_id: batchDetails?.session_id?._id,
                    stream_id: batchDetails?.stream_id?._id,
                    payment_type: courseDetails?.payment_type,
                    old_student_discount: 0,
                    handicapped_discount: feeDetails.ph_total_discount,
                    tuition_discount: courseDetails.tuition_discount || 0,
                    admission_fee_discount: courseDetails.admission_fee_discount || 0,
                    books_fee_discount: courseDetails.books_fee_discount || 0,
                    instalment_fee: courseDetails.instalment_fee,
                    instalment_discount: courseDetails?.instalment_discount,
                    n_instalments: 1,
                    admission_fee: feeDetails.general_admission_fee,
                    tution_fee: feeDetails.general_tuition_fee,
                    book_fee: feeDetails.general_books_fee,
                    total_fee: feeDetails?.general_total_fee - courseDetails?.admission_fee_discount - courseDetails?.tuition_discount - courseDetails?.books_fee_discount,
                    created_by: user._id,
                    modified_by: user._id,
                    special_relaxation_data: null
                });

            }
        });
    }
    const [oldTotalFee, setOldTotalFee] = useState(0 as any);
    const [newTotalFee, setNewTotalFee] = useState(0 as any);
    const [feeDiff, setFeeDiff] = useState(0 as any);
    const [amtForm] = useForm();
    useEffect(() => {
        if (newBatchData?.feeDetails && oldBalanceDetails?.paid_amt) {
            let oldTotalFee = oldBalanceDetails?.paid_amt + oldBalanceDetails?.balance_amt;
            let newTotalFee = newBatchData?.feeDetails?.[0]?.general_total_fee - courseDetails?.admission_fee_discount - courseDetails?.tuition_discount - courseDetails?.books_fee_discount;
            setOldTotalFee(oldTotalFee)
            setNewTotalFee(newTotalFee)
            setFeeDiff((newTotalFee - oldTotalFee) > 0 ? (newTotalFee - oldTotalFee) : 0)
        }
    }, [oldBalanceDetails, newBatchData])
    useEffect(() => {
        if (amtForm && step == 2) {
            amtForm.setFieldValue('amount', transferCharges)
        }
    }, [transferCharges, step])
    useEffect(() => {
        let insEntries = [];
        if (transferCharges > 0 && newBatchData?.batchDetails) {
            //add instalment
            insEntries.push({
                candidate_id: candidateData._id,
                instalment_no: 1,
                batch_id: newBatchData?.batchDetails?._id,
                batch_type_id: newBatchData?.batchDetails?.batch_type_id?._id,
                center_id: newBatchData?.batchDetails?.center_id?._id,
                course_id: newBatchData?.batchDetails?.course_id?._id,
                exam_id: newBatchData?.batchDetails?.exam_id?._id,
                session_id: newBatchData?.batchDetails?.session_id?._id,
                stream_id: newBatchData?.batchDetails?.stream_id?._id,
                admission_amount: 0,
                admission_discount: 0,
                tuition_amount: 0,
                tuition_discount: 0,
                books_amount: 0,
                books_discount: 0,
                transfer_charges: transferCharges,
                gross_instalment_amount: transferCharges,
                instalment_discount: 0,
                net_instalment_amount: transferCharges,
                prefered_instalment_date: formatDate(moment()),
                realized: true,
                actual_payment_date: formatDate(moment()),
                created_by: user._id,
                modified_by: user._id,
            })
        }
        if (feeDiff > 0) {
            //add instalment
            insEntries.push({
                candidate_id: candidateData._id,
                instalment_no: insEntries?.length + 1,
                batch_id: newBatchData?.batchDetails?._id,
                batch_type_id: newBatchData?.batchDetails?.batch_type_id?._id,
                center_id: newBatchData?.batchDetails?.center_id?._id,
                course_id: newBatchData?.batchDetails?.course_id?._id,
                exam_id: newBatchData?.batchDetails?.exam_id?._id,
                session_id: newBatchData?.batchDetails?.session_id?._id,
                stream_id: newBatchData?.batchDetails?.stream_id?._id,
                admission_amount: 0,
                admission_discount: 0,
                tuition_amount: feeDiff,
                tuition_discount: 0,
                books_amount: 0,
                books_discount: 0,
                fee_difference_amount: feeDiff,
                gross_instalment_amount: feeDiff,
                instalment_discount: 0,
                net_instalment_amount: feeDiff,
                prefered_instalment_date: formatDate(moment()),
                realized: false,
                actual_payment_date: null,
                created_by: user._id,
                modified_by: user._id,
            })
        }
        setInstalmentEntries(insEntries)
    }, [feeDiff, transferCharges, newBatchData])
    const addPayment = (values: any) => {
        setAddedTransferFee(transferCharges)
        setPaymentEntries([{
            candidate_id: candidateData._id,
            batch_id: newBatchData?.batchDetails?._id,
            batch_type_id: newBatchData?.batchDetails?.batch_type_id?._id,
            center_id: newBatchData?.batchDetails?.center_id?._id,
            course_id: newBatchData?.batchDetails?.course_id?._id,
            exam_id: newBatchData?.batchDetails?.exam_id?._id,
            session_id: newBatchData?.batchDetails?.session_id?._id,
            stream_id: newBatchData?.batchDetails?.stream_id?._id,
            sno: 1,
            payment_mode: paymentMode,
            book_fee: 0,
            tuition_fee: 0,
            adm_fee: 0,
            amount: transferCharges,
            transfer_fee: true,
            payment_type: 'transfer',
            actual_payment_date: formatDate(moment()),
            edc_charges: 0,
            qr_charges: 0,
            total_amount: transferCharges,
            in_favor_of: values?.in_favor_of ? values?.in_favor_of : '',
            bank_name: values?.branch_name ? values?.branch_name : '',
            branch_name: values?.bank_name ? values?.bank_name : '',
            ref_no: values?.ref_no ? values?.ref_no : '',
            is_book_fee: false,
            created_by: user._id,
            modified_by: user._id,
        }])
    }
    console.log("newBatchData", newBatchData)
    console.log("courseDetails", courseDetails)
    console.log("allInstalments", allInstalments)
    console.log("oldBalanceDetails", oldBalanceDetails)
    console.log("oldFeeStruct", oldFeeStructure)
    console.log("courseEnrolmentData", courseEnrolmentData)
    console.log("instalmentEntries", instalmentEntries)
    console.log("paymentEntries", paymentEntries)
    console.log("feeDiff", feeDiff)
    const [submitClicked, setSubmitClicked] = useState(false as any);
    const submitTransfer = () => {
        setSubmitClicked(true)
        const payload = {
            courseEnrolment: { ...courseEnrolmentData, remarks: remarks },
            instalments: instalmentEntries,
            payments: paymentEntries?.map((val: any) => {
                return { ...val, remarks: remarks }
            }),
            transferedFromId: courseDetails?._id
        };
        dispatch(requestCourseEnrolment(payload)).then((val: any) => {
            if (val?.payload?.data?.success) {
                setSubmitClicked(false)
                // window.open(
                //     ROUTE_PRINT_RECEIPT_TRANSFER(val?.payload?.data?.data?.payments[0]._id), "_blank");

                notification.success({ message: "Transfer Success. New Roll No: " + val?.payload?.data?.data?.courseEnrolment?.roll_no });
                close(true)
            }
        });

    }
    return (
        <Row>
            <Col span={24}>
                <Descriptions
                    title="Enrolment Details"
                    className="table-description"
                >
                    <Descriptions.Item label="Registration Number">
                        {candidateData?.registration_number}
                    </Descriptions.Item>
                    <Descriptions.Item label="Roll No">{courseDetails?.roll_no}</Descriptions.Item>
                    <Descriptions.Item label="Candidate Name">
                        {candidateData?.candidate_name}
                    </Descriptions.Item>
                    <Descriptions.Item label="Center">
                        {batchDetails?.center_id?.center_name}
                    </Descriptions.Item>
                    <Descriptions.Item label="Session">
                        {batchDetails?.session_id?.session}
                    </Descriptions.Item>
                    <Descriptions.Item label="Course">
                        {batchDetails?.course_id?.course_name}
                    </Descriptions.Item>
                    <Descriptions.Item label="Batch Type">
                        {batchDetails?.batch_type_id?.batch_type_name}
                    </Descriptions.Item>
                    <Descriptions.Item label="Exam">
                        {batchDetails?.exam_id?.exam_name}
                    </Descriptions.Item>
                    <Descriptions.Item label="Stream">
                        {batchDetails?.stream_id?.stream_name}
                    </Descriptions.Item>
                    <Descriptions.Item label="Batch Details" span={24}>
                        {batchDetails?.batch_code_id?.batch_code}:{" "}
                        {formatDate(batchDetails?.start_date)} -{" "}
                        {formatDate(batchDetails?.end_date)} (
                        {batchDetails?.batch_time_id?.batch_time_from} -{" "}
                        {batchDetails?.batch_time_id?.batch_time_to})
                    </Descriptions.Item>
                </Descriptions>
            </Col>
            <Col span={24} className="mt-3">
                <Descriptions title="Course Fee Details" className="table-description">
                    <Descriptions.Item label="Payment Criteria">
                        {courseDetails?.n_instalments > 0 ? "Instalments" : "Full Payment"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Submitted Amount">
                        {paidData?.total_submitted}
                    </Descriptions.Item>
                    <Descriptions.Item label="Total Fee">
                        {courseDetails?.admission_fee -
                            courseDetails?.admission_fee_discount +
                            courseDetails?.tution_fee - courseDetails?.tuition_discount +
                            courseDetails?.book_fee - courseDetails?.books_fee_discount}
                    </Descriptions.Item>
                    <Descriptions.Item label="Admission Fee">
                        {courseDetails?.admission_fee -
                            courseDetails?.admission_fee_discount}
                    </Descriptions.Item>
                    <Descriptions.Item label="Tution Fee">
                        {courseDetails?.tution_fee - courseDetails?.tuition_discount}
                    </Descriptions.Item>
                    <Descriptions.Item label="Book Fee">
                        {courseDetails?.book_fee - courseDetails?.books_fee_discount}
                    </Descriptions.Item>
                    {/* <Descriptions.Item label="Old Student Discount">
                        {courseDetails?.old_student_discount}
                    </Descriptions.Item>
                    <Descriptions.Item label="Handicapped Discount">
                        {courseDetails?.handicapped_discount}
                    </Descriptions.Item>
                    <Descriptions.Item label="Special Discount">
                        {courseDetails?.tuition_discount}
                    </Descriptions.Item> */}

                    <Descriptions.Item label="Admission Submitted Amount">
                        {paidData?.admission_fee}
                    </Descriptions.Item>
                    <Descriptions.Item label="Tuition Submitted Amount">
                        {paidData?.tuition_fee}
                    </Descriptions.Item>
                    <Descriptions.Item label="Books Submitted Amount">
                        {paidData?.book_fee}
                    </Descriptions.Item>
                    <Descriptions.Item label="Installment Charges">
                        {courseDetails?.instalment_fee - courseDetails?.instalment_discount}
                    </Descriptions.Item>
                </Descriptions>
            </Col>
            {step == 1 && <>
                <Col span={24} className="mt-3">
                    <Descriptions title={`${mode} Transfer Details`} className="table-description">
                    </Descriptions>
                </Col>
                <Col span={24}>
                    <CourseCardCreateTransfer oldBatchId={batchDetails?._id} courseData={newBatchData} setCourseData={setNewBatchDataAndFee} mode={mode} />
                </Col>
            </>}
            <Divider />
            {step == 2 && <>
                <Col span={24}>
                    <Descriptions
                        title="New Enrolment Details"
                        className="table-description"
                    >
                        <Descriptions.Item label="Registration Number">
                            {candidateData?.registration_number}
                        </Descriptions.Item>
                        <Descriptions.Item label="Roll No">XXXXXXXXX</Descriptions.Item>
                        <Descriptions.Item label="Candidate Name">
                            {candidateData?.candidate_name}
                        </Descriptions.Item>
                        <Descriptions.Item label="Center">
                            {newBatchData?.batchDetails?.center_id?.center_name}
                        </Descriptions.Item>
                        <Descriptions.Item label="Session">
                            {newBatchData?.batchDetails?.session_id?.session}
                        </Descriptions.Item>
                        <Descriptions.Item label="Course">
                            {newBatchData?.batchDetails?.course_id?.course_name}
                        </Descriptions.Item>
                        <Descriptions.Item label="Batch Type">
                            {newBatchData?.batchDetails?.batch_type_id?.batch_type_name}
                        </Descriptions.Item>
                        <Descriptions.Item label="Exam">
                            {newBatchData?.batchDetails?.exam_id?.exam_name}
                        </Descriptions.Item>
                        <Descriptions.Item label="Stream">
                            {newBatchData?.batchDetails?.stream_id?.stream_name}
                        </Descriptions.Item>
                        <Descriptions.Item label="Batch Details" span={24}>
                            {newBatchData?.batchDetails?.batch_code_id?.batch_code}:{" "}
                            {formatDate(newBatchData?.batchDetails?.start_date)} -{" "}
                            {formatDate(newBatchData?.batchDetails?.end_date)} (
                            {newBatchData?.batchDetails?.batch_time_id?.batch_time_from} -{" "}
                            {newBatchData?.batchDetails?.batch_time_id?.batch_time_to})
                        </Descriptions.Item>
                    </Descriptions>
                </Col>

                <Col span={24} className="mt-3">
                    <Descriptions title="New Fee Details" className="table-description">
                        <Descriptions.Item label="Payment Criteria">
                            {courseDetails?.n_instalments > 0 ? "Instalments" : "Full Payment."}
                        </Descriptions.Item>
                        <Descriptions.Item label="Submitted Amount">
                            {paidData?.total_submitted}
                        </Descriptions.Item>
                        <Descriptions.Item label="Old Total Fee">
                            {oldTotalFee}
                        </Descriptions.Item>
                        <Descriptions.Item label="New Total Fee">
                            {newTotalFee}
                        </Descriptions.Item>
                        <Descriptions.Item label="Old Balance">
                            {oldBalanceDetails?.balance_amt}
                        </Descriptions.Item>
                        <Descriptions.Item label="Fee Difference">
                            {feeDiff}
                        </Descriptions.Item>
                        <Descriptions.Item label="New Balance">
                            {oldBalanceDetails?.balance_amt} + {(newTotalFee - oldTotalFee)}
                        </Descriptions.Item>
                        <Descriptions.Item label="Transfer Charges">
                            {oldFeeStructure?.transfer_fee}
                        </Descriptions.Item>
                        <Descriptions.Item label="Add Instalment Fee">
                            <Checkbox onChange={(e) => {
                                if (e?.target?.checked) {
                                    let _newTotalFee = newTotalFee + (newBatchData?.feeDetails?.[0]?.installment_charges || 0);
                                    setNewTotalFee(_newTotalFee)
                                    setFeeDiff((_newTotalFee - oldTotalFee) > 0 ? (_newTotalFee - oldTotalFee) : 0)
                                } else {
                                    let oldTotalFee = oldBalanceDetails?.paid_amt + oldBalanceDetails?.balance_amt;
                                    let newTotalFee = newBatchData?.feeDetails?.[0]?.general_total_fee - courseDetails?.admission_fee_discount - courseDetails?.tuition_discount - courseDetails?.books_fee_discount;

                                    setNewTotalFee(newTotalFee)
                                    setFeeDiff((newTotalFee - oldTotalFee) > 0 ? (newTotalFee - oldTotalFee) : 0)
                                }
                            }} />
                        </Descriptions.Item>

                        {/* <Descriptions.Item label="Admission Fee">
                        {courseDetails?.admission_fee -
                            courseDetails?.admission_fee_discount}
                    </Descriptions.Item>
                    <Descriptions.Item label="Old Tution Fee">
                        {courseDetails?.tution_fee - courseDetails?.tuition_discount}
                    </Descriptions.Item>
                    <Descriptions.Item label="Old Book Fee">
                        {courseDetails?.book_fee - courseDetails?.books_fee_discount}
                    </Descriptions.Item> */}
                        {/* <Descriptions.Item label="Old Student Discount">
                        {courseDetails?.old_student_discount}
                    </Descriptions.Item>
                    <Descriptions.Item label="Handicapped Discount">
                        {courseDetails?.handicapped_discount}
                    </Descriptions.Item>
                    <Descriptions.Item label="Special Discount">
                        {courseDetails?.tuition_discount}
                    </Descriptions.Item> */}

                        {/* <Descriptions.Item label="Admission Submitted Amount">
                        {paidData?.admission_fee}
                    </Descriptions.Item>
                    <Descriptions.Item label="Tuition Submitted Amount">
                        {paidData?.tuition_fee}
                    </Descriptions.Item>
                    <Descriptions.Item label="Books Submitted Amount">
                        {paidData?.book_fee}
                    </Descriptions.Item>
                    <Descriptions.Item label="Admission Amount Pending">
                        {paidData?.admission_fee}
                    </Descriptions.Item>
                    <Descriptions.Item label="Tuition Amount Pending">
                        {paidData?.tuition_fee}
                    </Descriptions.Item>
                    <Descriptions.Item label="Books Amount Pending">
                        {paidData?.book_fee}
                    </Descriptions.Item> */}
                    </Descriptions>
                </Col>

                <Col span={24}>
                    <Descriptions
                        title="Special Relaxation"
                        className="table-description"
                        style={{ marginTop: "20px" }}
                    >
                        {transferChargesWaivedOff == false && <Descriptions.Item label="Transfer Charges Discount">
                            <InputNumber value={transferChargesDiscount} onChange={(e) => {
                                if (e > oldFeeStructure?.transfer_fee) {
                                    e = oldFeeStructure?.transfer_fee
                                }
                                if (e < 0) {
                                    e = 0;
                                }
                                setTransferChargesDiscount(e)
                                setTransferCharges(oldFeeStructure?.transfer_fee - e)
                            }} />
                        </Descriptions.Item>}
                        <Descriptions.Item label="Waiveoff Charges">
                            <Checkbox checked={transferChargesWaivedOff} onChange={(e) => {
                                setTransferChargesWaivedOff(e?.target?.checked)
                                setTransferChargesDiscount(oldFeeStructure?.transfer_fee)
                                setTransferCharges(0)
                            }} />
                        </Descriptions.Item>
                        <Descriptions.Item label="Final Transfer Charges">
                            {transferCharges}
                        </Descriptions.Item>
                    </Descriptions>

                </Col>
                {transferCharges > 0 && <><Col span={24}>
                    <Descriptions
                        title="Payment Details"
                        style={{ marginTop: "20px" }}
                    >
                        <Descriptions.Item label="Payment Mode">
                            <Radio.Group
                                value={paymentMode}
                                onChange={(evt) => setPaymentMode(evt.target.value)}
                            >
                                <Radio value={"Cash"}>Cash</Radio>
                                <Radio value={"EDC"}>
                                    EDC
                                </Radio>
                                <Radio value={"DD"}>
                                    DD
                                </Radio>
                                <Radio value={"Cheque"}>
                                    Cheque
                                </Radio>
                                <Radio value={"QR"}>
                                    QR Code
                                </Radio>
                            </Radio.Group>
                        </Descriptions.Item>
                    </Descriptions>

                    <Form onFinish={addPayment} form={amtForm}>
                        {
                            paymentMode === "Cash" ? <Descriptions title="Cash" style={{ marginTop: "20px" }}>
                                <Descriptions.Item>
                                    <Form.Item name="amount" label={"Amount"}>
                                        <Input
                                            placeholder={"Enter Amount"} disabled
                                        ></Input>
                                    </Form.Item>
                                </Descriptions.Item>
                                <Descriptions.Item>
                                    <Button type="primary" htmlType="submit">Add Payment</Button>
                                </Descriptions.Item>
                            </Descriptions>
                                : paymentMode === "QR" ? <Row gutter={20}>
                                    <Col span={8}>
                                        <Form.Item name={"amount"} label={"Amount"}>
                                            <Input placeholder={"Enter Amount"} disabled></Input>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item name={"ref_no"} label={"Transaction Number"}>
                                            <Input></Input>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8} className={"text-right mt-5"}>
                                        <Button type="primary" htmlType="submit">Add Payment</Button>
                                    </Col>
                                </Row>
                                    : paymentMode === "DD" ?
                                        <Row gutter={20}>
                                            <Col span={8}>
                                                <Form.Item name={"amount"} label={"Amount"}>
                                                    <Input placeholder={"Enter Amount"} disabled></Input>
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item name={"date"} label={"DD Date"}>
                                                    <DatePicker style={{ width: "100%" }}></DatePicker>
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item name={"in_favor_of"} label={"In Favor of"}>
                                                    <Input></Input>
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item name={"ref_no"} label={"DD No"}>
                                                    <Input></Input>
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item name={"bank_name"} label={"Bank Name"}>
                                                    <Input></Input>
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item name={"branch_name"} label={"Branch"}>
                                                    <Input></Input>
                                                </Form.Item>
                                            </Col>
                                            <Col span={8} offset={16} className={"text-right mt-5"}>
                                                <Button type="primary" htmlType="submit">Add Payment</Button>
                                            </Col>
                                        </Row>
                                        : paymentMode === "Cheque" ?
                                            <Row gutter={20}>
                                                <Col span={8}>
                                                    <Form.Item name={"amount"} label={"Amount"}>
                                                        <Input placeholder={"Enter Amount"} disabled></Input>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8}>
                                                    <Form.Item name={"date"} label={"Cheque Date "}>
                                                        <DatePicker style={{ width: "100%" }}></DatePicker>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8}>
                                                    <Form.Item name={"in_favor_of"} label={"In Favor of"}>
                                                        <Input></Input>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8}>
                                                    <Form.Item name={"ref_no"} label={"Cheque No"}>
                                                        <Input></Input>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8}>
                                                    <Form.Item name={"bank_name"} label={"Bank Name"}>
                                                        <Input></Input>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8}>
                                                    <Form.Item name={"branch_name"} label={"Branch"}>
                                                        <Input></Input>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8} className={"text-right mt-5"}>
                                                    <Button type="primary" htmlType="submit">Add Payment</Button>
                                                </Col>
                                            </Row>
                                            :
                                            <Row gutter={20}>
                                                <Col span={8}>
                                                    <Form.Item name={"amount"} label={"Amount"}>
                                                        <Input placeholder={"Enter Amount"} disabled></Input>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8}>
                                                    <Form.Item name={"date"} label={"Date "}>
                                                        <DatePicker style={{ width: "100%" }}></DatePicker>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8}>
                                                    <Form.Item name={"in_favor_of"} label={"In Favor of"}>
                                                        <Input></Input>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8}>
                                                    <Form.Item name={"ref_no"} label={"Transaction Number"}>
                                                        <Input></Input>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8}>
                                                    <Form.Item name={"bank_name"} label={"Bank Name"}>
                                                        <Input></Input>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8}>
                                                    <Form.Item name={"branch_name"} label={"Branch"}>
                                                        <Input></Input>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8} className={"text-right mt-5"}>
                                                    <Button type="primary" htmlType="submit">Add Payment</Button>
                                                </Col>
                                            </Row>
                        }
                    </Form>


                    <Table
                        loading={false}
                        dataSource={paymentEntries}
                        columns={payColumns}
                        size="small"
                        pagination={false}
                    />
                    <p style={{ paddingTop: '10px' }}><strong>Transfer Charges: {transferCharges}/{addedTransferFee}</strong></p>
                </Col>
                </>}
                <Col span={24}><Descriptions title="Remarks" style={{ marginTop: "20px" }}>
                    <Descriptions.Item>
                        <TextArea
                            value={remarks}
                            onChange={(evt: any) => setRemarks(evt.target.value)}
                            placeholder={"Enter Fee Remark"}
                        ></TextArea>
                    </Descriptions.Item>
                </Descriptions>

                </Col>
                {submitClicked === false && <Col span={24} className={"text-right mt-10"}>
                    <Space>
                        <Popconfirm disabled={transferCharges != addedTransferFee} title="Are you sure you want to initiate transfer?" onConfirm={submitTransfer}>
                            <Button type="primary">{mode} Transfer</Button>
                        </Popconfirm>
                        <Button type="default" onClick={() => close(false)}>
                            Cancel
                        </Button>
                    </Space>
                </Col>}
            </>}
        </Row>
    );
}
