import {
  CloseCircleOutlined, EditFilled, EyeFilled, FileExcelFilled, FileExcelOutlined,
  PlusOutlined
} from "@ant-design/icons";
import { Button, Col, Drawer, Row, Space, Tooltip } from "antd";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { BiFilterAlt } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMasterDropdownData } from "../../../core/store/reducers/masters.slice";
import { genericWebsiteUpdate, getGenericList, setFilters } from "../../../core/store/reducers/website.slice";
import ContentHeaderComponent from "../../../shared/components/content-header/content-header.component";
import DataTableComponent from "../../../shared/components/data-table/data-table.component";
import GenericFormComponent from "../../../shared/components/generic-form/generic-form.component";
import { API_BASE_URL, TABLE_ROWS_COUNT } from "../../../shared/constants";
import { API_DOWNLOAD_FTD_REGISTRATIONS_BY_ID, API_DOWNLOAD_QUIZ_SUBMISSION_BY_ID } from "../../../shared/routes/website.routes";
import { RootState } from "../../../shared/types/common";
import { showNotification } from "../../../shared/utils/utils";
import QuestionsListComponent from "../questions/questions.component";
import CreateEditQuizComponent from "./create-edit-quiz.component";

const ManageQuizPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // List states
  const { loading, genericList, filters, total } = useSelector(
    (state: RootState) => state.website
  );
  const { screenType } = useSelector((state: RootState) => state.settings);
  const { user } = useSelector((state: RootState) => state.auth);

  // List local state
  const [entityListData, setEntityListData] = useState([] as any);

  // Flag to open create/edit form
  const [createEditOpen, setCreateEditOpen] = useState(false);
  const [quizDetailsOpen, setQuizDetailsOpen] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(null as any);
  const [filterVisible, setFilterVisible] = useState(false);

  // Actions and selections
  const [sorter, setSorter] = useState({ sort_by: "", sort_type: "" });
  const [pagination, setPagination] = useState({ page: 1, limit: TABLE_ROWS_COUNT });

  // Functions and Constants
  const loadList = useCallback(
    (_page: any = 1) => {
      const data = {
        ...pagination,
        ...sorter,
      };
      dispatch(getGenericList({ params: data, body: filters, module: 'quiz' }));
    },
    [filters, dispatch, sorter, pagination]
  );

  const createEntityListner = () => {
    setCreateEditOpen(true);
    setSelectedEntity(null);
  };

  const toggleStatus = (data: any) => {
    dispatch(genericWebsiteUpdate({ payload: { _id: data._id, status: !data.status }, module: 'quiz' })).then((val: any) => {
      if (val?.payload?.data?.success) {
        showNotification("Status Updated Successfully", "success");
        loadList();
      }
    })
  }
  // useEffect Hooks
  useEffect(() => {
    loadList();
  }, [loadList]);

  //filters, loadList
  useEffect(() => {
    genericList?.length >= 0 &&
      setEntityListData(
        genericList.map((obj: any, index: number) => {
          return { key: index, ...obj };
        })
      );
  }, [genericList]);

  const onFilterApply = (values: any) => {
    let arrFilter = [];
    for (let [key, value] of Object.entries(values)) {
      if (value !== "") {
        arrFilter.push({ key: key, value: value });
      }
    }
    let refinedArray = arrFilter.filter((data) => {
      return data.value !== undefined;
    });
    let obj = {} as any;
    refinedArray.forEach((data: any) => {
      obj[data.key] = data.value;
    });
    setPagination({
      page: 1,
      limit: pagination.limit,
    });
    dispatch(setFilters(obj)); //filters
  };

  const onPageChange = (pageNumber: number) => {
    setPagination({
      page: pageNumber,
      limit: pagination.limit,
    });
  };

  const editEntityListner = (entity: any) => {
    setSelectedEntity(entity);
    setCreateEditOpen(true);
  };
  const viewQuestions = (quizData: any) => {
    // const quizId = quizData._id;
    // dispatch(getQuizDetails(quizId)).then
    setSelectedEntity(quizData)
    setQuizDetailsOpen(true)
  }
  const exitViewQuestions = () => {
    // const quizId = quizData._id;
    // dispatch(getQuizDetails(quizId)).then
    setSelectedEntity(null)
    setQuizDetailsOpen(false)
  }
  const columns = [
    {
      title: "Quiz Name",
      dataIndex: "quiz_name",

      key: "quiz_name",
    },
    {
      title: "Quiz Type",
      dataIndex: "quiz_type",

      key: "quiz_type",
    },
    {
      title: "Exam",
      render: (rec: any) => {
        return <>{rec?.website_exam_id?.exam_name} ({rec?.website_sub_exam_id?.sub_exam_name})</>
      },

      key: "website_exam_id",
    },
    {
      title: "Subject",
      dataIndex: ['subject_id', 'name'],
      key: "subject_id",
    },
    {
      title: "Topic",
      dataIndex: ['topic_id', 'name'],
      key: "topic_id",
    },
    {
      title: "Duration",
      dataIndex: "duration",

      key: "duration",
    },
    {
      title: "Questions",
      dataIndex: "questions_count",

      key: "questions_count",
    },
    {
      title: "status",
      dataIndex: "status",

      key: "status",
      render: (a: any) => {
        return a ? "Active" : "Inactive";
      },
    },
    {
      title: "Created Date",
      dataIndex: "created_at",

      key: "created_at",
      render: (a: any) => {
        return a ? moment(a).format("MMM DD, Y LT") : "";
      },
    },
    {
      title: "Updated Date",
      dataIndex: "updated_at",

      key: "updated_at",
      render: (a: any) => {
        return a ? moment(a).format("MMM DD, Y LT") : "";
      },
    },
    {
      title: "Actions",
      width: "160px",
      fixed: "right",
      render: (a: any) => {
        return (
          <>
            <Tooltip title={"View Questions"}>
              <Button
                onClick={() => editEntityListner(a)}
                type="link"
                className="table-button"
                icon={<EditFilled />}
              ></Button>
            </Tooltip>
            <Tooltip title={"View Questions"}>
              <Button
                onClick={() => viewQuestions(a)}
                type="link"
                className="table-button"
                icon={<EyeFilled />}
              ></Button>
            </Tooltip>
            <Tooltip title={"Mark Inactive"}>
              <Button
                onClick={() => toggleStatus(a)}
                type="link"
                className="table-button"
                icon={<CloseCircleOutlined />}
              ></Button>
            </Tooltip>
            <Tooltip title={"Download Quiz Registration CSV"}>
              <Button
                type="link"
                className="table-button"
                icon={<a href={API_BASE_URL + API_DOWNLOAD_FTD_REGISTRATIONS_BY_ID(a?._id)}><FileExcelFilled /></a>}
              ></Button>
            </Tooltip>
            <Tooltip title={"Download Quiz Submission CSV"}>
              <Button
                type="link"
                className="table-button"
                icon={<a href={API_BASE_URL + API_DOWNLOAD_QUIZ_SUBMISSION_BY_ID(a?._id)}><FileExcelOutlined /></a>}
              ></Button>
            </Tooltip>
          </>
        );
      },
      key: "actions",
    },
  ];

  const onChangeHandler = (_pagination: any, _filters: any, _sorter: any) => {
    if (_sorter.order === undefined) {
      setSorter({ sort_by: "", sort_type: "" });
    } else {
      let order = "";
      let columnKey = "";
      if (_sorter.order === "ascend") order = "asc";
      else order = "desc";

      if (_sorter.columnKey === "enabled") {
        columnKey = "status";
      } else if (_sorter.columnKey === "displayName") {
        columnKey = "name";
      } else {
        columnKey = _sorter.columnKey;
      }
      setSorter({ sort_by: columnKey, sort_type: order });
    }
  };
  const [exams, setExams] = useState([] as any);
  const [subExams, setSubExams] = useState([] as any);
  const [subjects, setSubjects] = useState([] as any);
  const [topics, setTopics] = useState([] as any);
  useEffect(() => {
    dispatch(getMasterDropdownData({ schemaKey: "exam-subject", labelKey: "name" })).then((val: any) => {
      if (val?.payload?.data?.success) {
        setSubjects(val?.payload?.data?.data?.map(((val: any) => {
          return { label: val.name, value: val._id }
        })))
      }
    })
  }, [])

  const formProps = {
    name: "banner-filters",
    enableEnterSubmit: true,
    formElements: [
      {
        name: "quiz_name",
        label: "",
        placeholder: "Quiz Name",
        type: "Input",
        colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
      },
      // {
      //   name: "website_exam_id",
      //   label: "",
      //   placeholder: "Search Exam",
      //   type: "Select",
      //   colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
      // },
      {
        name: "subject_id",
        label: "",
        placeholder: "Search Subject",
        type: "Select",
        options: subjects,
        colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
      },
      // {
      //   name: "topic_id",
      //   label: "",
      //   placeholder: "Search Topic",
      //   type: "Select",
      //   colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
      // }
    ],
    initialValues: {

    },
    extraButtons: [
      {
        htmlType: "submit",
        colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 3 },
        loading: false,
        submitButtonText: "Clear",
        type: "clearForm",
      },
      {
        htmlType: "submit",
        colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 3 },
        loading: false,
        submitButtonText: "Apply",
        submitButtonType: "primary",
      },
    ],
    onFinish: onFilterApply,
    onFinishFailed: null,
    onValuesChange: null,
  };

  const createEditSubmit = () => {
    setCreateEditOpen(false);
    loadList();
  };

  const headerButtons = (
    <Space>
      <Button
        type="primary"
        // size="large"
        icon={<PlusOutlined />}
        onClick={createEntityListner}
      >
        <span className="font-size-14">New {!screenType?.isPortrait && "Quiz"}</span>
      </Button>
      <Button
        shape="circle"
        type="primary"
        icon={
          <span role="img" className="anticon">
            <BiFilterAlt />
          </span>
        }
        onClick={() => {
          setFilterVisible(!filterVisible);
        }}
      ></Button>
    </Space>
  );
  return (
    <>
      <div
        className="mx-6 mt-2 bg-primary-white-blueish border-primary-gray-02"
        style={{ borderBottom: "none!important" }}
      >
        <Row>
          <Col span={24}>
            <ContentHeaderComponent
              title="Manage Quiz"
              subtitle=""
              buttons={headerButtons}
            />
          </Col>
        </Row>
        {filterVisible && (
          <Row className="bg-body">
            <Col span={24} className="py-1">
              <div className="px-3 table-filter-form">
                <GenericFormComponent {...formProps} />
              </div>
            </Col>
          </Row>
        )}
      </div>

      <DataTableComponent
        loading={loading}
        dataSource={entityListData}
        columns={columns}
        onChange={onChangeHandler}
        title={"Banners List"}
        defaultPage={0}
        currentPage={pagination.page}
        totalRows={total}
        onPageChange={onPageChange}
        maxWidth="2000px"

      />
      {createEditOpen && (
        <CreateEditQuizComponent
          onClose={() => setCreateEditOpen(false)}
          visible={createEditOpen}
          selectedQuiz={selectedEntity}
          onSubmit={createEditSubmit}
        />
      )}
      {
        quizDetailsOpen &&
        <Drawer
          maskClosable={false}
          title={`${selectedEntity?.quiz_name} - Questions List`}
          className="form-drawer"
          width={screenType.isPortrait ? '90%' : (screenType.isLg || screenType.isXl ? '100%' : '100%')}
          onClose={() => {
            exitViewQuestions()
          }}
          open={quizDetailsOpen}
        >
          <QuestionsListComponent onClose={exitViewQuestions} quizData={selectedEntity} />
        </Drawer>

      }
    </>
  );
};
export default ManageQuizPage;
