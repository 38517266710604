import {
  BankAddEditForm,
  BatchCodeAddEditForm,
  BatchTimingsAddEditForm,
  BatchTypeAddEditForm,
  CenterAddEditForm,
  CenterCityAddEditForm,
  CompanyAddEditForm,
  CourseAddEditForm,
  ExamAddEditForm, ExamSubjectAddEditForm, ExamSubjectRelationshipAddEditForm, SessionAddEditForm,
  StreamAddEditForm,
  SubjectTopicAddEditForm,
  VenueAddEditForm
} from "./master-add-edit-form-data";
import {
  BankFilterForm,
  BatchCodeFilterForm,
  BatchFilterForm,
  BatchTimingFilterForm,
  BatchTypeFilterForm,
  CenterCityFilterForm,
  CenterFilterForm,
  CompanyFilterForm,
  CourseFilterForm,
  ExamFilterForm, SessionFilterForm,
  StreamFilterForm,
  VenueFilterForm
} from "./master-filter-forms";
import {
  BankSchema,
  BatchCodeSchema,
  BatchSchema,
  BatchTimingSchema,
  BatchTypeRelationshipSchema,
  BatchTypeSchema,
  CenterCitySchema,
  CenterSchema,
  CompanySchema,
  CourseRelationshipSchema,
  CourseSchema,
  ExamSchema,
  ExamSubjectRelationshipSchema,
  ExamSubjectSchema,
  ExamTypeRelationshipSchema, FeeSchema, SessionSchema,
  StreamRelationshipSchema,
  StreamSchema,
  SubjectTopicSchema,
  VenueRelationshipSchema,
  VenueSchema
} from "./master.schemas";

export const masterModules = [
  {
    key: "center-city",
    actions: ["reorder", "status_change", "add", "edit", "filter"],
    label: "Center City",
    schema: CenterCitySchema,
    filterForm: CenterCityFilterForm,
    addEditForm: CenterCityAddEditForm,
    tableWidth: "1000px",
  },
  {
    key: "center",
    actions: ["reorder", "status_change", "add", "edit", "filter"],
    label: "Centers",
    allowStatusChange: true,
    schema: CenterSchema,
    filterForm: CenterFilterForm,
    addEditForm: CenterAddEditForm,
    tableWidth: "2500px",
  },
  {
    key: "course",
    actions: ["reorder", "status_change", "add", "edit", "filter"],
    label: "Courses",
    schema: CourseSchema,
    filterForm: CourseFilterForm,
    addEditForm: CourseAddEditForm,
    tableWidth: "1000px",
  },
  {
    key: "batch-type",
    actions: ["reorder", "status_change", "add", "edit", "filter"],
    label: "Batch Types",
    schema: BatchTypeSchema,
    filterForm: BatchTypeFilterForm,
    addEditForm: BatchTypeAddEditForm,
    tableWidth: "1000px",
  },
  {
    key: "exam",
    actions: ["reorder", "status_change", "add", "edit", "filter"],
    label: "Exam",
    schema: ExamSchema,
    filterForm: ExamFilterForm,
    addEditForm: ExamAddEditForm,
    tableWidth: "1000px",
  },
  {
    key: "stream",
    actions: ["reorder", "status_change", "add", "edit", "filter"],
    label: "Stream",
    schema: StreamSchema,
    filterForm: StreamFilterForm,
    addEditForm: StreamAddEditForm,
    tableWidth: "1000px",
  },
  {
    key: "session",
    actions: ["reorder", "status_change", "add", "edit", "filter"],
    label: "Session",
    schema: SessionSchema,
    filterForm: SessionFilterForm,
    addEditForm: SessionAddEditForm,
    tableWidth: "2000px",
  },
  {
    key: "batch-code",
    actions: ["reorder", "status_change", "add", "edit", "filter"],
    label: "Batch Code",
    schema: BatchCodeSchema,
    filterForm: BatchCodeFilterForm,
    addEditForm: BatchCodeAddEditForm,
    tableWidth: "1000px",
  },
  {
    key: "batch-timings",
    actions: ["reorder", "status_change", "add", "edit", "filter"],
    label: "Batch Timings",
    schema: BatchTimingSchema,
    filterForm: BatchTimingFilterForm,
    addEditForm: BatchTimingsAddEditForm,
    tableWidth: "1000px",
  },
  {
    key: "venue",
    actions: ["reorder", "status_change", "add", "edit", "filter"],
    label: "Venue",
    schema: VenueSchema,
    filterForm: VenueFilterForm,
    addEditForm: VenueAddEditForm,
    tableWidth: "1000px",
  },
  {
    key: "company",
    actions: ["reorder", "status_change", "add", "edit", "filter"],
    label: "Company",
    schema: CompanySchema,
    filterForm: CompanyFilterForm,
    addEditForm: CompanyAddEditForm,
    tableWidth: "1000px",
  },
  {
    key: "bank",
    actions: ["reorder", "status_change", "add", "edit", "filter"],
    label: "Bank",
    schema: BankSchema,
    filterForm: BankFilterForm,
    addEditForm: BankAddEditForm,
    tableWidth: "1000px",
  },
  // {
  //   key: "bank-branch",
  //   actions: ["reorder", "status_change", "add", "edit", "filter"],
  //   label: "Bank Branch",
  //   schema: BankBranchSchema,
  //   filterForm: BankBranchFilterForm,
  //   addEditForm: BankBranchAddEditForm,
  //   tableWidth: "1000px",
  // },
  {
    key: "course-relationship",
    label: "Course Relationships",
    schema: CourseRelationshipSchema,
  },
  {
    key: "batch-type-relationship",
    label: "Batch Type Relationships",
    schema: BatchTypeRelationshipSchema,
  },
  {
    key: "exam-type-relationship",
    label: "Exam Type Relationships",
    schema: ExamTypeRelationshipSchema,
  },
  {
    key: "stream-relationship",
    label: "Stream Relationships",
    schema: StreamRelationshipSchema,
  },
  {
    key: "venue-relationship",
    label: "Venue Relationships",
    schema: VenueRelationshipSchema,
  },
  {
    key: "batch",
    label: "Batches",
    schema: BatchSchema,
    filterForm: BatchFilterForm,
    tableWidth: "1800px",
  },
  {
    key: "fee",
    label: "Fee",
    schema: FeeSchema,
    tableWidth: "1200px",
  },
  {
    key: "exam-subject",
    label: "Exam Subject",
    schema: ExamSubjectSchema,
    tableWidth: "1200px",
    addEditForm: ExamSubjectAddEditForm,
  },
  {
    key: "subject-topic",
    label: "Subject Topics",
    schema: SubjectTopicSchema,
    tableWidth: "1200px",
    addEditForm: SubjectTopicAddEditForm,
  },
  {
    key: "exam-subject-relationship",
    label: "Exam Subject Relationship",
    schema: ExamSubjectRelationshipSchema,
    tableWidth: "1200px",
    addEditForm: ExamSubjectRelationshipAddEditForm,
  },
  // {
  //   key: "receipt-static-data",
  //   actions: ["reorder", "status_change", "add", "edit", "filter"],
  //   label: "Receipt Static Data",
  //   schema: ReceiptStaticDataSchema,
  //   filterForm: ReceiptStaticDataFilterForm,
  //   addEditForm: ReceiptStaticDataAddEditForm,
  //   tableWidth: "1000px",
  // },
  // {
  //   key: "bank-deposit",
  //   actions: ["reorder", "status_change", "add", "edit", "filter"],
  //   label: "Bank Deposit",
  //   schema: BankDepositSchema,
  //   filterForm: BankDepositDataFilterForm,
  //   addEditForm: BankDepositDataAddEditForm,
  //   tableWidth: "1000px",
  // },
  // {
  //   key: "i-card-static-data",
  //   actions: ["reorder", "status_change", "add", "edit", "filter"],
  //   label: "I Card Static Data",
  //   schema: ICardStaticDataSchema,
  //   filterForm: ICardStaticDataFilterForm,
  //   addEditForm: ICardStaticDataAddEditForm,
  // },
];

export const masterModulesKeys = masterModules.map((module: any) => {
  return module.key;
});
