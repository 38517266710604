import moment from "moment";

const CommonSchema: any = (schema_name: string) => [
  {
    title: "System ID",
    dataIndex: "_id",
    key: "_id",
  },
  {
    title: "URL",
    dataIndex: "seo_url",
    sorter: true,
    key: "seo_url",
  },
  {
    title: "Name",
    dataIndex: schema_name,
    sorter: true,
    key: schema_name,
  },
  ...(schema_name == "content_sub_category_name"
    ? [
        {
          title: "Content Category",
          dataIndex: ["content_category_id", "content_category_name"],
          sorter: true,
          key: "content_category_id",
        },
      ]
    : schema_name == "content_category_name"
    ? [
        {
          title: "Type",
          dataIndex: "display_position",
          sorter: true,
          key: "display_position",
        },
      ]
    : schema_name == "course_name"
    ? [
        {
          title: "Published",
          dataIndex: "published",
          sorter: true,
          key: "published",
          render: (a: any) => {
            return a ? "Published" : "Not Published";
          },
        },
      ]
    : schema_name == "content_item_title"
    ? [
        {
          title: "Content Category",
          dataIndex: ["content_category_id", "content_category_name"],
          sorter: true,
          key: "content_category_id",
        },
        {
          title: "Content Sub Category",
          dataIndex: ["content_sub_category_id", "content_sub_category_name"],
          sorter: true,
          key: "content_sub_category_id",
        },
      ]
    : []),

  {
    title: "Order",
    dataIndex: "order",
    sorter: true,
    key: "order",
  },
  {
    title: "status",
    dataIndex: "status",
    sorter: true,
    key: "status",
    render: (a: any) => {
      return a ? "Active" : "Inactive";
    },
  },
  {
    title: "Created Date",
    dataIndex: "created_at",
    sorter: true,
    key: "created_at",
    render: (a: any) => {
      return a ? moment(a).format("MMM DD, Y LT") : "";
    },
  },
  {
    title: "Updated Date",
    dataIndex: "updated_at",
    sorter: true,
    key: "updated_at",
    render: (a: any) => {
      return a ? moment(a).format("MMM DD, Y LT") : "";
    },
  },
];
const CommonFilterForm: any = (schema_name: string, schema_label: string) => [
  {
    name: schema_name,
    label: "",
    placeholder: schema_label + " Name",
    type: "Input",
    colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
  },
];

export const allModules = [
  {
    key: "course-category",
    name_key: "course_category_name",
    label: "Course Category",
    schema: CommonSchema("course_category_name"),
    filterForm: CommonFilterForm("course_category", "Course Category"),
    tableWidth: "1000px",
  },
  {
    key: "course",
    name_key: "course_name",
    label: "Courses",
    schema: CommonSchema("course_name"),
    filterForm: CommonFilterForm("course_name", "Course"),
    tableWidth: "1000px",
  },
  {
    key: "test-series",
    name_key: "test_series_name",
    label: "Test Series",
    schema: CommonSchema("test_series_name"),
    filterForm: CommonFilterForm("test_series", "Test Series"),
    tableWidth: "1000px",
  },
  {
    key: "exam-category",
    name_key: "exam_category_name",
    label: "Exam Category",
    schema: CommonSchema("exam_category_name"),
    filterForm: CommonFilterForm("exam_category_name", "Exam Category"),
    tableWidth: "1000px",
  },
  {
    key: "exam",
    name_key: "exam_name",
    label: "Exams",
    schema: CommonSchema("exam_name"),
    filterForm: CommonFilterForm("exam_name", "Exams"),
    tableWidth: "1000px",
  },
  {
    key: "sub-exam",
    name_key: "sub_exam_name",
    label: "Sub Exams",
    schema: CommonSchema("sub_exam_name"),
    filterForm: CommonFilterForm("sub_exam_name", "Sub Exam"),
    tableWidth: "1000px",
  },
  {
    key: "content-category",
    name_key: "content_category_name",
    label: "Content Category",
    schema: CommonSchema("content_category_name"),
    filterForm: CommonFilterForm("content_category_name", "Content Category"),
    tableWidth: "1000px",
  },
  {
    key: "content-sub-category",
    name_key: "content_sub_category_name",
    label: "Content Sub Category",
    schema: CommonSchema("content_sub_category_name"),
    filterForm: CommonFilterForm(
      "content_sub_category",
      "Content Sub Category"
    ),
    tableWidth: "1000px",
  },
  {
    key: "content-item",
    name_key: "content_item_title",
    label: "Content Item",
    schema: CommonSchema("content_item_title"),
    filterForm: CommonFilterForm("content_item_title", "Content Item"),
    tableWidth: "1000px",
  },
  {
    key: "centers",
    name_key: "center_name",
    label: "Center",
    schema: CommonSchema("center_name"),
    filterForm: CommonFilterForm("center_name", "Center"),
    tableWidth: "1000px",
  },
];

export const modulesKeys = allModules.map((module: any) => {
  return module.key;
});
