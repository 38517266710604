import { PictureFilled } from "@ant-design/icons";
import { Form, message, Upload } from "antd";
import type { UploadFile } from "antd/es/upload/interface";
import { RcFile } from "antd/lib/upload";
import { useState } from "react";
import { getBase64 } from "../../utils/utils";

const Base64ImageUpload = ({ name, maxSize = 300 }: any) => {
  const form = Form.useFormInstance();
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');


    }
    const isLt2M = maxSize;
    if (!isLt2M) {
      message.error('Image must smaller than 150 KB!');


    }
    if (isJpgOrPng && isLt2M) {
      return true
    }
    return Upload.LIST_IGNORE;
  };



  const handleChangePhoto = ({ file: info, fileList: newFileList }: any) => {

    if (info.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj).then((url) => {
        form.setFieldsValue({ [name]: url });
      })
      // return;
    }

    setFileList(newFileList);
  };





  const uploadButton = (
    <>
      <div className="image-area">
        <PictureFilled className="font-size-24" />
      </div>
      <div className="text font-size-12">Asset Image</div>
    </>
  );

  return (
    <>
      <Upload
        accept=".png,.jpeg"
        name={name}
        listType="picture-card"
        fileList={fileList}
        showUploadList={{ showPreviewIcon: false }}
        beforeUpload={(file: any) => beforeUpload(file)}
        onChange={(file: any) => handleChangePhoto(file)}
      >
        {fileList.length < 1 && uploadButton}
      </Upload>
    </>
  );
};

export default Base64ImageUpload;
