import { Drawer, Spin } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Client } from "../../core/api-client";
import {
  createMasterData,
  getCourseListByCenter, getMasterDropdownData,
  getTopicBySubject,
  updateMasterData
} from "../../core/store/reducers/masters.slice";
import { getAllExamsAndCategories } from "../../core/store/reducers/website.slice";
import GenericFormComponent from "../../shared/components/generic-form/generic-form.component";
import { API_GET_MASTER_DROPDOWN } from "../../shared/routes/masters.routes";
import { RootState } from "../../shared/types/common";
import { showNotification } from "../../shared/utils/utils";

interface Props {
  onClose: () => void;
  visible: boolean;
  selectedModuleData?: any;
  moduleData: any;
  onSubmit?: () => void;
}
const CreateEditMaster = ({
  onClose,
  visible,
  selectedModuleData = null,
  moduleData,
  onSubmit = () => {
    return;
  },
}: Props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth);
  const { screenType } = useSelector((state: RootState) => state.settings);
  const [loader, setLoader] = useState(true as any);
  const [addEditFormData, setAddEditFormData] = useState([] as any);
  const [formProps, setFormProps] = useState({} as any);
  const [examList, setExamList] = useState([] as any)
  const [subjectList, setSubjectList] = useState([] as any)
  const [examCategories, setExamCategories] = useState([] as any);
  const [exams, setExams] = useState([] as any);
  const [topics, setTopics] = useState([] as any);

  useEffect(() => {
    if (moduleData?.key === "exam" || moduleData?.key === "exam-subject-relationship") {
      examCategories?.length == 0 && dispatch(getAllExamsAndCategories()).then((val: any) => {
        if (val?.payload?.data?.success) {
          setExamCategories(val?.payload?.data?.data?.map((val: any) => {
            return { name: val.exam_category_name, value: val._id, items: val?.items }
          }))
          setData("exam")
        }
      })
    }

    (async () => {
      await dispatch(getMasterDropdownData({ schemaKey: "exam", labelKey: "exam_name" })).then((val: any) => {
        if (val?.payload?.data?.success) {
          setExamList(val?.payload?.data?.data?.map((val: any) => {
            return { name: val.exam_name, value: val._id }
          }))

        }
      })

      await dispatch(getMasterDropdownData({ schemaKey: "exam-subject", labelKey: "name" })).then((val: any) => {
        if (val?.payload?.data?.success) {
          setSubjectList(val?.payload?.data?.data?.map((val: any) => {
            return { name: val.name, value: val._id }
          }))

        }
      })
    }
    )();
  }, [])

  console.log(exams, "examCategoried")

  const getExamsData = (values: any) => {
    let exams = [] as any
    examCategories.filter((val: any) => values?.indexOf(val.value) !== -1).map((val: any) => {
      val.items.map((_val: any) => {
        exams.push({ name: _val.exam_name, value: _val._id })
      })

    })
    setExams(exams)
  }


  const getExamsTopics = (values: any) => {

    dispatch(getTopicBySubject(values)).then((val: any) => {
      if (val?.payload?.data?.success) {
        setTopics(val?.payload?.data?.data?.map((val: any) => {
          return { name: val.name, value: val._id }
        }))
      }
    })
  }

  useEffect(() => {
    if (moduleData?.key === "exam") {
      setData("exam")
    }
    if (moduleData?.key === "subject-topic") {
      setData("subject-topic")
    }
    if (moduleData?.key === "exam-subject-relationship") {
      setData("exam-subject-relationship")
    }

  }, [examList, subjectList, examCategories, exams, topics])

  const setData = (key: any) => {
    if (key === "exam") {
      setAddEditFormData([
        ...moduleData?.addEditForm,
        {
          name: "exam_categories",
          label: "Exam Category",
          placeholder: "Exam Category",
          type: "Select",
          mode: "multiple",
          options: examCategories, colSpan: 12,
          onChange: (key: any) => getExamsData(key)
        },

        {
          name: "exams",
          label: "Exams",
          placeholder: "Exams",
          type: "Select",
          mode: "multiple",
          options: exams, colSpan: 12,
        },
      ])
    }
    if (key === "subject-topic") {
      setAddEditFormData([
        //   {
        //   name: "exam_id",
        //   label: "Exam Name",
        //   placeholder: "Exam Name",
        //   type: "Select",
        //   options: examList,
        //   onChange: (key: any) => { console.log(key) }
        // },
        {
          name: "subject_id",
          label: "Subject Name",
          placeholder: "Subject Name",
          type: "Select",
          options: subjectList,
        },
        {
          name: "name",
          label: "Topic Name",
          placeholder: "Topic Name",
          type: "Input",
        },
        {
          name: "description",
          label: "Description",
          placeholder: "Description",
          type: "Input",
        },])
      setLoader(false)
    }

    if (key === "exam-subject-relationship") {
      setAddEditFormData([{
        name: "exam_category_id",
        label: "Exam Category",
        placeholder: "Exam Category",
        type: "Select",
        // mode: "multiple",
        options: examCategories, colSpan: 12,

      }, {
        name: "subject_id",
        label: "Subject Name",
        placeholder: "Subject Name",
        type: "Select",
        options: subjectList,
        colSpan: 12,
        onChange: (key: any) => getExamsTopics(key)
      }, {
        name: "topic_ids",
        label: "Topics",
        placeholder: "Topic",
        type: "Select",
        mode: "multiple",
        options: topics, colSpan: 12,
      },])
      setLoader(false)
    }
  }



  if (typeof selectedModuleData?.center_city_ref === 'object') {
    selectedModuleData.center_city_ref = selectedModuleData.center_city_ref._id
  }
  var colSpan = screenType.isPortrait ? 24 : 12;
  const onFinish = (values: any) => {
    let payload = {
      ...values,
      modified_by: user._id,
    };
    if (payload?.center_city_ref) {
      payload.city_id = payload.center_city_ref;
    }
    if (payload?.batch_time_from) {
      payload.batch_time_from = moment(payload.batch_time_from).format("hh:mm A")
    }
    if (payload?.batch_time_to) {
      payload.batch_time_to = moment(payload.batch_time_to).format("hh:mm A")
    }
    if (selectedModuleData === null) {
      payload.created_by = user._id;
      dispatch(createMasterData({ path: moduleData.key, body: payload })).then(
        (val: any) => {
          if (val?.payload?.data) {
            showNotification("New Record Created", "success");
            onSubmit();
          }
        }
      );
    } else {
      payload.updated_by = user._id;
      payload.modified_by = user._id;
      payload = { ...selectedModuleData, ...payload };
      dispatch(updateMasterData({ path: moduleData.key, body: payload })).then(
        (val: any) => {
          if (val?.payload?.data) {
            showNotification("Record Updated", "success");
            onSubmit();
          }
        }
      );
    }
  };

  const onFinishFailed = (_errorInfo: any) => {
    return;
  };

  const onValuesChange = (value: any, _allValues: any) => {
    if (value.centre) {
      dispatch(getCourseListByCenter({ center_id: value.centre })).then(
        (val: any) => {
          if (val?.payload?.data?.data?.length > 0) {
          }
        }
      );
    }
  };
  useEffect(() => {
    if (moduleData?.addEditForm?.length > 0 && (moduleData?.key !== "subject-topic" || moduleData?.key !== "exam-subject-relationship")) {
      prepareForm(moduleData.addEditForm);
    }
  }, [moduleData]);




  const prepareForm = async (formData: any) => {
    for (let k = 0; k < formData.length; ++k) {
      formData[k].colSpan = formData[k].colSpan ? formData[k].colSpan : colSpan;
      if (formData[k].api === true) {
        const ddData = await Client.get(
          API_GET_MASTER_DROPDOWN(formData[k].schemaKey),
          { params: { key: formData[k].labelKey } }
        );
        if (ddData?.data?.success) {
          formData[k].options = ddData.data.data.map((val: any) => {
            return {
              value: val[formData[k].valKey],
              label: (
                <span className="capitalize">{val[formData[k].labelKey]}</span>
              ),
            };
          });
        }
      }
    }
    setAddEditFormData(formData);
    setLoader(false)
  };

  useEffect(() => {
    if (selectedModuleData?.subject_id?._id && moduleData?.key === "exam-subject-relationship") {
      getExamsTopics(selectedModuleData?.subject_id?._id)
    }
  }, [selectedModuleData])

  useEffect(() => {

    if (typeof selectedModuleData?.batch_time_from == 'string') {
      selectedModuleData.batch_time_from = moment("2023-01-01 " + selectedModuleData.batch_time_from)
    }
    if (typeof selectedModuleData?.batch_time_to == 'string') {
      selectedModuleData.batch_time_to = moment("2023-01-01 " + selectedModuleData.batch_time_to)
    }

    if (addEditFormData?.length > 0) {
      setFormProps({
        name: "creat-edit-" + moduleData.key,
        heading: (selectedModuleData ? "Edit " : "Add New ") + moduleData.label,
        initialValues: {
          ...selectedModuleData,
          ...(selectedModuleData?.exam_category_id?._id ? { exam_category_id: selectedModuleData?.exam_category_id?._id } : {}),
          ...(selectedModuleData?.subject_id?._id ? { subject_id: selectedModuleData?.subject_id?._id } : {}),
        },
        formElements: addEditFormData,
        extraButtons: [
          {
            // htmlType: "button",
            colSpan: 5,
            loading: false,
            submitButtonText: "Cancel",
            onClick: onClose,
          },
          {
            // htmlType: "button",
            colSpan: 5,
            loading: false,
            submitButtonText: "Clear",
            type: "clearForm",
          },
          {
            htmlType: "submit",
            colSpan: 5,
            loading: false,
            submitButtonText: "Save",
            submitButtonType: "primary",
          },
        ],
        onFinish: onFinish,
        onFinishFailed: onFinishFailed,
        onValuesChange: onValuesChange,
      });
    }
  }, [addEditFormData]);

  console.log(addEditFormData);
  return (
    <>
      {formProps?.heading && (
        <Drawer
          maskClosable={false}
          title={<strong>{formProps.heading}</strong>}
          className="form-drawer"
          width={
            screenType.isPortrait
              ? "90%"
              : screenType.isLg || screenType.isXl
                ? 720
                : 500
          }
          onClose={onClose}
          open={visible}
        >
          {
            loader ? <Spin></Spin> : <GenericFormComponent {...formProps} />
          }
        </Drawer>
      )}
    </>
  );
};

export default CreateEditMaster;
