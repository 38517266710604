import { MenuOutlined, UserOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import React from "react";
import { useNavigate } from "react-router-dom";
// import icons
import { BiRupee } from "react-icons/bi";
import { CgWebsite } from "react-icons/cg";
import {
  RiDashboardLine,
  RiDatabase2Line, RiUserAddLine,
  RiUserReceivedLine
} from "react-icons/ri";

import { useSelector } from "react-redux";
import "../../../assets/styles/sidebar.less";
import {
  ROUTE_BANK_DEPOSIT, ROUTE_DAILY_EXPENSES,
  ROUTE_EDUCATION_DEBIT_TALLY,
  ROUTE_EDUCATION_INCOME_TALLY,
  ROUTE_EDUCATION_INCOME_TALLY_ONLINE,
  ROUTE_FEE_CLEARANCE,
  ROUTE_FEE_CLEARANCE_ONLINE,
  ROUTE_PUBLICATION_DEBIT_TALLY,
  ROUTE_PUBLICATION_INCOME_TALLY
} from "../../routes/accounts.routes";
import {
  ROUTE_ADMISSION_STATUS_REPORT,
  ROUTE_CANDIDATES_LIST, ROUTE_ENROLMENTS_ALL_DETAILS, ROUTE_ENROLMENTS_LIST,
  ROUTE_FEE_CLOSING_APPROVAL,
  ROUTE_FEE_CLOSING_APPROVAL_ONLINE,
  ROUTE_INSTALMENTS_LIST, ROUTE_ONLINE_ENROLMENTS_LIST, ROUTE_PAYMENTS_LIST,
  ROUTE_REFUNDS_LIST
} from "../../routes/admission.routes";
import { ROUTE_DASHBOARD } from "../../routes/dashboard.routes";
import {
  ROUTE_ENQUIRY_DASHBOARD,
  ROUTE_ENQUIRY_LIST
} from "../../routes/lms.routes";
import { ROUTE_MASTER_LIST } from "../../routes/masters.routes";
import {
  ROUTE_MANAGE_USERS,
  ROUTE_MANAGE_USER_GROUPS
} from "../../routes/user-management.routes";
import {
  ROUTE_MANAGE_CLASS,
  ROUTE_MANAGE_COURSE_EXAMS, ROUTE_MANAGE_FILES,
  ROUTE_MANAGE_GALLERY, ROUTE_MANAGE_HOME_BANNERS,
  ROUTE_MANAGE_PRODUCTS,
  ROUTE_MANAGE_QUIZ,
  ROUTE_MANAGE_TAGS, ROUTE_MANAGE_TESTIMONIALS, ROUTE_MANAGE_TOPIC
} from "../../routes/website.routes";
import { RootState } from "../../types/common";

const MENU_ITEMS = [
  {
    label: "Dashboard",
    key: "dashboard",
    route: ROUTE_DASHBOARD,
    icon: (
      <span role="img" className="anticon">
        <RiDashboardLine />
      </span>
    ),
    children: [],
  },
  {
    label: "Users",
    key: "users",
    icon: <UserOutlined />,
    route: "",
    permission: [],
    children: [
      {
        label: "Manage Users",
        key: "manage-users",
        route: ROUTE_MANAGE_USERS,
        // icon: <IconComponent icon={InactiveIcon} />,
      },
      {
        label: "Roles",
        key: "roles",
        route: 'roles',
        // icon: <IconComponent icon={InactiveIcon} />,
      },
      {
        label: "Profiles",
        key: "profiles",
        route: ROUTE_MANAGE_USER_GROUPS,
        // icon: <IconComponent icon={InactiveIcon} />,
      },
    ],
  },
  {
    label: "Enquiry",
    key: "enquiry",
    icon: (
      <span role="img" className="anticon">
        <RiUserReceivedLine />
      </span>
    ),
    permission: ['counsellor', 'center-head', 'lms-admin', 'marketing'],
    // route: "",
    children: [
      {
        label: "Dashboard",
        key: "enq-dashboard",
        route: ROUTE_ENQUIRY_DASHBOARD,
        permission: ['counsellor', 'center-head', 'lms-admin', 'marketing'],
      },
      {
        label: "All Enquiries",
        key: "all-enquiries",
        route: ROUTE_ENQUIRY_LIST,
        permission: ['counsellor', 'center-head', 'lms-admin', 'marketing'],
      },
    ],
  },
  {
    label: "Masters",
    key: "masters",
    route: ROUTE_MASTER_LIST,
    icon: (
      <span role="img" className="anticon">
        <RiDatabase2Line />
      </span>
    ),
    permission: [],
    children: [],
  },
  {
    label: "Admissions",
    key: "admissions",
    route: 'admissions',
    icon: (
      <span role="img" className="anticon">
        <RiUserAddLine />
      </span>
    ),
    permission: ['counsellor', 'accounts', 'center-head'],
    children: [
      {
        label: "Manage Candidates",
        key: "manage-candidates",
        route: ROUTE_CANDIDATES_LIST,
        permission: ['counsellor', 'center-head', 'lms-admin'],
        // icon: <IconComponent icon={InactiveIcon} />,
      },
      // {
      //   label: "Bulk Import Candidates",
      //   key: "import-candidates",
      //   route: ROUTE_CANDIDATES_IMPORT,
      //   permission: ['admin'],
      //   // icon: <IconComponent icon={InactiveIcon} />,
      // },
      {
        label: "Manage Course Enrolments",
        key: "manage-enrolment",
        route: ROUTE_ENROLMENTS_LIST,
        permission: ['counsellor', 'center-head', 'lms-admin'],
        // icon: <IconComponent icon={InactiveIcon} />,
      },
      {
        label: "Manage Course Instalments",
        key: "manage-instaments",
        route: ROUTE_INSTALMENTS_LIST,
        permission: ['counsellor', 'center-head', 'lms-admin'],
        // icon: <IconComponent icon={InactiveIcon} />,
      },
      {
        label: "Manage Course Payments",
        key: "manage-payments",
        route: ROUTE_PAYMENTS_LIST,
        permission: ['counsellor', 'center-head', 'lms-admin'],
        // icon: <IconComponent icon={InactiveIcon} />,
      },
      {
        label: "Other Course Payments",
        key: "other-payments",
        route: ROUTE_ONLINE_ENROLMENTS_LIST,
        permission: ['counsellor'],
        // icon: <IconComponent icon={InactiveIcon} />,
      },
      // {
      //   label: "Bulk Allot Online Package",
      //   key: "allot-package",
      //   route: ROUTE_BULK_ALLOT_PACKAGE,
      //   permission: ['admin'],
      //   // icon: <IconComponent icon={InactiveIcon} />,
      // },
      {
        label: "Fee Closing Approval",
        key: "fee-closing-approval",
        route: ROUTE_FEE_CLOSING_APPROVAL,
        permission: ['accounts', 'counsellor'],
        // icon: <IconComponent icon={InactiveIcon} />,
      },
      {
        label: "Fee Closing Approval (Online)",
        key: "fee-closing-approval",
        route: ROUTE_FEE_CLOSING_APPROVAL_ONLINE,
        permission: ['accounts', 'counsellor'],
      },
      {
        label: "Admission Status Report",
        key: "adm-status-report",
        route: ROUTE_ADMISSION_STATUS_REPORT,
        permission: ['counsellor', 'accounts', 'center-head', 'lms-admin'],
        // icon: <IconComponent icon={InactiveIcon} />,
      },
      {
        label: "All Details (Classroom)",
        key: "all-details-c",
        route: ROUTE_ENROLMENTS_ALL_DETAILS,
        permission: ['center-head', 'lms-admin', 'counsellor'],
        // icon: <IconComponent icon={InactiveIcon} />,
      },
    ],
  },
  {
    label: "Accounts",
    key: "accounts",
    route: 'accounts',
    icon: (
      <span role="img" className="anticon">
        <BiRupee />
      </span>
    ),
    permission: ['accounts'],
    children: [
      {
        label: "Fee Clearance",
        key: "fee-clearance",
        route: ROUTE_FEE_CLEARANCE,
        permission: ['accounts'],
        // icon: <IconComponent icon={InactiveIcon} />,
      },
      {
        label: "Fee Clearance (Online)",
        key: "fee-clearance-online",
        route: ROUTE_FEE_CLEARANCE_ONLINE,
        permission: ['accounts'],
        // icon: <IconComponent icon={InactiveIcon} />,
      },
      {
        label: "Manage Refunds",
        key: "manage-refunds",
        route: ROUTE_REFUNDS_LIST,
        permission: ['accounts'],
        // icon: <IconComponent icon={InactiveIcon} />,
      },
      {
        label: "Bank Deposit",
        key: "bank-deposit",
        route: ROUTE_BANK_DEPOSIT,
        permission: ['accounts'],
        // icon: <IconComponent icon={InactiveIcon} />,
      },
      {
        label: "Daily Expenses",
        key: "daily-expenses",
        route: ROUTE_DAILY_EXPENSES,
        permission: ['accounts'],
        // icon: <IconComponent icon={InactiveIcon} />,
      },
      {
        label: "Education Income (Tally)",
        key: "education-income-tally",
        route: ROUTE_EDUCATION_INCOME_TALLY,
        permission: ['accounts'],
        // icon: <IconComponent icon={InactiveIcon} />,
      },
      {
        label: "Education Income Online (Tally)",
        key: "education-income-online-tally",
        route: ROUTE_EDUCATION_INCOME_TALLY_ONLINE,
        permission: ['accounts'],
        // icon: <IconComponent icon={InactiveIcon} />,
      },
      {
        label: "Education Debit (Tally)",
        key: "education-debit-tally",
        route: ROUTE_EDUCATION_DEBIT_TALLY,
        permission: ['accounts'],
        // icon: <IconComponent icon={InactiveIcon} />,
      },
      {
        label: "Publication Income (Tally)",
        key: "publication-income-tally",
        route: ROUTE_PUBLICATION_INCOME_TALLY,
        permission: ['accounts'],
        // icon: <IconComponent icon={InactiveIcon} />,
      },
      {
        label: "Publication Debit (Tally)",
        key: "publication-debit-tally",
        route: ROUTE_PUBLICATION_DEBIT_TALLY,
        permission: ['accounts'],
        // icon: <IconComponent icon={InactiveIcon} />,
      },
      {
        label: "Pending Dues",
        key: "manage-dues",
        route: ROUTE_INSTALMENTS_LIST,
        permission: ['accounts'],
        // icon: <IconComponent icon={InactiveIcon} />,
      },
      {
        label: "Other Payments",
        key: "other-payments",
        route: ROUTE_ONLINE_ENROLMENTS_LIST,
        permission: ['accounts'],
        // icon: <IconComponent icon={InactiveIcon} />,
      },
    ],
  },
  // {
  //   label: "HR",
  //   key: "hr",
  //   route: 'hr',
  //   icon: (
  //     <span role="img" className="anticon">
  //       <RiFileUserLine />
  //     </span>
  //   ),
  //   children: [],
  // },
  {
    label: "Website",
    key: "website",
    route: 'website',
    icon: (
      <span role="img" className="anticon">
        <CgWebsite />
      </span>
    ),
    permission: ['webmaster', 'live-coordinator'],
    children: [
      {
        label: "File Manager",
        key: "file-manager",
        route: ROUTE_MANAGE_FILES,
        permission: ['webmaster'],
      },
      {
        label: "Banners",
        key: "banner",
        route: ROUTE_MANAGE_HOME_BANNERS,
        permission: ['webmaster'],
      },
      {
        label: "Products",
        key: "products",
        route: ROUTE_MANAGE_PRODUCTS,
        permission: ['webmaster'],
      },
      {
        label: "Course Category",
        key: "course-category",
        route: ROUTE_MANAGE_COURSE_EXAMS('course-category'),
        permission: ['webmaster'],
      },
      {
        label: "Courses",
        key: "courses-wb",
        route: ROUTE_MANAGE_COURSE_EXAMS('course'),
        permission: ['webmaster'],
      },
      // {
      //   label: "Test Series Series",
      //   key: "test-wb",
      //   route: ROUTE_MANAGE_COURSE_EXAMS('test-series'),
      //   permission: ['webmaster'],
      // },

      {
        label: "Exam Category",
        key: "exam-cat",
        route: ROUTE_MANAGE_COURSE_EXAMS('exam-category'),
        permission: ['webmaster'],
      },
      {
        label: "Exams",
        key: "exams",
        route: ROUTE_MANAGE_COURSE_EXAMS('exam'),
        permission: ['webmaster', 'live-coordinator'],
      },
      {
        label: "Sub Exams",
        key: "sub-exam",
        route: ROUTE_MANAGE_COURSE_EXAMS('sub-exam'),
        permission: ['webmaster', 'live-coordinator'],
      },
      {
        label: "Centers",
        key: "centers",
        route: ROUTE_MANAGE_COURSE_EXAMS('centers'),
        permission: ['webmaster'],
      },
      {
        label: "Content Category",
        key: "content-category",
        route: ROUTE_MANAGE_COURSE_EXAMS('content-category'),
        permission: ['webmaster'],
      },
      {
        label: "Content Sub Category",
        key: "course-content-sub-category",
        route: ROUTE_MANAGE_COURSE_EXAMS('content-sub-category'),
        permission: ['webmaster'],
      },

      {
        label: "Tags",
        key: "tags",
        route: ROUTE_MANAGE_TAGS,
        permission: ['webmaster'],
      },
    ],
  },
  {
    label: "Learning Material",
    key: "l-m-s",
    icon: (
      <span role="img" className="anticon">
        <RiUserReceivedLine />
      </span>
    ),
    permission: ['live-coordinator', 'webmaster'],
    // route: "",
    children: [
      {
        label: "Quiz",
        key: "quiz",
        route: ROUTE_MANAGE_QUIZ,
        permission: ['live-coordinator'],
      },
      {
        label: "Live Classes",
        key: "class",
        route: ROUTE_MANAGE_CLASS,
        permission: ['live-coordinator'],
      },
      {
        label: "Concept Videos",
        key: "topics",
        route: ROUTE_MANAGE_TOPIC("concept-videos"),
        permission: ['live-coordinator'],
      },

      {
        label: "Assignment Videos",
        key: "topics",
        route: ROUTE_MANAGE_TOPIC("assignment_videos"),
        permission: ['live-coordinator'],
      },
      {
        label: "Content Items",
        key: "content",
        route: ROUTE_MANAGE_COURSE_EXAMS('content-item'),
        permission: ['webmaster', 'live-coordinator'],
      },
      {
        label: "Gallery",
        key: "gallery",
        route: ROUTE_MANAGE_GALLERY,
        permission: ['webmaster'],
      },
      {
        label: "Testimonials",
        key: "testimonials",
        route: ROUTE_MANAGE_TESTIMONIALS,
        permission: ['webmaster'],
      },
      // ,

      // {
      //   label: "Class Annotation Pdf",
      //   key: "topics",
      //   route: ROUTE_MANAGE_TOPIC("class_annotation_pdf"),
      //   permission: ['webmaster'],
      // }

      // , {
      //   label: "Class Test",
      //   key: "topics",
      //   route: ROUTE_MANAGE_TOPIC("class_test"),
      //   permission: ['webmaster'],
      // }
      // , {
      //   label: "Practice Test",
      //   key: "topics",
      //   route: ROUTE_MANAGE_TOPIC("practice_test"),
      //   permission: ['webmaster'],
      // }
    ],
  },
];

const MenuComponent: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state.auth);
  console.log(user)
  const role = user.user_role;
  return (
    <>
      <Menu
        onClick={(e: any) => {
          if (e.key !== "") {
            navigate(e.key);
          }
        }}
        defaultOpenKeys={[MENU_ITEMS[0].label]}
        mode="horizontal"
        theme="dark"
        itemIcon={true}
        expandIcon={<MenuOutlined />}
        overflowedIndicator={<MenuOutlined />}
      >
        {MENU_ITEMS.map((menuGroup: any) => {
          if ([...(menuGroup?.permission || []), 'admin'].indexOf(role) !== -1) {
            if (menuGroup?.children?.length === 0) {
              return (
                <Menu.Item
                  key={menuGroup.route}
                  icon={menuGroup.icon}
                  title={menuGroup.label}
                >
                  {menuGroup.label}
                </Menu.Item>
              );
            }

            else {
              return (
                <SubMenu
                  key={menuGroup.label}
                  icon={menuGroup.icon}
                  title={menuGroup.label}
                >
                  {menuGroup.children &&
                    menuGroup.children.map((menuItem: any) => {
                      if ([...(menuItem?.permission || []), 'admin'].indexOf(role) !== -1) {
                        return (
                          <Menu.Item icon={menuItem.icon} key={menuItem.route}>
                            {menuItem.label}
                          </Menu.Item>
                        );
                      }

                    })}
                </SubMenu>
              );
            }
          }
        })}
      </Menu>
      {/* </Sider> */}
    </>
  );
};

export default MenuComponent;
