import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  API_CREATE_MASTER_DATA,
  API_GET_BATCH_AND_FEE_CLASSROOM,
  API_GET_BATCH_RELATION,
  API_GET_BATCH_TYPE_RELATION,
  API_GET_CENTER_LIST,
  API_GET_COURSES,
  API_GET_COURSES_BY_CENTER_RELATION,
  API_GET_COURSE_RELATIONSHIP_BY_CENTER_COURSE_ID,
  API_GET_EXAMS_RELATION,
  API_GET_FEE_STRUCTURE_RELATION,
  API_GET_MASTER_DATA,
  API_GET_MASTER_DROPDOWN,
  API_GET_STREAMS_RELATION,
  API_GET_SUBJECT_BY_EXAM,
  API_GET_TOPIC_BY_SUBJECT,
  API_GET_VENUE_RELATION,
  API_UPDATE_MASTER_DATA,
} from "../../../shared/routes/masters.routes";
import { Client } from "../../api-client";
const config = {
  name: "masters",
};

export const getCourseRelationByCenterCourse: any = createAsyncThunk(
  `${config.name}/getCourseRelationByCenterCourse`,
  async (params: any) => {
    return Client.get(API_GET_COURSE_RELATIONSHIP_BY_CENTER_COURSE_ID, {
      params,
    });
  }
);
export const getCenterList: any = createAsyncThunk(
  `${config.name}/getCenterList`,
  async () => {
    return Client.get(API_GET_CENTER_LIST);
  }
);
export const getCourseListByCenter: any = createAsyncThunk(
  `${config.name}/getCourseListByCenter`,
  async (params: any) => {
    return Client.get(API_GET_COURSES_BY_CENTER_RELATION, { params });
  }
);
export const getCourseList: any = createAsyncThunk(
  `${config.name}/getCourseList`,
  async (params: any) => {
    return Client.get(API_GET_COURSES, { params });
  }
);

export const getBatchType: any = createAsyncThunk(
  `${config.name}/getBatchType`,
  async (params: any) => {
    return Client.get(API_GET_BATCH_TYPE_RELATION, { params });
  }
);

export const getExams: any = createAsyncThunk(
  `${config.name}/getExams`,
  async (params: any) => {
    return Client.get(API_GET_EXAMS_RELATION, { params });
  }
);

export const getFeeStructure: any = createAsyncThunk(
  `${config.name}/getFeeStructure`,
  async (params: any) => {
    return Client.get(API_GET_FEE_STRUCTURE_RELATION, { params });
  }
);
export const getBatchAndFeeClassroom: any = createAsyncThunk(
  `${config.name}/getBatchAndFeeClassroom`,
  async (batch_id: any) => {
    return Client.get(API_GET_BATCH_AND_FEE_CLASSROOM(batch_id));
  }
);
export const getStreams: any = createAsyncThunk(
  `${config.name}/getStreams`,
  async (params: any) => {
    return Client.get(API_GET_STREAMS_RELATION, { params });
  }
);
export const getVenues: any = createAsyncThunk(
  `${config.name}/getVenues`,
  async (params: any) => {
    return Client.get(API_GET_VENUE_RELATION, { params });
  }
);

export const getBatch: any = createAsyncThunk(
  `${config.name}/getBatch`,
  async (params: any) => {
    return Client.get(API_GET_BATCH_RELATION, { params });
  }
);

export const getMasterDataList: any = createAsyncThunk(
  `${config.name}/getMasterDataList`,
  async ({ path, params, body }: any) => {
    return Client.post(API_GET_MASTER_DATA(path), body, { params });
  }
);
export const createMasterData: any = createAsyncThunk(
  `${config.name}/createMasterData`,
  async ({ path, body }: any) => {
    return Client.post(API_CREATE_MASTER_DATA(path), body);
  }
);
export const updateMasterData: any = createAsyncThunk(
  `${config.name}/updateMasterData`,
  async ({ path, body }: any) => {
    return Client.put(API_UPDATE_MASTER_DATA(path, body._id), body);
  }
);

export const getMasterDropdownData: any = createAsyncThunk(
  `${config.name}/getMasterDropdownData`,
  async ({ schemaKey, labelKey }: any) => {
    return await Client.get(API_GET_MASTER_DROPDOWN(schemaKey), {
      params: { key: labelKey },
    });
  }
);
export const getSubjectByExam: any = createAsyncThunk(
  `${config.name}/getSubjectByExam`,
  async (exam_id: any) => {
    return await Client.get(API_GET_SUBJECT_BY_EXAM, {
      params: { exam_id: exam_id },
    });
  }
);
export const getTopicBySubject: any = createAsyncThunk(
  `${config.name}/getTopicBySubject`,
  async (subject_id: any) => {
    return await Client.get(API_GET_TOPIC_BY_SUBJECT, {
      params: { subject_id: subject_id },
    });
  }
);

export const masterReducer = createSlice({
  name: config.name,
  initialState: {
    centers: [] as any,
    total: 0 as number,
    filters: {} as any,
    loader: false,
    courses: [] as any,
    companies: [] as any,
    masterData: [] as any,
    batchType: [] as any,
    exams: [] as any,
    streams: [] as any,
    venues: [] as any,
    batch: [] as any,
    feeStructure: [] as any,
    subjects: [] as any,
    topics: [] as any,
  },
  reducers: {
    clearMasters: (state) => {
      state.centers = [];
      state.total = 0;
      state.topics = [];
      state.subjects = [];
      state.feeStructure = [];
      state.batch = [];
      state.venues = [];
      state.streams = [];
      state.exams = [];
      state.batchType = [];
      state.companies = [];
      state.courses = [];
      state.filters = {};
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCenterList.pending, (state) => {
        state.loader = true;
      })
      .addCase(getCenterList.fulfilled, (state, action) => {
        state.loader = false;
        state.centers = action?.payload?.data?.data;
      })
      .addCase(getCenterList.rejected, (state) => {
        state.loader = false;
        state.total = 0;
      });

    builder
      .addCase(getCourseListByCenter.pending, (state) => {
        state.loader = true;
      })
      .addCase(getCourseListByCenter.fulfilled, (state, action) => {
        state.loader = false;
        state.courses = action?.payload?.data?.data;
      })
      .addCase(getCourseListByCenter.rejected, (state) => {
        state.loader = false;
        state.total = 0;
      });

    builder
      .addCase(getMasterDataList.pending, (state) => {
        state.loader = true;
      })
      .addCase(getMasterDataList.fulfilled, (state, action) => {
        state.loader = false;
        state.companies = action?.payload?.data?.data;
        state.masterData = action?.payload?.data?.data;
      })
      .addCase(getMasterDataList.rejected, (state) => {
        state.loader = false;
        state.total = 0;
      });

    builder
      .addCase(getMasterDropdownData.pending, (state) => {
        state.loader = true;
      })
      .addCase(getMasterDropdownData.fulfilled, (state, action) => {
        state.loader = false;
        state.masterData = action?.payload?.data?.data;
      })
      .addCase(getMasterDropdownData.rejected, (state) => {
        state.loader = false;
        state.total = 0;
      });

    builder
      .addCase(getBatchType.pending, (state) => {
        state.loader = true;
      })
      .addCase(getBatchType.fulfilled, (state, action) => {
        state.loader = false;
        state.batchType = action?.payload?.data?.data;
      })
      .addCase(getBatchType.rejected, (state) => {
        state.loader = false;
        state.total = 0;
      });

    builder
      .addCase(getExams.pending, (state) => {
        state.loader = true;
      })
      .addCase(getExams.fulfilled, (state, action) => {
        state.loader = false;
        state.exams = action?.payload?.data?.data;
      })
      .addCase(getExams.rejected, (state) => {
        state.loader = false;
        state.total = 0;
      });

    builder
      .addCase(getStreams.pending, (state) => {
        state.loader = true;
      })
      .addCase(getStreams.fulfilled, (state, action) => {
        state.loader = false;
        state.streams = action?.payload?.data?.data;
      })
      .addCase(getStreams.rejected, (state) => {
        state.loader = false;
        state.total = 0;
      });

    builder
      .addCase(getVenues.pending, (state) => {
        state.loader = true;
      })
      .addCase(getVenues.fulfilled, (state, action) => {
        state.loader = false;
        state.venues = action?.payload?.data?.data;
      })
      .addCase(getVenues.rejected, (state) => {
        state.loader = false;
        state.total = 0;
      });

    builder
      .addCase(getBatch.pending, (state) => {
        state.loader = true;
      })
      .addCase(getBatch.fulfilled, (state, action) => {
        state.loader = false;
        state.batch = action?.payload?.data?.data;
      })
      .addCase(getBatch.rejected, (state) => {
        state.loader = false;
        state.total = 0;
      });

    builder
      .addCase(getFeeStructure.pending, (state) => {
        state.loader = true;
      })
      .addCase(getFeeStructure.fulfilled, (state, action) => {
        state.loader = false;
        state.feeStructure = action?.payload?.data?.data;
      })
      .addCase(getFeeStructure.rejected, (state) => {
        state.loader = false;
        state.total = 0;
      });

    builder
      .addCase(getSubjectByExam.pending, (state) => {
        state.loader = true;
      })
      .addCase(getSubjectByExam.fulfilled, (state, action) => {
        state.loader = false;
        state.subjects = action?.payload?.data?.data;
      })
      .addCase(getSubjectByExam.rejected, (state) => {
        state.loader = false;
        state.total = 0;
      });

    builder
      .addCase(getTopicBySubject.pending, (state) => {
        state.loader = true;
      })
      .addCase(getTopicBySubject.fulfilled, (state, action) => {
        state.loader = false;
        state.topics = action?.payload?.data?.data;
      })
      .addCase(getTopicBySubject.rejected, (state) => {
        state.loader = false;
        state.total = 0;
      });
  },
});

export const { clearMasters } = masterReducer.actions;
export default masterReducer.reducer;
